<template src="./previousNameOptions.html"></template>
<style lang="scss">
@import "./previousNameOptions.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import moment from "moment";
export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    datePicker: DatePicker,
    numerictextbox: NumericTextBox,
  },
  props: {
    value: {
      type: Object,
      default: "",
    },
    index: {
      type: Number,
      default: null,
    },
    errormsg: {
      type: Array,
      default: null,
    },
    onSync: {
      type: Function,
      default: () => { },
    },
    lengthOfOptions: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      previous_asx_code: "",
      previous_company_name: "",
      previous_company_date_of_change: "",
      payload: "",
    };
  },
  mounted() {
    this.previous_asx_code = this.value.previous_asx_code;
    this.previous_company_name = this.value.previous_company_name;
    this.previous_company_date_of_change = this.value.previous_company_date_of_change ? this.value.previous_company_date_of_change : null;
  },
  computed: {},
  methods: {
    checkIfIndexPresent() {
      if (this.index !== null) {
        return true;
      } else {
        return false;
      }
    },
    inputHandler(event) {
      // this.errormsg[this.index].previous_asx_code = false;
      // this.errormsg[this.index].previous_company_name = false;

      this.payload = {
        previous_asx_code: this.previous_asx_code,
        previous_company_name: this.previous_company_name,
        previous_company_date_of_change: this.previous_company_date_of_change,
      };
      this.onSyncHandler();
    },
    onSyncHandler() {
      if (this.checkIfIndexPresent() != false) {
        this.onSync(this.payload, this.index);
      } else {
        this.onSync(this.payload);
      }
    },
    onDateChange(event) {
      if (event.target.value) {
        // const timezoneOffset = moment().utcOffset();
        // this.previous_company_date_of_change = moment(event.target.value).add(timezoneOffset, 'minutes');
        this.previous_company_date_of_change = event.target.value;
        // this.previous_company_date_of_change = moment(event.target.value).format("DD-MM-YYYY");
      }else{
        this.previous_company_date_of_change = null;
      }
      this.inputHandler();

    },
    QuantityCloseHandler(e) {
      this.$emit("QuantityCloseHandler", e)
    }
  },
};
</script>

<style></style>
