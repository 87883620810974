<AppGlobalLoader :loaderColor="'solid'" :isFullpage="true" v-if="isLoadingDashBoard || isLoadingDashColumnData" />
<AppSnackBar :message="isDashboardErrorNotify.message" :notificationType="isDashboardErrorNotify.type"
  v-if="isDashboardErrorNotify.isNotify">
</AppSnackBar>
<div class="dashboard-header d-flex flex-wrap justify-content-between">
  <div class="d-flex left py-2">
    <k-button v-if="loggedInUserData?.role?.alias == 'admin'" :theme-color="'primary'" :fill-mode="'outline'"
      :class="'me-2'" @click="goToCreateCompany">New Company</k-button>

    <div class="label-with-dropdown-wrap me-2">
      <label :class="isDropdeoOpen ? 'dropdown-append-label active':'dropdown-append-label'"
        for="ddw">{{this.selectedView.name == "sector_view" ? 'Sector:' : 'Commodity:'}}</label>
      <dropdownlist id="ddw" :popup-settings="
      popupSettings" :value="defaultItemSector" :data-items="sectorDropdownData" @focus="onFocusSector"
        @open="onOpenCDD('commodity')" @close="onCloseCDD('commodity')" :text-field="'name'"
        :class="this.selectedView.name == 'sector_view' ? 'h-32 pl-45' : 'h-32'" @filterchange="filterChangeSector"
        :data-item-key="'id'" :filterable="true" @change="handleSectorDropdown">
      </dropdownlist>
    </div>

    <projectFilterPopupTemplate v-if="this.selectedView.name == 'sector_view' && defaultItemSector?.name=='Any Sector'"
      :projectFilter="projectFilter" @projectFilterEmit="projectFilterEmit" />


    <div v-if="this.selectedView.name == 'project_view'" class="d-flex align-items-center gap-3 ms-2 me-3 ps-1">
      <label v-for="radio in reportRadioOne" :key="radio.key" class="radio-container last-of-type:mb-0">{{ radio.label
        }}
        <input @change="handleRadioChange" type="radio" :id="radio.key" :value="radio.label" :name="'radioGroup'"
          v-model="radio_filter" />
        <span class="radiomark"></span>
      </label>
    </div>
    <div class="me-2">      
      <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'bottom'"
            :open="tooltipStates.tooltipLithiumType">
        <div ref="secondElement" @mouseenter="selectedView.name == 'project_view' && radio_filter == 'Any' && showTooltip('lithium_type')" title=" "
         @mouseleave="hideTooltip('lithium_type')" :class="`label-with-dropdown-wrap type-dropdown ${radio_filter == 'Any' ? 'type-dropdown-filter':''}`" v-if="selectedView.name == 'project_view' && defaultItemSector?.name  == 'Lithium'">
          <label :disabled="radio_filter == 'Any'" :class="isLithiumTypeDropdownOpen ? 'dropdown-append-label active':'dropdown-append-label'"
            for="abc">Type:</label>
          <dropdownlist id="abc" :popup-settings="popupSettings" :disabled="radio_filter == 'Any'" :value="defaultLithiumType" :data-items="filteredLithiumTypeData"
            @open="onOpenCDD('lithium')" @close="onCloseCDD('lithium')" :text-field="'name'"
            :class="this.selectedView.name == 'sector_view' ? 'h-32 pl-45' : 'h-32'" @filterchange="filterChangeLithiumType"
            :data-item-key="'id'" :filterable="true" @change="handleLithiumTypeChange">
          </dropdownlist>
        </div>
        <template v-slot:myTooltipTemplate="{ props }">
          <div class="tooltip-content text-center">Set as 'Primary' commodity to<br/> change selection</div>
        </template>
      </Tooltip>
    </div>


    <k-button @click="resetFilters" :theme-color="'primary'" :fill-mode="'outline'"
      :class="'me-2 color-border-black focus-shadow-none'">Reset Filters</k-button>
    <div v-if="this.selectedView.name == 'sector_view'" class="d-flex align-items-center">
      <span
        class="d-inline-block h-22 primary-color font-12 line-h-1_2 border-1-orange p-1 radius-4 me-2">Delayed</span>
      <p class="font-13 line-h-1_4 mb-0">
        ASX data is delayed by at least 20 minutes
      </p>
    </div>
  </div>
  <div class="d-flex right py-2">
    <span class="font-16 color-gray-600 d-flex align-items-center me-2">Showing
      <span class="base-color px-1">{{totalCompaniesCount}}</span>
      results</span>
    <k-button :theme-color="'primary'"
      v-if="selectedView?.name!='sector_view' && loggedInUserData?.role?.alias == 'admin' && defaultItemSector.alias == 'all_commodities'"
      :fill-mode="'outline'" :class="'ms-2 me-2 color-border-black focus-shadow-none'" :icon="'download'"
      @click="downloadProjectFile('csv')">Project Download</k-button>

    <k-button v-else :theme-color="'primary'"
      v-if="loggedInUserData?.role?.alias == 'admin' && defaultItemSector.alias == 'all_commodities'"
      :fill-mode="'outline'" :class="'ms-2 me-2 color-border-black focus-shadow-none'" :icon="'download'"
      @click="downloadFile('csv')">CSV</k-button>
    <div
      :title="loggedInUserData.is_trial_user == 1 ? 'Watchlist download is not available in Free Trial' : 'Download watchlist in TXT format'">
      <k-button v-if="selectedView?.name=='sector_view'" @click="downloadFile('txt')" :theme-color="'primary'"
        :disabled="loggedInUserData.is_trial_user == 1" :fill-mode="'outline'"
        :class="'ms-2 me-2 color-border-black focus-shadow-none'" :icon="'download'">Watchlist</k-button>
    </div>
    <k-button @click="showColumnsDrawer" id="buttonEl" :theme-color="'primary'" :fill-mode="'outline'"
      :class="'me-2 color-border-black focus-shadow-none'" :icon="'filter'">Columns</k-button>
    <!-- <dropdownlist :style="{ width: '230px' }" :value="selectedView" :data-items="viewTypes" :text-field="'title'"
      :class="'h-32'" :data-item-key="'id'" @change="onChangeView">
    </dropdownlist> -->
  </div>
</div>

<div id="searchBox" class="columns-drawer bg-white" :class="isshowColumnsDrawer? 'show':''">
  <div class="d-flex justify-content-between align-items-center px-20 py-16 border-bottom-1-gray">
    <h4 class="font-16 line-h-1 font-weight-700">Columns</h4>
    <k-button @click="showColumnsDrawer" :fillmode="'clear'" :class="'p-0 transparent'">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.25 6.01953L11.7695 10.5L16.25 14.9805L14.4805 16.75L10 12.2695L5.51953 16.75L3.75 14.9805L8.23047 10.5L3.75 6.01953L5.51953 4.25L10 8.73047L14.4805 4.25L16.25 6.01953Z"
          fill="#6C757D" />
      </svg>
    </k-button>
  </div>

  <div class="columns-drawer-content py-15 px-20">
    <div class="" v-for="(sector, index) in columnsArrayForFilter">
      <k-checkbox @change="handleColumnParentCeckbox($event,sector)" v-model="sector.is_selected"
        :disabled="sector.is_disabled" :class="'color-primary checkbox-label-font-15 d-block mb-2'"
        :label="sector.name" />
      <div v-if="sector.child" v-for="(sectorChild, indexChild) in sector?.child" class="ps-3">
        <k-checkbox @change="handleColumnChildCeckbox($event,sector)" v-model="sectorChild.is_selected"
          :disabled="sectorChild.is_disabled" :class="'color-primary checkbox-label-font-15 d-block mb-2'"
          :label="sectorChild.name" />
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center px-16 py-12 border-top-1-gray">
    <k-button @click="resetColumn" :theme-color="'primary'" :fill-mode="'outline'"
      :class="'font-16 w-100 me-1'">Reset</k-button>
    <k-button @click="applyColumn" :theme-color="'primary'" :class="'font-16 w-100 ms-1'">Apply</k-button>
  </div>
</div>

<div v-if="headerCellsArray?.length > 0 && selectedCommoditiesData" class="dashboard-table-area"
  :class="defaultItemSector.alias=='lithium'? selectedView.name == 'project_view'?'lithiumActive lithiumProjectActive' : 'lithiumActive' : ''">
  <Grid v-if="!isLoadingGrid" ref="gridRef" :resizable="false" :class="'dashboard-grid'"
    :data-items="selectedCommoditiesData" :groupable="true" :group="group" :columns="columnsArrayForGrid"
    @scroll="scrollHandler">
    <template v-slot:companyTemplate="{ props }">
      <td class="k-table-td k-grid-content-sticky cursor-pointer"
        @click="selectedView.name == 'project_view' ? dashboardTableToProject(props?.dataItem) : onRowClick($event, props)">
        <div class="w-100 text-start">
          <p class="font-15 line-h-1_4 font-weight-500 base-color mb-0">
            {{selectedView.name == 'project_view' ? props.dataItem.project_name : props.dataItem.name}}
          </p>
          <!-- <p class="font-15 line-h-1_4 font-weight-500 base-color mb-0">
            {{props.dataItem.name}}
          </p> -->
          <p class="font-12 line-h-1_4 font-weight-400 gray-color-700 mb-0">
            {{props.dataItem.code}} {{ selectedView.name == 'project_view' ? ` - ${props.dataItem.name}` : ''}}
          </p>
        </div>
      </td>
    </template>

    <template v-for="(template, index) in templateCellsArray" v-slot:[template?.cell]="value">
      <td :class="template?.className">
        <div :class="!['trader_user'].includes(loggedInUserData?.role?.alias) ? 'cursor-pointer' : ''" style="height: 37px; line-height: 37px;" v-if="template.field=='project_name'"
          @mousedown="handleTableToProjectMouseDown"
          @mouseup="handleTableToProjectMouseUp($event, value?.props?.dataItem)">
          {{value?.props?.dataItem?.project_name}}</div>

        <span v-if="template.field=='volume' ||
          template.field=='turnover' ||
          template.field=='average_volume' ||
          template.field=='average_value'">
          {{value?.props?.dataItem?.[template?.field]?.value}}<span
            class="font-12">{{value?.props?.dataItem?.[template?.field]?.number_format}}</span>
        </span>

        <span :style="{filter: `${loggedInUserData.is_trial_user == 1 && template.blur ? 'blur(3px)' : ''} `}" v-if="template.field=='pct_change'|| template.field=='month' || template.field=='cf_net_change'
              || template.field=='week' || template.field=='year' || template.field=='ytd'"
          :class="value?.props?.dataItem?.[template?.field]?.is_positive ?  'text-success' : value?.props?.dataItem?.[template?.field]?.is_zero? '' : 'text-danger'">
          {{loggedInUserData.is_trial_user == 1 && template.blur ? "?$&%" :
          value?.props?.dataItem?.[template?.field]?.value}}
        </span>

        <span :style="{filter: `${loggedInUserData.is_trial_user == 1 && template.blur ? 'blur(3px)' : ''} `}"
          v-else-if="template.field !='pct_change' && 
          template.field !='month' && 
          template.field != 'cf_net_change' && 
          template.field != 'week' && 
          template.field !='year' && 
          template.field !='ytd' &&
          template.field != 'project_name' &&
          template.field != 'turnover' &&
          template.field != 'volume' &&
          template.field != 'average_volume' &&
          template.field != 'average_value' &&
          template.field != 'cash' &&
          template.field != 'debt'">
          {{loggedInUserData.is_trial_user == 1 && template?.blur ? (template.field=='country' ||
          template.field=='state' || template.field=='stage' || template.field=='status') ? "?????????" : "????" :
          (value?.props?.dataItem?.[template?.field]?.value != undefined)?
          value?.props?.dataItem?.[template?.field]?.value : value?.props?.dataItem?.[template?.field]}}<span
            class="font-12">{{value?.props?.dataItem?.[template?.field]?.number_format}} </span>
        </span>
        <span :style="{filter: `${loggedInUserData.is_trial_user == 1 && template.blur ? 'blur(3px)' : ''} `}" v-if="template.field =='cash' || 
          template.field =='debt'" :title="formatCashDebtDate(value?.props.dataItem.as_of_date)">
          {{loggedInUserData.is_trial_user == 1 && template?.blur ? (template.field=='country' ||
          template.field=='state' || template.field=='stage' || template.field=='status') ? "?????????" : "????" :
          (value?.props?.dataItem?.[template?.field]?.value != undefined)?
          value?.props?.dataItem?.[template?.field]?.value : value?.props?.dataItem?.[template?.field]}}<span
            class="font-12">{{value?.props?.dataItem?.[template?.field]?.number_format}} </span>
        </span>

      </td>
    </template>

    <template v-for="(cell, index) in headerCellsArray" v-slot:[cell?.headerCell]="value">
      <div>
        <div @click="clickEventhandler"
          v-if="cell?.headerCell == 'SearchTemplate' || cell?.headerCell == 'projectNameTemplate'" class="search-box">
          <span v-if="cell?.type_of_filter?.length > 1 && cell?.type_of_filter?.includes('sort')"
            class="company-name-shorting" :class="this.selectedView.name == 'sector_view' ? 'company-name-width' : ''">
            <span class="k-icon shorter-icon w-10" @click="columnSortHandler(index, cell)"
              :class="cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc ? 'k-i-sort-desc-sm' : !cell?.sort_value?.is_asc && cell?.sort_value?.is_desc ? ' k-i-sort-asc-sm' : ''"></span>
            <span @click="columnSortHandler(index, cell)">{{selectedViewTypeForFilter}}</span>
            <k-button v-if="this.selectedView.name == 'project_view'" v-click-outside="projectDropdownOutside"
              class="project-filter-toggle-button" ref="button" @click="projectFilterPopupToggle"
              :icon="'k-icon k-i-arrow-60-down'" :size="'small'" />
            <Popup :anchor="'button'" :show="projectFilterPopupShow" :popup-class="'popup-content'"
              :anchor-align="anchorAlignProperty" :popup-align="popupAlignProperty">
              <ul class="company-project-select list-unstyled">
                <li class="project-inner-dropdown cursor-pointer"
                  :class="selectedViewTypeForFilter == 'Company' ? 'selected' : ''" @click="handleInnerFilterItem">
                  Company</li>
                <li class="project-inner-dropdown cursor-pointer"
                  :class="selectedViewTypeForFilter == 'Project' ? 'selected' : ''" @click="handleInnerFilterItem">
                  Project</li>
              </ul>
            </Popup>
          </span>

          <k-input ref="companySearch"
            :placeholder="this.selectedView.name == 'project_view' ? selectedViewTypeForFilter == 'Company' ? 'Search by Company' : 'Search by Project' : 'Search'"
            :spellcheck="false" v-model="companyDebounce" @input="debounceSearch($event, cell)" />

          <span class="close-icon" v-if="companyDebounce?.length" @click="clearSearch($event,cell)"><span
              class="k-icon k-i-close"></span></span>
          <span class="icon">
            <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.7063 20H17.4156L21 23.5844L19.5844 25L16 21.4156V20.7063L15.1656 19.8719C14.3031 20.5781 13.2 21 12 21C9.2375 21 7 18.7625 7 16C7 13.2375 9.2375 11 12 11C14.7625 11 17 13.2375 17 16C17 17.2 16.5781 18.3031 15.8719 19.1656L16.7063 20ZM12 12C9.79063 12 8 13.7906 8 16C8 18.2094 9.79063 20 12 20C14.2094 20 16 18.2094 16 16C16 13.7906 14.2094 12 12 12Z"
                fill="#6C757D" />
            </svg>
          </span>
        </div>
        <div
          v-else-if="cell?.headerCell != 'SearchTemplate' && cell?.type_of_filter?.length == 1 && cell?.type_of_filter?.includes('sort')"
          class="sort-filter" :class="`${convertDocumentName(value?.props?.title)} double-line`">
          <span @click="columnSortHandler(index, cell)" class=""><span class="k-icon"
              :class="cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-desc-sm' : (!cell?.sort_value?.is_asc && cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-asc-sm' : (cell?.sort_value?.is_desc && !cell?.sort_value?.is_asc && cell.className === 'text-right' ? 'k-i-sort-desc-sm' : (cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-right' ? 'k-i-sort-asc-sm' : '')))"></span>{{value?.props?.title}}</span>
        </div>

        <div v-else-if="cell?.headerCell != 'SearchTemplate' && cell?.type_of_filter?.includes('filter')"
          class="header-filter">
          <span
            v-if="cell?.type_of_filter?.length == 1 && !cell?.type_of_filter?.includes('sort')">{{value?.props?.title}}</span>

          <span v-if="cell?.type_of_filter?.length > 1 && cell?.type_of_filter?.includes('sort')"
            @click="columnSortHandler(index, cell)" :class="`${convertDocumentName(value?.props?.title)} double-line`"><span class="k-icon"
              :class="cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-desc-sm' : (!cell?.sort_value?.is_asc && cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-asc-sm' : (cell?.sort_value?.is_desc && !cell?.sort_value?.is_asc && cell.className === 'text-right' ? 'k-i-sort-desc-sm' : (cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-right' ? 'k-i-sort-asc-sm' : '')))"></span>{{value?.props?.title}}</span>

          <k-button @click="filterTemplateHandler(index)" :disabled="loggedInUserData.is_trial_user == 1 && cell?.blur"
            :icon="(cell?.is_apply) ? 'filter-clear':'filter'"
            :class="(cell?.is_apply || cell?.is_filter_visible) ? 'filter-apply':''"
            :style="(cell?.is_apply) ?'background-color: #F5D2CB;':''" :ref="cell?.headerCell" :size="'small'" />

          <headerFilterPopupTemplate
            v-if="(cell?.field == 'volume' || cell?.field == 'turnover' || cell?.field == 'average_volume' || cell?.field == 'shares_on_issue' || cell?.field == 'market_cap' || cell?.field == 'cash' || cell?.field == 'debt' || cell?.field == 'enterprise_value' || cell?.field == 'net_cash' || cell?.field == 'average_value') && cell?.is_filter_visible"
            :cellData="cell" id="filter" :index="index" :onCancel="onCancelFilter" :key="index"
            :anchor="cell?.headerCell" @filterEmitFunc="filterEmitFunc" :isShowFilter="cell.is_filter_visible"
            :enableDragScroll="enableDragScroll">
          </headerFilterPopupTemplate>

          <headerFilterPopupTemplateTwo
            v-if="(cell?.field != 'volume' && cell?.field != 'turnover' && cell?.field != 'average_volume' && cell?.field != 'shares_on_issue' && cell?.field != 'market_cap' && cell?.field != 'cash' && cell?.field != 'debt' && cell?.field != 'enterprise_value' && cell?.field != 'net_cash' && cell?.field != 'average_value') && cell?.is_filter_visible"
            :cellData="cell" id="filter" :index="index" :onCancel="onCancelFilter" :key="index"
            :anchor="cell?.headerCell" @filterEmitFunc="filterEmitFunc" :isShowFilter="cell.is_filter_visible"
            :enableDragScroll="enableDragScroll">
          </headerFilterPopupTemplateTwo>
        </div>

        <div
          v-else-if="cell?.headerCell != 'SearchTemplate' && cell?.type_of_filter?.length >0 && cell?.type_of_filter?.includes('dropdown')"
          class="header-filter">
          <span v-if="cell?.type_of_filter?.includes('sort')" @click="columnSortHandler(index, cell)" :class="`${convertDocumentName(value?.props?.title)} double-line`">
            <span class="k-icon"
              :class="cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-desc-sm' : (!cell?.sort_value?.is_asc && cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-asc-sm' : (cell?.sort_value?.is_desc && !cell?.sort_value?.is_asc && cell.className === 'text-right' ? 'k-i-sort-desc-sm' : (cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-right' ? 'k-i-sort-asc-sm' : '')))"></span>
            <span class="">{{value?.props?.title}}</span>
          </span>
          <span v-if="!cell?.type_of_filter?.includes('sort')">{{value?.props?.title}}</span>

          <k-button @click="filterTemplateHandler(index)" :ref="cell?.headerCell" :size="'small'"
            :disabled="loggedInUserData.is_trial_user == 1 && cell?.blur"
            :class="isDropDownFilterShowHide[cell.field] ? 'search-apply':''"
            :icon="isDropDownFilterShowHide[cell.field] ? 'filter-clear':'k-icon k-i-arrow-60-down'"
            :style="isDropDownFilterShowHide[cell.field] ?'background-color: #F5D2CB;':''" />

          <headerDropDownFilterPopupTemplate
            v-if="cell?.field != 'country' && cell?.field != 'state' && cell?.field != 'stage' && cell?.is_filter_visible"
            :cellData="cell" id="filter" :index="index" :onCancel="onCancelFilter" :key="index"
            :anchor="cell?.headerCell" @filterEmitFunc="filterEmitFunc" @dropDownFilterShowHide="dropDownFilterShowHide"
            :countryData="columnFilterObject" :sortFilterObject="sortFilterObject" :isCommodities="isCommodities"
            :isDisplayAll="checkedSwitch" @updateColumnFilterObject="updateColumnFilterObject"
            :commodities="defaultItemSector" :view="selectedView" :isShowFilter="cell.is_filter_visible">
          </headerDropDownFilterPopupTemplate>

          <headerDropDownFilterPopupTemplateForCountry
            v-if="(cell?.field == 'country' || cell?.field == 'state' || cell?.field == 'stage') && cell?.is_filter_visible"
            :cellData="cell" id="filter" :index="index" :onCancel="onCancelFilter" :key="index"
            :anchor="cell?.headerCell" @filterEmitFunc="filterEmitFunc" @dropDownFilterShowHide="dropDownFilterShowHide"
            :countryData="columnFilterObject" :sortFilterObject="sortFilterObject" :isCommodities="isCommodities"
            :isDisplayAll="checkedSwitch" @updateColumnFilterObject="updateColumnFilterObject"
            :commodities="defaultItemSector" :view="selectedView" :isShowFilter="cell.is_filter_visible">
          </headerDropDownFilterPopupTemplateForCountry>
        </div>

        <div class="t-header-search-btn-wrap header-filter"
          v-else-if="cell?.headerCell != 'SearchTemplate' && cell?.type_of_filter?.length > 0  && cell?.type_of_filter?.includes('search')">
          <span v-if="cell?.type_of_filter?.includes('sort')" @click="columnSortHandler(index, cell)" :class="`${convertDocumentName(value?.props?.title)} double-line`">
            <span class="k-icon"
              :class="cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-desc-sm' : (!cell?.sort_value?.is_asc && cell?.sort_value?.is_desc && cell.className === 'text-left' ? 'k-i-sort-asc-sm' : (cell?.sort_value?.is_desc && !cell?.sort_value?.is_asc && cell.className === 'text-right' ? 'k-i-sort-desc-sm' : (cell?.sort_value?.is_asc && !cell?.sort_value?.is_desc && cell.className === 'text-right' ? 'k-i-sort-asc-sm' : '')))"></span>
            <span>{{value?.props?.title}}</span>
          </span>
          <span v-if="!cell?.type_of_filter?.includes('sort')">{{value?.props?.title}}</span>

          <k-button @click="filterTemplateHandler(index)" :ref="cell?.headerCell" :size="'small'"
            :disabled="loggedInUserData.is_trial_user == 1 && cell?.blur"
            :class="(cell?.filter_value?.to > 0 || cell?.filter_value?.from > 0 || cell?.is_filter_visible || cell?.search_value!='') ? 'search-apply':''"
            :icon="(cell?.filter_value?.to > 0 || cell?.filter_value?.from > 0|| cell?.search_value!='') ? 'filter-clear':'k-icon k-i-zoom'"
            :style="(cell?.filter_value?.to > 0 || cell?.filter_value?.from > 0|| cell?.search_value!='') ?'background-color: #F5D2CB;':''" />
          <headerSearchPopupTemplate ref="searchTemplateRef" :cellData="cell" id="filter" :index="index"
            :onCancel="onCancelFilter" :key="index" :anchor="cell?.headerCell" @filterEmitFunc="filterEmitFunc"
            :isShowFilter="cell.is_filter_visible">
          </headerSearchPopupTemplate>
        </div>

        <div class="d-flex gap-1" v-if="cell?.headerCell == 'main_project_information_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'" :open="tooltipStates.tooltipOne">
            <span class="k-icon k-font-icon k-i-info" title="a" ref="firstElement" @mouseenter="showTooltip('one')"
              @mouseleave="hideTooltip('one')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The company's main project within the nominated Sector</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_mineral_resource_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'" :open="tooltipStates.tooltipTwo">
            <span class="k-icon k-font-icon k-i-info" title="b" ref="secondElement" @mouseenter="showTooltip('two')"
              @mouseleave="hideTooltip('two')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Mineral Resource for all<br> company projects in the
                nominated Sector. The<br> attributable Mineral Resource is calculated using<br> the Current Ownership of
                each project or deposit.
              </div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_mineral_resource_hard_rock_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipMineralHardRock">
            <span class="k-icon k-font-icon k-i-info" title="group_mineral_resource_hard_rock_template"
              ref="secondElement" @mouseenter="showTooltip('mineral_hard_rock')"
              @mouseleave="hideTooltip('mineral_hard_rock')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Mineral Resource for all<br> company projects in the
                nominated Sector. The<br> attributable Mineral Resource is calculated using<br> the Current Ownership of
                each project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_mineral_resource_brine_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipMineralBrine">
            <span class="k-icon k-font-icon k-i-info" title="group_mineral_resource_brine_template" ref="secondElement"
              @mouseenter="showTooltip('mineral_brine')" @mouseleave="hideTooltip('mineral_brine')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Mineral Resource for all<br> company projects in the
                nominated Sector. The<br> attributable Mineral Resource is calculated using<br> the Current Ownership of
                each project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_mineral_resource_clay_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipMineralClay">
            <span class="k-icon k-font-icon k-i-info" title="group_mineral_resource_clay_template" ref="secondElement"
              @mouseenter="showTooltip('mineral_clay')" @mouseleave="hideTooltip('mineral_clay')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Mineral Resource for all<br> company projects in the
                nominated Sector. The<br> attributable Mineral Resource is calculated using<br> the Current Ownership of
                each project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_mineral_resource_total_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipMineralTotal">
            <span class="k-icon k-font-icon k-i-info" title="group_mineral_resource_total_template" ref="secondElement"
              @mouseenter="showTooltip('mineral_total')" @mouseleave="hideTooltip('mineral_total')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Mineral Resource for all<br> company projects in the
                nominated Sector. The<br> attributable Mineral Resource is calculated using<br> the Current Ownership of
                each project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_ore_resource_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipThree">
            <span class="k-icon k-font-icon k-i-info" title="c" ref="thirdElement" @mouseenter="showTooltip('three')"
              @mouseleave="hideTooltip('three')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Ore Reserve for all company<br> projects in the
                nominated Sector. The attributable<br> Ore reserve is calculated using the Current Ownership<br> of each
                project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_ore_resource_hard_rock_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipOreHardRock">
            <span class="k-icon k-font-icon k-i-info" title="group_ore_resource_hard_rock_template" ref="thirdElement"
              @mouseenter="showTooltip('ore_hard_rock')" @mouseleave="hideTooltip('ore_hard_rock')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Ore Reserve for all company<br> projects in the
                nominated Sector. The attributable<br> Ore reserve is calculated using the Current Ownership<br> of each
                project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_ore_resource_brine_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipOreBrine">
            <span class="k-icon k-font-icon k-i-info" title="group_ore_resource_brine_template" ref="thirdElement"
              @mouseenter="showTooltip('ore_brine')" @mouseleave="hideTooltip('ore_brine')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Ore Reserve for all company<br> projects in the
                nominated Sector. The attributable<br> Ore reserve is calculated using the Current Ownership<br> of each
                project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_ore_resource_clay_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'" :open="tooltipStates.tooltipOreClay">
            <span class="k-icon k-font-icon k-i-info" title="group_ore_resource_clay_template" ref="thirdElement"
              @mouseenter="showTooltip('ore_clay')" @mouseleave="hideTooltip('ore_clay')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Ore Reserve for all company<br> projects in the
                nominated Sector. The attributable<br> Ore reserve is calculated using the Current Ownership<br> of each
                project or deposit.</div>
            </template>
          </Tooltip>
        </div>
        <div class="d-flex gap-1" v-if="cell?.headerCell == 'group_ore_resource_total_template'">
          <span>{{cell.title}}</span>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'"
            :open="tooltipStates.tooltipOreTotal">
            <span class="k-icon k-font-icon k-i-info" title="group_ore_resource_total_template" ref="thirdElement"
              @mouseenter="showTooltip('ore_total')" @mouseleave="hideTooltip('ore_total')"></span>
            <template v-slot:myTooltipTemplate="{ props }">
              <div class="tooltip-content">The total attributable Ore Reserve for all company<br> projects in the
                nominated Sector. The attributable<br> Ore reserve is calculated using the Current Ownership<br> of each
                project or deposit.</div>
            </template>
          </Tooltip>
        </div>
      </div>

    </template>
  </Grid>
</div>