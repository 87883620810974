<template src="./projectView.html"></template>
<style lang="scss">
@import "./projectView.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { getProjectView } from "@/services/newCompany.service";
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import deleteProject from "../../companyOverview/deleteProject/deleteProject.vue";
import router from "@/router";
import { configuration } from "@/configurationProvider";
import { dateFormatter, formatTwoOrThreeDecimal } from "@/helpers/utility";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import FsLightbox from "fslightbox-vue/v3";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import AppLightBox from "@/components/common/app-lightbox/AppLightBox.vue";
import axios from "axios";
export default {
  components: {
    kbutton: Button,
    Grid: Grid,
    AppGlobalLoader,
    "k-input": Input,
    DropDownButton,
    numerictextbox: NumericTextBox,
    deleteProject,
    AppSnackBar,
    FsLightbox,
    Tooltip,
    AppLightBox,
  },

  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    isLoadingCreateProjectUpdate() {
      return this.$store.state.newProject.tabisLoadingCreateUpdate;
    },
    notificationProject() {
      return this.$store.state.newProject.isCreateUpdateProjectNotify;
    },
    modifiedSector() {
      const isMarketMapPresent =
        this.localTableResource?.company?.market_map?.alias;

      if (!isMarketMapPresent) {
        return this.localTableResource?.company?.sector;
      }
      const modifiedSector = [...this.localTableResource?.company?.sector];
      const matchedIndex = modifiedSector.findIndex(
        (sector) =>
          sector.alias == this.localTableResource?.company?.market_map?.alias
      );

      if (matchedIndex != -1 && matchedIndex != 0) {
        const matchedObject = modifiedSector.splice(matchedIndex, 1)[0];
        modifiedSector.unshift(matchedObject);
      } else if (matchedIndex === -1) {
        modifiedSector.unshift(this.localTableResource?.company?.market_map);
      }

      return modifiedSector;
    },
  },
  data: function () {
    return {
      localTableResource: [],
      passStatuses: [{ id: 1, text: "Delete" }],
      deleteProjectId: 0,
      photoPathCompany: "",
      isLoadingLocal: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      isAdmin: false,
      toggler: false,
      multiple_table_last_deposit_row: 0,
      multiple_table_last_deposit_row_ore: 0,
      mineralImagePath: "",
      oreImagePath: "",
      mineralImageSize: 0,
      oreImageSize: 0,
      feasibility_field_resource: [],
      mineral_resource_spans: [],
      ore_resource_spans: [],
      rowspanCache: 1,
      res: [
        {
          id: 1,
          title: "Measured",
          tonnes: 1,
          au_gt: 2,
          au_koz: 3,
          cu: 4,
          ag_gt: 5,
          zn: 6,
          pb: 7,
          sb: null,
          co: null,
          wo3: null,
          deposit: null,
          deposit_percentage: "5",
          inEdit: true,
        },
        {
          id: 2,
          title: "Indicated",
          tonnes: 2,
          au_gt: 4,
          au_koz: 5,
          cu: null,
          ag_gt: null,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
          inEdit: true,
        },
        {
          id: 3,
          title: "Total",
          tonnes: 3,
          au_gt: 3.3333,
          au_koz: 8,
          cu: 1.3333,
          ag_gt: 1.6667,
          zn: 2,
          pb: 2.3333,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
        },
        {
          id: 4,
          title: "Indicated",
          tonnes: 55,
          au_gt: null,
          au_koz: 6,
          cu: 667,
          ag_gt: 5,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
          inEdit: true,
        },
        {
          id: 5,
          title: "Total",
          tonnes: 55,
          au_gt: null,
          au_koz: 662,
          cu: 667,
          ag_gt: 5,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
        },
        {
          id: 6,
          title: "Measured",
          tonnes: 5,
          au_gt: 5677,
          au_koz: 72.8475,
          cu: 8,
          ag_gt: 6,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit: null,
          deposit_percentage: "8",
          inEdit: true,
        },
        {
          id: 7,
          title: "Indicated",
          tonnes: 5,
          au_gt: 6,
          au_koz: 6,
          cu: 8,
          ag_gt: null,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
          inEdit: true,
        },
        {
          id: 8,
          title: "Total",
          tonnes: 10,
          au_gt: 2841.5,
          au_koz: 78.8475,
          cu: 8,
          ag_gt: 3,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
          inEdit: true,
        },
        {
          id: 9,
          title: "Measured",
          tonnes: 61,
          au_gt: 465.3607,
          au_koz: 81.8475,
          cu: 602.1148,
          ag_gt: 5.082,
          zn: 0.0984,
          pb: 0.1148,
          sb: null,
          co: null,
          wo3: null,
          deposit: null,
          deposit_percentage: null,
          inEdit: true,
        },
        {
          id: 10,
          title: "Indicated",
          tonnes: 7,
          au_gt: 5.4286,
          au_koz: 667,
          cu: 5.7143,
          ag_gt: null,
          zn: null,
          pb: null,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
        },
        {
          id: 11,
          title: "Total",
          tonnes: 68,
          au_gt: 418.0147,
          au_koz: 748.8475,
          cu: 540.7206,
          ag_gt: 4.5588,
          zn: 0.0882,
          pb: 0.1029,
          sb: null,
          co: null,
          wo3: null,
          deposit_percentage: null,
          inEdit: true,
        },
      ],
    };
  },
  created() { },
  mounted() {
    this.projectView();
  },
  methods: {
    onClickStudyType(item) {
      const payload = item?.pdf_details;

      if (!payload || !Object.keys(payload).length) {
        // this.$store.dispatch("toastifyNotification", {
        //   text: "You don't have any uploaded PDF.",
        //   duration: 3000,
        //   className: "error",
        // });
        return;
      }

      this.openStudyPdfFile(payload);
    },
    downloadStudyPdfFile(payload) {
      var token = localStorage.getItem("mnb_session");
      this.$store.dispatch("toastifyNotification", {
        text: "Your download is in progress!",
        duration: 3000,
        className: "success",
      });
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      instance
        .post(
          configuration.apiBaseUrl + `/api/v1/company/project/get-pdf`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${payload.image_name}`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.$store.dispatch("toastifyNotification", {
            text: "Failed to download the PDF.",
            duration: 3000,
            className: "error",
          })
        })
    },
    openStudyPdfFile(payload) {
      var token = localStorage.getItem("mnb_session");
      // this.$store.dispatch("toastifyNotification", {
      //   text: "Your PDF is being opened!",
      //   duration: 3000,
      //   className: "success",
      // });

      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      instance
        .post(
          configuration.apiBaseUrl + `/api/v1/company/project/get-pdf`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$store.dispatch("toastifyNotification", {
            text: "Failed to open the PDF.",
            duration: 3000,
            className: "error",
          });
        });
    },
    formatDecimalNumber(number) {
      // if (number == "-") {
      //   return number?.toString();
      // } else if (parseFloat(number) < 2) {
      //   const numberString = number.toString();
      //   const decimalIndex = numberString.indexOf(".");
      //   const len =
      //     decimalIndex !== -1 ? numberString.length - decimalIndex - 1 : null;
      //   if (len >= 3) {
      //     return "$" + number;
      //   }
      //   return "$" + parseFloat(number)?.toFixed(3);
      // } else if (parseFloat(number) >= 2) {
      //   return "$" + parseFloat(number)?.toFixed(2);
      // } else {
      //   return number;
      // }
      return formatTwoOrThreeDecimal(number)
    },
    projectView() {
      this.isLoadingLocal = true;
      let payload = {
        companyId: this.$route.params.id,
        projectId: this.$route.params.projectId,
      };
      getProjectView(payload)
        .then((response) => {
          this.localTableResource = response?.data?.result?.project;
          this.feasibility_field_resource =
            this.localTableResource?.feasibility_field_resource;
          this.tempPhotoPath =
            configuration.apiBaseUrl +
            "/" +
            this.localTableResource?.image_path;
          this.mineralImagePath =
            configuration.apiBaseUrl +
            "/" +
            this.localTableResource?.mineral_image_path;
          this.oreImagePath =
            configuration.apiBaseUrl +
            "/" +
            this.localTableResource?.ore_image_path;
          this.photoPathCompany = this.localTableResource?.company?.image_path
            ? configuration.apiBaseUrl +
            "/" +
            this.localTableResource?.company?.image_path
            : "";
          this.isLoadingLocal = false;
          if (
            this.localTableResource?.main_commodities?.alias != "kaolin" &&
            this.localTableResource?.main_commodities?.alias != "potash"
          ) {
            this.mineral_resource_spans = this.calculateSpan(
              this.localTableResource.multiple_deposit_mineral_resource.resource
            );
            this.ore_resource_spans = this.calculateSpan(
              this.localTableResource.multiple_deposit_ore_resource.resource
            );

            if (this.localTableResource?.deposit_type?.name == "Multiple") {
              this.multiple_table_last_deposit_row =
                this.localTableResource?.multiple_deposit_mineral_resource
                  ?.resource?.length > 0 &&
                this.calculateLastMineralRow(
                  this.localTableResource?.multiple_deposit_mineral_resource
                    ?.resource
                );

              // this.multiple_table_last_deposit_row_ore = this.localTableResource?.multiple_deposit_ore_resource?.resource[this.localTableResource?.multiple_deposit_ore_resource?.resource.length - 1].id - 2;
              this.multiple_table_last_deposit_row_ore =
                this.localTableResource?.multiple_deposit_ore_resource?.resource
                  ?.length > 0 &&
                this.calculateLastOreRow(
                  this.localTableResource?.multiple_deposit_ore_resource
                    ?.resource
                );
            }

            if (
              this.localTableResource?.deposit_type?.name == "Single" &&
              this.localTableResource?.project_name == "Sigatoka"
            ) {
              if (
                this.localTableResource?.mineral_resource.resource[0]?.title ==
                "Measured"
              ) {
                this.localTableResource.mineral_resource.resource[0].title =
                  "Unclassified";
              }
            }
            if (
              this.localTableResource?.deposit_type?.name == "Multiple" &&
              this.localTableResource?.project_name == "Sigatoka"
            ) {
              for (
                let i = 0;
                i <
                this.localTableResource?.multiple_deposit_mineral_resource
                  ?.resource.length;
                i++
              ) {
                if (
                  this.localTableResource?.multiple_deposit_mineral_resource
                    ?.resource[i]?.title == "Measured"
                ) {
                  this.localTableResource.multiple_deposit_mineral_resource.resource[
                    i
                  ].title = "Unclassified";
                }
              }
            }
          }
          this.mineralImageSize =
            this.localTableResource?.mineral_image_size > 0
              ? this.localTableResource?.mineral_image_size
              : 100;
          this.oreImageSize =
            this.localTableResource?.ore_image_size > 0
              ? this.localTableResource?.ore_image_size
              : 100;
          if (
            this.localTableResource?.project_name == "La Jara Mesa" ||
            this.localTableResource?.project_name == "Westmoreland"
          ) {
            this.localTableResource?.mineral_resource?.resource?.forEach(
              (item) => {
                if (item.title == "Measured") {
                  item.title = "Measured & Indicated";
                }
              }
            );
          }
        })
        .catch((error) => {
          this.isLoadingLocal = false;
        });
    },
    calculateSpan(resource) {
      let arr = [];
      let count = 0;
      for (let i = 0 + count; i < resource.length; i++) {
        if (i == 0 || (i > 0 && resource[i - 1].title == "Total")) {
          count = 0;
          for (let j = i; j < i + 4; j++) {
            if (resource[j].title != "Total") {
              count++;
            } else {
              arr.push(count + 1);
              break;
            }
          }
        } else {
          arr.push(0);
        }
      }
      return arr;
    },
    calculateLastMineralRow(resource) {
      // if (resource[resource.length - 2].title == "Total") {
      //   return resource.length;
      // } else if (resource[resource.length - 3].title == "Total") {
      //   return resource.length - 1;
      // } else if (resource[resource.length - 4].title == "Total") {
      //   return resource.length - 2;
      // } else if (resource[resource.length - 5].title == "Total") {
      //   return resource.length - 3;
      // }
      for (let i = 2; i <= 5; i++) {
        if (resource[resource.length - i]?.title === "Total") {
          return resource.length - (i - 2);
        }
      }
    },
    calculateLastOreRow(resource) {
      // if (resource[resource.length - 2].title == "Total") {
      //   return resource.length;
      // } else if (resource[resource.length - 3].title == "Total") {
      //   return resource.length - 1;
      // } else if (resource[resource.length - 4].title == "Total") {
      //   return resource.length - 2;
      // }
      for (let i = 2; i <= 4; i++) {
        if (resource[resource.length - i]?.title === "Total") {
          return resource.length - (i - 2);
        }
      }
    },
    toCompany(company) {
      this.$router.push({
        name: "companyOverview",
        params: {
          companyId: company.uuid,
        },
      });
    },
    deleteProject(event, props) {
      if (event.item.id == 1) {
        this.deleteProjectId = this.$route.params.projectId;
        this.$store.commit("SET_PROJECT_DELETE_MODAL_SHOW_HIDE", true);
      }
    },
    back() {
      router.go(-1);
    },
    iconUsingName(e) {
      if (e) {
        let names = e.split(" ");
        if (names.length >= 2) {
          let firstName = names[0];
          let lastName = names[1];
          return firstName.charAt(0) + lastName.charAt(0);
        } else {
          return e.charAt(0);
        }
      }
    },
    formatDate(date, format) {
      return dateFormatter(date, format);
    },
    getTimeIn12HourClock(timeStr) {
      let [hour, minute] = timeStr.split(":").map(Number);
      let dateObj = new Date();
      dateObj.setHours(hour);
      dateObj.setMinutes(minute);
      let hour12 = dateObj.getHours() % 12 || 12;
      let minuteStr = dateObj.getMinutes().toString().padStart(2, "0");
      let amPm = dateObj.getHours() >= 12 ? "pm" : "am";
      return `${hour12}:${minuteStr}${amPm}`;
    },
    formatNumberWithoutDoller(number) {
      if (number >= 1000000000) {
        const num = number / 1000000000;
        if (parseFloat(num) >= 2) {
          return parseFloat(num)?.toFixed(2) + " B";
        } else {
          return parseFloat(num)?.toFixed(3) + " B";
        }
      } else if (number >= 1000000) {
        const num = number / 1000000;
        if (parseFloat(num) >= 2) {
          return parseFloat(num)?.toFixed(2) + " M";
        } else {
          return parseFloat(num)?.toFixed(3) + " M";
        }
      } else if (number == "-") {
        return number?.toString();
      } else {
        const num = number;
        if (parseFloat(num) >= 2) {
          return parseFloat(num)?.toFixed(2);
        } else if (parseFloat(num) <= 2) {
          return parseFloat(num)?.toFixed(3);
        } else {
          return num;
        }
      }
    },
    goToEditProject() {
      this.$router.push({
        name: "editProject",
        params: {
          id: this.$route.params.id,
          projectId: this.$route.params.projectId,
        },
      });
    },
    goToProjectMap() {
      this.$router.push({
        name: "projectviewmap",
        params: {
          id: this.$route.params.id,
          projectId: this.$route.params.projectId,
          placeMark: this.localTableResource?.has_placemark,
        },
      });
    },
    valueInsideParentheses(input) {
      const matches = input.match(/\((.*?)\)/);
      if (matches) {
        return matches[1];
      } else {
        return null;
      }
    },
    formatNumber(number) {
      if (number >= 1000000000) {
        return "$" + (number / 1000000000).toFixed(2) + " B";
      } else if (number >= 1000000) {
        return "$" + (number / 1000000).toFixed(2) + " M";
      } else if (number == "-") {
        return number.toString();
      } else {
        return "$" + number?.toFixed(2);
      }
    },
    showLargerView(index) {
      this.currentIndex = index;
      this.showLarger = true;
    },
    convertDateFormat(date) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      if (date) {
        const dateParts = date.split("-");
        const day = parseInt(dateParts[2], 10);
        const monthIndex = parseInt(dateParts[1], 10) - 1;
        const year = parseInt(dateParts[0], 10);

        const suffix = this.getOrdinalSuffix(day);

        return `${day}${suffix} ${months[monthIndex]} ${year}`;
      } else {
        return date;
      }
    },
    getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      } else {
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      }
    },
    formatNumberWithComma(value, precision){
      const num = parseFloat(value);
      if (isNaN(num)) return value;

      return precision ? value : num.toLocaleString();
    }
  },
};
</script>
