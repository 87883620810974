<template src="./deleteCompanyModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";


export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    isClub: {
      type: String,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.companyCreateEdit.isCompanydeleteModalOpen;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      message: ''
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("SET_COMPANY_DELETE_MODAL_SHOW_HIDE", false);
    },
    deleteClub() {
      this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
      this.$store.dispatch("deleteLocalCompany", this.$route.params.id)
    },
    modalHandler(data) {
      this.$store.commit("SET_COMPANY_DELETE_MODAL_SHOW_HIDE", false);
    },
  },
};
</script>