<template src="./companyOverViewTable.html"></template>
<style lang="scss">
@import "./companyOverViewTable.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import moment from "moment";
export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    datePicker: DatePicker,
    numerictextbox: NumericTextBox,
  },
  props: {
    value: {
      type: Object,
      default: "",
    },
    index: {
      type: Number,
      default: null,
    },
    errormsg: {
      type: Array,
      default: null,
    },
    onSync: {
      type: Function,
      default: () => { },
    },
    lengthOfOptions: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      option_code: "",
      exercise_price: "",
      expiry_date: "",
      payload: "",
    };
  },
  mounted() {
    this.option_code = this.value.option_code;
    this.exercise_price = this.value.exercise_price;
    this.expiry_date = this.value.expiry_date ? this.value.expiry_date : null;
  },
  computed: {},
  methods: {
    checkIfIndexPresent() {
      if (this.index !== null) {
        return true;
      } else {
        return false;
      }
    },
    onSyncHandler() {
      if (this.checkIfIndexPresent() != false) {
        this.onSync(this.payload, this.index);
      } else {
        this.onSync(this.payload);
      }
    },
    onDateChange(event) {
      if (event.target.value){
        // const timezoneOffset = moment().utcOffset();
        // this.expiry_date = moment(event.target.value).add(timezoneOffset, 'minutes');
        this.expiry_date = event.target.value
      } else{
        this.expiry_date = null;
      }
      this.inputHandler();
    },
    inputHandler(event) {
      this.errormsg[this.index].option_code = false;
      this.errormsg[this.index].exercise_price = false;

      this.payload = {
        option_code: this.option_code,
        exercise_price: this.exercise_price,
        expiry_date: this.expiry_date,
      };
      this.onSyncHandler();
    },
    QuantityCloseHandler(e) {
      this.$emit("QuantityCloseHandler", e)
    }
  },
};
</script>

<style></style>
