export type State = {
  isEditTenementsModalShow: boolean;
  isSingleTenementsModalShow: boolean;
  isMultipleTenementsModalShow: boolean;
};

const state: State = {
  isEditTenementsModalShow: false,
  isSingleTenementsModalShow: false,
  isMultipleTenementsModalShow: false,
};

const tenements = {
  state,
  getters: {},
  mutations: {
    SET_TENEMENTS_EDIT_MODAL_SHOW(
      state: { isEditTenementsModalShow: boolean },
      data: any
    ) {
      state.isEditTenementsModalShow = data;
    },
    SET_SINGLE_TENEMENTS_MODAL_SHOW(
      state: { isSingleTenementsModalShow: boolean },
      data: any
    ) {
      state.isSingleTenementsModalShow = data;
    },
    SET_MULTIPLE_TENEMENTS_MODAL_SHOW(
      state: { isMultipleTenementsModalShow: boolean },
      data: any
    ) {
      state.isMultipleTenementsModalShow = data;
    },
  },
  actions: {},
};
export default tenements;
