import Login from "../../components/auth/login/login.vue";

const loginRoute = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin",
    name: "Loginadmin",
    component: Login,
  },
];

export default loginRoute;
