import { POST } from "@/service";
import router from "@/router";
import { configuration } from "@/configurationProvider";
import axios from "axios";
import {
  dashboardColumnList,
  dashboardCompanies,
  getCommoditiesService,
  getSearchCompany,
  getSelectedCommoditiesDataService,
} from "@/services/dashboard.service";

export type State = {
  systemDashboardCompanies: [];
  systemDashboardColumnList: [];
  companySearchWithoutProjectList: [];
  isLoadingDashBoard: boolean;
  isLoadingDashColumnData: boolean;

  headerCellsForDynamicTemplate: any;
  commoditiesList: any;
  selectedCommoditiesData: any;
  selectedCommoditiesPaginationInfo: any;
  isDashboardErrorNotify: {};
  isProject: boolean;
  isCompanyView: boolean;
};

const state: State = {
  systemDashboardCompanies: [],
  systemDashboardColumnList: [],
  companySearchWithoutProjectList: [],
  isLoadingDashBoard: false,
  isCompanyView: false,
  isLoadingDashColumnData: false,

  headerCellsForDynamicTemplate: [],
  commoditiesList: [],
  selectedCommoditiesData: [],
  selectedCommoditiesPaginationInfo: {},
  isDashboardErrorNotify: {},
  isProject: false,
};

const dashboard = {
  state,
  getters: {},
  mutations: {
    SET_DASHBOARD_COMPANIES(
      state: { systemDashboardCompanies: [] },
      data: any
    ) {
      state.systemDashboardCompanies = data;
    },
    SET_DASHBOARD_COLUMNLIST(
      state: { systemDashboardColumnList: [] },
      data: any
    ) {
      state.systemDashboardColumnList = data;
    },
    SET_ISLOADING_DASHBOARD(state: { isLoadingDashBoard: boolean }, data: any) {
      state.isLoadingDashBoard = data;
    },
    SET_ISCOMPANYVIEW(state: { isCompanyView: boolean }, data: any) {
      state.isCompanyView = data;
    },
    SET_ISLOADING_DASHBOARD_COLUMNDATA(
      state: { isLoadingDashColumnData: boolean },
      data: any
    ) {
      state.isLoadingDashColumnData = data;
    },
    SET_COMPANYSEARCHWITHOUTPROJECT_TOPBAR(
      state: { companySearchWithoutProjectList: [] },
      data: any
    ) {
      state.companySearchWithoutProjectList = data;
    },
    SET_HEADERCELLS_FOR_DYNAMIC_TEMPLATE(
      state: { headerCellsForDynamicTemplate: any },
      data: any
    ) {
      state.headerCellsForDynamicTemplate = data;
    },
    SET_SELECTED_COMMODITIES_DATA(
      state: { selectedCommoditiesData: any },
      data: any
    ) {
      if (data?.length == 0) {
        state.selectedCommoditiesData = [];
      } else if (data?.data?.length > 0) {
        const newData = data.data.filter((item: any) => {
          return !state.selectedCommoditiesData.some(
            (existingItem: any) => existingItem?.id === item?.id
          );
        });

        state.selectedCommoditiesData = [
          ...state.selectedCommoditiesData,
          ...newData,
        ];
      }
    },
    SET_SELECTED_COMMODITIES_PAGINATION_INFO(
      state: { selectedCommoditiesPaginationInfo: any },
      data: any
    ) {
      state.selectedCommoditiesPaginationInfo = data;
    },
    SET_COMMODITIES_LIST(state: { commoditiesList: any }, data: any) {
      state.commoditiesList = data;
    },
    SET_DASHBOARD_FILTERS_POPUP_ERROR(
      state: { isDashboardErrorNotify: {} },
      data: any
    ) {
      state.isDashboardErrorNotify = data;
    },
    SET_SELECTED_VIEW(state: { isProject: boolean }, data: any) {
      state.isProject = data;
    },
  },
  actions: {
    dashboardCompanies({ commit }: any, payload: any) {
      dashboardCompanies()
        .then((response) => {
          commit("SET_DASHBOARD_COMPANIES", response?.data?.result?.companies);
          commit("SET_ISLOADING_DASHBOARD", false);
        })
        .catch((error) => {
          commit("SET_ISLOADING_DASHBOARD", false);
        });
    },
    dashboardColumnList({ commit }: any, payload: any) {
      dashboardColumnList(payload)
        .then((response) => {
          commit("SET_DASHBOARD_COLUMNLIST", response.data.result);
          commit("SET_ISLOADING_DASHBOARD", false);
          commit("SET_ISLOADING_DASHBOARD_COLUMNDATA", false);
        })
        .catch((error) => {
          commit("SET_ISLOADING_DASHBOARD", false);
        });
    },
    getSearchCompany({ commit }: any, payload: any) {
      getSearchCompany(payload)
        .then((response) => {
          commit(
            "SET_COMPANYSEARCHWITHOUTPROJECT_TOPBAR",
            response?.data?.result?.company
          );
        })
        .catch((error) => {
          commit("SET_COMPANYSEARCHWITHOUTPROJECT_TOPBAR", [
            { name: "No results" },
          ]);
          commit("SET_ISLOADING_DASHBOARD", false);
        });
    },
    getCommodities({ commit }: any, payload: any) {
      commit("SET_ISLOADING_DASHBOARD", true);
      getCommoditiesService()
        .then((response) => {
          commit("SET_ISLOADING_DASHBOARD", false);
          commit(
            "SET_COMMODITIES_LIST",
            response?.data?.result?.primary_commodities
          );
        })
        .catch((error) => {
          commit("SET_ISLOADING_DASHBOARD", false);
        });
    },
    getSelectedCommoditiesData({ commit }: any, payload: any) {
      getSelectedCommoditiesDataService(payload)
        .then((response) => {
          commit("SET_ISLOADING_DASHBOARD", false);
          commit("SET_ISLOADING_DASHBOARD_COLUMNDATA", false);
          commit(
            "SET_SELECTED_COMMODITIES_PAGINATION_INFO",
            response?.data?.result
          );
          commit("SET_ISLOADING_DASHBOARD_COLUMNDATA", false);
          commit("SET_SELECTED_COMMODITIES_DATA", response?.data?.result);
        })
        .catch((error) => {
          commit("SET_ISLOADING_DASHBOARD", false);
        });
    },
  },
};
export default dashboard;
