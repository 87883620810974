<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.72369 4.54809L6.72356 4.54808V4.55556V9.49307V9.50964L6.72622 9.526C6.74768 9.65796 6.82669 9.76888 6.93446 9.8285C7.04348 9.88881 7.19523 9.89948 7.31884 9.80038L10.5468 7.35803L10.5469 7.35812L10.5523 7.35379C10.6697 7.25858 10.7036 7.10897 10.7036 7C10.7036 6.89103 10.6697 6.74142 10.5523 6.64621L10.5523 6.64612L10.5468 6.64197L7.31608 4.19753L7.30743 4.19099L7.29814 4.18539C7.17483 4.11113 7.01785 4.0961 6.88851 4.19264C6.77292 4.27891 6.72839 4.41924 6.72369 4.54809ZM3.87179 0.794872C2.13189 0.794872 0.794872 2.51942 0.794872 4.55556V9.44444C0.794872 11.4806 2.13189 13.2051 3.87179 13.2051H12.1282C13.8681 13.2051 15.2051 11.4806 15.2051 9.44444V4.55556C15.2051 2.51942 13.8681 0.794872 12.1282 0.794872H3.87179ZM3.87179 1.64957H12.1282C13.3714 1.64957 14.4359 2.89836 14.4359 4.55556V9.44444C14.4359 11.1016 13.3714 12.3504 12.1282 12.3504H3.87179C2.62862 12.3504 1.5641 11.1016 1.5641 9.44444V4.55556C1.5641 2.89836 2.62862 1.64957 3.87179 1.64957Z"
      fill="black"
      stroke="black"
      stroke-width="0"
      />
      <!-- stroke-width="0.410257" -->
  </svg>
</template>

<script>
export default {
  name: "iconVideo",
};
</script>
