<template>
  <div class="d-flex not-found-wrapper">
    <div class="error-container">
      <div class="image">
        <img loading="lazy" src="../../../assets/images/error-img.svg" class="block object-cover" />
      </div>
      <div class="error-content">
        <h6>404 Error!</h6>
        <h1>
          Page not found
        </h1>
        <router-link :to="`/companies`">
          <kbutton :size="'large'" :fill-mode="'solid'" :theme-color="'primary'" :rounded="'full'"
            :class="'font-20 font-weight-400 view-btn-rounded-full'">
            Back
            to Home</kbutton>
        </router-link>
      </div>
    </div>

  </div>
</template>

<style lang="scss">
@import "./app-not-found.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";

export default {
  props: {
    incorrectPath: String,
  },
  components: {
    kbutton: Button,
  },
};
</script>
