<template src="./deleteCompanyOptionsModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";


export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    deleteCompanyOptionsId: {
      type: Number,
    },
    companyId: {
      type: Number,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.companyCreateEdit.isCompanyOptionsdeleteModalOpen;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      message:''
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("SET_COMPANY_OPTIONS_DELETE_MODAL_SHOW_HIDE", false);
    },
    deleteCompanyOptions() {
      var payload ={
        companyId:this.companyId,
        optionId:this.deleteCompanyOptionsId
      } 
      this.$store.dispatch("deleteLocalCompanyOption",payload)


    },
    modalHandler(data) {
      this.$store.commit("SET_COMPANY_OPTIONS_DELETE_MODAL_SHOW_HIDE", false);
    },
  },
};
</script>