<script>
import { POST } from '@/service';
export default {
  name: "emailQuery",
  data() {
    return {
      email: this.$route.query.email,
      isLive: false,
    }
  },
  created() {
    if(window.location.href.includes("https://app.miningbull.com.au/")){
      this.isLive = true;
    }else{
      this.isLive = false;
    }
    const userToken = localStorage.getItem("mnb_session");
    if(userToken){
      this.$store.dispatch("checkToken");
      if(this.loggedInUserData == null || Object.keys(this.loggedInUserData).length == 0){
        this.$store.dispatch("getUserInformation")
      }
    }
    else {
      sessionStorage.setItem('reDirectToEmail',true)
      sessionStorage.setItem('email',this.email)
      this.$router.push("/")
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.loggedInUserData?.email === this.email && this.loggedInUserData?.validation_uuid) {
        POST('api/v1/user/upgrade-status');
        if(this.isLive){
          window.location.href = `https://miningbull.com.au/subscribe/?customAuthToken=${this.loggedInUserData?.validation_uuid}`;
        }else{
          window.location.href = `https://mnbstage.mywebcommander.com/subscribe/?customAuthToken=${this.loggedInUserData?.validation_uuid}`;
        }
      }
      else {
        this.$router.push("/")
      }
    },2000)
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
  },
  methods: {
  }
}
</script>
