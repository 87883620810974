<template>
  <div>
    <topBarComponent
      v-if="$route.name != 'Login' && $route.name != 'Loginadmin' && $route.name != 'forgetPassword' && $route.name != 'resetPassword'">
    </topBarComponent>
    <router-view />
  </div>
</template>

<script>
import '@progress/kendo-ui';
import topBarComponent from './components/topBarComponent/topBarComponent.vue';

export default {
  name: "App",
  components: {
    topBarComponent
  },
  data: function () {
    return {
      appRender: false,
    };
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
  },
  created() {
    const userToken = localStorage.getItem("mnb_session");
    if (userToken) {
      this.$store.dispatch("checkToken");
      if (this.loggedInUserData == null || Object.keys(this.loggedInUserData).length == 0) {
        this.$store.dispatch("getUserInformation")
      }
    } 
  },
  mounted() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.$store.commit("SET_TIMEZONE", timezone)


    let timeoutIds = [];

    const resetTimers = () => {
      timeoutIds.forEach(id => clearTimeout(id));
      timeoutIds = [startTimer(43200000), startTimer(3600000)];
    };

    const startTimer = (duration) => {
      return setTimeout(() => {
        if (duration === 43200000) {
          this.$store.dispatch("signOut");
        } else if (duration === 3600000) {
          // location.reload();
        }
      }, duration);
    };

    document.addEventListener("mousemove", resetTimers);
    document.addEventListener("keypress", resetTimers);


    document.addEventListener('keydown', function (event) {
      if (!event.target.classList.contains("enable_highlight_and_copy")) {
        if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'I') {
          event.preventDefault();
        }
        if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
          event.preventDefault();
        }
      }
    });
    resetTimers();
  },
};
</script>

<style lang='scss'>
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./assets/scss/app.scss";</style>