<template>
  <div :class="`sidebar-menu bg-white d-flex flex-column ${showSideMenu ? 'show' : ''
    }`">
    <div>
      <div v-if="!loggedInUserData?.is_admin" class="d-flex align-items-center sidebar-menu-item py-10 px-16 cursor-pointer" @click="accountClick">
        <iconUser />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Account</span>
      </div>
      <div @click="toMarketMap" class="d-flex align-items-center sidebar-menu-item py-10 px-16 cursor-pointer">
        <iconMarketMap />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">market map</span>
      </div>
      <div @click="toDatabase(true)" class="d-flex align-items-center sidebar-menu-item py-10 px-16 cursor-pointer">
        <iconData />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Companies</span>
      </div>
      <div @click="!['trader_user'].includes(loggedInUserData?.role?.alias) && toDatabase(false)" :class="`d-flex align-items-center sidebar-menu-item py-10 px-16 ${!['trader_user'].includes(loggedInUserData?.role?.alias) ? 'cursor-pointer' : 'disabled'}`">
        <iconHammer class="icon-hammer" />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Projects</span>
      </div>
      <div @click="toProjectMaps" :class="`d-flex align-items-center sidebar-menu-item py-10 px-16 ${!['trader_user', 'explorer_user'].includes(loggedInUserData?.role?.alias) ? 'cursor-pointer' : 'disabled'}`">
        <iconProjectMaps />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Interactive Maps</span>
      </div>
      <div @click="toVideoTutorialPage" :class="`d-flex align-items-center sidebar-menu-item py-10 px-16 cursor-pointer`">
        <iconVideo />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Video Tutorials</span>
      </div>
    </div>
    <div class="mt-auto">
      <div v-if="loggedInUserData?.is_admin == 1" @click="toColumnConfiguration" class="d-flex align-items-center sidebar-menu-item border-none py-10 px-16 cursor-pointer">
        <iconConfiguration />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Column Configuration</span>
      </div>
      <div class="d-flex align-items-center sidebar-menu-item py-10 px-16 cursor-pointer" @click="signOut">
        <iconLogout />
        <span class="font-16 font-weight-400 text-black text-capitalize menu-title">Log out</span>
      </div>
    </div>
  </div>
</template>

<script>
import iconUser from "@/components/svg-icons/iconUser.vue";
import iconMarketMap from "@/components/svg-icons/iconMarketMap.vue";
import iconData from "@/components/svg-icons/iconData.vue";
import iconHammer from "@/components/svg-icons/iconHammer.vue";
import iconProjectMaps from "@/components/svg-icons/iconProjectMaps.vue";
import iconLogout from "@/components/svg-icons/iconLogout.vue";
import iconContact from "@/components/svg-icons/iconContact.vue";
import iconInformation from "@/components/svg-icons/iconInformation.vue";
import iconConfiguration from "@/components/svg-icons/iconConfiguration.vue";
import iconVideo from "@/components/svg-icons/iconVideo.vue";

export default {
  props: {
    onCloseSideMenu: {
      type: Function,
      required: true,
    },
    showSideMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    iconUser,
    iconMarketMap,
    iconData,
    iconHammer,
    iconProjectMaps,
    iconLogout,
    iconContact,
    iconInformation,
    iconConfiguration,
    iconVideo
  },
  data: function () {
    return {
      isAdmin: false,
      autobillUser: null,
      isLive: false,
      validationUuid: null,
    };
  },
  created() { },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
  },
  mounted() {
    if (window.location.href.includes("https://app.miningbull.com.au/")) {
      this.isLive = true;
    } else {
      this.isLive = false;
    }
    if (this.loggedInUserData?.is_admin !== 1) {
      this.validationUuid = this.loggedInUserData?.validation_uuid;
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
    },
    accountClick() {
      if (this.loggedInUserData?.is_admin !== 1) {
        if (!this.validationUuid) {
          // this.autobillUser = this.loggedInUserData?.autobill_user;
          this.validationUuid = this.loggedInUserData?.validation_uuid;
        }
        if (this.isLive) {
          window.open(
            `https://miningbull.com.au/subscribe/?customAuthToken=${this.validationUuid}`,
            "_blank"
          );
          this.$store.dispatch("getUserInformation");
        } else {
          window.open(
            `https://mnbstage.mywebcommander.com/subscribe/?customAuthToken=${this.validationUuid}`,
            "_blank"
          );
          this.$store.dispatch("getUserInformation");
        }
        this.onCloseSideMenu();
      } else {
        this.onCloseSideMenu();
        return;
      }
    },
    toDatabase(isCompanyView) {
      this.onCloseSideMenu();
      this.$store.commit("SET_ISCOMPANYVIEW", isCompanyView);
      const path = isCompanyView ? '/companies' : '/projects';
      this.$router.push(path);
    },
    toProjectMaps() {
      if(!['trader_user', 'explorer_user'].includes(this.loggedInUserData?.role?.alias)){
        this.onCloseSideMenu();
        this.$router.push("/interactivemaps");
      }else return
    },
    toColumnConfiguration(){
      this.onCloseSideMenu();
      this.$router.push("/column-configuration");
    },
    toMarketMap() {
      this.onCloseSideMenu();
      this.$router.push("/marketMap");
    },
    toContactSupport() {
      this.onCloseSideMenu();
      window.open("https://www.miningbull.com.au/contact-us/", "_blank");
    },
    toInformation() {
      this.onCloseSideMenu();
      window.open("https://www.miningbull.com.au/information/", "_blank");
    },
    toVideoTutorialPage(){
      this.onCloseSideMenu();
      window.open("https://www.miningbull.com.au/video-tutorials/", "_blank");
    }
  },
};
</script>

<style></style>
