<AppGlobalLoader :loaderColor="'solid'" :isFullpage="true" v-if="isLoadingCreateProjectUpdate" />
<AppSnackBar :message="notificationProject.message" :notificationType="notificationProject.type"
  v-if="notificationProject.isNotify">
</AppSnackBar>
<div v-if="!isLoadingLocal" class="overview-page-nav d-flex justify-content-between border-bottom-1-gray p-11">
  <div class="left d-flex h-66">
    <kbutton @click="back" :fill-mode="'clear'" :icon="'k-icon k-i-arrow-chevron-left'"
      :class="'py-0 font-20 font-weight-400 h-66'"></kbutton>
    <div class="overview-avatar h-66">
      <span v-if="!photoPathCompany"
        class="d-inline-block h-66 w-66 line-h-66px text-center radius-100 bg-primary white-color">
        {{iconUsingName(localTableResource?.company?.name)}}
      </span>
      <img v-if="photoPathCompany" :src="photoPathCompany" />
    </div>
    <div @click="toCompany(localTableResource?.company)" class="overview-info d-flex flex-column justify-content-center ps-3 h-66 cursor-pointer">
      <h4 class="font-20 line-h-1_4 mb-0">
        {{localTableResource?.company?.name}}
      </h4>
      <p class="font-12 line-h-1_4 mb-0">
        {{localTableResource?.company?.code}}
      </p>
    </div>
    <div class="overview-tags ps-3 pt-3 h-66">
      <span v-for="(sector, index) in modifiedSector" :key="sector.id"
        :class="`d-inline-block h-22 font-12 line-h-1_2 border-1-normal p-1 radius-4 me-2 ${ sector.alias == localTableResource?.company?.market_map?.alias ? 'active_market_map' : ''}`">{{sector?.name}}</span>
    </div>
  </div>
  <div class="right d-flex">
    <div>
      <h2 class="font-28 line-h-1_3 font-weight-300 d-flex align-items-center mb-0 overview-tags ps-0">
        {{formatDecimalNumber(localTableResource?.company?.top_ribbon?.price)}}
        <span class="font-16 font-weight-500 mt-12 ms-2"
          :class="localTableResource?.company?.day>0?'green-color':localTableResource?.company?.day==0?'':'red-color'">
          <span v-if="localTableResource?.company?.day>0" class="align-text-bottom">
            <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25" stroke="#28A745" fill="#28A745" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <span v-if="localTableResource?.company?.day<0" class="align-text-bottom">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          {{localTableResource?.company?.top_ribbon?.day}}
          ({{localTableResource?.company?.top_ribbon?.pct_change}}%)</span>
      </h2>
      <div class="d-flex align-items-center">
        <span
          class="d-inline-block h-22 primary-color font-12 line-h-1_2 border-1-orange p-1 radius-4 me-2">Delayed</span>
        <p class="font-12 line-h-1_4 mb-0">
          Last updated at
          {{localTableResource?.company?.price_time ?
          getTimeIn12HourClock(localTableResource?.company?.price_time):""}}
          {{formatDate(localTableResource?.company?.price_date,'DD/MM/YY')}}
        </p>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-end ps-4">
      <p class="font-12 line-h-1_4 font-weight-400 mb-1">
        Bid:
        <b>{{localTableResource?.company?.top_ribbon?.bid}}</b>
      </p>
      <p class="font-12 line-h-1_4 font-weight-400 mb-1">
        Volume:
        <b>{{localTableResource?.company?.top_ribbon?.volume?.value ? localTableResource?.company?.top_ribbon?.volume.value : localTableResource?.company?.top_ribbon?.volume}}</b><b class="font-10">{{localTableResource?.company?.top_ribbon?.volume?.number_format}}</b>
      </p>
      <p class="font-12 line-h-1_4 font-weight-400 mb-1">
        RVol:
        <b>{{localTableResource?.company?.top_ribbon?.intraday_rvol?.value}}</b><b class="font-10">{{localTableResource?.company?.top_ribbon?.intraday_rvol?.number_format}}</b>
      </p>
    </div>
    <div class="d-flex flex-column justify-content-end ps-5 pe-4">
      <p class="font-12 line-h-1_4 font-weight-400 mb-1">
        Ask:
        <b>{{localTableResource?.company?.top_ribbon?.ask}}</b>
    </p>
      <p class="font-12 line-h-1_4 font-weight-400 mb-1">
        Value:
        <b>{{localTableResource?.company?.top_ribbon?.turnover?.value ? localTableResource?.company?.top_ribbon?.turnover?.value : localTableResource?.company?.top_ribbon?.turnover }}</b><b class="font-10">{{localTableResource?.company?.top_ribbon?.turnover?.number_format}}</b>
      </p>
      <p class="font-12 line-h-1_4 font-weight-400 mb-1">
        VWAP:
        <b>{{localTableResource?.company?.top_ribbon?.vwap}}</b>
      </p>
    </div>
  </div>
</div>

<div v-if="!isLoadingLocal" class="k-overflow-y-auto max-h-100-180 min-h-100-157">

  <div class="pt-32 pb-16 px-56">
    <div class="d-flex justify-content-between align-items-center border-bottom-1-normal pb-1">
      <h4 class="font-20 font-weight-400 line-h-1_4">
        {{localTableResource?.project_name}}
      </h4>
      <span v-if="loggedInUserData?.role?.alias != 'admin'">Last Updated {{formatDate(localTableResource?.last_updated_date, 'DD/MM/YYYY')}}</span>
      <div v-if="loggedInUserData?.role?.alias == 'admin'" class="d-flex align-items-center justify-content-end">
        <span class="me-2">Last Updated {{formatDate(localTableResource?.last_updated_date, 'DD/MM/YYYY')}}</span>
        <kbutton @click="goToEditProject" :class="'h-38'" :size="'medium'" :icon="''">Edit Project</kbutton>
        <DropDownButton :popup-settings="popupSettings" :icon="'more-vertical'" :items="passStatuses"
          :class="'btn-inside-transparent btn-inside-p-0 h-38 ms-3 btn-focus-bg-gray'" @itemclick="deleteProject" />
      </div>
    </div>
  </div>

  <div class="px-56">
    <div class="row">
      <div class="col-md-9 col-sm-7 col-12">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">
                Primary Commodities
              </p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                <span v-if="localTableResource?.main_commodities?.length > 0"
                  v-for="(mainComo,index) in localTableResource?.main_commodities">
                  {{ mainComo.name}}{{ index < localTableResource?.main_commodities?.length - 1 ? ', ' : '' }} </span>
                    <span
                      v-if="localTableResource?.additional_main_commodities?.length>0 && localTableResource?.stage?.stage_name=='Exploration'">{{localTableResource?.main_commodities?
                      ", "+localTableResource?.additional_main_commodities:localTableResource?.additional_main_commodities}}</span>
              </p>
              <p v-if="localTableResource?.stage?.stage_name!='Exploration'"
                class="font-14 line-h-1_4 font-weight-700 mb-0">
                {{ localTableResource?.project_name == "Barrambie" && localTableResource?.main_commodities?.name == "Vanadium" ? "Titanium" : localTableResource?.main_commodities?.name}}{{(localTableResource?.additional_main_commodities?.length>0&&localTableResource?.main_commodities?.name)?", "+localTableResource?.additional_main_commodities : localTableResource?.additional_main_commodities}}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">
                Other Commodities
              </p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                <span> {{ localTableResource?.other_commodities}}
                </span>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">Stage</p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                {{ localTableResource?.stage?.stage_name ? localTableResource?.stage?.stage_name : ""}}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">Location</p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                {{ localTableResource?.state?.state_name?localTableResource?.state?.state_name
                : localTableResource?.state}}{{ (localTableResource?.country?.country_name &&
                localTableResource?.state?.state_name) ?", "+localTableResource?.country?.country_name:localTableResource?.country?.country_name}}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">Status</p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                {{ localTableResource?.status?.status_name ? localTableResource?.status?.status_name : ""}}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">
                Current Ownership
              </p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                {{ localTableResource?.current_ownership ? localTableResource?.current_ownership+"%" : ""}}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">
                Future Ownership
              </p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0">
                {{ localTableResource?.ultimate_ownership ? localTableResource?.ultimate_ownership+"%" : ""}}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="project-view-info-box mb-24">
              <p class="font-14 line-h-1_4 font-weight-400 mb-1">
                Ownership Note
              </p>
              <p class="font-14 line-h-1_4 font-weight-700 mb-0 word-break-word">
                {{ localTableResource?.ownership_note ? localTableResource?.ownership_note : ""}}
              </p>
            </div>
          </div>
        </div>
        <div v-if="localTableResource?.project_summary" class="mb-24">
          <p class="font-15 line-h-1_4 font-weight-700 mb-1">Project Summary</p>
          <p class="font-15 line-h-1_4 font-weight-400 mb-0 text-color-3">
            {{localTableResource?.project_summary}}
          </p>
        </div>

        <div v-if="(localTableResource?.main_commodities?.alias != 'kaolin' && localTableResource?.main_commodities?.alias != 'potash') && localTableResource?.deposit_type?.name == 'Single' || localTableResource?.deposit_type == null" class="mb-46 mt-46">
          <div v-if="localTableResource?.column_mineral?.length && localTableResource?.mineral_resource?.resource?.length"
            class="">
            <AppGlobalLoader :class="' text-center'" :loaderColor="'solid'"
              v-if="!localTableResource?.column_mineral?.length && !localTableResource?.mineral_resource?.resource?.length" />
      
            <div
              v-if="localTableResource?.column_mineral?.length && localTableResource?.mineral_resource?.resource?.length && !localTableResource?.is_hidden_mineral"
              class="col-12 mb-24 no-gray-bg">
              <p class="font-15 mb-2 font-weight-700">Mineral Resource</p>
              <div class="mb-3">
                <div class="table-auto-column-w-fixed">
                  <Grid :class="(localTableResource?.project_name == 'La Jara Mesa' || localTableResource?.project_name == 'Westmoreland') ? 'mineral-resource-first-col-140 mineral-resource single-table' : 'mineral-resource single-table'" ref="grid" :data-items="localTableResource?.mineral_resource?.resource"
                    @itemchange="itemChange" :columns="localTableResource?.column_mineral">
                    <template v-for="column in localTableResource?.column_mineral" v-slot:[`${column.field}Cell`]="{ props }">
                      <td>
                            <div>
                              {{  props.dataItem[column.field] }}
                            </div>
                        </td>
                      </template>
                  </Grid>
                </div>
              </div>
              <div class="">
                <div class="">
                  <ul class="mr-15">
                    <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.mineral_resource?.mineral_note_1">
                      {{localTableResource?.mineral_resource?.mineral_note_1}}
                    </li>
                    <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.mineral_resource?.mineral_note_2">
                        {{localTableResource?.mineral_resource?.mineral_note_2}}
                    </li>
                    <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.mineral_resource?.mineral_note_3">
                      {{localTableResource?.mineral_resource?.mineral_note_3}}
                    </li>
                  </ul>
                  <ul class="mr-15" v-for="(single,index) in localTableResource?.mineral_resource?.cut_off_grade"
                    :key="index">
                    <li v-if="single.label && single[index]" class="font-12--75 line-h-1_4 w-100">
                      {{single.label}}: <span>{{single[index]}}</span>
                    </li>
                  </ul>
                  <ul class="mr-15" v-for="(single,index) in localTableResource?.mineral_resource?.current_ownership"
                    :key="index">
                    <li v-if="single.label && single[index] && (localTableResource?.current_ownership != single[index])" class="font-12--75 line-h-1_4 w-100">
                      {{single.label}}: <span>{{single[index]}}%</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(localTableResource?.main_commodities?.alias != 'kaolin' && localTableResource?.main_commodities?.alias != 'potash') && localTableResource?.deposit_type?.name == 'Multiple'" class="mb-46 mt-46">
          
          <div v-if="localTableResource?.multiple_deposit_column_mineral?.length>3 && localTableResource?.multiple_deposit_mineral_resource?.resource?.length"
            class="">
            <AppGlobalLoader :class="' text-center'" :loaderColor="'solid'"
              v-if="!localTableResource?.multiple_deposit_column_mineral?.length>3 && !localTableResource?.multiple_deposit_mineral_resource?.resource?.length" />     
              
              <div
              v-if="localTableResource?.multiple_deposit_column_mineral?.length>3 && localTableResource?.multiple_deposit_mineral_resource?.resource?.length"
              class="col-12 mb-24 no-gray-bg">
              <p class="font-15 mb-2 font-weight-700">Mineral Resource</p>
              <div class="mb-3">
                <div class="table-auto-column-w-fixed">
                    <Grid :class="'mineral-resource data-view-state multiple-table'" ref="grid" :data-items="localTableResource?.multiple_deposit_mineral_resource?.resource"
                    @itemchange="itemChange" :columns="localTableResource?.multiple_deposit_column_mineral">
                    <template v-for="column in localTableResource?.multiple_deposit_column_mineral" v-slot:[`${column.field}Cell`]="{ props }">
                      
                      <td :rowspan="mineral_resource_spans[props.dataIndex-1]" v-if="props.field == 'deposit' && mineral_resource_spans[props.dataIndex-1] > 0">
                        <template v-if="props.dataIndex === multiple_table_last_deposit_row">
                          <div class="text-center">
                            Total
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-center font-13">
                            {{ props.dataItem[column.field]}}
                          </p>
                          <p v-if="localTableResource.current_ownership != null && localTableResource.current_ownership.includes('-')" class="text-center mt-2 text-box-group">
                            ({{props.dataItem.deposit_percentage}}%)
                          </p>
                        </template>
                      </td>
                      <td v-else-if="props.field != 'deposit' && props.field != 'btn'">
                            <div>
                              {{  props.dataItem[column.field] }}
                            </div>
                        </td>
                      </template>
                  </Grid>
                </div>
              </div>
              <div class="">
                <ul class="mr-15">
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.multiple_deposit_mineral_resource?.mineral_note_1">
                    {{localTableResource?.multiple_deposit_mineral_resource?.mineral_note_1}}
                  </li>
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.multiple_deposit_mineral_resource?.mineral_note_2">
                    {{localTableResource?.multiple_deposit_mineral_resource?.mineral_note_2}}
                  </li>
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.multiple_deposit_mineral_resource?.mineral_note_3">
                    {{localTableResource?.multiple_deposit_mineral_resource?.mineral_note_3}}
                  </li>
                </ul>
                <ul class="mr-15" v-for="(single,index) in localTableResource?.multiple_deposit_mineral_resource?.cut_off_grade"
                  :key="index">
                  <li v-if="single.label && single[index]" class="font-12--75 line-h-1_4 w-100">
                    {{single.label}}: <span>{{single[index]}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(localTableResource?.main_commodities?.alias != 'kaolin' && localTableResource?.main_commodities?.alias != 'potash') && localTableResource?.deposit_type?.name == 'Single' || localTableResource?.deposit_type == null" class="mb-46 no-gray-bg">
          <div v-if="localTableResource?.column_ore?.length && localTableResource?.ore_resource?.resource?.length" class="">
            <AppGlobalLoader :class="'text-center'" :loaderColor="'solid'"
              v-if="!localTableResource?.column_ore?.length && !localTableResource?.ore_resource?.resource?.length" />
      
            <div
              v-if="localTableResource?.column_ore?.length && localTableResource?.ore_resource?.resource?.length && !localTableResource?.is_hidden_ore"
              class="col-12 mb-24">
              <p class="font-15 font-weight-700 mb-2">Ore Reserve</p>
              <div class="mb-3">
                <div class="table-auto-column-w-fixed">
                  <Grid :class="'mineral-resource single-table'" ref="grid" :data-items="localTableResource?.ore_resource?.resource"
                    @itemchange="itemChange" :columns="localTableResource?.column_ore">
                    <template v-for="column in localTableResource?.column_ore" v-slot:[`${column.field}Cell`]="{ props }">
                      <td>
                            <div>
                              {{  props.dataItem[column.field] }}
                            </div>
                        </td>
                      </template>
                  </Grid>
                </div>
              </div>
              <div class="">
                <ul class="mr-15">
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.ore_resource?.ore_note_1">
                    {{localTableResource?.ore_resource?.ore_note_1}}
                  </li>
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.ore_resource?.ore_note_2">
                    {{localTableResource?.ore_resource?.ore_note_2}}
                  </li>
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.ore_resource?.ore_note_3">
                    {{localTableResource?.ore_resource?.ore_note_3}}
                  </li>
                </ul>
                <ul class="mr-15" v-for="(single,index) in localTableResource?.ore_resource.cut_off_grade" :key="index">
                  <li v-if="single.label && single[index]" class="font-12--75 line-h-1_4 w-100">
                    {{single.label}}: <span>{{single[index]}}</span>
                  </li>
                </ul>
                <ul class="mr-15" v-for="(single,index) in localTableResource?.ore_resource.current_ownership" :key="index">
                  <li v-if="single.label && single[index] && (localTableResource?.current_ownership != single[index])" class="font-12--75 line-h-1_4 w-100">
                    {{single.label}}: <span>{{single[index]}}%</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(localTableResource?.main_commodities?.alias != 'kaolin' && localTableResource?.main_commodities?.alias != 'potash') && localTableResource?.deposit_type?.name == 'Multiple'" class="mb-46 no-gray-bg">
          
          <div v-if="localTableResource?.multiple_deposit_column_ore?.length>3 && localTableResource?.multiple_deposit_ore_resource?.resource?.length" class="">
            <AppGlobalLoader :class="'text-center'" :loaderColor="'solid'"
              v-if="!localTableResource?.multiple_deposit_column_ore?.length>3 && !localTableResource?.multiple_deposit_ore_resource?.resource?.length" />      
           
              <div
              v-if="localTableResource?.multiple_deposit_column_ore?.length>3 && localTableResource?.multiple_deposit_ore_resource?.resource?.length"
              class="col-12 mb-24">
              <p class="font-15 font-weight-700 mb-2">Ore Reserve</p>
              <div class="mb-3">
                <div class="table-auto-column-w-fixed">
                  <Grid :class="'mineral-resource ore-reserve data-view-state multiple-table'" ref="grid" :data-items="localTableResource?.multiple_deposit_ore_resource?.resource"
                    @itemchange="itemChange" :columns="localTableResource?.multiple_deposit_column_ore">
                    <template v-for="column in localTableResource?.multiple_deposit_column_ore" v-slot:[`${column.field}Cell`]="{ props }">
                      <td :rowspan="ore_resource_spans[props.dataIndex-1]" v-if="props.field == 'deposit' && ore_resource_spans[props.dataIndex-1] > 0">
                        <template v-if="props.dataIndex === multiple_table_last_deposit_row_ore">
                          <div class="text-center">
                            Total
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-center font-13">
                            {{ props.dataItem[column.field]}}
                          </p>
                          <p v-if="localTableResource.current_ownership != null && localTableResource.current_ownership.includes('-')" class="text-center mt-2 text-box-group">
                            ({{props.dataItem.deposit_percentage}}%)
                          </p>
                        </template>
                      </td>
                      <td v-else-if="props.field != 'deposit' && props.field != 'btn'">
                            <div>
                              {{  props.dataItem[column.field] }}
                            </div>
                        </td>
                      </template>
                  </Grid>
                </div>
              </div>
              <div class="">
                <ul class="mr-15">
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.multiple_deposit_ore_resource?.ore_note_1">
                    {{localTableResource?.multiple_deposit_ore_resource?.ore_note_1}}
                  </li>
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.multiple_deposit_ore_resource?.ore_note_2">
                    {{localTableResource?.multiple_deposit_ore_resource?.ore_note_2}}
                  </li>
                  <li class="font-12--75 line-h-1_4 w-100" v-if="localTableResource?.multiple_deposit_ore_resource?.ore_note_3">
                    {{localTableResource?.multiple_deposit_ore_resource?.ore_note_3}}
                  </li>
                </ul>
                <ul class="mr-15" v-for="(single,index) in localTableResource?.multiple_deposit_ore_resource.cut_off_grade" :key="index">
                  <li v-if="single.label && single[index]" class="font-12--75 line-h-1_4 w-100">
                    {{single.label}}: <span>{{single[index]}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="" v-if="localTableResource?.main_commodities?.alias == 'kaolin' || localTableResource?.main_commodities?.alias == 'potash'">
          <div  v-if="localTableResource?.mineral_image_path" class="mb-46 mt-46">
            <p class="font-15 mb-2 font-weight-700">Mineral Resource</p>
            <img :src="mineralImagePath" alt="Mineral Table" :style="{width: `${mineralImageSize}%`}" style="transform-origin: top left;">
          </div>
          <div v-if="localTableResource?.ore_image_path" class="mb-46 mt-46">
            <p class="font-15 mb-2 font-weight-700">Ore Reserve</p>
            <img :src="oreImagePath" alt="Mineral Table" :style="{width: `${oreImageSize}%`}" style="transform-origin: top left;">
          </div>
        </div>

        
        <div
          v-if="localTableResource?.is_feasibility_exists && ['Scoping', 'Pre-Feasibility', 'Feasibility', 'Restart', 'Construction', 'Production'].includes(localTableResource?.stage?.stage_name) 
              && feasibility_field_resource?.length && !['trader_user', 'explorer_user'].includes(loggedInUserData?.role?.alias)" class="mb-40">
          <p class="font-15 font-weight-700 mb-2">Feasibility Study</p>
          <div class="feasibility-study-table">
            <table class="styled-table">
              <tbody>
                <tr v-for="(item, index) in feasibility_field_resource" :key="index">
          
                  <template v-if="item.value && !['Feasibility Study', 'Release Date', 'Study Type (Full)', 'Notes', 'Study Type (Short)'].includes(item.label)">
                    <td class="font-13 base-color">{{ item.label }}</td>
                    <td class="font-13 base-color text-center"> {{item?.unit || "-" }} </td>
                    <td class="font-13 base-color text-center">{{ formatNumberWithComma(item.value, item?.decimal_precision) || "-" }}</td>
                  </template>
          
                  <!-- @click="onClickStudyType(item)" class="see-more primary-color font-weight-500">{{item.label == 'Study Type (Full)' ? 'Study Type' : item.label}}</span></td> -->
                  <template v-else-if="['Release Date', 'Study Type (Full)'].includes(item.label) && (item.value || item.pdf_details)">
                    <td v-if="item.pdf_details && ['Study Type (Full)'].includes(item.label)" class="font-13 base-color"><span
                       @click="onClickStudyType(item)" class="">{{item.label == 'Study Type (Full)' ? 'Study Type' : item.label}}</span></td>
                    <td v-else class="font-13 base-color">{{item.label == 'Study Type (Full)' ? 'Study Type' : item.label}}</td>
                    <td v-if="!['Release Date'].includes(item.label)" colspan="2" :class="`font-13 text-center ${item.pdf_details ? 'primary-color cursor-pointer see-more font-weight-500' : 'base-color'}`" @click="onClickStudyType(item)">
                      {{item.value}}</td>
                    <td v-if="['Release Date'].includes(item.label)" colspan="2" class="font-13 base-color text-center">
                      {{formatDate(item.value,'DD/MM/YYYY')}}</td>
                  </template>
  
                  <template v-else-if="['Notes'].includes(item.label)">
                    <td colspan="3" class="notes-column">
                      <ul class="mt-1">
                        <li v-for="noteItem in item?.notes" :key="noteItem.id" class="font-12--75 line-h-1_4 w-100">
                          {{ noteItem?.value }}
                        </li>
                      </ul>
                    </td>
                  </template>          
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
      <div class="col-md-3 col-sm-5 col-12 text-right">
        <div class="photo-box">
          <div class="text-center">
            <AppLightBox v-if="localTableResource?.image_path" :trial="loggedInUserData.is_trial_user" :imageSrc="tempPhotoPath" />
            <p v-if="localTableResource?.image_path" class="mt-2 mb-0 font-12--75">{{convertDateFormat(localTableResource?.image_date_published)}}</p>
            <kbutton v-if="localTableResource?.is_project_map_exists || localTableResource?.has_placemark!=0"
              @click="goToProjectMap" :class="'h-32 mt-2 view-map-btn view-btn-rounded-full'" :size="'medium'"
              :theme-color="'primary'" :icon="''">View In Map <span class="k-icon k-font-icon k-i-globe"></span></kbutton> </div>
        </div>
      </div>      
    </div>
  </div>
</div>

<deleteProject :isReload="false" :deleteProjectId="deleteProjectId"></deleteProject>