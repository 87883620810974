<template src="./AppModal.html"></template>
<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import kendo from '@progress/kendo-ui';
import AppLoader from "../app-global-loader/AppGlobalLoader.vue";

export default {
  props: {
    handler: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      default: 'modal-md',
    },
    footer: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "app-loader": AppLoader,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("modalHandler", false);
    },
  },
};
</script>