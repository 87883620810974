<template src="./headerSearchPopupTemplate.html" ></template>

<script>
import { Input } from '@progress/kendo-vue-inputs';
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { filter_object_generator_from_cell_data } from '@/helpers/utility';
export default {
  name: "headerSearchPopupTemplate",
  components: {
    DropDownButton,
    Popup,
    "k-button": Button,
    "k-input": Input,
    "k-checkbox": Checkbox,
  },
  props: {
    isShowFilter: {
      type: Boolean,
      default: false
    },
    anchorTag: {
      type: String,
      default: 'showFilter'
    },
    onCancel: {
      type: Function,
      default: () => { }
    },
    index: {
      type: Number,
      default: 0
    },
    cellData: {
      type: Object,
      default: () => { }
    },
    checkBoxDatas: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
  },
  data: function () {
    return {
      preservedState: {}
    };
  },
  mounted() {
    this.preservedState = { ...this?.cellData?.search_value };
  },
  methods: {
    debounceSearch(event, cellData) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.applyEmit();
      }, 600);
    },
    applyEmit() {
      const filterData = filter_object_generator_from_cell_data(this.cellData, 'search_value',)
      this.$emit('filterEmitFunc', filterData?.data, filterData?.fieldName, filterData?.parentFieldName, filterData?.subParentFieldName, filterData?.hasAnyParentField, filterData?.hasAnySubParentField);
    },
    handleChange(e, item) {
      item.isChecked = e.value;
    },
    clearSearchData() {
      this.cellData.search_value = "";
      const filterData = filter_object_generator_from_cell_data(this.cellData, 'search_value',)
      this.$emit('filterEmitFunc', filterData?.data, filterData?.fieldName, filterData?.parentFieldName, filterData?.subParentFieldName, filterData?.hasAnyParentField, filterData?.hasAnySubParentField);
    },
    onCancelClick() {
      if (this.preservedState.from != this.cellData.search_value.from || this.preservedState.to != this.cellData.search_value.to) {
        this.cellData.search_value = this.preservedState.search_value;
      }
      this.onCancel(this.index)
    },
  },
};

</script>