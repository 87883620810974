<template src="./systemDashboard.html"></template>
<style lang="scss">
@import "./systemDashboard.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { process } from "@progress/kendo-data-query";
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import headerFilterPopupTemplate from "./headerFilterPopupTemplate/headerFilterPopupTemplate.vue";
import headerFilterPopupTemplateTwo from "./headerFilterPopupTemplateTwo/headerFilterPopupTemplateTwo.vue";
import headerSearchPopupTemplate from "./headerSearchPopupTemplate/headerSearchPopupTemplate.vue";
import headerDropDownFilterPopupTemplate from "./headerDropDownFilterPopupTemplate/headerDropDownFilterPopupTemplate.vue";
import headerDropDownFilterPopupTemplateForCountry from "./headerDropDownFilterPopupTemplateForCountry/headerDropDownFilterPopupTemplateForCountry.vue";
import projectFilterPopupTemplate from "./projectFilterPopupTemplate/projectFilterPopupTemplate.vue";
import { ref, onMounted, watch } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { dashboardColumnList, getUserData } from "@/services/dashboard.service";
import IsShowFilterModel from "@/models/IsShowFilterModel";
import filterShowHideProjectViewModel from "@/models/filterShowHideProjectViewModel";
import filterShowHideLithiumModel from "@/models/filterShowHideLithiumModel";
import dashboardFilterModal from "@/models/dashboardFilterModal";
import { thisTypeAnnotation } from "@babel/types";
import { filter_object_generator_from_cell_data } from "@/helpers/utility";
import { configuration } from "@/configurationProvider";
import axios from "axios";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Switch } from "@progress/kendo-vue-inputs";
import vClickOutside from "click-outside-vue3";
import moment from "moment";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import CryptoJS from "crypto-js";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    "k-button": Button,
    "k-input": Input,
    "k-checkbox": Checkbox,
    Grid: Grid,
    Popup,
    AppGlobalLoader,
    dropdownlist: DropDownList,
    headerFilterPopupTemplate,
    headerFilterPopupTemplateTwo,
    headerSearchPopupTemplate,
    headerDropDownFilterPopupTemplate,
    headerDropDownFilterPopupTemplateForCountry,
    projectFilterPopupTemplate,
    AppSnackBar,
    "k-switch": Switch,
    Tooltip,
  },

  data: function () {
    return {
      secretKeyEncryption: "1fcd35d4efe320460827f80c89e640ce",
      take: 10,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      gridPerPageCount: 20,
      isShowFilterModel: new IsShowFilterModel(),
      isShowFilterProjectModel: new filterShowHideProjectViewModel(),
      isShowFilterLithiumModel: new filterShowHideLithiumModel(),
      filterApiModel: new dashboardFilterModal(),
      count: 0,
      gridData: [],
      group: [],
      dashboardModel: {
        name: "",
        Id: 1,
      },
      sessionId: "",
      isshowColumnsDrawer: false,
      sectorDropdownData: [],
      defaultItemSector: {
        id: 0,
        name: "Any Sector",
        alias: "all_commodities",
      },
      selectedView: { id: 1, title: "Company View", name: "sector_view" },
      viewTypes: [
        {
          id: 1,
          title: "Company View",
          name: "sector_view",
        },
        {
          id: 2,
          title: "Project View",
          name: "project_view",
        },
      ],
      viewTypesForSort: [
        {
          id: 1,
          title: "Company",
          name: "sector_view",
        },
        {
          id: 2,
          title: "Project",
          name: "project_view",
        },
      ],
      selectedViewTypeForSort: {
        id: 2,
        title: "Project",
        name: "project_view",
      },
      isSectorView: true,
      isProductView: false,
      isLithiumView: false,
      lastScrollTop: 0,
      lastScrollLeft: 0,
      isDropdeoOpen: false,
      headerCellsArray: [],
      templateCellsArray: [],
      totalCompanies: 0,
      columnsArrayForGrid: [],
      columnsArrayForFilter: [],
      columnFilterObject: {},
      projectViewInnerFilterObjectProject: {},
      projectViewInnerFilterObjectCompany: {},
      sortFilterObject: {},
      projectViewInnerSortProjectObject: {},
      projectViewInnerSortCompanyObject: {},
      projectFilter: {},
      selectedHeaderCellIndex: 0,
      selectedSortingCellIndex: null,
      companyDebounce: "",
      isDropDownFilterShowHide: {},
      widthChangeFieldNames: [
        "total_mineral_resource",
        "total_ore_resource",
        "group_mineral_resource",
        "group_ore_resource",
        "project_information",
        "share_performance",
        "volume_one",
        "financial_information",
        "main_project_information",
        "volume_one",
        "group_mineral_resource_hard_rock",
        "group_mineral_resource_clay",
        "group_mineral_resource_brine",
        "group_ore_resource_hard_rock",
        "group_ore_resource_clay",
        "group_ore_resource_brine",
        "total_mineral_resource_hard_rock",
        "total_mineral_resource_clay",
        "total_mineral_resource_brine",
        "total_ore_resource_hard_rock",
        "total_ore_resource_clay",
        "total_ore_resource_brine",
        "total_mineral_resource_lce",
        "total_ore_resource_lce",
        "feasibility_study",
      ],
      worldLengthToPixelObject: {
        1: 45,
        2: 35,
        3: 40,
        4: 45,
        5: 48,
        6: 60,
        7: 45,
        8: 68,
        9: 45,
        10: 80,
        11: 85,
        12: 45,
        13: 98,
        14: 110,
        15: 45,
        16: 45,
        17: 45,
        18: 45,
        19: 45,
        20: 45,
        21: 45,
        22: 45,
        23: 45,
        24: 45,
        25: 45,
        26: 45,
        27: 45,
        28: 45,
        29: 45,
        30: 45,
        31: 45,
        32: 45,
        33: 45,
        34: 45,
        35: 45,
        36: 45,
        37: 45,
        38: 45,
        39: 45,
        40: 45,
        41: 45,
        42: 45,
        43: 45,
        44: 45,
        45: 45,
        46: 45,
        47: 45,
        48: 45,
        49: 45,
        50: 45,
      },
      perIconPixel: 25,
      isFromReset: false,

      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-height",
      },
      isAdmin: false,
      checkedSwitch: true,
      rememberSwitch: true,
      isCommodities: false,
      mouseDownTime: 0,
      toggleClick: false,
      projectFilterPopupShow: false,
      selectedViewTypeForFilter: "",
      popupOffset: { right: 150 },
      projectViewInnerDropdownChange: 0,
      tooltipStates: {
        tooltipOne: false,
        tooltipTwo: false,
        tooltipThree: false,
        tooltipMineralHardRock: false,
        tooltipMineralBrine: false,
        tooltipMineralClay: false,
        tooltipMineralTotal: false,
        tooltipOreHardRock: false,
        tooltipOreBrine: false,
        tooltipOreClay: false,
        tooltipOreTotal: false,
        tooltipLithiumType: false,
      },
      isProjectfiltervisible: false,
      reportRadioOne: [
        {
          key: "Primary",
          label: "Primary",
          size: "medium",
        },
        {
          key: "Any",
          label: "Any",
          size: "medium",
        },
      ],
      calledPages: [],
      radio_filter: "Primary",
      isLoadingGrid: false,
      defaultLithiumType: {
          id: 0,
          name: "All",
          alias: "all",
        },
      rememberedLithiumType: null,
      lithiumTypeData: [
        {
          id: 0,
          name: "All",
          alias: "all",
        },
        {
          id: 1,
          name: "Hard Rock",
          alias: "lithium_hard_rock",
        },
        {
          id: 2,
          name: "Clay",
          alias: "lithium_clay",
        },
        {
          id: 3,
          name: "Brine",
          alias: "lithium_brine",
        },
      ],
      isLithiumTypeDropdownOpen: false,
      filteredLithiumTypeData: [],
    };
  },
  setup() {
    const tabId = ref(window.name);

    const initializeSession = () => {
      const defaultSessionModel = {
        sessionId: window.name,
        commoditiesStore: {
          id: 0,
          name: "Any Sector",
          alias: "all_commodities",
        },
        columnModelStore: [],
        columnModelStoreForCompany: [],
        columnModelStoreForProject: [],
        sectorView: { id: 1, title: "Company View", name: "sector_view" },
        columnFilterObjectStore: {},
        sortFilterObjectStore: {},
        headerCellsArrayStore: [],
        checkedSwitchStore: true,
        projectViewSort: {},
        projectViewInnerSortProject: {},
        projectViewInnerSortCompany: {},
        projectViewFilter: {},
        projectInnerViewType: {},
        projectViewInnerFilterProject: {},
        projectViewInnerFilterCompany: {},
        sectorViewSort: {},
        sectorViewFilter: {},
        isHeaderDropDownFilterCompany: {},
        isHeaderDropDownFilterProject: {},
        radio_filter: "Primary",
        projectFilter: {},
      };

      const sessionIdsAndModel = [defaultSessionModel];
      let encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(sessionIdsAndModel),
        "1fcd35d4efe320460827f80c89e640ce"
      ).toString();

      window.sessionStorage.setItem("sessionIdsAndModel", encryptedData);
      // window.sessionStorage.setItem("sessionIdsAndModel", JSON.stringify(sessionIdsAndModel));
    };

    let decryptedDataEncrypt =
      window.sessionStorage.getItem("sessionIdsAndModel");
    let decryptedData = null;
    if (decryptedDataEncrypt) {
      let bytes = CryptoJS.AES.decrypt(
        decryptedDataEncrypt,
        "1fcd35d4efe320460827f80c89e640ce"
      );
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    onMounted(() => {
      if (!tabId.value) {
        tabId.value = Math.random().toString(36).substr(2, 9); // Generate unique tabId
        window.name = tabId.value;
        initializeSession();
      } else if (!decryptedData) {
        initializeSession();
      }
    });

    return {
      tabId,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        // console.log("Route name changed:", to.name);
        this.selectedView =
          to.name === "HomeCompanies" ? this.viewTypes[0] : this.viewTypes[1];
        let payload = {
          value: this.selectedView,
        };

        this.onChangeView(payload);
      }
    },
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    isLoadingDashBoard() {
      return this.$store.state.dashboard.isLoadingDashBoard;
    },
    isCompanyView() {
      return this.$store.state.dashboard.isCompanyView;
    },
    isLoadingDashColumnData() {
      return this.$store.state.dashboard.isLoadingDashColumnData;
    },
    commoditiesList() {
      return this.$store.state.dashboard.commoditiesList;
    },
    skip() {
      return (
        this.$store.state.dashboard.selectedCommoditiesPaginationInfo.from -
        1 || 0
      );
    },
    currentPage() {
      return (
        this.$store.state.dashboard.selectedCommoditiesPaginationInfo
          .current_page || 0
      );
    },
    totalCompaniesCount() {
      return (
        this.$store.state.dashboard.selectedCommoditiesPaginationInfo.total || 0
      );
    },
    numberOfItems() {
      return (
        this.$store.state.dashboard.selectedCommoditiesPaginationInfo.total || 0
      );
    },
    selectedCommoditiesData() {
      return this.$store.state.dashboard.selectedCommoditiesData;
    },
    systemDashboardColumnListValue() {
      return this.$store.state.dashboard.systemDashboardColumnList;
    },
    isDashboardErrorNotify() {
      return this.$store.state.dashboard.isDashboardErrorNotify;
    },
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
  },
  created() {
    if (!this.loggedInUserData?.role?.alias) {
    }
  },
  mounted() {
    this.filteredLithiumTypeData = this.lithiumTypeData.slice();
    this.initialMounted();
  },
  methods: {
    initialMounted() {
      this.selectedView =
        this.$route.name === "HomeCompanies"
          ? this.viewTypes[0]
          : this.viewTypes[1];

      this.defaultItemSector = {
        id: 0,
        name:
          this.selectedView.name === "sector_view"
            ? "Any Sector"
            : "All Commodities",
        alias: "all_commodities",
      };

      this.$store.commit("SET_ISLOADING_DASHBOARD", true);

      document.addEventListener("click", this.documentClick);
      document.addEventListener("mousedown", this.onDocumentClick);
      document.addEventListener("touchstart", this.onDocumentClick);

      const tabId = ref(window.name);
      this.sessionId = tabId.value || "";

      if (tabId.value) {
        this.sessionStorageApiCall();
      } else {
        const payload = {
          commodities: this.defaultItemSector.alias,
          commodities_name: this.defaultItemSector.name,
          view: this.selectedView.name,
        };

        this.$store.dispatch("getCommodities");
        this.dashboardCompanniesFunc(payload);
        this.calledPages = [];

        this.$store.dispatch("getSelectedCommoditiesData", {
          ...payload,
          page: 1,
          is_commodities: this.isCommodities,
        });
      }

      this.headerCellsArray.forEach((cell) => {
        if (cell.is_filter_visible) {
          cell.is_filter_visible = false;
        }
      });

      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    scrollHandler: function (event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const e = event;
        if (e.target.scrollTop !== this.lastScrollTop) {
          this.lastScrollTop = e.target.scrollTop;

          const bottomOffset =
            e.target.scrollTop + e.target.clientHeight >=
            e.target.scrollHeight - e.target.clientHeight * 10;
          if (bottomOffset) {
            const nextPage = this.currentPage + 1;
            if (
              !this.calledPages.includes(nextPage) &&
              nextPage <= Math.ceil(this.numberOfItems / this.gridPerPageCount)
            ) {
              this.calledPages.push(nextPage);
              const payload = {
                commodities: this.defaultItemSector.alias,
                commodities_name: this.defaultItemSector.name,
                view: this.selectedView.name,
                page: this.currentPage + 1,
                ...(Object.keys(this.columnFilterObject).length > 0 && {
                  filters: this.columnFilterObject,
                }),
                ...(Object.keys(this.sortFilterObject).length > 0 && {
                  sorting: this.sortFilterObject,
                }),
                ...(this.selectedView.name == "sector_view"
                  ? { is_display_all: true }
                  : {
                    is_any_selected:
                      this.radio_filter == "Primary" ? false : true,
                  }),
                ...(this.selectedView.name == "sector_view" &&
                  this.defaultItemSector?.name == "Any Sector"
                  ? {
                    project_filter: this.projectFilter,
                  }
                  : {}),
                ...(this.selectedView?.name === "project_view" &&
                  this.defaultItemSector?.name === "Lithium" &&
                  this.radio_filter == 'Primary' && {
                  lithium_type: this.defaultLithiumType?.alias,
                }),
              };
              this.$store.dispatch("getSelectedCommoditiesData", payload);
            }
          }
          
        }
      }, 500);
    },
    projectFilterPopupToggle() {
      this.projectFilterPopupShow = !this.projectFilterPopupShow;
    },
    handleInnerFilterItem(e) {
      this.selectedViewTypeForFilter = e.target.innerText;
      if (e.target.innerText == "Company") {
        this.selectedViewTypeForSort = {
          id: 1,
          title: "Company",
          name: "sector_view",
        };
      } else if (e.target.innerText == "Project") {
        this.selectedViewTypeForSort = {
          id: 2,
          title: "Project",
          name: "project_view",
        };
      }
      this.projectViewInnerDropdownChange = 1;
      this.projectFilterPopupToggle();
      this.dataReload();
      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    sessionStorageApiCall() {
      let encryptedData = window.sessionStorage.getItem("sessionIdsAndModel");

      if (encryptedData) {
        let bytes = CryptoJS.AES.decrypt(
          encryptedData,
          "1fcd35d4efe320460827f80c89e640ce"
        );
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let sessionIdsAndModel = decryptedData || [];
        let index = sessionIdsAndModel.findIndex(
          (obj) => obj.sessionId === window.name
        );
        if (index === -1) return;

        const isSectorView = this.$route.name === "HomeCompanies";
        this.selectedView = isSectorView
          ? this.viewTypes[0]
          : this.viewTypes[1];

        const sessionData = sessionIdsAndModel[index];
        const isSectorViewSelected = this.selectedView.name === "sector_view";

        this.columnsArrayForFilter = isSectorViewSelected
          ? sessionData?.columnModelStoreForCompany
          : sessionData?.columnModelStoreForProject;

        this.columnFilterObject = isSectorViewSelected
          ? sessionData?.sectorViewFilter
          : sessionData?.projectViewFilter;

        this.sortFilterObject = isSectorViewSelected
          ? sessionData?.sectorViewSort
          : sessionData?.projectViewSort;

        this.defaultItemSector = sessionData?.commoditiesStore;
        if (
          this.defaultItemSector?.name == "Any Sector" ||
          this.defaultItemSector.name == "All Commodities"
        ) {
          this.defaultItemSector = {
            id: 0,
            name:
              this.selectedView.name === "sector_view"
                ? "Any Sector"
                : "All Commodities",
            alias: "all_commodities",
          };
        }
        // console.log(sessionData, "def");
        this.headerCellsArray = sessionData?.headerCellsArrayStore;
        this.checkedSwitch = sessionData?.checkedSwitchStore;
        this.rememberSwitch = this.checkedSwitch;

        this.isDropDownFilterShowHide = isSectorViewSelected
          ? sessionData?.isHeaderDropDownFilterCompany
          : sessionData?.isHeaderDropDownFilterProject;

        if (!isSectorViewSelected) {
          this.projectViewInnerFilterObjectCompany =
            sessionData?.projectViewInnerFilterCompany;
          this.projectViewInnerFilterObjectProject =
            sessionData?.projectViewInnerFilterProject;
          this.selectedViewTypeForSort = sessionData?.projectInnerViewType;
          this.projectViewInnerSortProjectObject =
            sessionData?.projectViewInnerSortProject;
          this.projectViewInnerSortCompanyObject =
            sessionData?.projectViewInnerSortCompany;
          this.radio_filter = sessionData?.radio_filter;
          this.defaultLithiumType = sessionData?.lithiumFilter;
        }

        const isSectorViewInSort =
          this.selectedViewTypeForSort?.name === "sector_view";
        this.selectedViewTypeForFilter =
          isSectorViewSelected || isSectorViewInSort ? "Company" : "Project";

        this.companyDebounce = isSectorViewSelected
          ? this.columnFilterObject?.company_name?.search_value
          : isSectorViewInSort
            ? sessionData?.projectViewInnerFilterCompany?.company_name
              ?.search_value
            : sessionData?.projectViewInnerFilterProject?.project_name
              ?.search_value;

        this.projectFilter = sessionData?.projectFilter;

        const payload = {
          commodities: this.defaultItemSector.alias,
          commodities_name: this.defaultItemSector.name,
          view: this.selectedView.name,
          ...(isSectorViewSelected
            ? {
              column_for_sector: this.columnsArrayForFilter,
              is_display_all: true,
              is_commodities: this.isCommodities,
            }
            : {
              column_for_project: this.columnsArrayForFilter,
              is_any_selected: this.radio_filter !== "Primary",
            }),
          ...(isSectorViewSelected &&
            this.defaultItemSector?.name === "Any Sector"
            ? { project_filter: this.projectFilter }
            : {}),
          ...(this.selectedView?.name === "project_view" &&
            this.defaultItemSector?.name === "Lithium" &&
            this.radio_filter == 'Primary' && {
            lithium_type: this.defaultLithiumType?.alias,
          }),
        };

        this.$store.dispatch("getCommodities");
        this.dashboardCompaniesFunc(payload);

        const dataPayload = {
          commodities: this.defaultItemSector.alias,
          commodities_name: this.defaultItemSector.name,
          view: this.selectedView.name,
          ...(Object.keys(this.columnFilterObject || {}).length && {
            filters: this.columnFilterObject,
          }),
          ...(Object.keys(this.sortFilterObject || {}).length && {
            sorting: this.sortFilterObject,
          }),
          ...(isSectorViewSelected
            ? { is_display_all: true }
            : { is_any_selected: this.radio_filter !== "Primary" }),
          ...(isSectorViewSelected &&
            this.defaultItemSector?.name === "Any Sector"
            ? { project_filter: this.projectFilter }
            : {}),
          is_commodities: this.isCommodities,
          page: 1,
          ...(this.selectedView?.name === "project_view" &&
            this.defaultItemSector?.name === "Lithium" &&
            this.radio_filter == 'Primary' && {
            lithium_type: this.defaultLithiumType?.alias,
          }),
        };

        this.$store.commit("SET_SELECTED_COMMODITIES_DATA", []);
        this.calledPages = [];
        this.$store.commit("SET_SELECTED_COMMODITIES_PAGINATION_INFO", 0);

        this.$store.dispatch("getSelectedCommoditiesData", dataPayload);

        // console.log(this.headerCellsArray, "headerCell");
      }
    },
    debounceSearch(event, cellData) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.companyDebounce = event?.value;
        cellData.search_value = this.companyDebounce;
        const filterData = filter_object_generator_from_cell_data(
          cellData,
          "search_value"
        );
        this.filterEmitFunc(
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );
      }, 600);
    },
    clearSearch(event, cellData) {
      this.companyDebounce = "";
      cellData.search_value = this.companyDebounce;
      const filterData = filter_object_generator_from_cell_data(
        cellData,
        "search_value"
      );
      this.filterEmitFunc(
        filterData?.data,
        filterData?.fieldName,
        filterData?.parentFieldName,
        filterData?.subParentFieldName,
        filterData?.hasAnyParentField,
        filterData?.hasAnySubParentField
      );
    },
    clickEventhandler(e) {
      this.$refs.companySearch.focus();
    },
    columnSortHandler(index, cellData) {
      if (this.loggedInUserData.is_trial_user == 1 && cellData?.blur) return;
      if (this.selectedSortingCellIndex != null) {
        if (this.selectedSortingCellIndex != index) {
          if (
            this.headerCellsArray?.[
              this?.selectedSortingCellIndex
            ]?.type_of_filter?.includes("sort")
          ) {
            this.headerCellsArray[
              this.selectedSortingCellIndex
            ].sort_value.is_asc = false;
            this.headerCellsArray[
              this.selectedSortingCellIndex
            ].sort_value.is_desc = false;
          }
          this.selectedSortingCellIndex = index;
        }
      } else {
        this.selectedSortingCellIndex = index;
      }

      if (this.headerCellsArray[index]?.className == "text-left") {
        if (
          !this.headerCellsArray[index].sort_value?.is_asc &&
          !this.headerCellsArray[index].sort_value?.is_desc
        ) {
          this.headerCellsArray[index].sort_value.is_asc = true;
          this.headerCellsArray[index].sort_value.is_desc = false;
          for (let i = 0; i < this.headerCellsArray.length; i++) {
            if (
              i !== index &&
              this.headerCellsArray[i]?.className == "text-left" &&
              this.headerCellsArray[i]?.type_of_filter?.includes("sort")
            ) {
              this.headerCellsArray[i].sort_value.is_asc = false;
              this.headerCellsArray[i].sort_value.is_desc = false;
            }
          }
        } else if (
          this.headerCellsArray[index].sort_value?.is_asc &&
          !this.headerCellsArray[index].sort_value?.is_desc
        ) {
          this.headerCellsArray[index].sort_value.is_asc = false;
          this.headerCellsArray[index].sort_value.is_desc = true;
          for (let i = 0; i < this.headerCellsArray.length; i++) {
            if (
              i !== index &&
              this.headerCellsArray[i]?.className == "text-left" &&
              this.headerCellsArray[i]?.type_of_filter?.includes("sort")
            ) {
              // console.log("abc-002", this.headerCellsArray[i]);
              this.headerCellsArray[i].sort_value.is_asc = false;
              this.headerCellsArray[i].sort_value.is_desc = false;
            }
          }
        } else if (
          !this.headerCellsArray[index].sort_value?.is_asc &&
          this.headerCellsArray[index].sort_value?.is_desc
        ) {
          this.headerCellsArray[index].sort_value.is_asc = false;
          this.headerCellsArray[index].sort_value.is_desc = false;
          for (let i = 0; i < this.headerCellsArray.length; i++) {
            if (
              i !== index &&
              this.headerCellsArray[i]?.className == "text-left" &&
              this.headerCellsArray[i]?.type_of_filter?.includes("sort")
            ) {
              // console.log("abc-003", this.headerCellsArray[i]);
              this.headerCellsArray[i].sort_value.is_asc = false;
              this.headerCellsArray[i].sort_value.is_desc = false;
            }
          }
        }
      } else if (this.headerCellsArray[index]?.className == "text-right") {
        if (
          !this.headerCellsArray[index]?.sort_value?.is_asc &&
          !this.headerCellsArray[index]?.sort_value?.is_desc
        ) {
          this.headerCellsArray[index].sort_value.is_asc = false;
          this.headerCellsArray[index].sort_value.is_desc = true;
          for (let i = 0; i < this.headerCellsArray.length; i++) {
            if (
              i !== index &&
              this.headerCellsArray[i]?.className == "text-right" &&
              this.headerCellsArray[i]?.type_of_filter?.includes("sort")
            ) {
              // console.log("abc-004", this.headerCellsArray[i]);
              this.headerCellsArray[i].sort_value.is_asc = false;
              this.headerCellsArray[i].sort_value.is_desc = false;
            }
          }
        } else if (
          !this.headerCellsArray[index].sort_value?.is_asc &&
          this.headerCellsArray[index].sort_value?.is_desc
        ) {
          this.headerCellsArray[index].sort_value.is_asc = true;
          this.headerCellsArray[index].sort_value.is_desc = false;
          for (let i = 0; i < this.headerCellsArray.length; i++) {
            if (
              i !== index &&
              this.headerCellsArray[i]?.className == "text-right" &&
              this.headerCellsArray[i]?.type_of_filter?.includes("sort")
            ) {
              // console.log("abc-005", this.headerCellsArray[i]);
              this.headerCellsArray[i].sort_value.is_asc = false;
              this.headerCellsArray[i].sort_value.is_desc = false;
            }
          }
        } else if (
          this.headerCellsArray[index].sort_value?.is_asc &&
          !this.headerCellsArray[index].sort_value?.is_desc
        ) {
          this.headerCellsArray[index].sort_value.is_asc = false;
          this.headerCellsArray[index].sort_value.is_desc = false;
          for (let i = 0; i < this.headerCellsArray.length; i++) {
            if (
              i !== index &&
              this.headerCellsArray[i]?.className == "text-right" &&
              this.headerCellsArray[i]?.type_of_filter?.includes("sort")
            ) {
              // console.log("abc-006", this.headerCellsArray[i]);
              this.headerCellsArray[i].sort_value.is_asc = false;
              this.headerCellsArray[i].sort_value.is_desc = false;
            }
          }
        }
      }
      let a = this.headerCellsArray.find(c => c.is_filter_visible)
      if(a){
        a.is_filter_visible = !a.is_filter_visible
      }

      const filterData = filter_object_generator_from_cell_data(
        cellData,
        "sort_value"
      );
      this.sortEmitFunc(
        filterData?.data,
        filterData?.fieldName,
        filterData?.parentFieldName,
        filterData?.subParentFieldName,
        filterData?.hasAnyParentField,
        filterData?.hasAnySubParentField
      );
    },
    resetFilters() {
      this.templateCellsArray = [];
      this.headerCellsArray = [];
      this.columnFilterObject = {};
      this.sortFilterObject = {};
      this.isDropDownFilterShowHide = {};
      this.companyDebounce = "";
      this.toggleClick = false;
      this.projectViewInnerFilterObjectProject = {};
      this.projectViewInnerFilterObjectCompany = {};
      this.projectViewInnerSortProjectObject = {};
      this.projectViewInnerSortCompanyObject = {};
      if (this.selectedView.name == "project_view") {
        this.selectedViewTypeForFilter = "Project";
        this.selectedViewTypeForSort = {
          id: 2,
          title: "Project",
          name: "project_view",
        };
      }
      this.projectFilter = {};
      var payload = {
        commodities: this.defaultItemSector.alias,
        commodities_name: this.defaultItemSector.name,
        is_commodities: this.radio_filter == "Primary" ? true : false,
        view: this.selectedView.name,
        ...(this.selectedView.name == "sector_view"
          ? {
            column_for_sector: this.columnsArrayForFilter,
            is_commodities: false,
            is_display_all: true,
          }
          : {
            column_for_project: this.columnsArrayForFilter,
            is_any_selected: this.radio_filter == "Primary" ? false : true,
          }),
        ...(this.selectedView.name == "sector_view" &&
          this.defaultItemSector?.name == "Any Sector"
          ? {
            project_filter: this.projectFilter,
          }
          : {}),
        is_reset_filters: true,
        ...(this.selectedView?.name === "project_view" &&
            this.defaultItemSector?.name === "Lithium" &&
            this.radio_filter == 'Primary' && {
            lithium_type: this.defaultLithiumType?.alias,
          }),
      };
      this.$store.commit("SET_ISLOADING_DASHBOARD", true);
      this.updateSessionModel();
      this.dashboardCompaniesFunc(payload);
      this.$store.commit("SET_SELECTED_COMMODITIES_DATA", []);
      this.calledPages = [];
      this.$store.commit("SET_SELECTED_COMMODITIES_PAGINATION_INFO", 0);
      this.$store.dispatch("getSelectedCommoditiesData", payload);
      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    dataReload() {
      let encryptedData = window.sessionStorage.getItem("sessionIdsAndModel");

      if (encryptedData) {
        let bytes = CryptoJS.AES.decrypt(
          encryptedData,
          "1fcd35d4efe320460827f80c89e640ce"
        );
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let sessionIdsAndModel = decryptedData || [];
        let index = sessionIdsAndModel.findIndex(
          (obj) => obj.sessionId === window.name
        );
        if (index === -1) return;

        const sessionData = sessionIdsAndModel[index] || {};
        this.columnFilterObject =
          this.selectedView?.name === "sector_view"
            ? sessionData?.sectorViewFilter || {}
            : sessionData?.projectViewFilter || {};

        this.sortFilterObject =
          this.selectedView?.name === "sector_view"
            ? sessionData?.sectorViewSort || {}
            : sessionData?.projectViewSort || {};

        this.isDropDownFilterShowHide =
          this.selectedView?.name === "sector_view"
            ? sessionData?.isHeaderDropDownFilterCompany || {}
            : sessionData?.isHeaderDropDownFilterProject || {};

        this.headerCellsArray = [];
        this.columnsArrayForFilter =
          this.selectedView?.name === "sector_view"
            ? sessionData?.columnModelStoreForCompany || []
            : sessionData?.columnModelStoreForProject || [];

        this.isCommodities = true;
        this.selectedViewTypeForFilter =
          this.selectedView?.name === "sector_view"
            ? "Company"
            : this.selectedViewTypeForSort?.name === "sector_view"
              ? "Company"
              : "Project";

        if (this.selectedView?.name === "project_view") {
          const isCompanyView =
            this.selectedViewTypeForSort?.name === "sector_view";
          if (isCompanyView) {
            this.companyDebounce =
              sessionData?.projectViewInnerFilterCompany?.company_name
                ?.search_value || "";
            this.projectViewInnerSortCompanyObject =
              sessionData?.projectViewInnerSortCompany || {};
          } else {
            this.companyDebounce =
              sessionData?.projectViewInnerFilterProject?.project_name
                ?.search_value || "";
            this.projectViewInnerSortProjectObject =
              sessionData?.projectViewInnerSortProject || {};
          }
        } else {
          this.companyDebounce =
            this.columnFilterObject?.company_name?.search_value || "";
        }

        if (
          this.selectedView?.name === "project_view" &&
          this.selectedViewTypeForSort?.name === "sector_view"
        ) {
          const searchTerm =
            sessionData?.projectViewInnerFilterCompany?.company_name;
          delete this.columnFilterObject?.project_name;
          if (searchTerm) {
            this.columnFilterObject.company_name = searchTerm;
          }
        } else if (
          this.selectedView?.name === "project_view" &&
          this.selectedViewTypeForSort?.name === "project_view"
        ) {
          const searchTerm =
            sessionData?.projectViewInnerFilterProject?.project_name;
          delete this.columnFilterObject?.company_name;
          if (searchTerm) {
            this.columnFilterObject.project_name = searchTerm;
          }
        }

        const payload = {
          commodities: this.defaultItemSector?.alias || "",
          commodities_name: this.defaultItemSector?.name || "",
          view: this.selectedView?.name || "",
          ...(this.selectedView?.name === "sector_view"
            ? {
              column_for_sector: this.columnsArrayForFilter,
              is_display_all: true,
            }
            : {
              column_for_project: this.columnsArrayForFilter,
              is_any_selected: this.radio_filter !== "Primary",
            }),
          ...(Object.keys(this.columnFilterObject).length > 0 && {
            filters: this.columnFilterObject,
          }),
          ...(Object.keys(this.sortFilterObject).length > 0 && {
            sorting: this.sortFilterObject,
          }),
          is_commodities: this.isCommodities,
          ...(this.selectedView?.name === "sector_view" && {
            toggle_click: this.toggleClick,
          }),
          ...(this.selectedView?.name === "project_view" &&
            this.selectedViewTypeForFilter === "Company" && {
            select_companies: true,
          }),
          ...(this.projectViewInnerDropdownChange === 1 && {
            project_view_inner_dropdown_change:
              this.projectViewInnerDropdownChange,
          }),
          ...(this.selectedView?.name === "sector_view" &&
            this.defaultItemSector?.name === "Any Sector" && {
            project_filter: this.projectFilter,
          }),
          ...(this.selectedView?.name === "project_view" &&
            this.defaultItemSector?.name === "Lithium" &&
            this.radio_filter == 'Primary' && {
            lithium_type: this.defaultLithiumType?.alias,
          }),
        };
        
        // Remove feasibility study from payload on commodity change
        if(payload?.sorting?.feasibility_study){
          delete payload.sorting.feasibility_study
        }
        if(payload?.filters?.feasibility_study){
          delete payload.filters.feasibility_study
        }

        this.$store.commit("SET_SELECTED_COMMODITIES_DATA", []);
        this.$store.commit("SET_ISLOADING_DASHBOARD", true);
        this.$store.commit("SET_ISLOADING_DASHBOARD_COLUMNDATA", true);
        this.dashboardCompaniesFunc(payload);
        this.calledPages = [];
        this.$store.commit("SET_SELECTED_COMMODITIES_PAGINATION_INFO", 0);
        this.$store.dispatch("getSelectedCommoditiesData", payload);
        if (this.projectViewInnerDropdownChange === 1) {
          this.projectViewInnerDropdownChange = 0;
        }
      }
    },
    onRowClick(event, props) {
      this.goToOverviewCompany(props?.dataItem?.company_uuid);
    },
    projectfilterTemplateHandler() {
      this.isProjectfiltervisible = true;
    },
    filterTemplateHandler(index) {
      this.selectedHeaderCellIndex = index;
      this.headerCellsArray.map((item, i) => {
        if (i == index) {
          item.is_filter_visible = true;
          this.$nextTick(() => {
            this.$refs?.searchTemplateRef?.$refs?.projectSearch?.focus();
          });
        } else {
          item.is_filter_visible = false;
        }
      });
    },
    onCancelFilter(index) {
      this.headerCellsArray[index].is_filter_visible = false;
      this.updateSessionModel();
    },
    goToEditCompany(rowId) {
      this.$router.push({
        name: "editCompany",
        params: {
          id: rowId,
        },
      });
    },
    goToOverviewCompany(rowId) {
      this.$router.push({
        name: "companyOverview",
        params: {
          id: rowId,
        },
      });
    },
    // onFocusSector(e) {
    //   this.sectorDropdownData = this.commoditiesList;
    //   let updatedCommoditiesList = [...this.sectorDropdownData];
    //   if (this.selectedView.name != "sector_view") {
    //     updatedCommoditiesList.unshift({
    //       id: 0,
    //       name: "All Commodities",
    //       alias: "all_commodities",
    //     });
    //   } else {
    //     updatedCommoditiesList.unshift({
    //       id: 0,
    //       name: "Any Sector",
    //       alias: "all_commodities",
    //     });
    //   }
    //   this.sectorDropdownData = updatedCommoditiesList;
    // },
    onFocusSector() {
      const isSectorView = this.selectedView.name === "sector_view";
      const defaultItem = {
        id: 0,
        name: isSectorView ? "Any Sector" : "All Commodities",
        alias: "all_commodities"
      };

      this.sectorDropdownData = [defaultItem, ...this.commoditiesList];
    },
    onOpenCDD(field) {
      if(field == 'commodity') this.isDropdeoOpen = true;
        else this.isLithiumTypeDropdownOpen = true
    },
    onCloseCDD(field) {
      if(field == 'commodity') this.isDropdeoOpen = false;
        else this.isLithiumTypeDropdownOpen = false
    },

    filterChangeSector(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataSector(event.filter)
          : this.commoditiesList?.slice();
      this.sectorDropdownData = newData;
    },
    filterDataSector(filter) {
      const data = this.commoditiesList?.slice();
      return filterBy(data, filter);
    },
    filterChangeLithiumType(event){
      const newData = 
          event.filter.value.length >= 1
          ? this.filterLithiumData(event.filter)
          : this.lithiumTypeData?.slice()
      this.filteredLithiumTypeData = newData
    },
    filterLithiumData(filter) {
      const data = this.lithiumTypeData?.slice();
      return filterBy(data, filter);
    },
    handleCountry(event) {
      this.defaultItemSector = event.value;
    },
    handleColumnParentCeckbox(event, sector) {
      if (sector?.child?.length > 0)
        sector.child.forEach((child) => (child.is_selected = event.value));
    },
    handleColumnChildCeckbox(event, sector) {
      if (sector?.child) {
        if (sector?.child.some((child) => child.is_selected)) {
          sector.is_selected = true;
        } else {
          sector.is_selected = false;
        }
      }
    },
    generateHeaderCellsArray(data) {
      this.templateCellsArray = [];
      const cellsArray = [];
      data.forEach((headers) => {
        if (headers?.headerCell) {
          const prevCellData = this.headerCellsArray.find(
            (cell) => cell.headerCell == headers?.headerCell
          );
          const data = {
            ...headers,
            ...(prevCellData &&
              Object.keys(prevCellData).length > 0 && { ...prevCellData }),
          };
          cellsArray.push(data);
        }
        if (headers?.children) {
          headers?.children?.forEach((item) => {
            if (item?.children) {
              item.children.forEach((child) => {
                if (child?.headerCell) {
                  const prevCellData = this.headerCellsArray.find(
                    (cell) => cell.headerCell == child?.headerCell
                  );
                  const data = {
                    ...child,
                    ...(prevCellData &&
                      Object.keys(prevCellData).length > 0 && {
                      ...prevCellData,
                    }),
                  };
                  cellsArray.push(data);
                }
                if (child?.cell && child?.cell != "companyTemplate") {
                  this.templateCellsArray.push(child);
                }
              });
            }
            if (item?.cell && item?.cell != "companyTemplate") {
              this.templateCellsArray.push(item);
            }
            if (item?.headerCell) {
              const prevCellData = this.headerCellsArray.find(
                (cell) => cell.headerCell == item?.headerCell
              );
              const data = {
                ...item,
                ...(prevCellData &&
                  Object.keys(prevCellData).length > 0 && { ...prevCellData }),
              };
              cellsArray.push(data);
            }
          });
        }
      });
      // console.log(this.headerCellsArray, 'ggg')
      this.headerCellsArray = structuredClone(cellsArray);
    },
    dashboardCompaniesFunc(payload) {
      dashboardColumnList(payload)
        .then((response) => {
          const columns = structuredClone(
            this.selectedView.name == "sector_view"
              ? response?.data?.result?.column
                ?.header_column_title_for_sector_view
              : response?.data?.result?.column
                ?.header_column_title_for_project_view
          );

          columns.map((item) => {
            if (item.children) {
              item.children.map((child) => {
                if (child?.children) {
                  child.children.map((subChild) => {
                    if (
                      subChild?.headerCell &&
                      this.widthChangeFieldNames.includes(
                        subChild?.sub_parent_field_name
                      )
                    ) {
                      subChild.width =
                        `${this.worldLengthToPixelObject[
                        subChild?.title.length
                        ] +
                        subChild?.type_of_filter?.length * this.perIconPixel
                        }px` || "150px";
                    }
                  });
                }
                if (
                  child?.headerCell &&
                  this.widthChangeFieldNames.includes(child?.parent_field_name)
                ) {
                  // console.log(child.field)
                  if (child.field == "average_volume") {
                    child.width = "160px";
                  } else if (child.field == "state") {
                    child.width = "195px";
                  } else if (child.field == "status") {
                    child.width = "100px";
                  } else if (child.field == "stage") {
                    child.width = "155px";
                  } else if (child.field == "project_name") {
                    child.width = "235px";
                  } else if (child.field == "country") {
                    child.width = "170px";
                  } else if (child.field == "ownership_note") {
                    child.width = "350px";
                  } else if (
                    child.field == "main_commodities" ||
                    child.field == "other_commodities"
                  ) {
                    child.width = "190px";
                  } else if (child.field == "current_ownership") {
                    child.width = "150px";
                  } else if (child.field == "ultimate_ownership") {
                    child.width = "140px";
                  } else if (child.field == "average_value") {
                    child.width = "150px";
                  } else if (child.field == "intraday_rvol") {
                    child.width = "130px";
                  } else if (child.field == "weekly_rvol") {
                    child.width = "140px";
                  } else if (child.field == "market_cap") {
                    child.width = "135px";
                  } else if (child.field == "net_cash_percentage") {
                    child.width = "130px";
                  } else if (
                    child.field == "mineral_tonnes" ||
                    child.field == "ore_tonnes" ||
                    child.field == "mineral_clay_tonnes_clay" ||
                    child.field == "mineral_hard_rock_tonnes_hard_rock" ||
                    child.field == "ore_hard_rock_tonnes_hard_rock" ||
                    child.field == "ore_clay_tonnes_clay"
                  ) {
                    child.width = "135px";
                  } else if (
                    child.field == "mineral_mc_au_m_koz" ||
                    child.field == "ore_mc_au_m_koz"
                  ) {
                    child.width = "163px";
                  } else if (
                    child.field == "mineral_mc_b2o3_m_mt" ||
                    child.field == "ore_mc_b2o3_m_mt"
                  ) {
                    child.width = "165px";
                  } else if (
                    child.field == "mineral_mc_sb_m_kt" ||
                    child.field == "ore_mc_sb_m_kt"
                  ) {
                    child.width = "153px";
                  } else if (
                    child.field == "mineral_mc_co_m_t" ||
                    child.field == "ore_mc_co_m_t"
                  ) {
                    child.width = "148px";
                  } else if (
                    child.field == "mineral_mc_cu_m_kt" ||
                    child.field == "ore_mc_cu_m_kt"
                  ) {
                    child.width = "153px";
                  } else if (
                    child.field == "mineral_carrots_ct_t" ||
                    child.field == "ore_carrots_ct_t"
                  ) {
                    child.width = "142px";
                  } else if (
                    child.field == "mineral_contained_ct_mct" ||
                    child.field == "ore_contained_ct_mct"
                  ) {
                    child.width = "173px";
                  } else if (
                    child.field == "mineral_mc_contained_m_mct" ||
                    child.field == "ore_mc_contained_m_mct"
                  ) {
                    child.width = "206px";
                  } else if (
                    child.field == "mineral_graphite_kt" ||
                    child.field == "ore_graphite_kt"
                  ) {
                    child.width = "140px";
                  } else if (
                    child.field == "mineral_mc_graphite_m_kt" ||
                    child.field == "ore_mc_graphite_m_kt"
                  ) {
                    child.width = "187px";
                  } else if (
                    child.field == "mineral_mc_hms_m_mt" ||
                    child.field == "ore_mc_hms_m_mt"
                  ) {
                    child.width = "170px";
                  } else if (
                    child.field == "mineral_mc_pb_m_kt" ||
                    child.field == "ore_mc_pb_m_kt"
                  ) {
                    child.width = "152px";
                  } else if (
                    child.field == "mineral_brine_brine_volume_mm3" ||
                    child.field == "ore_brine_brine_volume_mm3"
                  ) {
                    child.width = "162px";
                  } else if (
                    child.field == "mineral_brine_avg_li_mg_l" ||
                    child.field == "ore_brine_avg_li_mg_l"
                  ) {
                    child.width = "148px";
                  } else if (
                    child.field == "mineral_mc_mgo_m_mt" ||
                    child.field == "ore_mc_mgo_m_mt"
                  ) {
                    child.width = "168px";
                  } else if (
                    child.field == "mineral_mc_mn_m_mt" ||
                    child.field == "ore_mc_mn_m_mt"
                  ) {
                    child.width = "158px";
                  } else if (
                    child.field == "mineral_mc_mo_m_kt" ||
                    child.field == "ore_mc_mo_m_kt"
                  ) {
                    child.width = "155px";
                  } else if (
                    child.field == "mineral_mc_contained_m_kt" ||
                    child.field == "ore_mc_contained_m_kt"
                  ) {
                    child.width = "150px";
                  } else if (
                    child.field == "mineral_mc_nb2o5_m_kt" ||
                    child.field == "ore_mc_nb2o5_m_kt"
                  ) {
                    child.width = "170px";
                  } else if (
                    child.field == "mineral_mc_3e_m_koz" ||
                    child.field == "ore_mc_3e_m_koz"
                  ) {
                    child.width = "160px";
                  } else if (
                    child.field == "mineral_mc_p2o5_m_mt" ||
                    child.field == "ore_mc_p2o5_m_mt"
                  ) {
                    child.width = "165px";
                  } else if (
                    child.field == "mineral_brine_volume_mm3" ||
                    child.field == "ore_brine_volume_mm3"
                  ) {
                    child.width = "182px";
                  } else if (
                    child.field == "mineral_mc_k_m_mt" ||
                    child.field == "ore_mc_k_m_mt"
                  ) {
                    child.width = "150px";
                  } else if (
                    child.field == "mineral_drainable_brine_mass_mt" ||
                    child.field == "ore_drainable_brine_mass_mt"
                  ) {
                    child.width = "155px";
                  } else if (
                    child.field == "mineral_total_brine_mass_mt" ||
                    child.field == "ore_total_brine_mass_mt"
                  ) {
                    child.width = "140px";
                  } else if (
                    child.field == "mineral_mc_treo_m_kt" ||
                    child.field == "ore_mc_treo_m_kt"
                  ) {
                    child.width = "170px";
                  } else if (
                    child.field == "mineral_mc_rb2o_m_kt" ||
                    child.field == "ore_mc_rb2o_m_kt"
                  ) {
                    child.width = "165px";
                  } else if (
                    child.field == "mineral_mc_ag_m_moz" ||
                    child.field == "ore_mc_ag_m_moz"
                  ) {
                    child.width = "165px";
                  } else if (
                    child.field == "mineral_mc_ta2o5_m_t" ||
                    child.field == "ore_mc_ta2o5_m_t"
                  ) {
                    child.width = "160px";
                  } else if (
                    child.field == "mineral_mc_sn_m_kt" ||
                    child.field == "ore_mc_sn_m_kt"
                  ) {
                    child.width = "155px";
                  } else if (
                    child.field == "mineral_mc_wo3_m_kt" ||
                    child.field == "ore_mc_wo3_m_kt"
                  ) {
                    child.width = "158px";
                  } else if (
                    child.field == "mineral_mc_u3o8_m_mlbs" ||
                    child.field == "ore_mc_u3o8_m_mlbs"
                  ) {
                    child.width = "180px";
                  } else if (
                    child.field == "mineral_mc_v2o5_m_kt" ||
                    child.field == "ore_mc_v2o5_m_kt"
                  ) {
                    child.width = "162px";
                  } else if (
                    child.field == "mineral_mc_zn_m_kt" ||
                    child.field == "ore_mc_zn_m_kt" ||
                      child.field == "uranium_price"                    
                  ) {
                    child.width = "153px";
                  } else if (
                    child.field == "mineral_p2o5_as_apatite" ||
                    child.field == "ore_p2o5_as_apatite" ||
                    child.field == "study_type_short" ||
                    child.field == "gold_price"
                    ) {
                      child.width = "130px";
                    } else if ( 
                        child.field == "aisc"
                    ) {
                      child.width = "115px";
                    } else if (
                      child.field == "mineral_cao_as_calcite" ||
                      child.field == "ore_cao_as_calcite"
                  ) {
                    child.width = "125px";
                  } else if (                                          
                      child.field == "mining_method" ||
                      child.field == "common_tax_payback_period" ||
                      child.field == "common_pre_post_tax" ||
                      child.field == "payback_period" ||
                      child.field == "mine_life"
                  ) {
                    child.width = "120px";
                  } else if (
                      child.field == "annual_prod" ||
                      child.field == "initial_cap" ||
                      child.field == "market_cap_feasibility" ||
                      child.field == "copper_price"
                  ) {
                    child.width = "140px";
                  } else if (
                      child.field == "common_tax_discount_rate" ||
                      child.field == "common_tax_irr"
                  ) {
                    child.width = "107px";
                  }  else if (
                      child.field == "common_tax_npv" ||
                      child.field == "release_date"
                  ) {
                    child.width = "100px";
                  } else {
                      child.width = "118px";
                  }
                }
              });
            } else {
              if (item?.headerCell) {
                item.width =
                  `${this.worldLengthToPixelObject[item?.title.length] +
                  item?.type_of_filter.length * this.perIconPixel
                  }px` || "150px";
              }
            }
          });
          if (this.selectedView.name == "sector_view") {
            this.generateHeaderCellsArray(
              response?.data?.result?.column
                ?.header_column_title_for_sector_view
            );
            this.columnsArrayForGrid = columns;
            // console.log(this.columnsArrayForGrid)
            this.columnsArrayForFilter =
              response?.data?.result?.column?.column_for_sector;
          } else {
            this.generateHeaderCellsArray(
              response?.data?.result?.column
                ?.header_column_title_for_project_view
            );
            this.columnsArrayForGrid = columns;
            // console.log(this.columnsArrayForGrid)
            this.columnsArrayForFilter =
              response?.data?.result?.column?.column_for_project;
          }
          this.updateSessionModel();
          this.$store.commit("SET_ISLOADING_DASHBOARD", false);
          this.$store.commit("SET_ISLOADING_DASHBOARD_COLUMNDATA", false);
        })
        .catch((error) => {
          this.$store.commit("SET_ISLOADING_DASHBOARD", false);
        });
    },
    dashboardTableToProject(item) {
      this.$router.push({
        name: "projectView",
        params: {
          id: item.company_uuid,
          projectId: item.project_uuid,
        },
      });
    },
    handleTableToProjectMouseDown() {
      this.mouseDownTime = Date.now();
    },
    handleTableToProjectMouseUp(event, item) {
      const mouseUpTime = Date.now();
      const clickDuration = mouseUpTime - this.mouseDownTime;
      if (clickDuration > 200) {
        return;
      } else if(!['trader_user'].includes(this.loggedInUserData?.role?.alias)) {
        this.$router.push({
          name: "projectView",
          params: {
            id: item.company_uuid,
            projectId: item.project_uuid,
          },
        });
      }
    },
    filterData(items) {
      return items.filter((x) => x.title == "Main Project Information")[0]
        ?.children[1]?.country_list;
    },
    projectDropdownOutside() {
      this.projectFilterPopupShow = false;
    },
    documentClick(e) {
      const el = e.target;
      const el_popup = document.getElementById("searchBox");
      const el_button = document.getElementById("buttonEl");
      if (el_popup !== null && el_button !== null) {
        if (el_popup.contains(el)) {
        } else if (el_button.contains(el)) {
        } else {
          this.isshowColumnsDrawer = false;
        }
      }
    },
    onDocumentClick(e) {
      const el = e.target;
      const el_popup = document.getElementById("filter");
      if (el_popup !== null) {
        if (el_popup.contains(el)) {
        } else {
          this.headerCellsArray[
            this.selectedHeaderCellIndex
          ].is_filter_visible = false;
          if (
            !this.headerCellsArray[this.selectedHeaderCellIndex]?.is_apply &&
            (this.headerCellsArray[this.selectedHeaderCellIndex]?.filter_value
              ?.from > 0 ||
              this.headerCellsArray[this.selectedHeaderCellIndex]?.filter_value
                ?.to > 0)
          ) {
            this.headerCellsArray[
              this.selectedHeaderCellIndex
            ].filter_value.from = null;
            this.headerCellsArray[
              this.selectedHeaderCellIndex
            ].filter_value.to = null;
          }
        }
      }
    },
    handleViewTypeSort(e) {
      this.selectedViewTypeForSort = e.value;
      this.refetchAfterFilter();
    },
    filterEmitFunc(
      data,
      fieldName,
      parentFieldName,
      subParentFieldName,
      hasAnyParentField,
      hasAnySubParentField
    ) {
      // console.log(data)
      if (hasAnySubParentField) {
        if (this.columnFilterObject[subParentFieldName]) {
          if (this.columnFilterObject[subParentFieldName][parentFieldName]) {
            this.columnFilterObject[subParentFieldName][parentFieldName] = {
              ...this.columnFilterObject[subParentFieldName][parentFieldName],
              ...data,
            };
          } else {
            this.columnFilterObject[subParentFieldName][parentFieldName] = data;
          }
        } else {
          this.columnFilterObject[subParentFieldName] = {
            [parentFieldName]: data,
          };
        }
      } else if (hasAnyParentField) {
        if (this.columnFilterObject[fieldName]) {
          this.columnFilterObject[fieldName] = {
            ...this.columnFilterObject[fieldName],
            ...data,
          };
        } else {
          this.columnFilterObject[fieldName] = data;
        }
      } else {
        this.columnFilterObject[fieldName] = data;
      }
      this.refetchAfterFilter();
    },
    sortEmitFunc(
      data,
      fieldName,
      parentFieldName,
      subParentFieldName,
      hasAnyParentField,
      hasAnySubParentField
    ) {
      this.sortFilterObject = {};
      if (hasAnySubParentField) {
        if (this.sortFilterObject[subParentFieldName]) {
          if (this.sortFilterObject[subParentFieldName][parentFieldName]) {
            this.sortFilterObject[subParentFieldName][parentFieldName] = {
              ...this.sortFilterObject[subParentFieldName][parentFieldName],
              ...data,
            };
          } else {
            this.sortFilterObject[subParentFieldName][parentFieldName] = data;
          }
        } else {
          this.sortFilterObject[subParentFieldName] = {
            [parentFieldName]: data,
          };
        }
      } else if (hasAnyParentField) {
        if (this.sortFilterObject[fieldName]) {
          this.sortFilterObject[fieldName] = {
            ...this.sortFilterObject[fieldName],
            ...data,
          };
        } else {
          this.sortFilterObject[fieldName] = data;
        }
      } else {
        this.sortFilterObject[fieldName] = data;
      }
      this.projectSortWithFilter = true;
      this.refetchAfterFilter();
    },
    dropDownFilterShowHide(data, cellName) {
      const newData = { [cellName]: data };
      this.isDropDownFilterShowHide = {
        ...this.isDropDownFilterShowHide,
        ...newData,
      };
      this.updateSessionModel();
    },
    updateColumnFilterObject(parent, field) {
      if (Object.keys(this.columnFilterObject[parent]).includes(field)) {
        if (this.columnFilterObject[parent].hasOwnProperty(field)) {
          delete this.columnFilterObject[parent][field];
          this.refetchAfterFilter();
        }
      }
    },

    refetchAfterFilter() {
      if (
        this.selectedView.name == "sector_view" &&
        (this.columnFilterObject?.main_project_information ||
          this.columnFilterObject?.financial_information)
      ) {
        if (
          this.columnFilterObject?.main_project_information &&
          Object.keys(this.columnFilterObject?.main_project_information)
            .length == 0
        ) {
          this.columnFilterObject = {};
        }
        if (
          this.columnFilterObject?.financial_information &&
          Object.keys(this.columnFilterObject?.financial_information).length ==
          0
        ) {
          this.columnFilterObject = {};
        }
      }

      if (
        this.selectedView?.name == "project_view" &&
        !this.projectSortWithFilter
      ) {
        let filter = this.columnFilterObject;
        // this.columnFilterObject = this.selectedViewTypeForSort?.name == "sector_view" ? sessionIdsAndModel[index]?.sectorViewFilter : sessionIdsAndModel[index]?.projectViewFilter;
        if (this.selectedViewTypeForSort?.name == "sector_view") {
          if (filter?.project_name) {
            const value = filter?.project_name;
            delete filter.project_name;
            filter.company_name = value;
          }
          this.projectViewInnerFilterObjectCompany = filter;
          this.companyDebounce =
            this.projectViewInnerFilterObjectCompany?.company_name?.search_value;
        } else {
          if (filter?.company_name) {
            delete filter?.company_name;
          }
          this.projectViewInnerFilterObjectProject = filter;
          this.companyDebounce =
            this.projectViewInnerFilterObjectProject?.project_name?.search_value;
        }
      }
      if (
        this.selectedView?.name == "project_view" &&
        this.selectedViewTypeForSort?.name == "sector_view" &&
        this.projectSortWithFilter
      ) {
        let filter = this.sortFilterObject;
        let value = this?.sortFilterObject?.project_name;
        delete filter.project_name;
        filter.company_asx_code = value;
        this.projectViewInnerSortCompanyObject = filter;
      } else if (
        this.selectedView?.name == "project_view" &&
        this.selectedViewTypeForSort?.name == "project_view" &&
        this.projectSortWithFilter
      ) {
        this.projectViewInnerSortProjectObject = this.sortFilterObject;
      }

      this.$store.commit("SET_SELECTED_COMMODITIES_DATA", []);
      this.calledPages = [];
      // this.calledPages.push(1);
      this.$store.commit("SET_SELECTED_COMMODITIES_PAGINATION_INFO", 0);
      this.$store.dispatch("getSelectedCommoditiesData", {
        commodities: this.defaultItemSector.alias,
        commodities_name: this.defaultItemSector.name,
        view: this.selectedView.name,
        ...(Object.keys(this.columnFilterObject).length > 0 && {
          filters: this.columnFilterObject,
        }),
        ...(Object.keys(this.sortFilterObject).length > 0 && {
          sorting: this.sortFilterObject,
        }),
        ...(this.selectedView.name == "sector_view"
          ? { is_display_all: true }
          : { is_any_selected: this.radio_filter == "Primary" ? false : true }),
        ...(this.selectedView?.name == "project_view" &&
          this.selectedViewTypeForFilter == "Company" && {
          select_companies: true,
        }),
        ...(this.selectedView.name == "sector_view" &&
          this.defaultItemSector?.name == "Any Sector"
          ? {
            project_filter: this.projectFilter,
          }
          : {}),
        is_commodities: false,
        page: 1,
        ...(this.selectedView?.name === "project_view" &&
          this.defaultItemSector?.name === "Lithium" &&
          this.radio_filter == 'Primary' && {
          lithium_type: this.defaultLithiumType?.alias,
        }),
      });
      this.projectSortWithFilter = false;
      this.updateSessionModel();
    },
    onChangeView(e){
      this.selectedView = e.value;
      const isAllCommodities = this.defaultItemSector.alias === "all_commodities";
      const isSectorView = this.selectedView.name === "sector_view";

      this.defaultItemSector = {
        id: 0,
        name: isAllCommodities && isSectorView ? "Any Sector" : "All Commodities",
        alias: "all_commodities"
      };

      this.dataReload();
      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    // onChangeView(e) {
    //   const value = e.value;
    //   this.selectedView = value;
    //   if (
    //     this.defaultItemSector.alias == "all_commodities" &&
    //     this.selectedView.name == "sector_view"
    //   ) {
    //     this.defaultItemSector = {
    //       id: 0,
    //       name: "Any Sector",
    //       alias: "all_commodities",
    //     };
    //   } else if (
    //     this.defaultItemSector.alias == "all_commodities" &&
    //     this.selectedView.name != "sector_view"
    //   ) {
    //     this.defaultItemSector = {
    //       id: 0,
    //       name: "All Commodities",
    //       alias: "all_commodities",
    //     };
    //   }
    //   this.dataReload();
    //   this.$nextTick(() => {
    //     this.enableDragScroll();
    //   });
    // },
    getData(data) {
      return process(data, {
        group: this.group,
      });
    },
    goToCreateCompany() {
      this.$router.push({ name: "createCompany" });
    },
    // applyColumn() {
    //   let payload = {};
    //   if (this.selectedView.name == "sector_view") {
    //     payload = {
    //       commodities: this.defaultItemSector.alias,
    //       commodities_name: this.defaultItemSector.name,
    //       view: this.selectedView.name,
    //       column_for_sector: this.columnsArrayForFilter,
    //       is_display_all: true,
    //       ...(this.selectedView?.name === "project_view" &&
    //         this.defaultItemSector?.name === "Lithium" &&
    //         this.radio_filter == 'Primary' && {
    //         lithium_type: this.defaultLithiumType?.alias,
    //       }),
    //     };
    //   } else {
    //     payload = {
    //       commodities: this.defaultItemSector.alias,
    //       commodities_name: this.defaultItemSector.name,
    //       view: this.selectedView.name,
    //       column_for_project: this.columnsArrayForFilter,
    //       is_any_selected: this.radio_filter == "Primary" ? false : true,
    //       ...(this.selectedView?.name === "project_view" &&
    //         this.defaultItemSector?.name === "Lithium" &&
    //         this.radio_filter == 'Primary' && {
    //         lithium_type: this.defaultLithiumType?.alias,
    //       }),
    //     };
    //   }
    //   this.dashboardCompaniesFunc(payload);
    //   this.updateSessionModel();
    //   this.isshowColumnsDrawer = false;
    // },
    applyColumn() {
      const isSectorView = this.selectedView.name === "sector_view";
      const isProjectView = this.selectedView.name === "project_view";
      const isLithiumPrimary = 
        this.defaultItemSector?.name === "Lithium" && 
        this.radio_filter === "Primary";

      // Create a base payload object
      let payload = {
        commodities: this.defaultItemSector.alias,
        commodities_name: this.defaultItemSector.name,
        view: this.selectedView.name,
        ...(isProjectView && isLithiumPrimary && {
          lithium_type: this.defaultLithiumType?.alias,
        }),
      };

      // Add view-specific properties
      if (isSectorView) {
        Object.assign(payload, {
          column_for_sector: this.columnsArrayForFilter,
          is_display_all: true,
        });
      } else {
        Object.assign(payload, {
          column_for_project: this.columnsArrayForFilter,
          is_any_selected: this.radio_filter !== "Primary",
        });
      }

      // Execute functions and close the columns drawer
      this.dashboardCompaniesFunc(payload);
      this.updateSessionModel();
      this.isshowColumnsDrawer = false;
    },
    resetColumn() {
      let payload = {};
      payload = {
        commodities: this.defaultItemSector.alias,
        commodities_name: this.defaultItemSector.name,
        view: this.selectedView.name,
        ...(this.selectedView.name == "sector_view"
          ? {
            column_for_sector: this.columnsArrayForFilter,
            is_commodities: true,
            is_display_all: true,
          }
          : {
            column_for_project: this.columnsArrayForFilter,
            is_any_selected: this.radio_filter == "Primary" ? false : true,
            is_commodities: true,
          }),
        ...(this.selectedView.name == "sector_view"
          ? { is_display_all: true }
          : {}),
        is_reset: true,
        ...(this.selectedView?.name === "project_view" &&
            this.defaultItemSector?.name === "Lithium" &&
            this.radio_filter == 'Primary' && {
            lithium_type: this.defaultLithiumType?.alias,
          }),
      };
      this.dashboardCompaniesFunc(payload);
      this.updateSessionModel();
      this.isshowColumnsDrawer = false;
    },
    updateSessionModel() {
      let encryptedData = window.sessionStorage.getItem("sessionIdsAndModel");
      if (encryptedData) {
        // Decrypt the data
        let bytes = CryptoJS.AES.decrypt(
          encryptedData,
          "1fcd35d4efe320460827f80c89e640ce"
        );
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let sessionIdsAndModel = decryptedData || [];
        let index = sessionIdsAndModel.findIndex(
          (obj) => obj.sessionId === window.name
        );

        if (this.selectedView.name == "sector_view") {
          sessionIdsAndModel[index].sectorViewSort = this.sortFilterObject;
          sessionIdsAndModel[index].sectorViewFilter = this.columnFilterObject;
          sessionIdsAndModel[index].isHeaderDropDownFilterCompany =
            this.isDropDownFilterShowHide;
          sessionIdsAndModel[index].columnModelStoreForCompany =
            this.columnsArrayForFilter;
        } else {
          sessionIdsAndModel[index].projectViewSort = this.sortFilterObject;
          sessionIdsAndModel[index].projectViewFilter = this.columnFilterObject;
          sessionIdsAndModel[index].isHeaderDropDownFilterProject =
            this.isDropDownFilterShowHide;
          sessionIdsAndModel[index].columnModelStoreForProject =
            this.columnsArrayForFilter;
        }

        sessionIdsAndModel[index].commoditiesStore = this.defaultItemSector;
        sessionIdsAndModel[index].columnModelStore = this.columnsArrayForFilter;
        sessionIdsAndModel[index].sectorView = this.selectedView;
        sessionIdsAndModel[index].columnFilterObjectStore =
          this.columnFilterObject;
        sessionIdsAndModel[index].sortFilterObjectStore = this.sortFilterObject;
        sessionIdsAndModel[index].headerCellsArrayStore = this.headerCellsArray;
        sessionIdsAndModel[index].checkedSwitchStore = this.checkedSwitch;

        sessionIdsAndModel[index].projectViewInnerFilterCompany =
          this.projectViewInnerFilterObjectCompany;
        sessionIdsAndModel[index].projectViewInnerFilterProject =
          this.projectViewInnerFilterObjectProject;

        sessionIdsAndModel[index].projectInnerViewType =
          this.selectedViewTypeForSort;

        sessionIdsAndModel[index].projectViewInnerSortProject =
          this.projectViewInnerSortProjectObject;
        sessionIdsAndModel[index].projectViewInnerSortCompany =
          this.projectViewInnerSortCompanyObject;
        sessionIdsAndModel[index].radio_filter = this.radio_filter;
        sessionIdsAndModel[index].projectFilter = this.projectFilter;
        sessionIdsAndModel[index].lithiumFilter = this.defaultLithiumType;

        // Encrypt the updated data
        let updatedEncryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(sessionIdsAndModel),
          "1fcd35d4efe320460827f80c89e640ce"
        ).toString();

        window.sessionStorage.setItem(
          "sessionIdsAndModel",
          updatedEncryptedData
        );
      }
    },

    showColumnsDrawer() {
      this.isshowColumnsDrawer = !this.isshowColumnsDrawer;
    },
    handleLithiumTypeChange(e) {
      this.defaultLithiumType = e.value;
      this.rememberedLithiumType = this.defaultLithiumType
      this.dataReload();
      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    handleSectorDropdown(e) {
      this.defaultItemSector = e.value;
      this.toggleClick = false;
      if (this.checkedSwitch == true) {
        if (e.value.alias == "all_commodities") {
          this.checkedSwitch = false;
        } else {
          this.checkedSwitch = this.rememberSwitch;
        }
      } else {
        this.checkedSwitch = this.rememberSwitch;
      }
      if (this.defaultItemSector?.name == "All Commodities") {
        this.radio_filter = "Primary";
      }
      this.dataReload();
      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    setActiveFilter(propName) {
      for (const prop in this.isShowFilterModel) {
        if (prop !== propName && this.isShowFilterModel[prop]) {
          this.isShowFilterModel[prop] = false;
        }
      }
    },
    setProjectViewActiveFilter(propName) {
      for (const prop in this.isShowFilterProjectModel) {
        if (prop !== propName && this.isShowFilterProjectModel[prop]) {
          this.isShowFilterProjectModel[prop] = false;
        }
      }
    },
    downloadFile(type) {
      var token = localStorage.getItem("mnb_session");
      this.$store.dispatch("toastifyNotification", {
        text: "Your download is in progress!",
        duration: 3000,
        className: "warning",
      });
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      instance
        .post(
          configuration.apiBaseUrl + `/api/v1/download-${type}-file`,
          {
            commodities: this.defaultItemSector.alias,
            commodities_name: this.defaultItemSector.name,
            view: this.selectedView.name,
            ...(Object.keys(this.columnFilterObject).length > 0 && {
              filters: this.columnFilterObject,
            }),
            ...(Object.keys(this.projectFilter).length > 0 && {
              project_filter: this.projectFilter,
            }),
            ...(this.selectedView.name == "sector_view"
              ? { is_display_all: true }
              : {}),
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.defaultItemSector.name}.${type}`
          );
          document.body.appendChild(link);
          link.click();
        });
    },
    downloadProjectFile(type) {
      var token = localStorage.getItem("mnb_session");
      this.$store.dispatch("toastifyNotification", {
        text: "Your download is in progress!",
        duration: 3000,
        className: "warning",
      });
      // console.log("Here");
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      instance
        .post(
          configuration.apiBaseUrl + `/api/v1/download-project-${type}-file`,
          {
            commodities: this.defaultItemSector.alias,
            commodities_name: this.defaultItemSector.name,
            view: this.selectedView.name,
            ...(Object.keys(this.columnFilterObject).length > 0 && {
              filters: this.columnFilterObject,
            }),
            ...(Object.keys(this.projectFilter).length > 0 && {
              project_filter: this.projectFilter,
            }),
            ...(this.selectedView.name == "sector_view"
              ? { is_display_all: true }
              : {}),
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `All Projects.${type}`);
          document.body.appendChild(link);
          link.click();
        });
    },
    setLithiumViewActiveFilter(propName) {
      for (const prop in this.isShowFilterLithiumModel) {
        if (prop !== propName && this.isShowFilterLithiumModel[prop]) {
          this.isShowFilterLithiumModel[prop] = false;
        }
      }
    },
    onChangeSwitch(e) {
      this.checkedSwitch = e.target.value;
      this.rememberSwitch = e.target.value;
      this.toggleClick = true;
      this.dataReload();
      this.$nextTick(() => {
        this.enableDragScroll();
      });
    },
    enableDragScroll() {
      setTimeout(() => {
        let gridElement = this.$refs?.gridRef?.$el;
        if (gridElement) {
          const scrollArea = gridElement.querySelector(".k-grid-content");
          let isDragging = false;
          let lastX = 0;

          scrollArea.addEventListener("mousedown", (event) => {
            event.preventDefault();
            gridElement.classList.add("grabbed");
            isDragging = true;
            lastX = event.clientX;
          });

          scrollArea.addEventListener("mousemove", (event) => {
            if (isDragging) {
              const deltaX = event.clientX - lastX;
              scrollArea.scrollLeft -= deltaX;
              lastX = event.clientX;
            }
          });

          scrollArea.addEventListener("mouseup", () => {
            gridElement.classList.remove("grabbed");
            isDragging = false;
          });

          scrollArea.addEventListener("mouseleave", () => {
            if (isDragging) {
              gridElement.classList.remove("grabbed");
              isDragging = false;
            }
          });
        } else {
          setTimeout(() => {
            this.enableDragScroll();
          }, 50);
        }
      }, 300);
    },
    formatCashDebtDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    showTooltip(item) {
      const tooltipKey = this.formatItemName(item);
      if (tooltipKey && this.tooltipStates.hasOwnProperty(tooltipKey)) {
        this.tooltipStates[tooltipKey] = true;
      }

      // if (item == "one") {
      //   this.tooltipOne = true;
      // } else if (item == "two") {
      //   this.tooltipTwo = true;
      // } else if (item == "three") {
      //   this.tooltipThree = true;
      // } else if (item == "mineral_hard_rock") {
      //   this.tooltipMineralHardRock = true;
      // } else if (item == "mineral_brine") {
      //   this.tooltipMineralBrine = true;
      // } else if (item == "mineral_clay") {
      //   this.tooltipMineralClay = true;
      // } else if (item == "mineral_total") {
      //   this.tooltipMineralTotal = true;
      // } else if (item == "ore_hard_rock") {
      //   this.tooltipOreHardRock = true;
      // } else if (item == "ore_brine") {
      //   this.tooltipOreBrine = true;
      // } else if (item == "ore_clay") {
      //   this.tooltipOreClay = true;
      // } else if (item == "ore_total") {
      //   this.tooltipOreTotal = true;
      // } else if (item == "lithium_type") {
      //   this.tooltipLithiumType = true;
      // }
    },
    hideTooltip(item) {
      const tooltipKey = this.formatItemName(item);
      if (tooltipKey && this.tooltipStates.hasOwnProperty(tooltipKey)) {
        this.tooltipStates[tooltipKey] = false;
      }
      // if (item == "one") {
      //   this.tooltipOne = false;
      // } else if (item == "two") {
      //   this.tooltipTwo = false;
      // } else if (item == "three") {
      //   this.tooltipThree = false;
      // } else if (item == "mineral_hard_rock") {
      //   this.tooltipMineralHardRock = false;
      // } else if (item == "mineral_brine") {
      //   this.tooltipMineralBrine = false;
      // } else if (item == "mineral_clay") {
      //   this.tooltipMineralClay = false;
      // } else if (item == "mineral_total") {
      //   this.tooltipMineralTotal = false;
      // } else if (item == "ore_hard_rock") {
      //   this.tooltipOreHardRock = false;
      // } else if (item == "ore_brine") {
      //   this.tooltipOreBrine = false;
      // } else if (item == "ore_clay") {
      //   this.tooltipOreClay = false;
      // } else if (item == "ore_total") {
      //   this.tooltipOreTotal = false;
      // } else if (item == "lithium_type") {
      //   this.tooltipLithiumType = false;
      // }
    },
    formatItemName(item) {
      return "tooltip" + item.split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join('');
    },
    handleRadioChange(event) {
      if(event.target.value == 'Any'){
        this.defaultLithiumType = {
          id: 0,
          name: "All",
          alias: "all",
        }
      }else{
        this.defaultLithiumType = this.rememberedLithiumType || {
          id: 0,
          name: "All",
          alias: "all",
        }
      }
      if (this.defaultItemSector?.name == "All Commodities") return;
      this.isLoadingGrid = true;
      this.handleFilterPayload();
      setTimeout(() => {
        this.isLoadingGrid = false;
      }, 100);
    },
    projectFilterEmit(value) {
      // console.log(value, "projectFilterEmit");
      this.projectFilter = value;
      this.isCommodities = true;
      this.handleFilterPayload();
      setTimeout(() => {
        this.isLoadingGrid = false;
      }, 100);
    },
    handleFilterPayload() {
      let payload = {};

      payload = {
        commodities: this.defaultItemSector.alias,
        commodities_name: this.defaultItemSector.name,
        view: this.selectedView.name,
        column_for_project: this.columnsArrayForFilter,
        is_any_selected: this.radio_filter == "Primary" ? false : true,
        is_commodities: this.radio_filter == "Primary" ? true : false,
        ...(this.selectedView.name == "project_view"
          ? { is_any_selected: this.radio_filter == "Primary" ? false : true }
          : {}),
        ...(this.selectedView?.name == "sector_view" &&
          this.defaultItemSector?.name == "Any Sector"
          ? {
            project_filter: this.projectFilter,
          }
          : {}),
        ...(this.selectedView?.name === "project_view" &&
          this.defaultItemSector?.name === "Lithium" &&
          this.radio_filter == 'Primary' && {
          lithium_type: this.defaultLithiumType?.alias,
        }),
      };

      this.dashboardCompaniesFunc(payload);

      let dataPayload = {
        commodities: this.defaultItemSector.alias,
        commodities_name: this.defaultItemSector.name,
        view: this.selectedView.name,
        ...(Object.keys(this.columnFilterObject).length > 0 && {
          filters: this.columnFilterObject,
        }),
        ...(Object.keys(this.sortFilterObject).length > 0 && {
          sorting: this.sortFilterObject,
        }),
        ...(this.selectedView.name == "sector_view"
          ? { is_display_all: true }
          : { is_any_selected: this.radio_filter == "Primary" ? false : true }),
        ...(this.selectedView.name == "sector_view" &&
          this.defaultItemSector?.name == "Any Sector"
          ? {
            project_filter: this.projectFilter,
          }
          : {}),
        is_commodities: this.isCommodities,
        page: 1,
        ...(this.selectedView?.name === "project_view" &&
          this.defaultItemSector?.name === "Lithium" &&
          this.radio_filter == 'Primary' && {
          lithium_type: this.defaultLithiumType?.alias,
        }),
      };

      this.$store.commit("SET_SELECTED_COMMODITIES_DATA", []);
      this.calledPages = [];
      // this.calledPages.push(1);
      this.$store.commit("SET_SELECTED_COMMODITIES_PAGINATION_INFO", 0);
      this.$store.dispatch("getSelectedCommoditiesData", dataPayload);
      this.updateSessionModel();
    },
    convertDocumentName(name) {
      return name
      .replace(/[\/'().&$%]/g, "") // Remove '/', "'", '(', ')', and '.' characters
      .split(" ")             // Split by spaces
      .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
      .join("_");
    },
  },
};
</script>
