<div>
  <k-dialog v-if="handler" :class="cssClass" :title="title" @close="closeModal">
    <app-loader
      v-if="isLoading"
      :loaderColor="'dark'"
      class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center k-z-50"
    />
    <div class="modal-container">
      <slot name="content"></slot>
    </div>

    <dialog-actions-bar
      v-if="footer"
      :class="'justify-content-end modal-footer'"
    >
      <slot name="footer"></slot>
    </dialog-actions-bar>
  </k-dialog>
</div>
