import { GET, POST } from "@/service";
import router from "@/router";
import {
  createCompany,
  deleteLocalCompany,
  deleteLocalCompanyOption,
  deletePreviousCompanyName,
  getAsxCode,
  getCompany,
  getCreateViewDropdown,
  updateCompany,
  updateCompanyImage,
} from "@/services/company.service";
import { notificationHandler } from "../../helpers/utility";
import { getNotifications } from "@/services/dashboard.service";
export type State = {
  sector: [];
  asxCode: [];
  errorMsg: any;
  getClubModel: [];
  isCreateUpdateNotify: {};
  isLoadingCreateUpdate: boolean;
  isCompanydeleteModalOpen: boolean;
  isCompanyOptionsdeleteModalOpen: boolean;
  isPreviousCompanydeleteModalOpen: boolean;
  notifications: [];
};

const state: State = {
  sector: [],
  asxCode: [],
  errorMsg: {},
  getClubModel: [],
  isCreateUpdateNotify: {},
  isLoadingCreateUpdate: false,
  isCompanydeleteModalOpen: false,
  isCompanyOptionsdeleteModalOpen: false,
  isPreviousCompanydeleteModalOpen: false,
  notifications: [],
};

const companyCreateEdit = {
  state,
  getters: {},
  mutations: {
    SET_SECTOR(state: { sector: [] }, data: any) {
      state.sector = data;
    },
    SET_ASXCODE(state: { asxCode: [] }, data: any) {
      state.asxCode = data;
    },
    SET_COMPANY(state: { getClubModel: [] }, data: any) {
      state.getClubModel = data;
    },
    SET_NOTIFICATION_CREATE_UPDATE_COMPANY(
      state: { isCreateUpdateNotify: {} },
      data: any
    ) {
      state.isCreateUpdateNotify = data;
    },
    SET_ISLOADING_CREATEUPDATE(
      state: { isLoadingCreateUpdate: boolean },
      data: any
    ) {
      state.isLoadingCreateUpdate = data;
    },
    SET_COMPANY_DELETE_MODAL_SHOW_HIDE(
      state: { isCompanydeleteModalOpen: boolean },
      data: any
    ) {
      state.isCompanydeleteModalOpen = data;
    },
    SET_COMPANY_OPTIONS_DELETE_MODAL_SHOW_HIDE(
      state: { isCompanyOptionsdeleteModalOpen: boolean },
      data: any
    ) {
      state.isCompanyOptionsdeleteModalOpen = data;
    },
    SET_PREVIOUS_COMPANY_DELETE_MODAL_SHOW_HIDE(
      state: { isPreviousCompanydeleteModalOpen: boolean },
      data: any
    ) {
      state.isPreviousCompanydeleteModalOpen = data;
    },
    SET_NOTIFICATION(state: { notifications: [] }, data: any) {
      state.notifications = data;
    },
  },
  actions: {
    getCompanySector({ commit }: any) {
      getCreateViewDropdown()
        .then((response) => {
          commit("SET_SECTOR", response?.data?.result[0]?.sector);
        })
        .catch((error) => {
          commit("SET_IS_RELOADNEEDED", true);
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    getAsxCode({ commit }: any) {
      getAsxCode()
        .then((response) => {
          commit("SET_ASXCODE", response?.data?.result?.code);
        })
        .catch((error) => {
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    createCompany({ commit, dispatch }: any, payload: any) {
      createCompany(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = notificationHandler(
            "Your Company has been created successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Your Company has been created successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
            router.push(
              "/companyOverview/" + response?.data?.result?.company?.uuid
            );
          }, 4000);
        })
        .catch((error) => {
          commit("SET_IS_RELOADNEEDED", false);
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = error?.response?.data?.result?.message?.code[0]
            ? notificationHandler(
              error?.response?.data?.result?.message?.code[0],
              true,
              "warning"
            )
            : notificationHandler(
              "Failed to retrieve information from API.",
              true,
              "warning"
            );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    getCompany({ commit, dispatch }: any, payload: any) {
      getCompany(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          commit("SET_COMPANY", response?.data?.result?.company);
        })
        .catch((error) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    updateCompany({ commit, dispatch }: any, payload: any) {
      updateCompany(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = notificationHandler(
            "Your Company has been updated successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          dispatch("getCompany", payload);
          router.push(
            "/companyOverview/" + response?.data?.result?.company?.uuid
          );

          setTimeout(() => {
            let notification = notificationHandler(
              "Your Company has been updated successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        })
        .catch((error) => {
          commit("SET_IS_RELOADNEEDED", false);
          commit("SET_ISLOADING_CREATEUPDATE", false);
          console.log(error, "error");
          let notification = notificationHandler(
            error?.response?.data?.result?.message ||
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    updateCompanyImage({ commit, dispatch }: any, payload: any) {
      updateCompanyImage(payload)
        .then((response) => {
          let notification = notificationHandler(
            "Your Company has been updated successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Your Company has been updated successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
            router.push("/companies");
          }, 4000);
          dispatch("getCompany", payload);
        })
        .catch((error) => {
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    deleteLocalCompany({ commit, dispatch }: any, payload: any) {
      deleteLocalCompany(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          commit("SET_COMPANY_DELETE_MODAL_SHOW_HIDE", false);
          let notification = notificationHandler(
            "Company Delete Successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Company Delete Successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
            router.push("/companies");
          }, 4000);
        })
        .catch((error) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    deleteLocalCompanyOption({ commit, dispatch }: any, payload: any) {
      deleteLocalCompanyOption(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          commit("SET_COMPANY_OPTIONS_DELETE_MODAL_SHOW_HIDE", false);
          let notification = notificationHandler(
            "Company Option Deleted Successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          router.go(0);
          setTimeout(() => {
            let notification = notificationHandler(
              "Company Option Deleted Successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        })
        .catch((error) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    deletePreviousCompanyName({ commit, dispatch }: any, payload: any) {
      deletePreviousCompanyName(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          commit("SET_PREVIOUS_COMPANY_DELETE_MODAL_SHOW_HIDE", false);
          let notification = notificationHandler(
            "Company Previous Name Deleted Successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          router.go(0);
          setTimeout(() => {
            let notification = notificationHandler(
              "Company Previous Name Deleted Successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        })
        .catch((error) => {
          commit("SET_ISLOADING_CREATEUPDATE", false);
          let notification = notificationHandler(
            "Failed to retrieve information from API.",
            true,
            "warning"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Failed to retrieve information from API.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        });
    },
    updateNotification({ commit }: any) {
      getNotifications()
        .then((response) => {
          commit("SET_NOTIFICATION", response?.data?.result?.notifications);
        })
        .catch((error) => {
          // console.log(error)
        });
    },
  },
};
export default companyCreateEdit;
