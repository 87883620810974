import Vuex from "vuex";
import auth from "./auth/auth";
import companyCreateEdit from "./company/companyCreateEdit";
import dashboard from "./dashboard/dashboard";
import newProject from "./newProject/newProject";
import tenements from "./tenements/tenements";
import toastify from "./toastify/toastify";

export default new Vuex.Store({
  modules: {
    auth,
    companyCreateEdit,
    dashboard,
    newProject,
    tenements,
    toastify
  },
});
