export default class IsShowFilterModel {
  showFilter = false;
  showFilterSoi = false;
  showDebtTemplate = false;
  showEvTtemplate = false;
  showNetCashTemplate = false;
  showCashTemplate = false;
  searchFilterMarketCap = false;
  showSearchFilter = false;
  showDropdownFilter = false;
  show_project_name_template = false;
  show_country_template = false;
  show_state_template = false;
  show_current_o_ship_template = false;
  show_stage_template = false;
  mineral_tonnes_template = false;
  mineral_au_gt_template = false;
  mineral_contained_au_moz_template = false;
  mineral_mc_contained_m_moz_template = false;
  ore_tonnes_template = false;
  ore_au_gt_template = false;
  ore_contained_au_moz_template = false;
  ore_mc_contained_m_moz_template = false;
}
