<Popup :anchor="anchorTag" :show="isShowFilter" :popup-class="'filter-popup'" :anchor-align="anchorAlignProperty"
  :popup-align="popupAlignProperty">
  <div class="filter-box">
    <div class="filter-content d-flex align-items-end">
      <div class="filter-item">
        <label>From</label>
        <k-input v-model="inputFrom" type="text" :placeholder="'Min'" @keyup.enter="handleEnter" @input="handleFrom"></k-input>
      </div>
      <div class="filter-item">
        <label>To</label>
        <k-input v-model="inputTo" type="text" :placeholder="'Max'" @keyup.enter="handleEnter" @input="handleTo"></k-input>
      </div>
      <div class="filter-reset">
        <k-button @click="onCancelClick" :size="'large'" :icon="'reset'"></k-button>
      </div>
    </div>
  </div>
</Popup>