import { DELETE, GET, POST, PUT } from "@/service";

export const getCreateViewDropdown = () => {
  return GET(`api/v1/company/createView`);
};

export const getAsxCode = () => {
  return GET(`api/v1/company/asx-code`);
};

export const getCompany = (payload: any) => {
  return GET(`api/v1/company/${payload.uuid}/edit`);
};

export const getPrimaryRic = (payload: any) => {
  return POST(`api/v1/company/general-information/primary-ric`, payload);
};

export const getCompanyOverView = (payload: any) => {
  return GET(`api/v1/company/${payload.id}/show`);
};

export const getProjectListCompanyOverView = (payload: any) => {
  return GET(`api/v1/company/${payload.id}/project/list`);
};

export const postAsxCodeDebounce = (payload: any) => {
  return POST(`api/v1/company/validate/asx-code`, payload);
};

export const createCompany = (payload: any) => {
  return POST(`api/v1/company/create`, payload);
};

export const updateProjectRankCompanyOverView = (payload: any) => {
  const payloadRank = {
    rank: payload.rank,
  };
  return POST(`api/v1/project/${payload.id}/rank/update`, payloadRank);
};

export const updateCompanyImage = (payload: any) => {
  return POST(
    `api/v1/company/${payload.companyId}/image-upload/${payload.imageId}`
  );
};

export const updateCompany = (payload: any) => {
  return PUT(`api/v1/company/${payload.uuid}/update`, payload);
};

export const deleteLocalCompany = (companyId: number) => {
  return DELETE(`api/v1/company/${companyId}/delete`);
};

export const deleteLocalCompanyOption = (payload: any) => {
  return DELETE(
    `api/v1/company/${payload.companyId}/option/${payload.optionId}/delete`
  );
};

export const deletePreviousCompanyName = (payload: any) => {
  return DELETE(
    `api/v1/company/${payload.companyId}/previous/name/${payload.previous_name_id}/delete`
  );
};

export const deleteImage = (imageId: number) => {
  return DELETE(`api/v1/company/image/${imageId}/delete`);
};

export const getValuesFromRkd = (payload: any) => {
  return GET(`api/v1/company/get-values-by-rkd/${payload}`);
};
