<template src="./AppSnackBar.html"></template>

<script>
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import kendo from "@progress/kendo-ui";
export default {
  components: {
    "kendo-notification": Notification,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    snackbarOpen: {
      type: Boolean,
      required: true,
    },
    notificationType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.notification = this.$refs.notification.kendoWidget();
    this.setMsg();
  },
  methods: {
    setMsg() {
      this.notification.show(this.message, this.notificationType);
    },
  },
};
</script>

<style></style>
