<template src="./projectFilterPopupTemplate.html"></template>

<style lang="scss">
@import "./projectFilterPopupTemplate.scss";
</style>

<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { getCountryandState } from "@/services/dashboard.service";
import { Button } from "@progress/kendo-vue-buttons";

const emptyState = {
  id: 0,
  title: "Please Select",
  alias: "all_commodities",
}

export default {
  name: "projectFilterPopupTemplate",
  components: {
    kbutton: Button,
    DropDownList,
  },
  data() {
    return {
      popupShow: false,
      reportRadioOne: [
        {
          key: "Primary",
          label: "Primary",
          size: "medium",
        },
        {
          key: "Any",
          label: "Any",
          size: "medium",
        },
      ],
      radio_filter: "Primary",
      defaultItemSector: {
        id: 0,
        name: "Please Select",
        alias: "all_commodities",
      },
      defaultItemCountry: {
        id: 0,
        title: "Please Select",
        alias: "all_commodities",
      },
      defaultItemState: {
        id: 0,
        title: "Please Select",
        alias: "all_commodities",
      },
      defaultItemStage: {
        id: 0,
        title: "Please Select",
        alias: "all_commodities",
      },
      sectorDropdownData: [],
      countryList: [],
      countryListTemp: [],
      stateList: [],
      stateListTemp: [],
      stageList: [],
      stageListTemp: [],
      stageListComputed: [],
      preservedState: {},
      popupSettings: {
        popupClass: "popup-project-filter-select",
      },
    };
  },
  props: {
    projectFilter: {
      type: Object,
      default: null,
    },
  },
  watch: {
    projectFilter: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.setData(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    commoditiesList() {
      return this.$store.state.dashboard.commoditiesList;
    },
    filteredStates() {
      return this.stageListComputed.filter(
        (state) => state?.country_code === this.defaultItemCountry?.country_code
      );
    },
    isProjectFilterClearIcon() {
      return (
        this.defaultItemSector?.id > 0 ||
        this.defaultItemCountry?.id > 0 ||
        this.defaultItemState?.id > 0 ||
        this.defaultItemStage?.id > 0
      );
    },
  },
  mounted() {
    if (!this.commoditiesList?.length) this.$store.dispatch("getCommodities");
  },
  methods: {
    setData(newData) {
      this.radio_filter = newData.radio_filter || "Primary";
      this.defaultItemSector = newData.sector || {
        id: 0,
        name: "Please Select",
        alias: "all_commodities",
      };
      this.defaultItemCountry = newData.country || emptyState;
      this.defaultItemState = newData.state || emptyState;
      this.defaultItemStage = newData.stage || emptyState;
      if (!this.countryList?.length)
      this.getCountryAndStateData();
    },
    async getCountryAndStateData() {
      const payload = {
        commodities: "all_commodities",
        filters: {},
        is_commodities: true,
        is_display_all: true,
        sorting: {},
        view: "project_view",
      };

      try {
        this.isLoading = true;

        const response = await getCountryandState(payload);
        this.updateLists(response)
      } catch (error) {
        console.error("Error fetching country and state data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    updateLists(response) {
      console.log(response)
      if (!response || !response.data || !response.data.result) {
        console.error("Invalid response structure");
        return;
      }
      const { country, state, stage } = response?.data?.result;
      const unshiftSelectOption = (list) => {
        list.unshift({
          id: 0,
          title: "Please Select",
          alias: "all_commodities",
        });
        return list;
      };

      this.countryList = unshiftSelectOption([...country]);
      this.stateList = unshiftSelectOption([...state]);
      this.stageList = unshiftSelectOption([...stage]);

      this.countryListTemp = [...this.countryList];
      this.stateListTemp = [...this.stateList];
      this.stageListComputed = [...this.stateList];
      this.stageListTemp = [...this.stageList];
      // this.handleCountryToState();
    },
    onFocusSector(e) {
      this.sectorDropdownData = this.commoditiesList;
      let updatedCommoditiesList = [...this.sectorDropdownData];

      updatedCommoditiesList.unshift({
        id: 0,
        name: "Please Select",
        alias: "all_commodities",
      });

      this.sectorDropdownData = updatedCommoditiesList;
    },
    filterChangeSector(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataSector(event.filter)
          : this.commoditiesList?.slice();
      this.sectorDropdownData = newData;
    },
    filterDataSector(filter) {
      const data = this.commoditiesList?.slice();
      return filterBy(data, filter);
    },
    filterChangeCountry(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataCountry(event.filter)
          : this.countryListTemp?.slice();
      this.countryList = newData;
    },
    filterDataCountry(filter) {
      const data = this.countryListTemp?.slice();
      return filterBy(data, filter);
    },
    filterChangeState(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataState(event.filter)
          : this.stateListTemp?.slice();
      this.stateList = newData;
    },
    filterDataState(filter) {
      const data = this.stateListTemp?.slice();
      return filterBy(data, filter);
    },
    filterChangeStage(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataStage(event.filter)
          : this.stageListTemp?.slice();
      this.stageList = newData;
    },
    filterDataStage(filter) {
      const data = this.stageListTemp?.slice();
      return filterBy(data, filter);
    },
    togglePopup() {
      this.popupShow = !this.popupShow;
      if (this.popupShow) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      const popup = this.$refs.popup;
      const button = this.$refs.button;

      if (
        popup &&
        !popup.contains(event.target) &&
        button &&
        !button.contains(event.target)
      ) {
        this.popupShow = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleRadioChange(event) {
      this.projectFilterEmit();
    },
    handleDropdownSelect($event, handleName) {
      $event.event.stopPropagation();
      this[handleName] = $event.value;
      if (handleName == "defaultItemSector") {
        this.radio_filter = "Primary";
        // this.getCountryAndStateData();
        // this.defaultItemCountry = emptyState;
        // this.defaultItemState = emptyState;
        // this.defaultItemStage = emptyState;
      }

      // if (handleName == "defaultItemCountry") this.handleCountryToState();
      this.projectFilterEmit();
    },
    projectFilterEmit() {
      let payload = {
        radio_filter: this.radio_filter,
      };

      if (this.defaultItemSector.id != 0) {
        payload.sector = { ...this.defaultItemSector };
      }

      if (this.defaultItemCountry.id != 0) {
        payload.country = { ...this.defaultItemCountry };
        payload.sector = { ...this.defaultItemSector };
      }

      if (this.defaultItemState.id != 0) {
        payload.state = { ...this.defaultItemState };
        payload.sector = { ...this.defaultItemSector };
      }

      if (this.defaultItemStage.id != 0) {
        payload.stage = { ...this.defaultItemStage };
        payload.sector = { ...this.defaultItemSector };
      }

      this.$emit("projectFilterEmit", payload);
    },
    handleCountryToState() {
      this.stateList = this.filteredStates;
      this.stateListTemp = this.filteredStates;
    },
    resetFunc() {
      this.defaultItemSector = {
        id: 0,
        name: "Please Select",
        alias: "all_commodities",
      };
      this.defaultItemCountry = emptyState;
      this.defaultItemState = emptyState;
      this.defaultItemStage = emptyState;
      this.projectFilterEmit();
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
