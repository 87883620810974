<Popup :anchor="anchorTag" :show="isShowFilter" :popup-class="'filter-popup'" :anchor-align="anchorAlignProperty"
  :popup-align="popupAlignProperty">
  <div class="filter-box">
    <div class="filter-content d-flex align-items-end">
      <div class="filter-item">
        <label>From</label>
        <numerictextbox v-model="cellData.filter_value.from" type="number" :placeholder="'Min'" @keyup.enter="handleEnter" @input="handleFrom" />
        </div>
      <div class="filter-item">
        <label>To</label>
        <numerictextbox v-model="cellData.filter_value.to" type="number" :placeholder="'Max'" @keyup.enter="handleEnter" @input="handleTo" />
        </div>
      <div class="filter-reset">
        <k-button @click="onCancelClick" :size="'large'" :icon="'reset'"></k-button>
      </div>
    </div>
  </div>
</Popup>