<template src="./topBarComponent.html"></template>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { ComboBox } from "@progress/kendo-vue-dropdowns";
import vClickOutside from "click-outside-vue3";
import ItemRender from "./ItemRender";
import iconHamburger from "@/components/svg-icons/iconHamburger.vue";
import SideMenu from "../common/sideMenu/SideMenu.vue";
import { getUserData } from "@/services/dashboard.service";


export default {
  name: "topBarComponent",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    "k-input": Input,
    kbutton: Button,
    checkbox: Checkbox,
    DropDownButton,
    Popup,
    combobox: ComboBox,
    custom: ItemRender,
    SideMenu,
    iconHamburger,
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    buttonText: function () {
      return this.popupShow ? 'Hide' : 'Show';
    },
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
    companySearchWithoutProjectValue() {
      return this.$store.state.dashboard.companySearchWithoutProjectList;
    },
    notificationData() {
      return this.$store.state.companyCreateEdit.notifications;
    }
  },
  data: function () {
    return {
      popupShow: false,
      profileDropdown: [
        { id: 1, text: "Profile" },
        { id: 2, text: "Log Out" },
      ],
      imageUrl:
        "https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/529dbd52-8be8-45f1-8bce-f59bfdeb732e_638120432400497016.png",
      isSearchPopupOpen: false,
      searchingValue: "",
      listNoDataRender: "myTemplate",
      isAdmin: false,
      itemRender: "dataTemplate",
      autobillUser: null,
      originalValue: "",
      showSideMenu: false,
      sideMenuVisibility: false,

      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
    };
  },

  created() {
    const showNoti = localStorage.getItem("showNotification");
    if (showNoti == 1) {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    const currentURL = window.location.href;
    const isLocalhost = currentURL.includes("http://localhost:8080/");
    const isStaging = currentURL.includes("https://miningbull.daybud.com/");
    if (isLocalhost) {
      this.profileDropdown = [
        { id: 1, text: "Profile" },
        { id: 2, text: "Log Out" },
      ];
    } else if (isStaging) {
      this.profileDropdown = [
        { id: 1, text: "Profile" },
        { id: 2, text: "Log Out" },
      ];
    } else {
      this.profileDropdown = [{ id: 2, text: "Log Out" }];
    }
  },
  methods: {
    notificationToCompany(uuid) {
      if (uuid != null && uuid != "") {
        this.$router.push({
          name: "companyOverview",
          params: {
            id: uuid,
          },
        });
      }
    },
    onClick() {
      this.popupShow = !this.popupShow;
    },
    goToClubDashboard() {
      this.$router.push("/companies");
    },
    signOut() {
      this.$store.dispatch("signOut");
    },
    onChange(event) {
      const value = event.filter.value;
      this.searchingValue = value;
      this.debounceSearch(value);
    },
    onClickprofileDropdown(e) {
      if (e.item.id == 1) {
        if (loggedInUserData?.role?.alias != 'admin') {
          window.open(
            `https://mnbstage.mywebcommander.com/subscribe/?customToken=${this.autobillUser}`,
            "_blank"
          );
        } else return;
      }
      if (e.item.id == 2) {
        this.signOut();
      }
    },
    documentClick(e) {
      const el = e.target;
      const el_popup = document.getElementById("searchBoxTop");
      const el_button = document.getElementById("buttonElTop");
      this.searchingValue = "";
      if (el_popup !== null && el_button !== null) {
        if (el_popup.contains(el)) {
        } else if (el_button.contains(el)) {
        } else {
          this.isSearchPopupOpen = false;
        }
      }
    },
    onclickCompany(event) {
      if (event?.target?.value?.uuid) {
        this.$router.push({
          name: "companyOverview",
          params: {
            id: event?.target?.value?.uuid,
          },
        });
        this.isSearchPopupOpen = false;
      } else {
        if (event.event.key == "Enter") {
          this.$router.push({
            name: "companyOverview",
            params: {
              id: this.companySearchWithoutProjectValue[0].uuid,
            },
          });
        }
        this.isSearchPopupOpen = false;
      }

      this.searchingValue = "";
      this.$store.commit("SET_COMPANYSEARCHWITHOUTPROJECT_TOPBAR", []);
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearchChnageInputHandler(event);
      }, 0);
    },
    onSearchChnageInputHandler(e) {
      if (e.length >= 3) {
        this.isSearchPopupOpen = true;
        var payload = {
          name: e,
        };

        this.$store.dispatch("getSearchCompany", payload);
      } else {
        this.isSearchPopupOpen = false;
      }
    },
    // onclickCompanySpan(uuid) {
    //   console.log("close")
    //   if (uuid) {
    //     this.$router.push({
    //       name: "companyOverview",
    //       params: {
    //         id: uuid,
    //       },
    //     });
    //     this.isSearchPopupOpen = false;
    //   }

    //   this.searchingValue = "";
    //   this.$store.commit("SET_COMPANYSEARCHWITHOUTPROJECT_TOPBAR", []);
    // },
    onCng(event) {
      this.searchingValue = event.target.value;
    },

    handleSideMenu() {
      if (this.showSideMenu) {
        this.onCloseSideMenu();
      }

      if (!this.showSideMenu) {
        const timeId1 = setTimeout(() => {
          this.showSideMenu = true;
          clearTimeout(timeId1)
        }, 100);
      }
    },

    onCloseSideMenu() {
      if (this.showSideMenu) {
        const timeId2 = setTimeout(() => {
          this.showSideMenu = false;
          clearTimeout(timeId2)
        }, 100);
      }
    },
    closeNotification() {
      if (this.popupShow) {
        this.popupShow = false;
      }
    },
  },
};
</script>
