import {
  deleteProject,
  getCommoditiesTableResource,
  getNewProjectCreateView,
  projectCreate,
  updateProject,
} from "@/services/newCompany.service";
import { notificationHandler } from "../../helpers/utility";
import router from "@/router";

export type State = {
  tableResource: [];
  tabisLoadingCreateUpdate: boolean;
  tabisLoadingDelete: boolean;
  newProjectDropDownData: [];
  error: [];
  isCreateUpdateProjectNotify: {};
  isProjectdeleteModalOpen: false;
  timezone: string;
};

const state: State = {
  tableResource: [],
  tabisLoadingCreateUpdate: false,
  tabisLoadingDelete: false,
  newProjectDropDownData: [],
  error: [],
  isCreateUpdateProjectNotify: {},
  isProjectdeleteModalOpen: false,
  timezone: "",
};

const newProject = {
  state,
  getters: {},
  mutations: {
    SET_COMMODITIES_TABLE_RESOURCE(state: { tableResource: [] }, data: any) {
      state.tableResource = data;
    },
    SET_NEWPROJECT_CREATEVIEW_DATA(
      state: { newProjectDropDownData: [] },
      data: any
    ) {
      state.newProjectDropDownData = data;
    },
    SET_ISLOADING_CREATEUPDATE_PROJECT(
      state: { tabisLoadingCreateUpdate: boolean },
      data: any
    ) {
      state.tabisLoadingCreateUpdate = data;
    },
    SET_ISLOADING_DELETE_PROJECT(
      state: { tabisLoadingDelete: boolean },
      data: any
    ) {
      state.tabisLoadingDelete = data;
    },
    SET_NOTIFICATION_CREATE_UPDATE_PROJECT(
      state: { isCreateUpdateProjectNotify: {} },
      data: any
    ) {
      state.isCreateUpdateProjectNotify = data;
    },
    SET_ERROR(state: { error: [] }, data: any) {
      state.error = data;
    },
    SET_PROJECT_DELETE_MODAL_SHOW_HIDE(
      state: { isProjectdeleteModalOpen: boolean },
      data: any
    ) {
      state.isProjectdeleteModalOpen = data;
    },
    SET_TIMEZONE(state: { timezone: [] }, data: any) {
      state.timezone = data;
    },
  },
  actions: {
    getCommoditiesTableResource({ commit, dispatch }: any, payload: any) {
      getCommoditiesTableResource(payload)
        .then((response) => {
          commit(
            "SET_COMMODITIES_TABLE_RESOURCE",
            response.data.result.resource
          );
        })
        .catch((error) => {});
    },
    getNewProjectCreateView({ commit, dispatch }: any) {
      getNewProjectCreateView()
        .then((response) => {
          commit(
            "SET_NEWPROJECT_CREATEVIEW_DATA",
            response?.data?.result?.project
          );
        })
        .catch((error) => {});
    },
    projectCreate({ commit, dispatch }: any, payload: any) {
      projectCreate(payload)
        .then((response) => {
          router.go(-1);
          commit("SET_ERROR", []);
          commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          let notification = notificationHandler(
            "Project has been created successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_PROJECT", notification);
          setTimeout(() => {
            let notification = notificationHandler(
              "Project has been created successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_PROJECT", notification);
          }, 4000);
        })
        .catch((error) => {
          commit("SET_IS_RELOADNEEDED", false);
          commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          commit("SET_ERROR", error?.response?.data?.result?.message);
        });
    },
    deleteProject({ commit, dispatch }: any, payload: any) {
      deleteProject(payload)
        .then((response) => {
          if (payload?.isReload) router.go(0);
          else router.go(-1);
          commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          commit("SET_ISLOADING_DELETE_PROJECT", false);
          commit("SET_PROJECT_DELETE_MODAL_SHOW_HIDE", false);
          let notification = notificationHandler(
            "Project Deleted Successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);

          setTimeout(() => {
            let notification = notificationHandler(
              "Project Deleted Successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_COMPANY", notification);
          }, 4000);
        })
        .catch((error) => {
          commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          commit("SET_PROJECT_DELETE_MODAL_SHOW_HIDE", false);
          commit("SET_ERROR", error.response.data.result.message);
        });
    },
    updateProject({ commit, dispatch }: any, payload: any) {
      updateProject(payload)
        .then((response) => {
          commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          router.go(-1);
          let notification = notificationHandler(
            "Project has been updated successfully.",
            true,
            "success"
          );
          commit("SET_NOTIFICATION_CREATE_UPDATE_PROJECT", notification);

          setTimeout(() => {
            let notification = notificationHandler(
              "Project has been updated successfully.",
              false,
              "success"
            );
            commit("SET_NOTIFICATION_CREATE_UPDATE_PROJECT", notification);
          }, 4000);
        })
        .catch((error) => {
          commit("SET_IS_RELOADNEEDED", false);
          commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          commit("SET_ERROR", error?.response?.data?.result?.message);
        });
    },
  },
};
export default newProject;
