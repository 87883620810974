<template src="./newProjectCreateUpdate.html"></template>
<style lang="scss">
@import "./newProjectCreateUpdate.scss";
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-vue-inputs";
import newProjectCreateUpdateModel from "@/models/newProjectCreateUpdateModel";
import editTenementsModal from "./editTenementsModal/editTenementsModal.vue";
import editPlacementModal from "./editPlacementModal/editPlacementModal.vue";
import error from "@/models/newProjectCreateUpdateModel";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import {
  getNewProjectCreateView,
  getCommoditiesTableResource,
  getProjectEdit,
} from "@/services/newCompany.service";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { HTTP } from "@/service";
import { configuration } from "@/configurationProvider";
import { Upload } from "@progress/kendo-vue-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import percentageIconVue from "@/components/svg-icons/percentageIcon.vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import moment from "moment";

import router from "@/router";
import { dateFormatter } from "@/helpers/utility";
import AppInputWithDropdown from "@/components/common/AppInputWithDropdown/AppInputWithDropdown.vue"

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    kbutton: Button,
    "k-input": Input,
    numerictextbox: NumericTextBox,
    dropdownlist: DropDownList,
    multiselect: MultiSelect,
    AppGlobalLoader,
    checkbox: Checkbox,
    Upload,
    percentageIconVue,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    datePicker: DatePicker,
    "k-checkbox": Checkbox,
    editTenementsModal,
    editPlacementModal,
    TextArea,
    AppInputWithDropdown
  },
  data: function () {
    return {
      model: new newProjectCreateUpdateModel(),
      updatedData: [],
      editID: null,
      editID2: null,
      group: [{ field: "UnitsInStock" }],
      expandedItems: [],
      allData: [
        { id: 1, text: "Small" },
        { id: 2, text: "Medium" },
        { id: 3, text: "Large" },
      ],
      cut_off_grade: [
        { id: 1, label: "Small", value: 0 },
        { id: 2, label: "Medium", value: 0 },
      ],
      defaultItem: { id: 0, text: "Select.." },
      items: [],
      value: [],
      itemsStage: [],
      itemsStatus: [],
      itemsMainCommodities: [],
      itemsOwnershipClassificationShow: [],
      itemsOwnershipClassification: [
        { id: 1, label: "A" },
        { id: 2, label: "J" },
        { id: 3, label: "S" },
        { id: 4, label: "P" },
        { id: 5, label: "U" },
        { id: 6, label: "X" },
        { id: 7, label: "TBC" },
      ],
      itemsOwnershipClassificationValue: [],
      itemsOtherCommodities: [],
      itemsCountry: [],
      itemsLithiumType: [],
      items: [],
      islithiumType: false,
      defaultItemStage: {},
      defaultItemStatus: {},
      defaultItemMainCommodities: [],
      defaultItemOtherComodities: [],
      defaultItemCountry: {},
      defaultItemLithiumType: { id: 1, type: "Hard Rock" },
      defaultItemStates: {},
      isCheckBoxVisible: false,
      isMineralResourceTableDisable: false,
      isOreResourceTableDisable: false,
      localTableResource: [],
      apiImageUpload: "/api/v1/company/project/image-upload",
      tempPhotoPath: "",
      buttonName: "Create",
      imageUploadUrl:
        configuration.apiBaseUrl + "/api/v1/company/project/image-upload",
      economicImageUrl:
        configuration.apiBaseUrl + "/api/v1/company/project/image-upload",
      mineralImageUploadUrl:
        configuration.apiBaseUrl + "/api/v1/company/project/image-upload",
      oreImageUploadUrl:
        configuration.apiBaseUrl + "/api/v1/company/project/image-upload",
      token: localStorage.getItem("mnb_session"),
      files: [],
      itemStates: [],
      imageData: [],
      mineralImageData: [],
      oreImageData: [],
      value: [],
      isAdmin: false,
      popupSettings: {
        popupClass: "popup-country-select",
      },
      itemChng: null,
      curr_ownership_disable: false,
      multiple_table_last_deposit_row: 0,
      multiple_table_last_deposit_ore_row: 0,
      depositTypes: [
        {
          id: 1,
          name: "Single",
        },
        {
          id: 2,
          name: "Multiple",
        },
      ],
      deposit: {
        id: 1,
        name: "Single",
      },
      miningCode: [
        {
          id: 1,
          name: "JORC",
        },
        {
          id: 2,
          name: "NI 43-101",
        },
      ],
      updateCreateClicked: false,
      visibleDialog: false,
      tableToClear: [],
      multipleMineralTableSpand: false,
      multipleOreTableSpand: false,
      visibleDateDialog: false,
      isDateUpdatedManually: false,
      lastUpdatedDate: "",
      isShowPlacementEditModal: false,
      // fields: [
      //   { label: 'PDF Report', type: 'upload', accept: 'application/pdf', value: [{ "name": "1693563917.png" }], image_id: null, icon: false },
      //   { label: 'Study Type', type: 'text', placeholder: 'Enter study type', value: '', class: 'inline-group', icon: false },
      //   { label: 'Release Date', type: 'date', value: "2024-09-29T14:00:00.000Z", class: 'inline-group', icon: false },
      //   { label: 'Mining Method', type: 'select', options: ['OP', 'UG', 'OP & UG'], value: 'OP', icon: false },
      //   { label: 'Mine Life (Years)', type: 'number', placeholder: 'Enter mine life', min: 0, value: null, icon: false },
      //   { label: 'Processing Rate (Mtpa)', type: 'number', placeholder: 'Enter processing rate', min: 0, value: null, icon: false },
      //   { label: 'Pre-Tax NPV ($M)', type: 'number', placeholder: 'Enter pre-tax NPV', min: 0, value: null, icon: false },
      //   { label: 'Processing Recovery (%)', type: 'number', placeholder: 'Enter processing recovery', min: 0, max: 100, value: null, icon: true },
      //   { label: 'Average Production (koz pa)', type: 'number', placeholder: 'Enter average production', value: null, icon: false },
      //   { label: 'Post-Tax NPV ($M)', type: 'number', placeholder: 'Enter post-tax NPV', min: 0, value: null, icon: false },
      //   { label: 'Pre-Tax Discount Rate (%)', type: 'number', placeholder: 'Enter pre-tax discount rate', value: null, icon: true },
      //   { label: 'Post-Tax Discount Rate (%)', type: 'number', placeholder: 'Enter post-tax discount rate', value: null, icon: true },
      //   { label: 'Pre-Tax IRR (%)', type: 'number', placeholder: 'Enter pre-tax IRR', value: null, icon: true },
      //   { label: 'Post-Tax IRR (%)', type: 'number', placeholder: 'Enter post-tax IRR', value: null, icon: true },
      //   { label: 'Pre-Tax Payback Period (Years)', type: 'number', placeholder: 'Enter pre-tax payback period', value: null, icon: false },
      //   { label: 'Post-Tax Payback Period (Years)', type: 'number', placeholder: 'Enter post-tax payback period', value: null, icon: false },
      //   { label: 'Notes', type: 'textarea', placeholder: 'Enter any additional notes', max: 100, value: null, icon: false },
      // ],
      feasibility_field_resource: [],
      feasibility_common_field_resource: [],
    };
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    tableResource() {
      return this.$store.state.newProject.tableResource;
    },
    dropdownData() {
      return this.$store.state.newProject.newProjectDropDownData;
    },
    error() {
      return this.$store.state.newProject.error;
    },
    isLoadingCreateProjectUpdate() {
      return this.$store.state.newProject.tabisLoadingCreateUpdate;
    },
    timezone() {
      return this.$store.state.newProject.timezone;
    },
    isEditTenementsModalShow() {
      return this.$store.state.tenements.isEditTenementsModalShow;
    },
  },
  created() { },
  mounted() {
    this.handleProjectInitialization();
  },
  methods: {
    handleProjectInitialization() {
      this.$store.commit("SET_ERROR", []);

      const isUpdatingProject = !!this.$route?.params?.projectId;
      this.$store.commit("SET_ISLOADING_CREATEUPDATE_PROJECT", true);

      if (isUpdatingProject) {
        this.buttonName = "Update";
        this.getProjectEditFunc();
      } else {
        const payload = {
          commodities_name: this.defaultItemMainCommodities?.alias || "",
          lithium_type: this.islithiumType
            ? this.defaultItemLithiumType?.type
            : "",
        };

        this.tableResourceFunc(payload);
        this.getNewProjectCreateView();
      }

      this.itemsOwnershipClassificationShow = this.itemsOwnershipClassification;
    },
    getComponentType(field) {
      const componentMap = {
        text: "k-input",
        number: "k-input",
        date: "datePicker",
        select: "dropdownlist",
        upload: "Upload",
        textarea: "TextArea",
        inputWithDropdown: "AppInputWithDropdown",
      };
      return componentMap[field.type] || "";
    },

    getFieldProps(field) {
      const commonProps = {
        placeholder: field.placeholder || "",
        min: field.min,
        max: field.max,
      };

      const fieldPropsMap = {
        number: { type: "number", step: "any", ...commonProps },
        select: { "data-items": field.options },
        upload: {
          class: "w-100",
          restrictions: { allowedExtensions: [".pdf"] },
          saveHeaders: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "multipart/form-data",
          },
          defaultFiles: field?.value?.image_name? [{name:field?.value?.image_name}]:[],
          batch: false,
          multiple: false,
          withCredentials: false,
          saveUrl: this.economicImageUrl,
        },
        textarea: {
          class: "w-100 min-h-80",
          rows: 5,
          maxlength: field.max,
        },
        date: {
          format: "dd-MM-yyyy",
          value: field?.value ? new Date(field.value) : null,
          ...commonProps,
          placeholder: "DD-MM-YYYY",
        },
        inputWithDropdown: {
          field: field,
        }
      };

      return fieldPropsMap[field.type] || commonProps;
    },

    onCreateTenements(value) {
      this.model.selected_tenements = value;
    },
    toggleDialog(table) {
      this.tableToClear = table;
      this.visibleDialog = !this.visibleDialog;
    },
    handleClearTable() {
      let isSingleDeposit = this.model.deposit_type.name === "Single";

      this.tableToClear.forEach((item) => {
        for (const key in item) {
          let skipKeys = isSingleDeposit
            ? ["title", "id", "inEdit"]
            : ["title", "id", "inEdit", "deposit", "deposit_percentage"];
          if (!skipKeys.includes(key)) {
            item[key] = null;
          }
        }
      });

      this.tableToClear = [];
      this.visibleDialog = !this.visibleDialog;

    },
    calculateWeightedAverage(columnObj, tableResource) {
      const totalRow = tableResource.find((item) => item.title === "Total");

      if (totalRow) {
        const totalTonnes = totalRow.tonnes || 0;
        let totalWeightedValue = 0;
        let totalWeight = 0;

        tableResource.forEach((item) => {
          if (item.title !== "Total") {
            const itemValue = item[columnObj.field] || null;
            const itemTonnes =
              parseFloat(item[columnObj.weightedColumn]) || null;

            totalWeightedValue += itemValue * itemTonnes;
            totalWeight += itemTonnes;
          }
        });
        totalRow[columnObj.field] =
          totalWeight != 0 && !isNaN(totalWeight)
            ? this.formatNumberUpToFourDecimals(
              totalWeightedValue / totalWeight
            )
            : null;
        if (totalRow[columnObj.field] == undefined) {
          totalRow[columnObj.field] = null;
        }
      }
    },
    calculateWeightedEquation(columnObj, tableResource) {
      tableResource.forEach((item) => {
        if (item.title !== "Total") {
          const dynamicEquation = columnObj.weightedEquation.replace(
            /(\w+)/g,
            (columnName) => {
              if (columnName in item) {
                return `item.${columnName}`;
              }
              return columnName;
            }
          );
          const dynamicFunc = new Function("item", `return ${dynamicEquation}`);
          const equationResult = dynamicFunc(item);

          if (
            item[columnObj.field] == null ||
            item[columnObj.field] == "" ||
            isNaN(item[columnObj.field])
          ) {
            item[columnObj.field] =
              this.formatNumberUpToFourDecimals(equationResult);
            if (item[columnObj.field] == undefined) {
              item[columnObj.field] = null;
            }
          }
        }
      });
      this.calculateWeightedAverage(columnObj, tableResource);
    },
    calculateTable(columnsArray, tableResource) {
      if (
        this.defaultItemMainCommodities.alias == "potash" ||
        this.defaultItemMainCommodities.alias == "kaolin"
      )
        return;
      const totalRow = tableResource.find((item) => item.title === "Total");

      columnsArray.forEach((columnObj) => {
        const column = columnObj.field;

        if (column !== "title") {
          if (columnObj.weightedAverage && columnObj.weightedColumn) {
            if (
              totalRow[column] === null ||
              totalRow[column] === "" ||
              isNaN(totalRow[column])
            ) {
              if (columnObj.weightedEquation) {
                this.calculateWeightedEquation(columnObj, tableResource);
              } else {
                this.calculateWeightedAverage(columnObj, tableResource);
              }
            }
          } else if (columnObj.calculated && columnObj.equation) {
            this.calculateDynamicColumn(columnObj, tableResource);
          } else {
            if (
              totalRow[column] === null ||
              totalRow[column] === "" ||
              isNaN(totalRow[column])
            ) {
              totalRow[column] = tableResource.reduce((sum, item) => {
                if (
                  item.title !== "Total" &&
                  item[column] !== null &&
                  item[column] !== ""
                ) {
                  if (sum == undefined) sum = 0;
                  return sum + (parseFloat(item[column]) || null);
                }
                return this.formatNumberUpToFourDecimals(sum);
              }, 0);
            }
          }
        }
      });
    },

    calculateDynamicColumn(columnObj, tableResource) {
      const equation = columnObj.equation;
      const totalRow = tableResource.find((row) => row.title === "Total");
      let totalValue = 0;
      tableResource.forEach((item) => {
        if (item.title !== "Total") {
          const dynamicEquation = equation.replace(/(\w+)/g, (columnName) => {
            if (columnName in item) {
              return `item.${columnName}`;
            }
            return columnName;
          });
          const dynamicFunc = new Function("item", `return ${dynamicEquation}`);
          const equationResult = dynamicFunc(item);
          if (
            item[columnObj.field] == null ||
            item[columnObj.field] == "" ||
            isNaN(item[columnObj.field])
          ) {
            item[columnObj.field] =
              this.formatNumberUpToFourDecimals(equationResult);
            if (item[columnObj.field] == undefined) {
              item[columnObj.field] = null;
            }
          }
          totalValue += item[columnObj.field];
        }
      });
      if (totalRow) {
        totalRow[columnObj.field] =
          totalRow[columnObj.field] == "" || totalRow[columnObj.field] == null
            ? totalValue == 0
              ? null
              : this.formatNumberUpToFourDecimals(totalValue)
            : totalRow[columnObj.field];
      }
    },
    formatNumberUpToFourDecimals(number) {
      if (number == null || number == "") return;
      const decimalPlaces = (number.toString().split(".")[1] || "").length;

      if (decimalPlaces >= 4) {
        return parseFloat(number.toFixed(4));
      } else {
        return number;
      }
    },
    addMineralRows() {
      let depo_percentage = null;
      if (
        this.model.current_ownership &&
        !this.model.current_ownership.includes("-")
      ) {
        depo_percentage = this.model.current_ownership;
      }
      const total1 =
        this.model.multiple_deposit_mineral_resource.resource.pop();
      const total2 =
        this.model.multiple_deposit_mineral_resource.resource.pop();
      const total3 =
        this.model.multiple_deposit_mineral_resource.resource.pop();
      const total4 =
        this.model.multiple_deposit_mineral_resource.resource.pop();

      const tempTotal4 = { ...total4 };
      tempTotal4.deposit_percentage = depo_percentage;
      for (const temp in tempTotal4) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal4[temp] = null;
        } else if (temp == "id") {
          tempTotal4[temp] = total4.id;
        }
      }
      this.model.multiple_deposit_mineral_resource.resource.push(tempTotal4);

      const tempTotal3 = { ...total3 };
      tempTotal3.deposit_percentage = depo_percentage;
      for (const temp in tempTotal3) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal3[temp] = null;
        } else if (temp == "id") {
          tempTotal3[temp] = total3.id;
        }
      }
      this.model.multiple_deposit_mineral_resource.resource.push(tempTotal3);

      const tempTotal2 = { ...total2 };
      tempTotal2.deposit_percentage = depo_percentage;
      for (const temp in tempTotal2) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal2[temp] = null;
        } else if (temp == "id") {
          tempTotal2[temp] = total2.id;
        }
      }
      this.model.multiple_deposit_mineral_resource.resource.push(tempTotal2);

      const tempTotal1 = { ...total1 };
      tempTotal1.deposit_percentage = depo_percentage;
      for (const temp in tempTotal1) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal1[temp] = null;
        } else if (temp == "id") {
          tempTotal1[temp] = total1.id;
        }
      }
      this.model.multiple_deposit_mineral_resource.resource.push(tempTotal1);
      total1.id = total1.id + 4;
      total2.id = total2.id + 4;
      total3.id = total3.id + 4;
      total4.id = total4.id + 4;
      this.model.multiple_deposit_mineral_resource.resource.push(total4);
      this.model.multiple_deposit_mineral_resource.resource.push(total3);
      this.model.multiple_deposit_mineral_resource.resource.push(total2);
      this.model.multiple_deposit_mineral_resource.resource.push(total1);
      this.multiple_table_last_deposit_row =
        this.model.multiple_deposit_mineral_resource.resource[
          this.model.multiple_deposit_mineral_resource.resource.length - 1
        ].id - 3;
    },
    addOreRows() {
      let depo_percentage = null;
      if (
        this.model.current_ownership &&
        !this.model.current_ownership.includes("-")
      ) {
        depo_percentage = this.model.current_ownership;
      }
      const total1 = this.model.multiple_deposit_ore_resource.resource.pop();
      const total2 = this.model.multiple_deposit_ore_resource.resource.pop();
      const total3 = this.model.multiple_deposit_ore_resource.resource.pop();

      const tempTotal3 = { ...total3 };
      tempTotal3.deposit_percentage = depo_percentage;
      for (const temp in tempTotal3) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal3[temp] = null;
        } else if (temp == "id") {
          tempTotal3[temp] = total3.id;
        }
      }
      this.model.multiple_deposit_ore_resource.resource.push(tempTotal3);

      const tempTotal2 = { ...total2 };
      tempTotal2.deposit_percentage = depo_percentage;
      for (const temp in tempTotal2) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal2[temp] = null;
        } else if (temp == "id") {
          tempTotal2[temp] = total2.id;
        }
      }
      this.model.multiple_deposit_ore_resource.resource.push(tempTotal2);

      const tempTotal1 = { ...total1 };
      tempTotal1.deposit_percentage = depo_percentage;
      for (const temp in tempTotal1) {
        if (
          temp != "id" &&
          temp != "title" &&
          temp != "deposit" &&
          temp != "deposit_percentage"
        ) {
          tempTotal1[temp] = null;
        } else if (temp == "id") {
          tempTotal1[temp] = total1.id;
        }
      }
      this.model.multiple_deposit_ore_resource.resource.push(tempTotal1);
      total1.id = total1.id + 3;
      total2.id = total2.id + 3;
      total3.id = total3.id + 3;
      this.model.multiple_deposit_ore_resource.resource.push(total3);
      this.model.multiple_deposit_ore_resource.resource.push(total2);
      this.model.multiple_deposit_ore_resource.resource.push(total1);
      this.multiple_table_last_deposit_ore_row =
        this.model.multiple_deposit_ore_resource.resource[
          this.model.multiple_deposit_ore_resource.resource.length - 1
        ].id - 2;
    },
    removeMineralRows(id) {
      const idsToRemove = [id, id + 1, id + 2, id + 3];
      const newRow =
        this.model.multiple_deposit_mineral_resource.resource.filter(
          (item) => !idsToRemove.includes(item.id)
        );
      newRow.forEach((item, index) => {
        item.id = index + 1;
      });
      this.model.multiple_deposit_mineral_resource.resource = newRow;
      this.multiple_table_last_deposit_row =
        this.model.multiple_deposit_mineral_resource.resource[
          this.model.multiple_deposit_mineral_resource.resource.length - 1
        ].id - 3;
    },
    removeOreRows(id) {
      const idsToRemove = [id, id + 1, id + 2];
      const newRow = this.model.multiple_deposit_ore_resource.resource.filter(
        (item) => !idsToRemove.includes(item.id)
      );
      newRow.forEach((item, index) => {
        item.id = index + 1;
      });
      this.model.multiple_deposit_ore_resource.resource = newRow;
      this.multiple_table_last_deposit_ore_row =
        this.model.multiple_deposit_ore_resource.resource[
          this.model.multiple_deposit_ore_resource.resource.length - 1
        ].id - 2;
    },
    convertToObjectWithValueAndDisplay(array) {
      let transformedArray = array.map((obj) => {
        let newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (value != null) {
            newObj[key] = {
              value: value,
              display: String(value),
            };
          } else {
            newObj[key] = {
              value: value,
              display: "",
            };
          }
        });
        return newObj;
      });
      return transformedArray;
    },
    calculateMultipleTable(columns, tableResource) {
      let weightedMeasured = {},
        weightedIndicated = {},
        weightedInferred = {},
        weightedTotal = {};
      let totalMeasured = {},
        totalIndicated = {},
        totalInferred = {},
        totalTotal = {};
      let lastMeasured, lastIndicated, lastInferred, lastTotal;
      for (let i = 0; i < tableResource.length - 4; i += 4) {
        const measuredRow = tableResource[i];
        const indicatedRow = tableResource[i + 1];
        const inferredRow = tableResource[i + 2];
        const totalRow = tableResource[i + 3];

        lastMeasured = tableResource[tableResource.length - 4];
        lastIndicated = tableResource[tableResource.length - 3];
        lastInferred = tableResource[tableResource.length - 2];
        lastTotal = tableResource[tableResource.length - 1];

        if (totalRow && measuredRow && indicatedRow && inferredRow) {
          columns.forEach((column) => {
            if (
              column.field !== "title" &&
              column.field !== "btn" &&
              column.field !== "deposit"
            ) {
              if (column.calculated && column.equation) {
                this.calculateCalculatedMultipleFields(
                  column,
                  measuredRow,
                  indicatedRow,
                  inferredRow,
                  totalRow,
                  totalMeasured,
                  totalIndicated,
                  totalInferred,
                  totalTotal
                );
              } else if (column.weightedAverage && column.weightedColumn) {
                if (column.weightedEquation) {
                  this.calculateMultipleWeightedAverageWithEquation(
                    column,
                    measuredRow,
                    indicatedRow,
                    inferredRow,
                    totalRow,
                    weightedMeasured,
                    weightedIndicated,
                    weightedInferred,
                    weightedTotal
                  );
                } else {
                  this.calculateMultipleWeightedAverage(
                    column,
                    measuredRow,
                    indicatedRow,
                    inferredRow,
                    totalRow,
                    weightedMeasured,
                    weightedIndicated,
                    weightedInferred,
                    weightedTotal
                  );
                }
              } else {
                totalRow[column.field] =
                  totalRow[column.field] == "" || totalRow[column.field] == null
                    ? this.formatNumberUpToFourDecimals(
                      (measuredRow[column.field] || null) +
                      (indicatedRow[column.field] || null) +
                      (inferredRow[column.field] || null)
                    )
                    : totalRow[column.field];
                if (
                  totalRow[column.field] == 0 ||
                  isNaN(totalRow[column.field])
                ) {
                  totalRow[column.field] = null;
                }
                totalMeasured[column.field] = isNaN(totalMeasured[column.field])
                  ? measuredRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalMeasured[column.field] + measuredRow[column.field]
                  );
                totalIndicated[column.field] = isNaN(
                  totalIndicated[column.field]
                )
                  ? indicatedRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalIndicated[column.field] + indicatedRow[column.field]
                  );
                totalInferred[column.field] = isNaN(totalInferred[column.field])
                  ? inferredRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalInferred[column.field] + inferredRow[column.field]
                  );
                totalTotal[column.field] = isNaN(totalTotal[column.field])
                  ? totalRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalTotal[column.field] + totalRow[column.field]
                  );
              }
            }
          });
        }
      }
      for (let i = tableResource.length - 4; i < tableResource.length; i++) {
        columns.forEach((column) => {
          if (
            column.field !== "title" &&
            column.field !== "btn" &&
            column.field !== "deposit"
          ) {
            if (column.weightedAverage && column.weightedColumn) {
              if (
                lastMeasured[column.field] == null ||
                lastMeasured[column.field] == ""
              ) {
                lastMeasured[column.field] = this.formatNumberUpToFourDecimals(
                  weightedMeasured[column.field] /
                  totalMeasured[column.weightedColumn]
                );
                if (isNaN(lastMeasured[column.field])) {
                  lastMeasured[column.field] = null;
                }
              }
              if (
                lastIndicated[column.field] == null ||
                lastIndicated[column.field] == ""
              ) {
                lastIndicated[column.field] = this.formatNumberUpToFourDecimals(
                  weightedIndicated[column.field] /
                  totalIndicated[column.weightedColumn]
                );
                if (isNaN(lastIndicated[column.field])) {
                  lastIndicated[column.field] = null;
                }
              }
              if (
                lastInferred[column.field] == null ||
                lastInferred[column.field] == ""
              ) {
                lastInferred[column.field] = this.formatNumberUpToFourDecimals(
                  weightedInferred[column.field] /
                  totalInferred[column.weightedColumn]
                );
                if (isNaN(lastInferred[column.field])) {
                  lastInferred[column.field] = null;
                }
              }
              if (
                lastTotal[column.field] == null ||
                lastTotal[column.field] == ""
              ) {
                lastTotal[column.field] = this.formatNumberUpToFourDecimals(
                  weightedTotal[column.field] /
                  totalTotal[column.weightedColumn]
                );
                if (isNaN(lastTotal[column.field])) {
                  lastTotal[column.field] = null;
                }
              }
            } else {
              if (
                lastMeasured[column.field] == null ||
                lastMeasured[column.field] == ""
              ) {
                lastMeasured[column.field] = this.formatNumberUpToFourDecimals(
                  totalMeasured[column.field]
                );
                if (isNaN(lastMeasured[column.field])) {
                  lastMeasured[column.field] = null;
                }
              }
              if (
                lastIndicated[column.field] == null ||
                lastIndicated[column.field] == ""
              ) {
                lastIndicated[column.field] = this.formatNumberUpToFourDecimals(
                  totalIndicated[column.field]
                );
                if (isNaN(lastIndicated[column.field])) {
                  lastIndicated[column.field] = null;
                }
              }
              if (
                lastInferred[column.field] == null ||
                lastInferred[column.field] == ""
              ) {
                lastInferred[column.field] = this.formatNumberUpToFourDecimals(
                  totalInferred[column.field]
                );
                if (isNaN(lastInferred[column.field])) {
                  lastInferred[column.field] = null;
                }
              }
              if (
                lastTotal[column.field] == null ||
                lastTotal[column.field] == ""
              ) {
                lastTotal[column.field] = this.formatNumberUpToFourDecimals(
                  totalTotal[column.field]
                );
                if (isNaN(lastTotal[column.field])) {
                  lastTotal[column.field] = null;
                }
              }
            }
          }
        });
      }
    },
    calculateMultipleOreTable(columns, tableResource) {
      let weightedProven = {},
        weightedProbable = {},
        weightedTotal = {};
      let totalProven = {},
        totalProbable = {},
        totalTotal = {};
      let lastProven, lastProbable, lastTotal;
      for (let i = 0; i < tableResource.length - 3; i += 3) {
        const provenRow = tableResource[i];
        const probableRow = tableResource[i + 1];
        const totalRow = tableResource[i + 2];

        lastProven = tableResource[tableResource.length - 3];
        lastProbable = tableResource[tableResource.length - 2];
        lastTotal = tableResource[tableResource.length - 1];

        if (totalRow && provenRow && probableRow) {
          columns.forEach((column) => {
            if (
              column.field !== "title" &&
              column.field !== "btn" &&
              column.field !== "deposit"
            ) {
              if (column.calculated && column.equation) {
                this.calculateCalculatedMultipleFieldsOre(
                  column,
                  provenRow,
                  probableRow,
                  totalRow,
                  totalProven,
                  totalProbable,
                  totalTotal
                );
              } else if (column.weightedAverage && column.weightedColumn) {
                if (column.weightedEquation) {
                  this.calculateMultipleWeightedAverageWithEquationOre(
                    column,
                    provenRow,
                    probableRow,
                    totalRow,
                    weightedProven,
                    weightedProbable,
                    weightedTotal
                  );
                } else {
                  this.calculateMultipleWeightedAverageOre(
                    column,
                    provenRow,
                    probableRow,
                    totalRow,
                    weightedProven,
                    weightedProbable,
                    weightedTotal
                  );
                }
              } else {
                totalRow[column.field] =
                  totalRow[column.field] == "" || totalRow[column.field] == null
                    ? this.formatNumberUpToFourDecimals(
                      (provenRow[column.field] || null) +
                      (probableRow[column.field] || null)
                    )
                    : totalRow[column.field];
                if (
                  totalRow[column.field] == 0 ||
                  isNaN(totalRow[column.field])
                ) {
                  totalRow[column.field] = null;
                }
                totalProven[column.field] = isNaN(totalProven[column.field])
                  ? provenRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalProven[column.field] + provenRow[column.field]
                  );
                totalProbable[column.field] = isNaN(totalProbable[column.field])
                  ? probableRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalProbable[column.field] + probableRow[column.field]
                  );
                totalTotal[column.field] = isNaN(totalTotal[column.field])
                  ? totalRow[column.field]
                  : this.formatNumberUpToFourDecimals(
                    totalTotal[column.field] + totalRow[column.field]
                  );
              }
            }
          });
        }
      }
      for (let i = tableResource.length - 3; i < tableResource.length; i++) {
        columns.forEach((column) => {
          if (
            column.field !== "title" &&
            column.field !== "btn" &&
            column.field !== "deposit"
          ) {
            if (column.weightedAverage && column.weightedColumn) {
              if (
                lastProven[column.field] == null ||
                lastProven[column.field] == ""
              ) {
                lastProven[column.field] = this.formatNumberUpToFourDecimals(
                  weightedProven[column.field] /
                  totalProven[column.weightedColumn]
                );
                if (isNaN(lastProven[column.field])) {
                  lastProven[column.field] = null;
                }
              }
              if (
                lastProbable[column.field] == null ||
                lastProbable[column.field] == ""
              ) {
                lastProbable[column.field] = this.formatNumberUpToFourDecimals(
                  weightedProbable[column.field] /
                  totalProbable[column.weightedColumn]
                );
                if (isNaN(lastProbable[column.field])) {
                  lastProbable[column.field] = null;
                }
              }
              if (
                lastTotal[column.field] == null ||
                lastTotal[column.field] == ""
              ) {
                lastTotal[column.field] = this.formatNumberUpToFourDecimals(
                  weightedTotal[column.field] /
                  totalTotal[column.weightedColumn]
                );
                if (isNaN(lastTotal[column.field])) {
                  lastTotal[column.field] = null;
                }
              }
            } else {
              if (
                lastProven[column.field] == null ||
                lastProven[column.field] == ""
              ) {
                lastProven[column.field] = this.formatNumberUpToFourDecimals(
                  totalProven[column.field]
                );
                if (isNaN(lastProven[column.field])) {
                  lastProven[column.field] = null;
                }
              }
              if (
                lastProbable[column.field] == null ||
                lastProbable[column.field] == ""
              ) {
                lastProbable[column.field] = this.formatNumberUpToFourDecimals(
                  totalProbable[column.field]
                );
                if (isNaN(lastProbable[column.field])) {
                  lastProbable[column.field] = null;
                }
              }
              if (
                lastTotal[column.field] == null ||
                lastTotal[column.field] == ""
              ) {
                lastTotal[column.field] = this.formatNumberUpToFourDecimals(
                  totalTotal[column.field]
                );
                if (isNaN(lastTotal[column.field])) {
                  lastTotal[column.field] = null;
                }
              }
            }
          }
        });
      }
    },
    calculateCalculatedMultipleFields(
      column,
      measuredRow,
      indicatedRow,
      inferredRow,
      totalRow,
      totalMeasured,
      totalIndicated,
      totalInferred,
      totalTotal
    ) {
      const equation = column.equation;

      this.dynamicEquation(equation, measuredRow, column);

      this.dynamicEquation(equation, indicatedRow, column);

      this.dynamicEquation(equation, inferredRow, column);

      totalRow[column.field] =
        totalRow[column.field] == "" || totalRow[column.field] == null
          ? this.formatNumberUpToFourDecimals(
            (this.formatNumberUpToFourDecimals(measuredRow[column.field]) ||
              null) +
            (this.formatNumberUpToFourDecimals(
              indicatedRow[column.field]
            ) || null) +
            (this.formatNumberUpToFourDecimals(inferredRow[column.field]) ||
              null)
          )
          : totalRow[column.field];
      if (totalRow[column.field] == 0 || isNaN(totalRow[column.field])) {
        totalRow[column.field] = null;
      }
      totalMeasured[column.field] = isNaN(totalMeasured[column.field])
        ? measuredRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalMeasured[column.field] + measuredRow[column.field]
        );

      totalIndicated[column.field] = isNaN(totalIndicated[column.field])
        ? indicatedRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalIndicated[column.field] + indicatedRow[column.field]
        );

      totalInferred[column.field] = isNaN(totalInferred[column.field])
        ? inferredRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalInferred[column.field] + inferredRow[column.field]
        );

      totalTotal[column.field] = isNaN(totalTotal[column.field])
        ? totalRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalTotal[column.field] + totalRow[column.field]
        );
    },
    calculateMultipleWeightedAverageWithEquation(
      column,
      measuredRow,
      indicatedRow,
      inferredRow,
      totalRow,
      weightedMeasured,
      weightedIndicated,
      weightedInferred,
      weightedTotal
    ) {
      const equation = column.weightedEquation;

      this.dynamicEquation(equation, measuredRow, column);

      this.dynamicEquation(equation, indicatedRow, column);

      this.dynamicEquation(equation, inferredRow, column);

      this.calculateMultipleWeightedAverage(
        column,
        measuredRow,
        indicatedRow,
        inferredRow,
        totalRow,
        weightedMeasured,
        weightedIndicated,
        weightedInferred,
        weightedTotal
      );
    },
    calculateMultipleWeightedAverage(
      column,
      measuredRow,
      indicatedRow,
      inferredRow,
      totalRow,
      weightedMeasured,
      weightedIndicated,
      weightedInferred,
      weightedTotal
    ) {
      let totalWeightedValue = 0;
      let totalWeight = 0;
      totalWeightedValue =
        measuredRow[column.field] * measuredRow[column.weightedColumn] +
        indicatedRow[column.field] * indicatedRow[column.weightedColumn] +
        inferredRow[column.field] * inferredRow[column.weightedColumn] ||
        null;
      // totalWeight += measuredRow[column.weightedColumn] || null;
      totalRow[column.field] =
        totalRow[column.field] == null
          ? this.formatNumberUpToFourDecimals(
            totalWeightedValue / totalRow[column.weightedColumn]
          )
          : totalRow[column.field];
      if (isNaN(totalRow[column.field])) {
        totalRow[column.field] = null;
      }

      // totalMeasured[column.field] = (measuredRow[column.weightedColumn] * measuredRow[column.field]) / totalMeasured[column.weightedColumn];
      weightedMeasured[column.field] = isNaN(weightedMeasured[column.field])
        ? measuredRow[column.weightedColumn] * measuredRow[column.field]
        : weightedMeasured[column.field] +
        measuredRow[column.weightedColumn] * measuredRow[column.field];

      weightedIndicated[column.field] = isNaN(weightedIndicated[column.field])
        ? indicatedRow[column.weightedColumn] * indicatedRow[column.field]
        : weightedIndicated[column.field] +
        indicatedRow[column.weightedColumn] * indicatedRow[column.field];

      weightedInferred[column.field] = isNaN(weightedInferred[column.field])
        ? inferredRow[column.weightedColumn] * inferredRow[column.field]
        : weightedInferred[column.field] +
        inferredRow[column.weightedColumn] * inferredRow[column.field];

      weightedTotal[column.field] = isNaN(weightedTotal[column.field])
        ? totalRow[column.weightedColumn] * totalRow[column.field]
        : weightedTotal[column.field] +
        totalRow[column.weightedColumn] * totalRow[column.field];
    },

    calculateCalculatedMultipleFieldsOre(
      column,
      provenRow,
      probableRow,
      totalRow,
      totalProven,
      totalProbable,
      totalTotal
    ) {
      const equation = column.equation;

      this.dynamicEquation(equation, provenRow, column);

      this.dynamicEquation(equation, probableRow, column);

      totalRow[column.field] =
        totalRow[column.field] == "" || totalRow[column.field] == null
          ? this.formatNumberUpToFourDecimals(
            (this.formatNumberUpToFourDecimals(provenRow[column.field]) ||
              null) +
            (this.formatNumberUpToFourDecimals(probableRow[column.field]) ||
              null)
          )
          : totalRow[column.field];
      if (totalRow[column.field] == 0 || isNaN(totalRow[column.field])) {
        totalRow[column.field] = null;
      }
      totalProven[column.field] = isNaN(totalProven[column.field])
        ? provenRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalProven[column.field] + provenRow[column.field]
        );

      totalProbable[column.field] = isNaN(totalProbable[column.field])
        ? probableRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalProbable[column.field] + probableRow[column.field]
        );

      totalTotal[column.field] = isNaN(totalTotal[column.field])
        ? totalRow[column.field]
        : this.formatNumberUpToFourDecimals(
          totalTotal[column.field] + totalRow[column.field]
        );
    },
    calculateMultipleWeightedAverageWithEquationOre(
      column,
      provenRow,
      probableRow,
      totalRow,
      weightedProven,
      weightedProbable,
      weightedTotal
    ) {
      const equation = column.weightedEquation;

      this.dynamicEquation(equation, provenRow, column);

      this.dynamicEquation(equation, probableRow, column);

      this.calculateMultipleWeightedAverageOre(
        column,
        provenRow,
        probableRow,
        totalRow,
        weightedProven,
        weightedProbable,
        weightedTotal
      );
    },
    calculateMultipleWeightedAverageOre(
      column,
      provenRow,
      probableRow,
      totalRow,
      weightedProven,
      weightedProbable,
      weightedTotal
    ) {
      let totalWeightedValue = 0;
      let totalWeight = 0;
      totalWeightedValue =
        provenRow[column.field] * provenRow[column.weightedColumn] +
        probableRow[column.field] * probableRow[column.weightedColumn] ||
        null;
      totalRow[column.field] =
        totalRow[column.field] == null
          ? this.formatNumberUpToFourDecimals(
            totalWeightedValue / totalRow[column.weightedColumn]
          )
          : totalRow[column.field];
      if (isNaN(totalRow[column.field])) {
        totalRow[column.field] = null;
      }

      weightedProven[column.field] = isNaN(weightedProven[column.field])
        ? provenRow[column.weightedColumn] * provenRow[column.field]
        : weightedProven[column.field] +
        provenRow[column.weightedColumn] * provenRow[column.field];

      weightedProbable[column.field] = isNaN(weightedProbable[column.field])
        ? probableRow[column.weightedColumn] * probableRow[column.field]
        : weightedProbable[column.field] +
        probableRow[column.weightedColumn] * probableRow[column.field];

      weightedTotal[column.field] = isNaN(weightedTotal[column.field])
        ? totalRow[column.weightedColumn] * totalRow[column.field]
        : weightedTotal[column.field] +
        totalRow[column.weightedColumn] * totalRow[column.field];
    },
    dynamicEquation(equation, row, column) {
      const eq = equation.replace(/(\w+)/g, (columnName) => {
        if (columnName in row) {
          return `row.${columnName}`;
        }
        return columnName;
      });
      const func = new Function("row", `return ${eq}`);
      const result = func(row);
      if (row[column.field] == null || row[column.field] == "") {
        row[column.field] = this.formatNumberUpToFourDecimals(result);
        if (isNaN(row[column.field])) {
          row[column.field] = null;
        }
      }
    },
    handleOwnershipInput(event, dataItem, field) {
      const filteredValue = event.target.value.replace(/[^\d.-]/g, "");
      dataItem[field] = filteredValue;
    },

    validateOwnerInput(field) {
      let userInput = this.model[field];
      let filteredInput = userInput.replace(/[^-0-9.]/g, "");
      if (filteredInput !== userInput) {
        this.model[field] = filteredInput;
      }
      if (this.model[field].includes("-")) {
        this.curr_ownership_disable = false;
      } else {
        this.curr_ownership_disable = true;
      }
      if (
        field == "current_ownership" &&
        !this.model[field].includes("-") &&
        this.localTableResource?.mineral_resource?.current_ownership &&
        this.localTableResource?.ore_resource?.current_ownership
      ) {
        this.localTableResource.mineral_resource.current_ownership.current_ownership.current_ownership =
          this.model[field];
        this.localTableResource.ore_resource.current_ownership.current_ownership.current_ownership =
          this.model[field];

        if (this.model?.multiple_deposit_mineral_resource?.resource) {
          for (
            let i = 0;
            i <
            this.model?.multiple_deposit_mineral_resource?.resource?.length - 4;
            i += 4
          ) {
            this.model.multiple_deposit_mineral_resource.resource[
              i
            ].deposit_percentage = this.model[field];
          }
        }
        if (this.model?.multiple_deposit_ore_resource?.resource) {
          for (
            let i = 0;
            i < this.model?.multiple_deposit_ore_resource?.resource?.length - 3;
            i += 3
          ) {
            this.model.multiple_deposit_ore_resource.resource[
              i
            ].deposit_percentage = this.model[field];
          }
        }
      }
      if (field == "current_ownership" && this.model[field].includes("-")) {
        if (this.model.deposit_type.name == "Single") {
          this.localTableResource.mineral_resource.current_ownership.current_ownership.current_ownership =
            "";
          this.localTableResource.ore_resource.current_ownership.current_ownership.current_ownership =
            "";
        }
        if (this.model.deposit_type.name == "Multiple") {
          if (this.model?.multiple_deposit_mineral_resource?.resource) {
            for (
              let i = 0;
              i <
              this.model?.multiple_deposit_mineral_resource?.resource?.length -
              4;
              i += 4
            ) {
              this.model.multiple_deposit_mineral_resource.resource[
                i
              ].deposit_percentage = "";
            }
          }
          if (this.model?.multiple_deposit_ore_resource?.resource) {
            for (
              let i = 0;
              i <
              this.model?.multiple_deposit_ore_resource?.resource?.length - 3;
              i += 3
            ) {
              this.model.multiple_deposit_ore_resource.resource[
                i
              ].deposit_percentage = "";
            }
          }
        }
      }
    },
    tableResourceFunc(payload) {
      getCommoditiesTableResource(payload)
        .then((response) => {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          this.localTableResource = response?.data?.result?.resource;
          this.feasibility_field_resource =
            this.localTableResource?.feasibility_field_resource;
          this.feasibility_common_field_resource =
            this.localTableResource?.feasibility_common_field_resource;
          this.$store.commit(
            "SET_COMMODITIES_TABLE_RESOURCE",
            response?.data?.result?.resource
          );
          if (response?.data?.result?.resource?.mineral_resource) {
            if (
              response?.data?.result?.resource?.mineral_resource
                ?.mineral_resource?.mineral_resource == null &&
              this.model.current_ownership &&
              !this.model.current_ownership.includes("-")
            ) {
              this.localTableResource.mineral_resource.current_ownership.current_ownership.current_ownership =
                this.model.current_ownership;
            }
          }
          if (response?.data?.result?.resource?.ore_resource) {
            if (
              response?.data?.result?.resource?.ore_resource?.mineral_resource
                ?.mineral_resource == null &&
              this.model.current_ownership &&
              !this.model.current_ownership.includes("-")
            ) {
              this.localTableResource.ore_resource.current_ownership.current_ownership.current_ownership =
                this.model.current_ownership;
            }
          }
          this.model.multiple_deposit_column_mineral =
            response?.data?.result?.resource?.multiple_deposit_column_mineral;
          this.model.multiple_deposit_column_ore =
            response?.data?.result?.resource?.multiple_deposit_column_ore;
          this.model.multiple_deposit_mineral_resource =
            response?.data?.result?.resource?.multiple_deposit_mineral_resource;
          this.model.multiple_deposit_ore_resource =
            response?.data?.result?.resource?.multiple_deposit_ore_resource;

          this.multiple_table_last_deposit_row =
            this.model.multiple_deposit_mineral_resource.resource[
              this.model.multiple_deposit_mineral_resource.resource.length - 1
            ].id - 3;
          this.multiple_table_last_deposit_ore_row =
            this.model.multiple_deposit_ore_resource.resource[
              this.model.multiple_deposit_ore_resource.resource.length - 1
            ].id - 2;

          this.model?.multiple_deposit_mineral_resource.resource?.forEach(
            (item) => {
              if (
                this.model.current_ownership &&
                !this.model.current_ownership.includes("-")
              ) {
                item.deposit_percentage = this.model.current_ownership;
              } else {
                item.deposit_percentage =
                  item.deposit_percentage == null ||
                    item.deposit_percentage == ""
                    ? null
                    : item.deposit_percentage;
              }
            }
          );
          this.model?.multiple_deposit_ore_resource.resource?.forEach(
            (item) => {
              if (
                this.model.current_ownership &&
                !this.model.current_ownership.includes("-")
              ) {
                item.deposit_percentage = this.model.current_ownership;
              } else {
                item.deposit_percentage =
                  item.deposit_percentage == null ||
                    item.deposit_percentage == ""
                    ? null
                    : item.deposit_percentage;
              }
            }
          );
          if (
            this.localTableResource?.stage?.stage_name != "Exploration" &&
            this.model?.deposit_type?.name == "Multiple"
          ) {
            this.model?.multiple_deposit_mineral_resource?.resource?.some(
              (item) => {
                if (item?.inEdit) {
                  this.multipleMineralTableSpand = true;
                  return true;
                } else {
                  this.multipleMineralTableSpand = false;
                }
              }
            );
            this.model?.multiple_deposit_ore_resource?.resource?.some(
              (item) => {
                if (item?.inEdit) {
                  this.multipleOreTableSpand = true;
                  return true;
                } else {
                  this.multipleOreTableSpand = false;
                }
              }
            );
          }
        })
        .catch((error) => { });
    },
    getNewProjectCreateView() {
      getNewProjectCreateView()
        .then((response) => {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          this.$store.commit(
            "SET_NEWPROJECT_CREATEVIEW_DATA",
            response?.data?.result?.project
          );
          this.feasibility_field_resource =
            response?.data?.result?.project?.feasibility_field_resource;
          this.feasibility_common_field_resource =
            response?.data?.result?.project?.feasibility_common_field_resource;

          this.itemsStage = response?.data?.result?.project?.stage?.slice();
          this.itemsStatus = response?.data?.result?.project?.status?.slice();
          this.itemsMainCommodities =
            response?.data?.result?.project?.main_commodities?.slice();
          this.itemsCountry = response?.data?.result?.project?.country?.slice();
          this.itemsLithiumType =
            response?.data?.result?.project?.lithium_type?.slice();
          this.itemStates = response?.data?.result?.project?.state
            ?.filter(
              (state) =>
                state.country_code === this.defaultItemCountry?.country_code
            )
            .slice();
          this.defaultItemStates = this.itemStates[0];
        })
        .catch((error) => { });
    },
    getProjectEditFunc() {
      let payload = {
        companyId: this.$route.params.id,
        projectId: this.$route?.params?.projectId,
      };
      getProjectEdit(payload)
        .then((response) => {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE_PROJECT", false);
          this.$store.commit(
            "SET_NEWPROJECT_CREATEVIEW_DATA",
            response?.data?.result?.resource
          );
          this.feasibility_field_resource =
            response?.data?.result?.project?.feasibility_field_resource;
          this.feasibility_common_field_resource =
            response?.data?.result?.project?.feasibility_common_field_resource;
          this.localTableResource = response?.data?.result?.project;
          this.defaultItemLithiumType = this.localTableResource?.lithium_type
            ? this.localTableResource?.lithium_type
            : this.defaultItemLithiumType;
          if (
            (!this.localTableResource?.ore_resource ||
              !this.localTableResource?.column_mineral ||
              !this.localTableResource?.mineral_resource ||
              !this.localTableResource?.column_ore) &&
            this.localTableResource?.stage?.stage_name != "Exploration"
          ) {
            this.islithiumType = false;

            let payload = {
              commodities_name:
                this.localTableResource?.main_commodities?.alias,
              lithium_type: this.islithiumType
                ? this.defaultItemLithiumType?.type
                : "",
            };
            this.isOreResourceTableDisable = false;
            this.isMineralResourceTableDisable = false;
            this.tableResourceFunc(payload);
          } else {
            this.isMineralResourceTableDisable =
              this.model.is_hidden_mineral > 0 ? true : false;
            this.isOreResourceTableDisable =
              this.model.is_hidden_ore > 0 ? true : false;
          }
          if (
            this.localTableResource?.stage?.stage_name != "Exploration" &&
            this.localTableResource?.main_commodities?.name == "Lithium"
          ) {
            this.islithiumType = true;
          }
          if (
            this.localTableResource?.stage?.stage_name == "Exploration" &&
            this.localTableResource?.main_commodities != null
          ) {
            this.islithiumType = this.localTableResource?.main_commodities.some(
              (obj) => obj.name == "Lithium"
            );
          }

          this.model = response?.data?.result?.project;
          this.itemsStage = response?.data?.result?.resource?.stage?.slice();
          this.itemsStatus = response?.data?.result?.resource?.status?.slice();
          this.itemsMainCommodities =
            response?.data?.result?.resource?.main_commodities?.slice();

          this.itemsCountry =
            response?.data?.result?.resource?.country?.slice();
          this.itemsLithiumType =
            response?.data?.result?.resource?.lithium_type?.slice();
          this.defaultItemStates = response?.data?.result?.project?.state;
          this.defaultItemStage = this.localTableResource?.stage;
          this.defaultItemStatus = this.localTableResource?.status;
          this.defaultItemMainCommodities =
            this.localTableResource?.main_commodities;

          this.lastUpdatedDate = this.model.last_updated_date;
          this.model.deposit_type =
            response?.data?.result?.project?.deposit_type == null ||
              response?.data?.result?.project?.deposit_type == "single"
              ? { id: 1, name: "Single" }
              : response?.data?.result?.project?.deposit_type;

          this.model.multiple_deposit_column_mineral =
            response?.data?.result?.project?.multiple_deposit_column_mineral;
          this.model.multiple_deposit_column_ore =
            response?.data?.result?.project?.multiple_deposit_column_ore;
          this.model.multiple_deposit_mineral_resource =
            response?.data?.result?.project?.multiple_deposit_mineral_resource;
          this.model.multiple_deposit_ore_resource =
            response?.data?.result?.project?.multiple_deposit_ore_resource;

          if (
            this.defaultItemMainCommodities?.alias != "kaolin" &&
            this.defaultItemMainCommodities?.alias != "potash" &&
            this.localTableResource?.stage?.stage_name != "Exploration" &&
            this.model?.multiple_deposit_mineral_resource?.resource &&
            this.model?.multiple_deposit_mineral_resource?.resource?.length > 0
          ) {
            this.multiple_table_last_deposit_row =
              this.model?.multiple_deposit_mineral_resource?.resource[
                this.model?.multiple_deposit_mineral_resource?.resource.length -
                1
              ].id - 3;
          }
          if (
            this.defaultItemMainCommodities?.alias != "kaolin" &&
            this.defaultItemMainCommodities?.alias != "potash" &&
            this.localTableResource?.stage?.stage_name != "Exploration" &&
            this.model?.multiple_deposit_mineral_resource?.resource &&
            this.model?.multiple_deposit_mineral_resource?.resource?.length > 0
          ) {
            this.multiple_table_last_deposit_ore_row =
              this.model?.multiple_deposit_ore_resource?.resource[
                this.model?.multiple_deposit_ore_resource?.resource.length - 1
              ].id - 2;
          }
          if (
            response?.data?.result?.project?.mineral_resource?.current_ownership
              ?.current_ownership?.current_ownership
          ) {
            if (
              response?.data?.result?.project?.mineral_resource
                ?.current_ownership?.current_ownership?.current_ownership ==
              null &&
              !response?.data?.result?.project?.current_ownership.includes("-")
            ) {
              this.localTableResource.mineral_resource.current_ownership.current_ownership.current_ownership =
                response?.data?.result?.project?.current_ownership;
            }
          }
          if (
            response?.data?.result?.project?.ore_resource?.current_ownership
              ?.current_ownership?.current_ownership
          ) {
            if (
              response?.data?.result?.project?.ore_resource?.current_ownership
                ?.current_ownership?.current_ownership == null &&
              !response?.data?.result?.project?.current_ownership.includes("-")
            ) {
              this.localTableResource.ore_resource.current_ownership.current_ownership.current_ownership =
                response?.data?.result?.project?.current_ownership;
            }
          }
          if (
            response?.data?.result?.project?.current_ownership &&
            response?.data?.result?.project?.current_ownership.includes("-")
          ) {
            this.curr_ownership_disable = false;
          } else {
            this.curr_ownership_disable = true;
          }
          if (this.localTableResource?.stage?.stage_name != "Exploration") {
            for (
              let i = 0;
              i <
              this.model?.multiple_deposit_mineral_resource?.resource?.length -
              4;
              i += 4
            ) {
              if (
                response?.data?.result?.project?.current_ownership &&
                !response?.data?.result?.project?.current_ownership.includes(
                  "-"
                )
              ) {
                this.model.multiple_deposit_mineral_resource.resource[
                  i
                ].deposit_percentage =
                  response?.data?.result?.project?.current_ownership;
              } else {
                this.model.multiple_deposit_mineral_resource.resource[
                  i
                ].deposit_percentage =
                  this.model.multiple_deposit_mineral_resource.resource[i]
                    .deposit_percentage == null ||
                    this.model.multiple_deposit_mineral_resource.resource[i]
                      .deposit_percentage == ""
                    ? null
                    : this.model.multiple_deposit_mineral_resource.resource[i]
                      .deposit_percentage;
              }
            }
          }
          if (this.localTableResource?.stage?.stage_name != "Exploration") {
            for (
              let i = 0;
              i <
              this.model?.multiple_deposit_ore_resource?.resource?.length - 3;
              i += 3
            ) {
              if (
                response?.data?.result?.project?.current_ownership &&
                !response?.data?.result?.project?.current_ownership.includes(
                  "-"
                )
              ) {
                this.model.multiple_deposit_ore_resource.resource[
                  i
                ].deposit_percentage =
                  response?.data?.result?.project?.current_ownership;
              } else {
                this.model.multiple_deposit_ore_resource.resource[
                  i
                ].deposit_percentage =
                  this.model.multiple_deposit_ore_resource.resource[i]
                    .deposit_percentage == null ||
                    this.model.multiple_deposit_ore_resource.resource[i]
                      .deposit_percentage == ""
                    ? null
                    : this.model.multiple_deposit_ore_resource.resource[i]
                      .deposit_percentage;
              }
            }
          }
          if (
            this.localTableResource?.stage?.stage_name != "Exploration" &&
            response?.data?.result?.project?.deposit_type?.name == "Multiple"
          ) {
            response?.data?.result?.project?.multiple_deposit_mineral_resource?.resource?.some(
              (item) => {
                if (item?.inEdit) {
                  this.multipleMineralTableSpand = true;
                  return true;
                } else {
                  this.multipleMineralTableSpand = false;
                }
              }
            );
            response?.data?.result?.project?.multiple_deposit_ore_resource?.resource?.some(
              (item) => {
                if (item?.inEdit) {
                  this.multipleOreTableSpand = true;
                  return true;
                } else {
                  this.multipleOreTableSpand = false;
                }
              }
            );
          }

          this.value =
            this.defaultItemStage?.stage_name == "Exploration"
              ? this.localTableResource?.main_commodities
              : "";
          this.itemsOwnershipClassificationValue =
            this.model?.ownership_classification;

          this.defaultItemCountry = this.localTableResource?.country;
          if (
            Array.isArray(this.localTableResource?.country) &&
            this.localTableResource?.country?.length == 0
          ) {
            this.defaultItemCountry = null;
          }
          this.itemStates = response?.data?.result?.resource?.state
            ?.filter(
              (state) =>
                state.country_code ===
                this.localTableResource?.country?.country_code
            )
            .slice();
          this.tempPhotoPath =
            configuration.apiBaseUrl + "/" + this.model?.image_path;
          if (this.model?.image_path) {
            this.imageData = [
              {
                name: this.model?.image_path.length
                  ? this.model?.image_path?.split("/").pop().toString()
                  : "",
              },
            ];
          }
          if (
            this.defaultItemMainCommodities?.alias == "kaolin" ||
            this.defaultItemMainCommodities?.alias == "potash"
          ) {
            if (this.model?.mineral_image_path) {
              this.mineralImageData = [
                {
                  name: this.model?.mineral_image_path.length
                    ? this.model?.mineral_image_path
                      ?.split("/")
                      .pop()
                      .toString()
                    : "",
                },
              ];
            }
            if (this.model?.ore_image_path) {
              this.oreImageData = [
                {
                  name: this.model?.ore_image_path.length
                    ? this.model?.ore_image_path?.split("/").pop().toString()
                    : "",
                },
              ];
            }
          }
          this.isCheckBoxVisible =
            this.defaultItemStage?.stage_name == "Exploration";
          if (
            this.localTableResource?.id &&
            this.localTableResource?.image_id
          ) {
            this.imageUploadUrl =
              configuration.apiBaseUrl +
              `/api/v1/company/project/${this.localTableResource?.id
              }/image-upload/${this.localTableResource?.image_id
                ? this.localTableResource?.image_id
                : 0
              }`;
          }
          if (
            this.localTableResource?.id &&
            this.localTableResource?.mineral_image_id
          ) {
            this.mineralImageUploadUrl =
              configuration.apiBaseUrl +
              `/api/v1/company/project/${this.localTableResource?.id
              }/image-upload/${this.localTableResource?.mineral_image_id
                ? this.localTableResource?.mineral_image_id
                : 0
              }`;
          }
          if (
            this.localTableResource?.id &&
            this.localTableResource?.ore_image_id
          ) {
            this.oreImageUploadUrl =
              configuration.apiBaseUrl +
              `/api/v1/company/project/${this.localTableResource?.id
              }/image-upload/${this.localTableResource?.ore_image_id
                ? this.localTableResource?.ore_image_id
                : 0
              }`;
          }

          if (this.defaultItemMainCommodities?.name == "Lithium") {
            this.islithiumType = true;
          }
          if (response?.data?.result?.resource?.lithium_type.length > 0) {
            for (const commodity of response?.data?.result?.project
              ?.main_commodities) {
              if (commodity.name == "Lithium") {
                this.islithiumType = true;
              }
            }
          }
          if (
            (this.localTableResource?.stage == "Exploration" &&
              this.localTableResource?.main_commodities == null) ||
            this.localTableResource?.main_commodities == ""
          ) {
            // this.defaultItemMainCommodities = []
          }
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", true);
        });
    },
    itemChange(e) {
      const data = this.localTableResource?.mineral_resource?.resource.slice();
      const index = data.findIndex((d) => d.id === e.dataItem.id);
      data[index] = { ...data[index], [e.field]: e.value };
      this.localTableResource.mineral_resource.resource = data;
    },
    texareaLengthCounter(maxValue, value) {
      if (value == null) value = "";
      return maxValue - value?.length;
    },
    areAllFieldsZero(dataPass) {
      const data = dataPass?.slice();
      let fieldTitle = "";
      for (
        var i = 1;
        i < this.localTableResource?.column_mineral?.length;
        i++
      ) {
        fieldTitle = this.localTableResource?.column_mineral[i]?.field;
        for (var j = 0; j < 3; j++) {
          if (data[data.length - 1][fieldTitle] != null) return false;
        }
      }
      return true;
    },
    itemChange2(e) {
      const data = this.localTableResource?.ore_resource?.resource?.slice();
      const index = data.findIndex((d) => d.id === e.dataItem.id);
      data[index] = { ...data[index], [e.field]: e.value };
      this.localTableResource.ore_resource.resource = data;
    },
    rowClick(e, table) {
      this.editID = e.dataItem.id;
      e.dataItem.inEdit = true;
      if (table == "first") {
        this.multipleMineralTableSpand = true;
      } else if (table == "second") {
        this.multipleOreTableSpand = true;
      }
    },
    rowClick2(e) {
      this.editID2 = e.dataItem.id;
      e.dataItem.inEdit = true;
    },
    closeEdit(e) {
      if (e.target === e.currentTarget) {
        this.editID = null;
      }
    },

    filterChangeStage(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataStage(event.filter)
          : this.dropdownData?.stage?.slice();
      this.itemsStage = newData;
    },
    filterDataStage(filter) {
      const data = this.dropdownData?.stage?.slice();
      return filterBy(data, filter);
    },

    filterChangeStatus(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataStatus(event.filter)
          : this.dropdownData?.status?.slice();
      this.itemsStatus = newData;
    },
    filterDataStatus(filter) {
      const data = this.dropdownData?.status?.slice();
      return filterBy(data, filter);
    },

    filterChangeMainCommodities(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataStageMainCommodities(event.filter)
          : this.dropdownData?.main_commodities?.slice();
      this.itemsMainCommodities = newData;
    },
    filterDataStageMainCommodities(filter) {
      const data = this.dropdownData?.main_commodities?.slice();
      return filterBy(data, filter);
    },

    filterChangeOwnershipClassification(event) {
      const newData =
        event.filter.value.length >= 1
          ? this.filterDataStageOwnershipClassification(event.filter)
          : this.itemsOwnershipClassification.slice();
      this.itemsOwnershipClassificationShow = newData;
    },
    filterDataStageOwnershipClassification(filter) {
      const data = this.itemsOwnershipClassification.slice();
      return filterBy(data, filter);
    },

    filterChangeCountry(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataCountry(event.filter)
          : this.dropdownData?.country?.slice();
      this.itemsCountry = newData;
    },
    filterDataCountry(filter) {
      const data = this.dropdownData?.country?.slice();
      return filterBy(data, filter);
    },
    filterChangeState(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataState(event.filter)
          : this.dropdownData?.state
            ?.filter(
              (state) =>
                state.country_code === this.defaultItemCountry?.country_code
            )
            .slice();
      this.itemStates = newData;
    },
    filterDataState(filter) {
      const data = this.dropdownData?.state
        ?.filter(
          (state) =>
            state.country_code === this.defaultItemCountry?.country_code
        )
        .slice();
      return filterBy(data, filter);
    },

    filterChangeLithiumType(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataLithiumType(event.filter)
          : this.dropdownData?.lithium_type?.slice();
      this.itemsLithiumType = newData;
    },
    filterDataLithiumType(filter) {
      const data = this.dropdownData?.lithium_type?.slice();
      return filterBy(data, filter);
    },

    mainComoditiesOnchange(e) {
      this.value = [...e.target.value];
      this.defaultItemMainCommodities = [...e.target.value];
      e.value.forEach((val) => {
        if (val.name == "Lithium") {
          this.islithiumType = true;
        } else {
          this.islithiumType = false;
        }
      });
    },
    handleMainCommodities(event) {
      this.islithiumType = false;
      this.defaultItemMainCommodities = event.value;
      if (event.value.name == "Lithium") {
        this.islithiumType = true;
      }
      let payload = {
        commodities_name: this.defaultItemMainCommodities?.alias,
        lithium_type: this.islithiumType
          ? this.defaultItemLithiumType?.type
          : "",
        project_uuid: this.$route?.params?.projectId,
      };
      this.tableResourceFunc(payload);
    },

    handleStageChange(event) {
      var errorModel = new error();
      if (event?.value?.stage_name != "Exploration") {
        if (this.defaultItemMainCommodities?.length > 1) {
          errorModel.commodity =
            "Multiple main commodities selected. Please select one main commodity";
          this.$store.commit("SET_ERROR", errorModel);
          return;
        } else {
          errorModel.commodity = "";
          this.$store.commit("SET_ERROR", errorModel);
          if (this.defaultItemMainCommodities?.length > 0) {
            this.defaultItemMainCommodities =
              this.defaultItemMainCommodities[0];
            let payload = {
              commodities_name: this.defaultItemMainCommodities?.alias,
              lithium_type: this.islithiumType
                ? this.defaultItemLithiumType?.type
                : "",
              project_uuid: this.$route?.params?.projectId,
            };
            this.tableResourceFunc(payload);
          }
          if (
            this.defaultItemMainCommodities == null ||
            this.defaultItemMainCommodities.length == 0
          ) {
            this.defaultItemMainCommodities = {};
          }
        }
      }
      if (event?.value?.stage_name == "Exploration") {
        this.isCheckBoxVisible = true;
        if (!this.defaultItemMainCommodities?.length) {
          if (
            this.$route?.params?.projectId &&
            this.defaultItemMainCommodities != null &&
            Object.keys(this.defaultItemMainCommodities).length != 0
          ) {
            this.defaultItemMainCommodities = [this.defaultItemMainCommodities];
          } else {
            this.defaultItemMainCommodities = [];
          }
        }
      }
      this.defaultItemStage = event.value;
      this.isCheckBoxVisible = false;
      this.isMineralResourceTableDisable = false;
      this.isOreResourceTableDisable = false;
    },
    handleStatus(event) {
      this.defaultItemStatus = event.value;
    },
    handleLithiumType(event) {
      this.defaultItemLithiumType = event.value;
      let payload = {
        commodities_name: this.defaultItemMainCommodities?.alias,
        lithium_type: this.islithiumType
          ? this.defaultItemLithiumType?.type
          : "",
        project_uuid: this.$route?.params?.projectId,
      };
      this.tableResourceFunc(payload);
    },
    // handleCountry(event) {
    //   this.defaultItemCountry = event.value;
    //   this.itemStates = this.dropdownData?.state
    //     ?.filter(
    //       (state) => state.country_code === this.defaultItemCountry.country_code
    //     )
    //     .slice();

    //   if (
    //     this.defaultItemCountry?.country_name != "Australia" ||
    //     this.defaultItemCountry?.country_name != "United States" ||
    //     this.defaultItemCountry?.country_name != "Canada"
    //   ) {
    //     this.defaultItemStates = "";
    //   }
    //   this.defaultItemStates = this.itemStates[0];
    //   if (!this.itemStates.length) {
    //     this.model.state = "";
    //   }
    // },
    handleCountry(event) {
      this.defaultItemCountry = event.value;
      const countryCode = this.defaultItemCountry.country_code;
      const countryName = this.defaultItemCountry.country_name;

      this.itemStates = this.dropdownData?.state?.filter(
        (state) => state.country_code === countryCode
      ).slice();

      if (!["Australia", "United States", "Canada"].includes(countryName)) {
        this.defaultItemStates = "";
      } else {
        this.defaultItemStates = this.itemStates[0];
      }

      if (!this.itemStates.length) {
        this.model.state = "";
      }
    },
    handleState(event) {
      this.defaultItemStates = event.value;
    },
    onChnageisMineralResourceTableDisable(event) {
      this.isMineralResourceTableDisable = event.value;
    },
    onChnageisOreResourceTableDisable(event) {
      this.isOreResourceTableDisable = event.value;
    },

    isVerify() {
      let isError = false;
      var errorModel = new error();
      if (this.model.project_name == "") {
        errorModel.project_name = "The project name field is required.";
        isError = true;
      }
      if (this.model.project_name.length < 1 && this.model.project_name != "") {
        errorModel.project_name =
          "The project name must be at least 1 characters.";
        isError = true;
      }
      if (this.model.project_name == "") {
        errorModel.project_name = "The project name field is required.";
        isError = true;
      }
      this.$store.commit("SET_ERROR", errorModel);
      return isError;
    },
    validate() {
      const errors = {};
      this.validateErrorMsg = {};

      const fieldErrorMessages = {
        studyType: "Study type is required.",
        mineLife: "Mine life cannot be negative.",
        processingRate: "Processing rate cannot be negative.",
        preTaxNPV: "Pre-tax NPV cannot be negative.",
        processingRecovery: "Processing recovery must be between 0 and 100%.",
        averageProduction: "Average production cannot be negative.",
        preTaxDiscountRate: "Pre-tax discount rate must be between 0 and 100%.",
        postTaxDiscountRate:
          "Post-tax discount rate must be between 0 and 100%.",
        preTaxIRR: "Pre-tax IRR must be between 0 and 100%.",
        postTaxIRR: "Post-tax IRR must be between 0 and 100%.",
        preTaxPaybackPeriod: "Pre-tax payback period cannot be negative.",
        postTaxPaybackPeriod: "Post-tax payback period cannot be negative.",
        notes: "Notes cannot exceed 100 characters.",
        // gold_price: 'Currency is required.',
      };

      this.feasibility_field_resource?.forEach((field) => {
        // if (!field.value && field.type === "text") {
        //   errors[field.label] = `${field.label} is required.`;
        // }

        // if (field.type === "number" && field.value !== null) {
        //   const value = parseFloat(field.value);

        //   if (field.min !== undefined && value < field.min) {
        //     errors[
        //       field.label
        //     ] = `${field.label} cannot be less than ${field.min}.`;
        //   }
        //   if (field.max !== undefined && value > field.max) {
        //     errors[
        //       field.label
        //     ] = `${field.label} cannot be greater than ${field.max}.`;
        //   }
        // }

        // if (field.type === "textarea" && field.value.length > field.max) {
        //   errors[
        //     field.label
        //   ] = `${field.label} cannot exceed ${field.max} characters.`;
        // }
        if(field.type == 'inputWithDropdown'){
          if(field.value && !field.currency){
            // errors[field.alias] = fieldErrorMessages[field.alias]
            errors[field.alias] = 'Currency is required.'
          }
        }
      });

      this.validateErrorMsg = errors;
      this.$store.commit("SET_ERROR", errors);
      return Object.keys(errors).length === 0;
    },
    ownershipClassificationOnchange(e) {
      this.itemsOwnershipClassificationValue = [...e.target.value];
    },
    shouldShowRedBorder(depositPercentage, currOwnershipDisable) {
      return (
        !currOwnershipDisable &&
        (depositPercentage === null || depositPercentage.trim() === "")
      );
    },
    filterEmptyColumns(column, resource) {
      const columnsToKeep = ["btn", "deposit"];
      const columnWithValues = column.filter((column) => {
        if (!columnsToKeep.includes(column.field)) {
          return resource.some((row) => {
            return row[column.field] != null;
          });
        }
      });
      return columnWithValues;
    },
    filterEmptyMineralRows(columns, resource) {
      let arr = [];

      for (let i = 0; i < resource.length - 4; i += 4) {
        let measuredRow = resource[i];
        let indicatedRow = resource[i + 1];
        let inferredRow = resource[i + 2];
        let totalRow = resource[i + 3];

        if (measuredRow && indicatedRow && inferredRow && totalRow) {
          columns.forEach((column) => {
            if (
              column.field !== "title" &&
              column.field !== "btn" &&
              column.field !== "deposit"
            ) {
              if (
                measuredRow[column.field] != null ||
                indicatedRow[column.field] != null ||
                inferredRow[column.field] != null ||
                totalRow[column.field] != null
              ) {
                if (
                  !arr.includes(
                    measuredRow,
                    indicatedRow,
                    inferredRow,
                    totalRow
                  )
                ) {
                  arr.push(measuredRow, indicatedRow, inferredRow, totalRow);
                }
              }
            }
          });
        }
      }
      arr.push(resource[resource.length - 4]);
      arr.push(resource[resource.length - 3]);
      arr.push(resource[resource.length - 2]);
      arr.push(resource[resource.length - 1]);
      arr.forEach((item, index) => {
        item.id = index + 1;
      });
      return arr;
    },
    filterEmptyOreRows(columns, resource) {
      let arr = [];

      for (let i = 0; i < resource.length - 3; i += 3) {
        let provenRow = resource[i];
        let probableRow = resource[i + 1];
        let totalRow = resource[i + 2];

        if (provenRow && probableRow && totalRow) {
          columns.forEach((column) => {
            if (
              column.field !== "title" &&
              column.field !== "btn" &&
              column.field !== "deposit"
            ) {
              if (
                provenRow[column.field] != null ||
                probableRow[column.field] != null ||
                totalRow[column.field] != null
              ) {
                if (!arr.includes(provenRow, probableRow, totalRow)) {
                  arr.push(provenRow, probableRow, totalRow);
                }
              }
            }
          });
        }
      }
      arr.push(resource[resource.length - 3]);
      arr.push(resource[resource.length - 2]);
      arr.push(resource[resource.length - 1]);
      arr.forEach((item, index) => {
        item.id = index + 1;
      });
      return arr;
    },
    createUpdate() {
      this.model.timezone = this.timezone;
      const errorModel = new error();
      if (
        this.model.deposit_type.name == "Single" &&
        this.defaultItemStage?.stage_name != "Exploration"
      ) {
        if (
          this.model.current_ownership &&
          this.model.current_ownership.includes("-") &&
          !this.localTableResource?.mineral_resource?.current_ownership
            ?.current_ownership?.current_ownership
        ) {
          if (
            !this.localTableResource?.ore_resource?.current_ownership
              ?.current_ownership?.current_ownership
          ) {
            errorModel.ore_current_ownership = "Current Ownership is required.";
          }
          errorModel.mineral_current_ownership =
            "Current Ownership is required.";
          this.$store.commit("SET_ERROR", errorModel);
          return;
        }
        if (
          this.model.current_ownership &&
          this.model.current_ownership.includes("-") &&
          !this.localTableResource?.ore_resource?.current_ownership
            ?.current_ownership?.current_ownership
        ) {
          errorModel.ore_current_ownership = "Current Ownership is required.";
          this.$store.commit("SET_ERROR", errorModel);
          return;
        }
      }
      if (
        this.model.deposit_type.name == "Multiple" &&
        this.defaultItemStage?.stage_name != "Exploration"
      ) {
        this.updateCreateClicked = true;
        for (
          let i = 0;
          i <
          this.model?.multiple_deposit_mineral_resource?.resource?.length - 4;
          i += 4
        ) {
          if (
            this.model.current_ownership &&
            this.model.current_ownership.includes("-") &&
            (this.model?.multiple_deposit_mineral_resource.resource[i]
              .deposit_percentage == null ||
              this.model?.multiple_deposit_mineral_resource.resource[i]
                .deposit_percentage == "")
          ) {
            errorModel.multiple_mineral_current_ownership =
              "Current Ownership is required.";
            for (
              let i = 0;
              i < this.model.multiple_deposit_ore_resource.resource.length - 3;
              i += 3
            ) {
              if (
                this.model.current_ownership &&
                this.model.current_ownership.includes("-") &&
                (this.model?.multiple_deposit_ore_resource.resource[i]
                  .deposit_percentage == null ||
                  this.model?.multiple_deposit_ore_resource.resource[i]
                    .deposit_percentage == "")
              ) {
                errorModel.multiple_ore_current_ownership =
                  "Current Ownership is required.";
                this.$store.commit("SET_ERROR", errorModel);
                return;
              }
            }
            this.$store.commit("SET_ERROR", errorModel);
            return;
          }
        }
        for (
          let i = 0;
          i < this.model?.multiple_deposit_ore_resource?.resource?.length - 3;
          i += 3
        ) {
          if (
            this.model.current_ownership &&
            this.model.current_ownership.includes("-") &&
            (this.model?.multiple_deposit_ore_resource?.resource[i]
              ?.deposit_percentage == null ||
              this.model?.multiple_deposit_ore_resource.resource[i]
                .deposit_percentage == "")
          ) {
            errorModel.multiple_ore_current_ownership =
              "Current Ownership is required.";
            this.$store.commit("SET_ERROR", errorModel);
            return;
          }
        }
      }
      for (
        let i = 0;
        i < this.model?.multiple_deposit_mineral_resource?.resource?.length - 4;
        i += 4
      ) {
        this.model.multiple_deposit_mineral_resource.resource[
          i + 1
        ].deposit_percentage =
          this.model?.multiple_deposit_mineral_resource?.resource[
            i
          ].deposit_percentage;
        this.model.multiple_deposit_mineral_resource.resource[i + 1].deposit =
          this.model?.multiple_deposit_mineral_resource?.resource[i].deposit;
        this.model.multiple_deposit_mineral_resource.resource[
          i + 2
        ].deposit_percentage =
          this.model?.multiple_deposit_mineral_resource?.resource[
            i
          ].deposit_percentage;
        this.model.multiple_deposit_mineral_resource.resource[i + 2].deposit =
          this.model?.multiple_deposit_mineral_resource?.resource[i].deposit;
        this.model.multiple_deposit_mineral_resource.resource[
          i + 3
        ].deposit_percentage =
          this.model?.multiple_deposit_mineral_resource?.resource[
            i
          ].deposit_percentage;
        this.model.multiple_deposit_mineral_resource.resource[i + 3].deposit =
          this.model?.multiple_deposit_mineral_resource?.resource[i].deposit;
      }

      for (
        let i = 0;
        i < this.model?.multiple_deposit_ore_resource?.resource?.length - 3;
        i += 3
      ) {
        this.model.multiple_deposit_ore_resource.resource[
          i + 1
        ].deposit_percentage =
          this.model?.multiple_deposit_ore_resource?.resource[
            i
          ].deposit_percentage;
        this.model.multiple_deposit_ore_resource.resource[i + 1].deposit =
          this.model?.multiple_deposit_ore_resource?.resource[i].deposit;
        this.model.multiple_deposit_ore_resource.resource[
          i + 2
        ].deposit_percentage =
          this.model?.multiple_deposit_ore_resource?.resource[
            i
          ].deposit_percentage;
        this.model.multiple_deposit_ore_resource.resource[i + 2].deposit =
          this.model?.multiple_deposit_ore_resource?.resource[i].deposit;
      }

      if (
        this.defaultItemStage?.stage_name != "Exploration" &&
        (this.defaultItemMainCommodities?.alias == "kaolin" ||
          this.defaultItemMainCommodities?.alias == "potash")
      ) {
        if (
          this.model.mineral_image_size == "" ||
          this.model.mineral_image_size == null
        ) {
          this.model.mineral_image_size = 100;
        }
        if (
          this.model.ore_image_size == "" ||
          this.model.ore_image_size == null
        ) {
          this.model.ore_image_size = 100;
        }
      }

      let mineral_resourceBind = {
        resource: this.localTableResource?.mineral_resource?.resource,
        cut_off_grade: this.localTableResource?.mineral_resource?.cut_off_grade,
        current_ownership:
          this.localTableResource?.mineral_resource?.current_ownership,
        mining_code: this.model?.mineral_resource?.mining_code,
        ...this.localTableResource?.mineral_resource,
      };
      let ore_resourceBind = {
        resource: this.localTableResource?.ore_resource?.resource,
        cut_off_grade: this.localTableResource?.ore_resource?.cut_off_grade,
        current_ownership:
          this.localTableResource?.ore_resource?.current_ownership,
        mining_code: this.model?.ore_resource?.mining_code,
        ...this.localTableResource?.ore_resource,
      };
      this.model.companyId = this.$route.params.id;
      this.model.stage = this.defaultItemStage?.stage_name
        ? this.defaultItemStage?.stage_name
        : "";
      this.model.status = this.defaultItemStatus?.status_name
        ? this.defaultItemStatus?.status_name
        : "";
      this.model.lithium_type = this.islithiumType
        ? this.defaultItemLithiumType
        : "";
      this.model.main_commodities =
        this.defaultItemMainCommodities?.length > 0
          ? this.defaultItemMainCommodities
          : this.defaultItemMainCommodities?.id == 0
            ? ""
            : this.defaultItemMainCommodities
              ? this.defaultItemMainCommodities
              : "";

      if (this.model?.main_commodities?.length === 0) {
        this.model.main_commodities = null;
      }

      this.model.country = this.defaultItemCountry
        ? this.defaultItemCountry?.id != 0
          ? this.defaultItemCountry
          : ""
        : "";
      this.model.is_hidden_mineral = this.areAllFieldsZero(
        this.localTableResource?.mineral_resource?.resource
      );
      this.model.is_hidden_ore = this.areAllFieldsZero(
        this.localTableResource?.ore_resource?.resource
      );
      this.model.mineral_resource =
        this.defaultItemStage?.stage_name != "Exploration"
          ? mineral_resourceBind
          : "";
      this.model.ore_resource =
        this.defaultItemStage?.stage_name != "Exploration"
          ? ore_resourceBind
          : "";
      this.model.state =
        this.itemStates.length > 0 && this.defaultItemStates?.id != 0
          ? this.defaultItemStates
          : "";
      this.model.additional_main_commodities = this.model
        .additional_main_commodities
        ? this.model.additional_main_commodities
        : "";
      this.model.ownership_classification = this
        .itemsOwnershipClassificationValue?.length
        ? this.itemsOwnershipClassificationValue
        : null;
      if (this.model.stage == "Please select..") {
        this.model.stage = "";
      }
      if (this.model.status == "Please select..") {
        this.model.status = "";
      }
      if(this.defaultItemStage?.stage_name == 'Scoping' || 
        this.defaultItemStage?.stage_name == 'Pre-Feasibility' || 
        this.defaultItemStage?.stage_name == 'Feasibility' || 
        this.defaultItemStage?.stage_name == 'Production' || 
        this.defaultItemStage?.stage_name == 'Restart' || 
        this.defaultItemStage?.stage_name == 'Construction') {
          if (!this.validate()) return
          this.updateCommonResourceValues()
          this.model.feasibility_field_resource = this.feasibility_field_resource;
          this.model.feasibility_common_field_resource = this.feasibility_common_field_resource;
        }else{
        this.model.feasibility_field_resource = null;
        this.model.feasibility_common_field_resource = null;
      }

      if (this.isVerify()) {
        return;
      } else {
        if (this.$route?.params?.projectId) {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE_PROJECT", true);
          this.$store.dispatch("updateProject", this.model);
        } else {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE_PROJECT", true);
          this.$store.dispatch("projectCreate", this.model);
        }
      }
    },
    updateCommonResourceValues() {
      let val = null
      this.feasibility_common_field_resource?.forEach((commonField, index) => {
        const preField = this.feasibility_field_resource?.find(f => f.alias === commonField.pre);
        const postField = this.feasibility_field_resource?.find(f => f.alias === commonField.post);
        if(commonField?.alias != 'common_pre_post_tax'){
          if (postField && postField.value) {
            commonField.value = postField.value;
            commonField.tax_type = 'Post Tax';
            if(postField?.currency){
              commonField.currency = postField?.currency
            } 
            if(index == 0) val = 'Post Tax';
          } else if (preField && preField.value) {
            commonField.value = preField.value;
            commonField.tax_type = 'Pre Tax';
            if(preField?.currency){
            commonField.currency = preField?.currency
            } 
            if(index == 0) val = 'Pre Tax';
          } else {
            commonField.value = null
            commonField.tax_type = null
          }
        }else{
          commonField.value = val
        }
      });

    },
    // oreResoureCutOffGrade(event, single, index) {
    //   if (event.target.value == "") {
    //     single[index] = null;
    //   } else single[index] = event.target.value;
    // },
    // mineralResoureCutOffGrade(event, single, index) {
    //   if (event.target.value == "") {
    //     single[index] = null;
    //   } else single[index] = event.target.value;
    // },
    // mineralResoureCurrentOwnership(event, single, index) {
    //   if (event.target.value == "") {
    //     single[index] = null;
    //   } else {
    //     const fitered = event.target.value.replace(/[^\d.-]/g, "");
    //     single[index] = fitered;
    //   }
    // },
    // oreResoureCurrentOwnership(event, single, index) {
    //   if (event.target.value == "") {
    //     single[index] = null;
    //   } else {
    //     const fitered = event.target.value.replace(/[^\d.-]/g, "");
    //     single[index] = fitered;
    //   }
    // },
    handleCutOffGrade(event, single, index) {
      single[index] = event.target.value === "" ? null : event.target.value;
    },
    handleCurrentOwnership(event, single, index) {
      single[index] = event.target.value === "" 
        ? null 
        : event.target.value.replace(/[^\d.-]/g, "");
    },
    back() {
      router.go(-1);
    },
    onStatusChange(event) {
      let { image_thumbnail_path, id, image_name } = event?.response?.response?.result?.image
      this.model.image_path = image_thumbnail_path;
      this.model.image_id = id;
      this.imageData = [{name: image_name}]
    },
    onRemove(event) {
      this.model.image_path = null;
      this.model.image_id = null;
    },
    onStatusEconomicStudyChange(itemModel, event) {
      let result = event?.response?.response?.result?.image;
      itemModel.value = null;
      itemModel.value = {
        image_id: result?.id,
        image_name: result?.image_name,
        image_path: result?.image_thumbnail_path,
      }
    },
    onInputDropdownChange(field){
    },
    onRemoveEconomicStudy(itemModel) {
      itemModel.value = null;
      for (let i  = 0; i < this.feasibility_field_resource?.length; i++){
        if(this.feasibility_field_resource[i]?.label == "Study Type (Full)"){
          this.feasibility_field_resource[i].pdf_details = null
          break
        }
      }
    },
    handleMineralImageStatus(event) {
      this.model.mineral_image_path =
        event?.response?.response?.result?.image?.image_thumbnail_path;
      this.model.mineral_image_id =
        event?.response?.response?.result?.image?.id;
    },
    onMineralImageRemove() {
      this.model.mineral_image_id = null;
      this.model.mineral_image_path = null;
    },
    handleOreImageStatus(event) {
      this.model.ore_image_path =
        event?.response?.response?.result?.image?.image_thumbnail_path;
      this.model.ore_image_id = event?.response?.response?.result?.image?.id;
    },
    onOreImageRemove() {
      this.model.ore_image_id = null;
      this.model.ore_image_path = null;
    },
    handleDepositType(e) {
      this.model.deposit_type = e.value;
    },
    handleMiningCode(e, field) {
      this.model[field].mining_code = e.value;
    },
    onDateChange(event) {
      this.model.image_date_published = event.target.value;
    },
    onLastUpdatedDateChange(event) {
      this.model.last_updated_date = event.target.value;
      this.isDateUpdatedManually = true;
    },
    toggleVisibleDateDialog() {
      this.visibleDateDialog = !this.visibleDateDialog;
    },
    handleDateYesDialog() {
      let date = dateFormatter(new Date(), "YYYY-MM-DD");
      this.model.last_updated_date = date;
      this.createUpdate();
      this.toggleVisibleDateDialog();
      this.isDateUpdatedManually = false;
    },
    handleDateNoDialog() {
      this.createUpdate();
      this.toggleVisibleDateDialog();
      this.isDateUpdatedManually = false;
    },
    toggleEditTenements() {
      this.$store.commit("SET_TENEMENTS_EDIT_MODAL_SHOW", true);
    },
    toggleCloseTenements() {
      this.$store.commit("SET_TENEMENTS_EDIT_MODAL_SHOW", false);
    },
    toggleEditPlacment() {
      this.isShowPlacementEditModal = true;
    },
    toggleClosePlacement() {
      this.isShowPlacementEditModal = false;
    },
    onSavePlacement(value) {
      this.model.latitude = value?.latitude;
      this.model.longitude = value?.longitude;
      this.toggleClosePlacement();
    },
  },
};
</script>
