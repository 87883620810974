<template src="./editTenementsModal.html"></template>
<style lang="scss">
@import "./editTenementsModal.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";

import { Checkbox } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { ListView, ListViewHeader } from "@progress/kendo-vue-listview";
import { filterBy } from "@progress/kendo-data-query";
import {
  getTenementListService,
  getStateListTenementsListService,
  updateTenementService,
  getNewProjectTenementListService,
  getSelectedProjectTenementListService,
} from "@/services/tenement.service.ts";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    dropdownlist: DropDownList,
    "k-checkbox": Checkbox,

    listView: ListView,
    listViewHeader: ListViewHeader,
  },
  props: {
    isClub: {
      type: String,
    },
    selected_tenements: {
      type: Object,
      default: null,
    },
    projectState: {
      type: Object,
      default: {},
    },
  },
  computed: {},
  data: function () {
    return {
      isLoading: false,
      visibleDialog: true,
      message: "",
      pagination: {
        total: 0,
        offset: 0,
        limit: 50,
      },
      availableItems: [],
      selectedItems: [],
      stateList: [],
      stateListTemp: [],
      checkedItems: [],
      state: null,
      search: "",
    };
  },
  mounted() {
    if (this.selected_tenements) {
      this.onSelectedCreate(this.selected_tenements);
    } else {
      this.selectedListApi({
        projectId: this.$route?.params?.projectId,
      });
    }
    this.getStateListTenementsList(this.$route.params.projectId || null);

    this.getTenementList({
      project_uuid: this.$route?.params?.projectId,
      limit: this.pagination.limit,
      offset: this.pagination.offset,
    });
  },
  methods: {
    onSelectedCreate(list) {
      this.selectedItems = list;
    },
    filterStateList(event) {
      console.log("stateListTemp:", this.stateListTemp);
      console.log("stateList:", this.stateList);
      const newData =
        event.filter.value.length >= 3
          ? this.filterStateData(event.filter)
          : this.stateListTemp?.slice();
      this.stateList = newData;
    },
    filterStateData(filter) {
      const data = this.stateListTemp?.slice();
      console.log("Filtered Data:", data);
      return filterBy(data, filter);
    },
    handleState(event) {
      this.state = event.value;
      this.availableItems = [];
      this.pagination.offset = 0;
      this.getTenementList({
        project_uuid: this.$route?.params?.projectId,
        state: this.state?.state,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      });
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
        this.availableItems = [];
        this.pagination.offset = 0;
        this.getTenementList({
          project_uuid: this.$route?.params?.projectId,
          state: this.state?.state,
          search: this.search,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
      }, 600);
    },
    getStateListTenementsList(payload) {
      this.isLoading = true;
      getStateListTenementsListService(payload)
        .then((response) => {
          this.isLoading = false;
          const result = response?.data?.result;
          if (this.$route.name != "newProject") {
            this.state = result?.selected_state;
          } else {
            this.state = result.states?.find((item) => {
              return item.state == this.projectState?.state_name;
            });
          }
          this.stateList = result.states;
          this.stateListTemp = this.stateList;
          if (this.state?.state) {
            this.getTenementList({
              project_uuid: this.$route?.params?.projectId,
              state: this.state?.state,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    async selectedListApi(payload) {
      this.isLoading = true;
      let service = getSelectedProjectTenementListService;
      try {
        const response = await service(payload);
        this.isLoading = false;
        const list = response?.data?.result?.selected_tenements;
        this.selectedItems = list;
      } catch (error) {
        this.isLoading = false;
        console.error("Error fetching tenement list:", error);
      }
    },
    async getTenementList(payload) {
      if (payload.state === "State or Territory") {
        delete payload.state;
      }
      this.isLoading = true;
      let service =
        this.$route.name == "newProject"
          ? getNewProjectTenementListService
          : getTenementListService;
      try {
        const response = await service(payload);
        this.isLoading = false;

        const list = response?.data?.result?.tenements;
        console.log(this.selectedItems, "select");
        if (list?.length) {
          list.forEach((item) => {
            const selectedItem = this.selectedItems.find(
              (selected) => selected.uuid === item.uuid
            );
            if (selectedItem) {
              item.is_selected = true;
            } else {
              item.is_selected = false;
            }
          });
          this.availableItems.push(...list);

          this.pagination.total = response?.data?.result?.total;
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Error fetching tenement list:", error);
      }
    },
    handleRemove(item) {
      const index = this.selectedItems.findIndex((i) => i.uuid === item.uuid);
      if (index > -1) {
        let availableDataIndex = this.availableItems.findIndex(
          (i) => i.uuid === item.uuid
        );
        if (availableDataIndex > -1) {
          this.availableItems[availableDataIndex].is_selected = false;
        }
        this.selectedItems.splice(index, 1);
        const checkedIndex = this.checkedItems.indexOf(item.uuid);
        if (checkedIndex > -1) {
          this.checkedItems.splice(checkedIndex, 1);
        }
      }
    },
    moveToSelected() {
      this.checkedItems.forEach((uuid) => {
        const item = this.availableItems.find((i) => i.uuid === uuid);
        if (item) {
          this.selectedItems.push(item);
          this.availableItems = this.availableItems.filter(
            (i) => i.uuid !== uuid
          );
        }
      });
      this.checkedItems = [];
    },
    moveToAvailable() {
      this.selectedItems.forEach((item) => {
        if (this.checkedItems.includes(item.uuid)) {
          this.availableItems.push(item);
        }
      });
      this.selectedItems = this.selectedItems.filter(
        (item) => !this.checkedItems.includes(item.uuid)
      );
      this.checkedItems = [];
    },
    cancelModal() {
      this.$store.commit("SET_TENEMENTS_EDIT_MODAL_SHOW", false);
    },
    scrollHandler(e) {
      if (this.pagination.total < this.pagination.offset) return;
      if (
        e.target.scrollTop + 50 >=
        e.target.scrollHeight - e.target.clientHeight
      ) {
        this.pagination.offset += 50;

        this.getTenementList({
          project_uuid: this.$route?.params?.projectId,
          state: this.state?.state,
          search: this.search,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
      }
    },
    handleCheck(item, list) {
      if (list === "available") {
        const index = this.availableItems.findIndex(
          (i) => i.uuid === item.uuid
        );
        if (index > -1) {
          if (item.is_selected) {
            const alreadySelected = this.selectedItems.some(
              (i) => i.uuid === item.uuid
            );
            if (!alreadySelected) {
              this.selectedItems.push(item);
            }
          } else {
            this.handleRemove(item);
          }
        }
      }
    },
    onclickSave() {
      let uuids = this.selectedItems.map((item) => item.uuid);
      if (this.$route?.params?.projectId) {
        this.updateTenements({
          tenement_uuids: uuids,
          project_uuid: this.$route?.params?.projectId,
        });
      } else {
        this.$emit("onCreateTenements", this.selectedItems);
        this.modalHandler();
      }
    },
    updateTenements(payload) {
      this.isLoading = true;
      updateTenementService(payload)
        .then((response) => {
          this.isLoading = false;
          this.$store.dispatch("toastifyNotification", {
            text:
              response?.data?.result?.message ||
              "Tenement(s) updated successfully.",
            duration: 5000,
            className: "success",
          });
          this.modalHandler();
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", false);
          this.isLoading = false;
          this.$store.dispatch("toastifyNotification", {
            text: error?.data?.result?.message || "Something Went Wrong.",
            duration: 5000,
            className: "error",
          });
          console.error("Error fetching tenement list:", error);
        });
    },
    modalHandler(data) {
      this.$store.commit("SET_TENEMENTS_EDIT_MODAL_SHOW", false);
    },
  },
};
</script>
