import { GET, POST } from "@/service";
import router from "@/router";
import { configuration } from "@/configurationProvider";
import axios from "axios";
import CryptoJS from 'crypto-js';

export type State = {
  isLoggedIn: boolean;
  errorMsg: any;
  userInfo: any;
  isTrial: any;
  isRefreshing: boolean;
  isReloadNeeded: boolean;
};

const state: State = {
  isLoggedIn: false,
  errorMsg: "",
  userInfo: {},
  isTrial: "",
  isRefreshing: false,
  isReloadNeeded: true,
};

const auth = {
  state,
  getters: {
    isAuthenticated: (state: any) => !!state.isLoggedIn,
  },
  mutations: {
    GET_TOKEN(state: { isLoggedIn: boolean }, data: any) {
      state.isLoggedIn = data;
    },
    INVALID_TOKEN() {
      state.isLoggedIn = false;
    },
    SET_ERROR_MESSAGE(state: { errorMsg: [] }, data: any) {
      state.errorMsg = data;
    },
    SET_ISLOADING_AUTH(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
    SET_USER_INFO(state: { userInfo: boolean }, data: any) {
      state.userInfo = data;
    },
    SET_IS_TRIAL(state: { isTrial: boolean }, data: any) {
      state.isTrial = data;
    },
    SET_IS_REFRESHING(state: { isRefreshing: boolean }, data: any) {
      state.isRefreshing = data;
    },
    SET_IS_RELOADNEEDED(state: { isReloadNeeded: boolean }, data: any) {
      state.isReloadNeeded = data;
    },
  },
  actions: {
    getTokenUser({ commit, dispatch }: any, payload: any) {
      POST(`api/v1/portal-login`, payload)
        .then((response) => {
          commit("SET_ISLOADING_AUTH", false);
          if (!response.data.result.token) {
            commit("INVALID_TOKEN", response.data.result);
          } else {
            if (
              sessionStorage.getItem("reDirectToEmail") &&
              response.data.result.user.email == sessionStorage.getItem("email")
            ) {
              POST("api/v1/user/upgrade-status");
              sessionStorage.removeItem("reDirectToEmail");
              sessionStorage.removeItem("email");
              if (
                window.location.href.includes("https://app.miningbull.com.au/")
              ) {
                window.location.href = `https://miningbull.com.au/subscribe/?customAuthToken=${response.data.result.user.validation_uuid}`;
              } else {
                window.location.href = `https://mnbstage.mywebcommander.com/subscribe/?customAuthToken=${response.data.result.user.validation_uuid}`;
              }
            } else {
              localStorage.setItem(
                "mnb_session",
                response.data.result.token.access_token
              );
              localStorage.setItem(
                "mnb_refreshToken",
                response.data.result.token.refresh_token
              );
              // localStorage.setItem("mnb_userData", JSON.stringify(response.data.result.user));
              commit("SET_USER_INFO", response.data.result.user);
              commit("GET_TOKEN", true);
              dispatch("getUserInformation");
              router.push("/companies");
            }
          }
        })
        .catch((error) => {
          commit("SET_ISLOADING_AUTH", false);
          commit("SET_ERROR_MESSAGE", error.response?.data?.result?.message);
          commit("SET_IS_TRIAL", error?.response?.data?.result?.validation_uuid);
        });
    },
    getTokenForAdmin({ commit, dispatch }: any, payload: any) {
      POST(`api/v1/login`, payload)
        .then((response) => {
          commit("SET_ISLOADING_AUTH", false);
          if (!response.data.result.token) {
            commit("INVALID_TOKEN", response.data.result);
          } else {
            localStorage.setItem(
              "mnb_session",
              response.data.result.token.access_token
            );
            localStorage.setItem(
              "mnb_refreshToken",
              response.data.result.token.refresh_token
            );
            // localStorage.setItem("mnb_userData", JSON.stringify(response.data.result.user));
            commit("SET_USER_INFO", response.data.result.user);
            if (response?.data?.result?.user?.is_admin == 1) {
              localStorage.setItem("showNotification", JSON.stringify(1));
            }
            commit("GET_TOKEN", true);
            dispatch("getUserInformation");
            router.push("/companies");
          }
        })
        .catch((error) => {
          commit("SET_ISLOADING_AUTH", false);
          commit("SET_ERROR_MESSAGE", error.response.data?.result?.message);
        });
    },
    signOut({ commit, dispatch }: any, payload: any) {
      GET(`api/v1/logout`)
        .then((resnponse) => {
          var payload = null;
          const userInfo = state.userInfo;
          if (userInfo?.is_admin !== 1) {
            payload = null;
          } else {
            payload = "/admin";
          }
          localStorage.removeItem("mnb_session");
          localStorage.removeItem("mnb_refreshToken");
          localStorage.removeItem("showNotification");
          sessionStorage.clear();
          dispatch("checkToken", payload);
        })
        .catch((error) => {
          commit("SET_ERROR_MESSAGE", error?.response?.data?.result?.message);
        });
    },
    getTokenUsingRefreshToken({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        commit("SET_IS_REFRESHING", true);
        // dispatch("toastifyNotification", {
        //     text: "Verifying your refresh token! Please allow a moment.",
        //     duration: 5000,
        //     className: "warning",
        // });
        let payload = {
          refresh_token: localStorage.getItem("mnb_refreshToken"),
        };
        // axios({
        //     url: `${configuration.apiBaseUrl}/api/v1/refresh_token`,
        //     method: "POST",
        //     data: payload,
        // })
        fetch(`${configuration.apiBaseUrl}/api/v1/refresh_token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("mnb_session")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-XSRF-TOKEN",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            // console.log(res)
            if (!res.ok && res.status === 400) {
              commit("SET_IS_REFRESHING", false);

              commit("GET_TOKEN", false);
              localStorage.clear();
              localStorage.removeItem("mnb_session");
              localStorage.removeItem("mnb_refreshToken");
              localStorage.removeItem("showNotification");
              sessionStorage.removeItem("sessionIdsAndModel");
              router.push("/");
              return reject(new Error("Failed to refresh token"));
            }
            return res.json();
          })
          .then((response) => {
            if (response?.result?.token?.access_token) {
              localStorage.setItem(
                "mnb_session",
                response?.result?.token?.access_token
              );
              localStorage.setItem(
                "mnb_refreshToken",
                response?.result?.token?.refresh_token
              );
              commit("GET_TOKEN", true);
              // if (state.isReloadNeeded) {
              //   commit("SET_IS_RELOADNEEDED", true);
              //   router.go(0);
              // } else {
              //   commit("SET_IS_RELOADNEEDED", true);
              // }
              //
              resolve(response.result.token.access_token);
            }
            commit("SET_IS_REFRESHING", false);
            reject(new Error("Token refresh failed"));
          })
          .catch((error) => {
            commit("SET_IS_REFRESHING", false);
            commit("GET_TOKEN", false);
            localStorage.removeItem("mnb_session");
            localStorage.removeItem("mnb_refreshToken");
            localStorage.removeItem("showNotification");
            sessionStorage.removeItem("sessionIdsAndModel");
            router.push("/");
            reject(error);
          });
      });
    },
    checkToken({ commit }: any, payload: any) {
      let token = localStorage.getItem("mnb_session");
      if (token) {
        commit("GET_TOKEN", true);
      } else {
        commit("GET_TOKEN", false);

        if (window.location.pathname == "/admin" || payload == "/admin") {
          router.push("/admin");
        } else router.push("/");
      }
    },
    getUserInformation({ commit, dispatch }: any, payload: any) {
      GET("api/v1/get/user")
        .then((res) => {

          // Dummy role
          let user = res.data.result.data
          // user.is_admin = 0
          // user.role = {
          //   id: 3,
          //   title: "trader_user",
          //   alias: "trader_user",
          //   created_at: "2023-02-15T18:02:24.000000Z",
          //   updated_at: "2023-02-15T18:02:27.000000Z"
          // }
          commit("SET_USER_INFO", user);
          

          // commit("SET_USER_INFO", res.data.result.data);
          commit("GET_TOKEN", true);
          if (
            window.location.pathname !== "/admin" &&
            window.location.pathname !== "/"
          ) {
            if (res?.data?.result?.data?.is_admin == 1) {
              dispatch("updateNotification");
            }
          }
        })
        .catch((err) => {
          commit("SET_ISLOADING_AUTH", false);
          commit("SET_ERROR_MESSAGE", err?.response?.data?.result?.message);
        });
    },
  },
};
export default auth;
