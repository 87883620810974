<Popup :anchor="anchorTag" :show="isShowFilter" :popup-class="'filter-popup'" :anchor-align="anchorAlignProperty"
  :popup-align="popupAlignProperty">
  <AppGlobalLoader :loaderColor="'solid'" :isFullpage="true" v-if="isLoading" />
  <div class="filter-box">
    <div class="filter-item-wrap pt-0 width-16-p-16">
      <k-input ref="projectSearch" :placeholder="'Search'" :icon-name="'search'" :class="'mb-0'" v-model="searchQuery"/>
      <div v-if="!isLoading" class="filter-checkbox-wrap px-1" v-for="item,index in filteredList">
        <div class="filter-checkbox">
          <k-checkbox :theme-color="'primary'" :label="item.title" :value="item.is_checked"
          @change="handleChange($event,item)" />
        </div>
      </div>
    </div>
    
    <div class="filter-footer f-wrap px-1 pb-0">
      <span class="primary-color text-decoration-underline cursor-pointer font-15" @click="clearList">Clear All</span>
      <k-button @click="onCancelClick" :size="'large'" :icon="'reset'" :class="'w-auto'"></k-button>
      <!-- <k-button :theme-color="'primary'" @click="applyEmit">Apply</k-button> -->
    </div>
  </div>
</Popup>