
    <div class="co-row d-flex justify-content-between align-items-stretch">
        <div class="co-col p-1">
            <k-input :class="'mb-0 w-100'" ref="OptionCodeInput" placeholder="Enter Option Code" type="text" v-model="option_code" @input="inputHandler" aria-required="true"></k-input>    
            <span class="error-msg position-relative w-100 d-block">{{errormsg[index]?.option_code? 'Please provide option code':''}}</span>
        </div>
        <!-- <div class="co-col p-1">
            <numerictextbox :class="'mb-0 w-100'" ref="QuantityInput" placeholder="Enter quantity" v-model="quantity" @input="inputHandler" aria-required="true"></numerictextbox>
            <span class="error-msg position-relative w-100 d-block">{{errormsg[index]?.quantity? 'Please provide quantity':''}}</span>
        </div> -->
        <div class="co-col p-1">
            <numerictextbox :class="'mb-0 w-100'" ref="ExercisePriceInput" placeholder="Enter Exercise Price" v-model="exercise_price" @input="inputHandler" aria-required="true"></numerictextbox>
            <span class="error-msg position-relative w-100 d-block">{{errormsg[index]?.exercise_price? 'Please provide exercise price.':''}}</span>
        </div>
        <div class="co-col p-1">
            <datePicker :format="'dd-MM-yyyy'" :class="'w-100 h-38 mb-0'" :style="{  }" :value="expiry_date?new Date(expiry_date): null" @change="onDateChange($event)" placeholder="DD-MM-YYYY"></datePicker>
        </div>
        <div class="co-col p-1 w-48 text-center min-w-48 max-w-48">
           <kbutton  @click="QuantityCloseHandler(index)" :class="''" :fill-mode="'clear'" :icon="'k-icon k-i-close-outline'"></kbutton>
        </div>
    </div>
