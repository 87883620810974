<modal :title="'Delete Company'" :handler="modalVisible" :cssClass="'c-modal-small-2'" @modalHandler="modalHandler">
  <template v-slot:content>
    <div>
      <p class="font-16 m-0 py-5 mb-3 text-center">
        Are you sure you want to delete this Company ?
      </p>
    </div>
  </template>
  <template v-slot:footer>
    <kbutton @click="cancelModal">Cancel</kbutton>
    <kbutton :theme-color="'primary'" @click="deleteClub">Delete</kbutton>
  </template>
</modal>