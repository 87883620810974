<template src="./AppInputWithDropdown.html"></template>

<style lang="scss">
// @import "./AppInputWithDropdown.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Button } from "@progress/kendo-vue-buttons";


export default {
  name: "AppInputWithDropdown",
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    "k-input": Input,
  },
  data() {
    return {
        selectedCurrency: this.field?.currency || null,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    }
  },
  watch: {
    selectedCurrency(newValue) {
      this.field.currency = newValue;
      this.$emit('inputDropdownChange', this.field)
    }
  },
  computed: {
    error() {
      return this.$store.state.newProject.error;
    },
  },
  mounted() {},
  methods: {
    handleInput(e){
        this.field.value == e.target.value
        this.$emit('inputDropdownChange', this.field)
    }
  }
};
</script>
