<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 1H1V15H4V1Z" fill="#212529" />
    <path d="M6 7V9H10V12L15 8L10 4V7H6Z" fill="#212529" />
  </svg>
</template>

<script>
export default {
  name: "iconLogout",
};
</script>
