<template>
  <AppGoogleMaps :polygonOptionProps="rectangleOptions" :placemarksMarkerList="placemarksMarkerList"
    @onSelectPolygon="onSelectPolygon" @onZoomEmitFunc="onZoomEmitFunc" @onOutsideClickEmitFunc="onOutsideClickEmitFunc"
    :isLoading="isLoading">
  </AppGoogleMaps>
</template>

<script>
import AppGoogleMaps from "@/components/common/AppGoogleMaps/AppGoogleMaps.vue";
import {
  getPlaceMarksWithinDataService,
  getTenementWithinPolygonDataService,
} from "@/services/tenement.service.ts";

export default {
  components: {
    AppGoogleMaps,
  },
  data() {
    return {
      loadedIds: [],
      rectangleOptions: [],
      placemarksMarkerList: [],
      isLoading: false,
    };
  },
  computed: {},
  mounted() { },
  methods: {
    getTenementWithinPolygonData(payload) {
      this.isLoading = true;
      getTenementWithinPolygonDataService(payload)
        .then((response) => {
          const newOptions = response?.data?.data || [];
          this.rectangleOptions = [...newOptions];
          this.isLoading = false;
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", false);
          this.isLoading = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    getPlaceMarksWithinData(payload) {
      this.isLoading = true;
      getPlaceMarksWithinDataService(payload)
        .then((response) => {
          const newOptions = response?.data?.markers || [];
          this.placemarksMarkerList = [...newOptions];
          this.isLoading = false;
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", false);
          this.isLoading = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    onZoomEmitFunc(payload) {
      let payloadZoom = {
        ...payload,
        tenement_uuids: this.loadedIds,
      };
      if (!this.isLoading && payload.zoom >= 8) {
        this.getTenementWithinPolygonData(payloadZoom);
        this.getPlaceMarksWithinData(payloadZoom);
      }
    },
    onSelectPolygon(payload) {
      const newUuids = payload.map((option) => option.tenement_uuids);
      this.loadedIds = [];
    },
    onOutsideClickEmitFunc(payload) {
      this.onZoomEmitFunc(payload);
    },
  },
};
</script>

<style scoped></style>
