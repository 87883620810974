<template>
  <li class="k-item search-sub-item" @click="handleClick">
    <span>{{ dataItem?.name }}</span>
    <span v-if="dataItem?.previous_name" class="sub-item">({{ dataItem?.previous_name }})</span>
  </li>
</template>
<script>
export default {
  emits: {
    click: null,
  },
  props: {
    id: String,
    index: Number,
    dataItem: [Object, String, Number],
    textField: String,
    focused: Boolean,
    selected: Boolean,
  },
  methods: {
    handleClick: function (e) {
      this.$emit("click", e);
    },
  },
};
</script>
