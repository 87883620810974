<template src="./companyOverview.html"></template>
<style lang="scss">
@import "./companyOverview.scss";
</style>

<script>
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import router from "@/router";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import { configuration } from "@/configurationProvider";
import { dateFormatter } from "@/helpers/utility";
import deleteCompanyModal from "./deleteCompanyModal/deleteCompanyModal.vue";
import deleteProject from "./deleteProject/deleteProject.vue";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar.vue";
import {
  getCompanyOverView,
  getProjectListCompanyOverView,
  updateProjectRankCompanyOverView,
} from "@/services/company.service";
import { format, formatDate } from "@progress/kendo-intl";
import { notificationHandler, formatTwoOrThreeDecimal } from "@/helpers/utility";
import { getUserData } from "@/services/dashboard.service";
import { Tooltip } from '@progress/kendo-vue-tooltip';

export default {
  name: "companyOverview",
  components: {
    "k-input": Input,
    kbutton: Button,
    checkbox: Checkbox,
    Grid: Grid,
    toolbar: GridToolbar,
    DropDownButton,
    AppGlobalLoader,
    deleteCompanyModal,
    AppSnackBar,
    deleteProject,
    numerictextbox: NumericTextBox,
    Tooltip,
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    columns() {
      if (this.loggedInUserData?.role?.alias == 'admin') {
        return this.projectTable;
      } else {
        return this.projectTable.filter(
          (status) =>
            status.cell !== "actionCell" && status.cell !== "rankingCell" && status.field !== "ultimate_ownership" && status.field !== "ownership_note"
        );
      }
    },
    columnsCompanyOption() {
      return [
        {
          field: "option_code",
          title: "Option Code",
        },
        {
          field: "company_option_price",
          title: "Price",
          cell: "price_cell",
        },
        {
          field: "exercise_price",
          title: "Exercise Price",
        },
        {
          field: "total_cost",
          title: "Total Cost",
        },
        {
          field: "premium_percentage",
          title: "Premium %",
          cell: "premium_cell",
        },
        {
          field: "expire_date",
          title: "Expiry date",
          cell: "expire_cell",
        },
      ];
    },
    columnsCompanyPreviousNames() {
      return [
        {
          field: "previous_asx_code",
          title: "Previous ASX Code",
        },
        {
          field: "previous_company_name",
          title: "Previous Company Name",
        },
        {
          field: "previous_company_date_of_change",
          title: "Date of Change",
          cell: "expire_cell",
        },
      ];
    },
    isLoadingCreateUpdate() {
      return this.$store.state.companyCreateEdit.isLoadingCreateUpdate;
    },
    notificationProject() {
      return this.$store.state.newProject.isCreateUpdateProjectNotify;
    },
    notification() {
      return this.$store.state.companyCreateEdit.isCreateUpdateNotify;
    },
    isLoadingCreateProjectUpdate() {
      return this.$store.state.newProject.tabisLoadingCreateUpdate;
    },
    tabisLoadingDeleteValue() {
      return this.$store.state.newProject.tabisLoadingDelete;
    },
    modifiedSector() {
      const isMarketMapPresent = this.overViewData?.company?.market_map?.alias;

      if (!isMarketMapPresent) {
        return this.overViewData?.company?.sector;
      }
      const modifiedSector = [...this.overViewData?.company?.sector];

      const matchedIndex = modifiedSector.findIndex(
        sector => sector.alias === this.overViewData?.company?.market_map?.alias
      );

      if (matchedIndex !== -1 && matchedIndex !== 0) {
        const matchedObject = modifiedSector.splice(matchedIndex, 1)[0];
        modifiedSector.unshift(matchedObject);
      } else if (matchedIndex === -1) {
        modifiedSector.unshift(this.overViewData?.company?.market_map);
      }

      return modifiedSector;
    },
    otherSectors() {
      let other_sector = this.overViewData?.sectors?.other_sectors?.filter((item) => {
        return item.alias != this.overViewData?.sectors?.primary_sector?.alias
      })
      return other_sector
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.$router.go(0);
      }
    },
  },
  data: function () {
    return {
      overViewData: [],
      model: {
        email: "",
        password: "",
      },
      tempPhotoPath: "",
      deleteProjectId: 0,
      passStatuses: [
        { id: 1, text: "Edit" },
        { id: 2, text: "Delete" },
      ],
      companyStatus: [{ id: 1, text: "Delete" }],
      itemsSp: [{}],

      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      isAsxAdded: "",
      rankingLocalData: 0,
      inEditDataId: 0,
      ProjectTableData: [],
      isAdmin: false,
      isChartShowMount: false,
      projectTable: [
        {
          field: "project_name",
          title: "Name",
          cell: "nameCell",
          editable: false,
        },
        {
          field: "status",
          title: "Status",
          cell: "cell_status",
          editable: false,
        },
        {
          field: "current_ownership",
          title: "Current Ownership",
          cell: "cell_current_ownership",
          editable: false,
        },
        {
          field: "ultimate_ownership",
          title: "Future Ownership",
          editable: false,
        },
        {
          field: "main_commodities",
          title: "Primary Commodities",
          cell: "mainCommodities",
          editable: false,
        },
        {
          field: "other_commodities",
          title: "Other Commodities",
          cell: "cell_other_commodities",
          editable: false,
        },
        {
          field: "country",
          title: "Country",
          cell: "countryCell",
          editable: false,
        },
        {
          field: "state",
          title: "State/Region",
          cell: "stateCell",
          editable: false,
        },
        {
          field: "stage",
          title: "Stage",
          cell: "cell_stage",
          editable: false,
        },
        {
          field: "ownership_note",
          title: "Ownership Note",
          editable: false,
        },
        {
          field: "rank",
          title: "Project Rank",
          cell: "rankingCell",
        },
        {
          field: "action",
          title: "Action",
          cell: "actionCell",
          editable: false,
        },
      ],
      isLive: false,
      tooltipOpen: false,
    };
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.isChartShowMount = true;
    }, 2000);
    if (window.location.href.includes("https://app.miningbull.com.au/")) {
      this.isLive = true;
    } else {
      this.isLive = false;
    }
    if (this.$route.params.id) {
      this.$store.commit("SET_ISLOADING_CREATEUPDATE", true);
      let payload = {
        id: this.$route.params.id,
      };
      getCompanyOverView(payload)
        .then((response) => {
          this.overViewData = response?.data?.result.data;
          this.isAsxAdded = "ASX:" + this.overViewData?.company?.code;
          if (this.overViewData?.company?.image_path)
            this.tempPhotoPath =
              configuration.apiBaseUrl +
              "/" +
              this.overViewData?.company?.image_path;
          this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
        })
        .catch((error) => {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
        });

      this.projectDataListApiCall(payload);
    }
  },
  beforeUnmount() {
    this.isChartShowMount = false;
  },
  methods: {
    rowClick: function (e) {
      if (this.loggedInUserData?.role?.alias != 'admin') return;
      const index = this.ProjectTableData.findIndex(
        (d) => d.id === e.dataItem.id
      );
      this.ProjectTableData.forEach((d, i) => {
        if (i !== index) {
          d.inEdit = false;
        }
      });
      // if (e.dataItem.stage != 'Exploration' && e.dataItem.main_commodities != '-')
      e.dataItem.inEdit = true;
      this.rankingLocalData = e.dataItem.rank;
    },
    applyRanking(e) {
      // if (this.rankingLocalData == 0) {
      //   let notification = notificationHandler(
      //     "Project Rank can't be 0.",
      //     true,
      //     "warning"
      //   );
      //   this.$store.commit(
      //     "SET_NOTIFICATION_CREATE_UPDATE_PROJECT",
      //     notification
      //   );
      //   setTimeout(() => {
      //     let notification = notificationHandler(
      //       "Project Rank can't be 0.",
      //       false,
      //       "warning"
      //     );
      //     this.$store.commit(
      //       "SET_NOTIFICATION_CREATE_UPDATE_PROJECT",
      //       notification
      //     );
      //   }, 4000);
      //   return;
      // }
      if (this.rankingLocalData === 0) {
        const notificationMessage = "Project Rank can't be 0.";
        const notificationType = "warning";

        this.$store.commit(
          "SET_NOTIFICATION_CREATE_UPDATE_PROJECT",
          notificationHandler(notificationMessage, true, notificationType)
        );

        setTimeout(() => {
          this.$store.commit(
            "SET_NOTIFICATION_CREATE_UPDATE_PROJECT",
            notificationHandler(notificationMessage, false, notificationType)
          );
        }, 4000);

        return;
      }
      e.dataItem.rank = this.rankingLocalData;
      e.dataItem.inEdit = false;
      if (e.dataItem.project_uuid && e.dataItem.rank) {
        let payload = {
          id: e.dataItem.project_uuid,
          rank: e.dataItem.rank,
        };
        updateProjectRankCompanyOverView(payload)
          .then((response) => {
            const payloadProjectTableReload = {
              id: this.$route.params.id,
            };
            this.projectDataListApiCall(payloadProjectTableReload);
          })
          .catch((error) => {
            this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
          });
      }
    },
    projectDataListApiCall(payload) {
      getProjectListCompanyOverView(payload)
        .then((response) => {
          this.ProjectTableData = response.data.result.project;
        })
        .catch((error) => {
          this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
        });
    },
    goToClubDashboard() {
      this.$router.push("/home");
    },
    deleteTemplate(e) {
      if (e.item.id == 1) {
        this.$store.commit("SET_COMPANY_DELETE_MODAL_SHOW_HIDE", true);
      }
    },
    close(e) {
      const index = this.ProjectTableData.findIndex(
        (d) => d.id === e.dataItem.id
      );
      e.dataItem.rank = this.ProjectTableData[index].rank;
      e.dataItem.inEdit = false;
    },
    getTimeIn12HourClock(timeStr) {
      let [hour, minute] = timeStr.split(":").map(Number);
      let dateObj = new Date();
      dateObj.setHours(hour);
      dateObj.setMinutes(minute);
      let hour12 = dateObj.getHours() % 12 || 12;
      let minuteStr = dateObj.getMinutes().toString().padStart(2, "0");
      let amPm = dateObj.getHours() >= 12 ? "pm" : "am";
      return `${hour12}:${minuteStr}${amPm}`;
    },
    deleteProject(event, props) {
      if (event.item.id == 1) {
        this.$router.push({
          name: "editProject",
          params: {
            id: this.$route.params.id,
            projectId: props.dataItem.project_uuid,
          },
        });
      }
      if (event.item.id == 2) {
        this.deleteProjectId = props.dataItem.project_uuid;
        this.$store.commit("SET_PROJECT_DELETE_MODAL_SHOW_HIDE", true);
      }
    },
    newProject() {
      this.$router.push({
        name: "newProject",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    projectView(event, props) {
      if(!['trader_user'].includes(this.loggedInUserData?.role?.alias)) {
        this.$router.push({
          name: "projectView",
          params: {
            id: this.$route.params.id,
            projectId: props.dataItem.project_uuid,
          },
        });
      }
    },
    iconUsingName(e) {
      if (e) {
        let names = e.split(" ");
        if (names.length >= 2) {
          let firstName = names[0];
          let lastName = names[1];
          return firstName.charAt(0) + lastName.charAt(0);
        } else {
          return e.charAt(0);
        }
      }
    },
    goToEditCompany() {
      this.$router.push({
        name: "editCompany",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    cellBackground: function (dataItem) {
      const examplePrice = dataItem.UnitPrice < 15;

      return {
        "background-color": examplePrice
          ? "rgb(243, 23, 0, 0.32)"
          : "rgb(55, 180, 0,0.32)",
      };
    },
    gotoPrev() {
      router.go(-1);
    },

    formatDate(date, format = true) {
      if (date == null || date == "" || date == "-") return "";
      else if (!format) {
        return dateFormatter(date, format);
      } else
        return format == "DD/MM"
          ? dateFormatter(date, format)
          : "at " + dateFormatter(date, format);
    },
    formatNumber(number) {
      if (number >= 1000000000) {
        return (
          "$" +
          (number / 1000000000)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") +
          "B"
        );
      } else if (number >= 1000000) {
        return (
          "$" +
          (number / 1000000)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") +
          "M"
        );
      } else if (number == "-") {
        return number.toString();
      } else {
        return (
          "$" +
          number
            ?.toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        );
      }
    },
    formatDecimalNumber(number) {
      // if (number == "-") {
      //   return number?.toString();
      // } else if (parseFloat(number) < 2) {
      //   const numberString = number.toString();
      //   const decimalIndex = numberString.indexOf(".");
      //   const len =
      //     decimalIndex !== -1 ? numberString.length - decimalIndex - 1 : null;
      //   if (len >= 3) {
      //     return "$" + number;
      //   }
      //   return "$" + parseFloat(number)?.toFixed(3);
      // } else if (parseFloat(number) >= 2) {
      //   return "$" + parseFloat(number)?.toFixed(2);
      // } else {
      //   return number;
      // }
      return formatTwoOrThreeDecimal(number)
    },
    formatNumberWithoutDoller(number) {
      if (number >= 1000000000) {
        const num = number / 1000000000;
        if (parseFloat(num) >= 2) {
          return parseFloat(num)?.toFixed(2) + " B";
        } else {
          return parseFloat(num)?.toFixed(3) + " B";
        }
      } else if (number >= 1000000) {
        const num = number / 1000000;
        if (parseFloat(num) >= 2) {
          return parseFloat(num)?.toFixed(2) + " M";
        } else {
          return parseFloat(num)?.toFixed(3) + " M";
        }
      } else if (number == "-") {
        return number?.toString();
      } else {
        const num = number;
        if (parseFloat(num) >= 2) {
          return parseFloat(num)?.toFixed(2);
        } else if (parseFloat(num) < 2) {
          return parseFloat(num)?.toFixed(3);
        } else {
          return num;
        }
      }
    },
    convertToNumber(value) {
      if (!value) return
      let numberValue = parseFloat(value.replace(',', ''));
      return numberValue
    },
    showTooltip() {
      this.tooltipOpen = true;
    },
    hideTooltip() {
      this.tooltipOpen = false;
    }
  },
};
</script>
