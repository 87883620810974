<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.4001 0.600006C0.95827 0.600006 0.600098 0.958178 0.600098 1.40001V3.00001C0.600098 3.44183 0.95827 3.80001 1.4001 3.80001H5.4001C5.84193 3.80001 6.2001 3.44183 6.2001 3.00001V1.40001C6.2001 0.958178 5.84193 0.600006 5.4001 0.600006H1.4001ZM1.4001 5.40001C0.95827 5.40001 0.600098 5.75818 0.600098 6.20001V7.80001C0.600098 8.24183 0.95827 8.60001 1.4001 8.60001H5.4001C5.84193 8.60001 6.2001 8.24183 6.2001 7.80001V6.20001C6.2001 5.75818 5.84193 5.40001 5.4001 5.40001H1.4001ZM1.4001 10.2C0.95827 10.2 0.600098 10.5582 0.600098 11V12.6C0.600098 13.0418 0.95827 13.4 1.4001 13.4H5.4001C5.84193 13.4 6.2001 13.0418 6.2001 12.6V11C6.2001 10.5582 5.84193 10.2 5.4001 10.2H1.4001ZM8.6001 0.600006C8.15827 0.600006 7.8001 0.958178 7.8001 1.40001V3.00001C7.8001 3.44183 8.15827 3.80001 8.6001 3.80001H12.6001C13.0419 3.80001 13.4001 3.44183 13.4001 3.00001V1.40001C13.4001 0.958178 13.0419 0.600006 12.6001 0.600006H8.6001ZM8.6001 5.40001C8.15827 5.40001 7.8001 5.75818 7.8001 6.20001V7.80001C7.8001 8.24183 8.15827 8.60001 8.6001 8.60001H12.6001C13.0419 8.60001 13.4001 8.24183 13.4001 7.80001V6.20001C13.4001 5.75818 13.0419 5.40001 12.6001 5.40001H8.6001Z"
      fill="#212121"
    />
  </svg>
</template>

<script>
export default {
  name: "iconMarketMap",
};
</script>
