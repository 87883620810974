<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.1624 8.95312L14.4593 9.93437C14.6624 10.0875 14.7187 10.3656 14.6031 10.5844L13.5437 12.4187C13.4156 12.6344 13.1468 12.725 12.9156 12.6281L11.4156 11.9969C10.9343 12.4094 10.3749 12.7344 9.76557 12.95L9.56245 14.5625C9.5312 14.8125 9.3187 15 9.06557 15H6.94682C6.6937 15 6.4812 14.8125 6.44995 14.5625L6.24682 12.95C5.63745 12.7344 5.07807 12.4094 4.59682 11.9969L3.09682 12.6281C2.86245 12.7281 2.5937 12.6375 2.4687 12.4187L1.40932 10.5844C1.28432 10.3656 1.34057 10.0875 1.54057 9.93437L2.83745 8.95312C2.7812 8.64375 2.74995 8.325 2.74995 8C2.74995 7.675 2.7812 7.35625 2.8312 7.04688L1.53432 6.06563C1.33432 5.9125 1.27807 5.63438 1.40307 5.41563L2.46245 3.58125C2.59057 3.36562 2.85932 3.275 3.09057 3.37188L4.59057 4.00312C5.07182 3.59063 5.6312 3.26562 6.24057 3.05L6.4437 1.4375C6.47495 1.1875 6.68745 1 6.94057 1H9.05932C9.31245 1 9.52495 1.1875 9.5562 1.4375L9.75307 3.05C10.3624 3.26562 10.9218 3.59063 11.4031 4.00312L12.9031 3.37188C13.1374 3.27188 13.4062 3.3625 13.5312 3.58125L14.5906 5.41563C14.7156 5.63438 14.6593 5.9125 14.4593 6.06563L13.1624 7.04688C13.2187 7.35625 13.2499 7.675 13.2499 8C13.2499 8.325 13.2187 8.64375 13.1624 8.95312ZM5.375 8C5.375 9.45 6.55 10.625 8 10.625C9.45 10.625 10.625 9.45 10.625 8C10.625 6.55 9.45 5.375 8 5.375C6.55 5.375 5.375 6.55 5.375 8Z"
            fill="#212529" />
    </svg>
</template>

<script>
export default {
    name: 'iconConfiguration'
}
</script>