<template>
    <div class="dropdown-header d-flex justify-content-between flex-row flex-wrap">
      <div class="mb-2 me-3c d-flex w-40-p">
        <dropdownlist
            :style="{ width: '230px' }"
            :data-items='dropItems'
            :text-field="'name'"
            :value="dropValue"
            :data-item-key="'value'"
            @change="handleDropdownChange"
        >
        </dropdownlist>
        <div class="d-flex align-items-center ps-3">
          <span
              class="d-inline-block h-22 primary-color font-12 line-h-1_2 border-1-orange p-1 radius-4 me-2">Delayed</span>
          <p class="font-13 line-h-1_4 mb-0">
              ASX data is delayed by at least 20 minutes
          </p>
        </div>
      </div>
      <div class="m-auto w-20-p">
        <div class="d-flex align-items-center gap-1">
          <b class="font-14">Market Map</b>
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'bottom'" :open="tooltipOpen">
              <span class="k-icon k-font-icon k-i-info" title="a" @mouseenter="showTooltip" @mouseleave="hideTooltip"></span>
              <template v-slot:myTooltipTemplate="{ props }">
                <div class="tooltip-content">
                  <ul>
                    <li class="pb-1">Companies are displayed by primary sector.</li>
                    <li class="pb-1">Market capitalisation is represented by the <br> size of the tile using a log equation. The <br>size of the tiles are therefore not to scale.</li>
                    <li class="pb-1">Only showing companies where the last <br>price was $0.01 or greater and meeting the <br>minimum liquidity requirements (Minimum <br>$10k average turnover or $50k in the current <br>session</li>
                  </ul>
                </div>
              </template>
          </Tooltip>
        </div>
      </div>
      <div class="ms-auto">
        <div class="mb-2 w-40-p">
          <div v-if="dropValue.value != 'daily_rvol' && dropValue.value != 'weekly_rvol'" class="color-boxes">
            <div class="box" style="background-color: #F02E2E;">
              <span>{{dropValue.value == 'day' ? '-6%' : dropValue.value == 'week' ? '-12%' : dropValue.value == 'month' ? '-24%' : dropValue.value == 'ytd' ? '-30%' : '-60%'}}</span>
            </div>
            <div class="box" style="background-color: #BD3D3D;">
              <span>{{dropValue.value == 'day' ? '-4%' : dropValue.value == 'week' ? '-8%' : dropValue.value == 'month' ? '-16%' : dropValue.value == 'ytd' ? '-20%' : '-40%'}}</span>
            </div>
            <div class="box" style="background-color: #8A4C4B;">
              <span>{{dropValue.value == 'day' ? '-2%' : dropValue.value == 'week' ? '-4%' : dropValue.value == 'month' ? '-8%' : dropValue.value == 'ytd' ? '-10%' : '-20%'}}</span>
            </div>
            <div class="box" style="background-color: #575B5A;">
              <span>0%</span>
            </div>
            <div class="box" style="background-color: #50825A;">
              <span>{{dropValue.value == 'day' ? '2%' : dropValue.value == 'week' ? '4%' : dropValue.value == 'month' ? '8%' : dropValue.value == 'ytd' ? '10%' : '20%'}}</span>
            </div>
            <div class="box" style="background-color: #4AAA59;">
              <span>{{dropValue.value == 'day' ? '4%' : dropValue.value == 'week' ? '6%' : dropValue.value == 'month' ? '16%' : dropValue.value == 'ytd' ? '20%' : '40%'}}</span>
            </div>
            <div class="box" style="background-color: #43D159;">
              <span>{{dropValue.value == 'day' ? '6%' : dropValue.value == 'week' ? '12%' : dropValue.value == 'month' ? '24%' : dropValue.value == 'ytd' ? '30%' : '60%'}}</span>
            </div>
          </div>
          <div v-if="dropValue.value == 'daily_rvol' || dropValue.value == 'weekly_rvol'" class="color-boxes">
            <div class="box" style="background-color: #1167BF;">
              <span>0</span>
            </div>
            <div class="box" style="background-color: #28639D;">
              <span>0.25</span>
            </div>
            <div class="box" style="background-color: #405F7C;">
              <span>0.75</span>
            </div>
            <div class="box" style="background-color: #575B5A;">
              <span>1</span>
            </div>
            <div class="box" style="background-color: #8A574B;">
              <span>1.5</span>
            </div>
            <div class="box" style="background-color: #BD523D;">
              <span>2</span>
            </div>
            <div class="box" style="background-color: #F04E2E;">
              <span>3</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="market-iframe-wrap">
      <iframe ref="iframe" :src="source" frameborder="0" allowfullscreen></iframe>
    </div>
</template>



<script>
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { Tooltip } from '@progress/kendo-vue-tooltip';
  export default{
    components: {
    'dropdownlist': DropDownList,
    Tooltip
},
    data: function () {
      return {
        source: "https://api.miningbull.com.au/market-map?currentSelection=day",
        dropItems: [
        {
          name: "Day Change",
          value: "day"
        },
        {
          name: "Week Change",
          value: "week"
        },
        {
          name: "Month Change",
          value: "month"
        },
        {
          name: "YTD Change",
          value: "ytd"
        },
        {
          name: "Year Change",
          value: "year"
        },
        {
          name: "Daily RVol",
          value: "daily_rvol"
        },
        {
          name: "Weekly Rvol",
          value: "weekly_rvol"
        },
        ],
        dropValue : {name: "Day Change", value: "day"},
        isLive: false,
        tooltipOpen: false,
      };
    },
    mounted(){
    if(window.location.href.includes("https://app.miningbull.com.au/")){
      this.isLive = true;
      this.source = "https://api.miningbull.com.au/market-map?currentSelection=day"
    }else{
      this.isLive = false;
      this.source = "https://miningbull-api.daybud.com/market-map?currentSelection=day"
    }
  },
    methods: {
      handleDropdownChange(event){
        this.dropValue = event.value;
        if(this.isLive){
          this.source = `https://api.miningbull.com.au/market-map?currentSelection=${this.dropValue.value}`
        }else{
          this.source = `https://miningbull-api.daybud.com/market-map?currentSelection=${this.dropValue.value}`
        }
      },
      showTooltip(){
        this.tooltipOpen = true;
      },
      hideTooltip(){
        this.tooltipOpen = false;
      }
    }
  }
</script>