export default class newProjectCreateUpdateModel {
  id: number = 0;
  companyId: string = "";
  project_name: string = "";
  status: string = "";
  stage: string = "";
  main_commodities: string = "";
  other_commodities: string = "";
  country: string = "";
  state: string = "";
  lithium_type: string = "";
  current_ownership: string = "";
  ownership_classification: [] = [];
  ultimate_ownership: string = "";
  latitude: string = "";
  longitude: string = "";
  ownership_note: string = "";
  project_summary: string = "";
  is_hidden_mineral: boolean = false;
  is_hidden_ore: boolean = false;
  image_id: number = 0;
  image_path: string = "";
  mineral_image_id: number = 0;
  mineral_image_path: string = "";
  mineral_image_size: number = 0;
  ore_image_id: number = 0;
  ore_image_path: string = "";
  ore_image_size: number = 0;
  mineral_resource: {} = {};
  ore_resource: {} = {};
  deposit_type: {} = {};
  mining_code: {} = {};
  multiple_deposit_column_mineral: [] = [];
  multiple_deposit_column_ore: [] = [];
  multiple_deposit_mineral_resource: [] = [];
  multiple_deposit_ore_resource: [] = [];
  image_date_published: string = "";
  selected_tenements: [] = [];
  feasibility_field_resource: [] = [];
  feasibility_common_field_resource: [] = [];
}
export class error {
  companyId: string = "";
  project_name: string = "";
  status: string = "";
  stage: string = "";
  main_commodities: string = "";
  other_commodities: string = "";
  country: string = "";
  state: string = "";
  current_ownership: string = "";
  ultimate_ownership: string = "";
  ownership_note: string = "";
  project_summary: string = "";
  is_hidden_mineral: string = "";
  is_hidden_ore: string = "";
  mineral_resource: string = "";
  ore_resource: string = "";
  multiple_mineral_current_ownership: string = "";
  multiple_ore_current_ownership: string = "";
}
