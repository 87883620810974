<template>
  <div v-if="isFullpage" class="loader-full">
    <Loader :theme-color="loaderColor" />
  </div>
  <div v-else class="">
    <Loader :theme-color="loaderColor" />
  </div>
</template>

<script>
import { Loader } from "@progress/kendo-vue-indicators";

export default {
  name: "AppGlobalLoader",
  components: {
    Loader
  },
  props: {
    loaderColor: {
      type: String,
      default: "primary"
    },
    isFullpage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>