<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.82498 0.000942421L4.86157 0L4.89715 0.000905954C4.91459 0.00187587 4.93208 0.00362458 4.94955 0.00617668L4.96205 0.0090304C5.02847 0.0193185 5.09448 0.0421886 5.15739 0.0781228L5.19773 0.10327L9.14081 2.77641L13.0813 0.105779C13.445 -0.14074 13.9379 0.0808376 13.9946 0.487469L14 0.565813V8.53779C14 8.69473 13.9321 8.84325 13.8148 8.94879L13.7522 8.99782L9.47495 11.8967C9.26739 12.0374 9.01779 12.0256 8.83118 11.9141L4.86126 9.22145L0.918687 11.8942C0.554956 12.1407 0.0621101 11.9192 0.00538679 11.5125L0 11.4342V3.4622C0 3.30526 0.0679316 3.15674 0.18518 3.0512L0.247829 3.00217L4.52505 0.10327C4.57482 0.0695361 4.62702 0.0445675 4.68018 0.0276389L4.77542 0.0066411L4.82498 0.000942421ZM12.8335 1.64849L9.72278 3.75678V10.354L12.8335 8.24572V1.64849ZM4.27722 1.64598L1.16651 3.75427V10.3515L4.27722 8.24321V1.64598ZM5.44556 1.64598V8.24321L8.55626 10.3515V3.75427L5.44556 1.64598Z" fill="#212121"/>
  </svg>
</template>

<script>
export default {
  name: "iconProjectMaps",
};
</script>
