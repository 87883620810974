<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 7C9.65625 7 11 5.65625 11 4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4C5 5.65625 6.34375 7 8 7Z"
      fill="#212529"
    />
    <path
      d="M2 14C2 10.6875 4.6875 8 8 8C11.3125 8 14 10.6875 14 14C14 14.5531 13.5531 15 13 15H3C2.44687 15 2 14.5531 2 14Z"
      fill="#212529"
    />
  </svg>
</template>

<script>
export default {
  name: "iconUser",
};
</script>
