<modal :title="'Edit Tenements'" :isLoading="isLoading" :handler="true"
  :cssClass="'c-modal-700 window-content-py-0 window-content-px-20 edit-tenements-modal'" @modalHandler="modalHandler">
  <template v-slot:content>
    <div class="row">
      <div class="col-6 border-right-normal-1">
        <div class="py-20">
          <dropdownlist :class="'w-100 mb-12'" :style="{  }" :value="state"
            :default-item="{id: 0, state:'State or Territory'}" :data-items="stateList" :text-field="'state'"
            :data-item-key="'id'" @filterchange="filterStateList" :filterable="true" @change="handleState">
          </dropdownlist>

          <div class="border-1-default p-10 rounded-1">
            <div v-if="isLoading || availableItems?.length > 0 || search && state">
              <listView :data-items="availableItems" :header="'myHeader'" :item="'myItem'"
                :style="{ width: '100%', height: availableItems?.length > 5 ? '314px' : ''}"
                :class="'no-border border-none listview-container'" @scroll="scrollHandler">
                <template v-slot:myHeader="{ props }" class="border-none">
                  <div class="search-box m-0">
                    <k-input @input="debounceSearch" ref="companySearch" :placeholder="'Enter tenement name or ID'" />
                    <span class="icon">
                      <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.7063 20H17.4156L21 23.5844L19.5844 25L16 21.4156V20.7063L15.1656 19.8719C14.3031 20.5781 13.2 21 12 21C9.2375 21 7 18.7625 7 16C7 13.2375 9.2375 11 12 11C14.7625 11 17 13.2375 17 16C17 17.2 16.5781 18.3031 15.8719 19.1656L16.7063 20ZM12 12C9.79063 12 8 13.7906 8 16C8 18.2094 9.79063 20 12 20C14.2094 20 16 18.2094 16 16C16 13.7906 14.2094 12 12 12Z"
                          fill="#6C757D" />
                      </svg>
                    </span>
                  </div>
                  <p v-if="!isLoading && availableItems.length==0" class="text-center font-13">
                    No matching tenements found.
                    <span class="d-block">Try a different search term or select a different
                      state/territory.</span>
                  </p>
                </template>
                <template v-slot:myItem="{ props }">
                  <k-checkbox v-model="props.dataItem.is_selected" @change="handleCheck(props.dataItem, 'available')"
                    :class="'color-primary checkbox-label-font-14 d-block mb-2'" :label="props.dataItem.name" />
                </template>
              </listView>
            </div>
            <div v-else class="empty-state">
              <p class="text-center font-13 mb-0">
                No state selected. Please choose a state from the dropdown menu.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="py-20">
          <p class="font-16 mb-2">Project Tenements</p>
          <div class="tenements-check-list overflow-y-auto">
            <div v-for="item in selectedItems" :key="item.id">
              <div class="border-1-default py-1 px-2 mb-1 d-flex align-items-center justify-content-between">
                <p class="font-14 m-0">{{ item.name }}</p>
                <span @click="handleRemove(item)" class="cursor-pointer d-flex">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                      fill="#212529" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <kbutton :disabled="isLoading" :theme-color="'primary'" @click="onclickSave">Save
      </kbutton>
    </div>
  </template>
</modal>