import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/systemDashboard/systemDashboard.vue";
import NotFound from "@/components/common/app-not-found/app-not-found.vue";
import MarketMap from "@/components/marketMap/marketMap.vue";
import LoginRoute from "./auth/LoginRoute.js";
import companyRoutes from "./companyRoutes";
import tenementsRoutes from "./tenementsRoutes";
import forgetPassword from "@/components/auth/forgetPassword/forgetPassword.vue";
import resetPassword from "@/components/auth/forgetPassword/resetPassword.vue";
import store from "../store/index";
import emailQuery from "@/components/Email/emailQuery.vue";
import ColumnConfiguration from "@/components/columnConfiguration/columnConfiguration.vue";
import { userValidation } from "@/helpers/utility.js";

const routes = [
  {
    path: "/companies",
    name: "HomeCompanies",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    beforeEnter: () => {
      userValidation(["admin", "pro_user", "explorer_user", "trial_user"]);
    },
    path: "/projects",
    name: "HomeProjects",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/email",
    name: "Email",
    component: emailQuery,
    props: (route) => ({ email: route.query.email }),
    meta: { requiresAuth: false },
  },
  {
    beforeEnter: () => {
      userValidation(["admin"]);
    },
    path: "/column-configuration",
    name: "ColumnConfiguration",
    component: ColumnConfiguration,
    meta: { requiresAuth: true },
  },
  ...LoginRoute,
  ...companyRoutes,
  ...tenementsRoutes,
  {
    path: "/marketMap",
    name: "Market Map",
    component: MarketMap,
    meta: { requiresAuth: true },
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: forgetPassword,
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: resetPassword,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
    name: "NotFound",
    props: (route) => ({ incorrectPath: route.params.pathMatch }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (window.self !== window.top) {
    next({
      path: "/",
    });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
