export default class filterShowHideProjectViewModel {
    status_template = false;
    project_name_template = false;
    country_template = false;
    state_template = false;
    stage_template = false;
    mineral_tonnes_template = false;
    mineral_au_gt_template = false;
    mineral_cut_of_grade_gt_template = false;
    mineral_contained_au_moz_template = false;
    ore_tonnes_template = false;
    ore_au_gt_template = false;
    ore_cut_of_grade_gt_template = false;
    ore_contained_au_moz_template = false;
  }
  