<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.925C14 3.92812 14 3.93125 14 3.93438C13.9844 4.99375 11.3031 5.85 8 5.85C4.69688 5.85 2.01562 4.99375 2 3.93438C2 3.93125 2 3.92812 2 3.925C2 2.8625 4.6875 2 8 2C11.3125 2 14 2.8625 14 3.925ZM14 5.375C14 5.375 14 5.38125 14 5.38438C13.9844 6.44375 11.3031 7.3 8 7.3C4.69688 7.3 2.01562 6.44375 2 5.38438V5.375L2.03125 8.0875C2.03125 8.0875 2.03125 8.1 2.03125 8.10312C2.04688 9.15625 4.7125 10.0094 8 10.0094C11.2875 10.0094 13.9531 9.15625 13.9688 8.10312C13.9688 8.1 13.9688 8.0875 13.9688 8.0875L14 5.375ZM14 9.375C14 9.375 14 9.38125 14 9.38437C13.9844 10.4437 11.3031 11.3 8 11.3C4.69688 11.3 2.01562 10.4437 2 9.38437V9.375L2.03125 12.0875C2.03125 12.0875 2.03125 12.1 2.03125 12.1031C2.04688 13.1562 4.7125 14.0094 8 14.0094C11.2875 14.0094 13.9531 13.1562 13.9688 12.1031C13.9688 12.1 13.9688 12.0875 13.9688 12.0875L14 9.375Z"
      fill="#212529"
    />
  </svg>
</template>

<script>
export default {
  name: "iconData",
};
</script>
