<template src="./columnConfiguration.html" />
<style lang="scss">
@import "./columnConfiguration.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import CompaniesTabContent from "./companiesTabContent/companiesTabContent.vue";
import ProjectsTabContent from "./projectsTabContent/projectsTabContent.vue";
import { getcolumnConfigurationListService, saveColumnConfigurationListService } from "@/services/columnConfiguration.service"
import { notificationHandler } from "@/helpers/utility";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";

export default {
    name: 'ColumnConfiguration',
    components: {
        "k-button": Button,
        dropdownlist: DropDownList,
        CompaniesTabContent,
        ProjectsTabContent,
        AppSnackBar
    },
    data() {
        return {
            isDropdeoOpen: false,
            isLithiumTypeDropdownOpen: false,
            toggleClick: false,
            selectedView: { id: 1, title: "Company View", name: "sector_view" },
            defaultItemSector: {
                id: 0,
                name: "Any Sector",
                alias: "all_commodities",
            },
            sectorDropdownData: [],
            popupSettings: {
                anchorAlign: {
                    horizontal: "right",
                    vertical: "bottom",
                },
                popupAlign: {
                    horizontal: "right",
                    vertical: "top",
                },
                popupClass: "popup-height",
            },
            selectedConfigurationTab: 1,
            selected: 0,
            configurationTabContentList: [
                { uuid: 1, name: "Companies", view: 'sector_view' },
                { uuid: 2, name: "Projects", view: 'project_view' },
            ],
            tabContentComponents: {
                1: 'CompaniesTabContent',
                2: 'ProjectsTabContent',
            },
            configurationList: [],
            configurationListToMatch: [],
            notification: {
                message: '',
                type: '',
                isNotify: false
            },
            isLoading: false,
            defaultLithiumType: {
                id: 0,
                name: "All",
                alias: "all",
            },
            lithiumTypeData: [
                {
                    id: 0,
                    name: "All",
                    alias: "all",
                },
                {
                    id: 1,
                    name: "Hard Rock",
                    alias: "lithium_hard_rock",
                },
                {
                    id: 2,
                    name: "Clay",
                    alias: "lithium_clay",
                },
                {
                    id: 3,
                    name: "Brine",
                    alias: "lithium_brine",
                },
            ],
            isLithiumTypeDropdownOpen: false,
            filteredLithiumTypeData: [],
        }
    },
    computed: {
        commoditiesList() {
            return this.$store.state.dashboard.commoditiesList;
        },
        currentTabComponent() {
            return this.tabContentComponents[this.selectedConfigurationTab];
        },
        isLoggedIn() {
            return this.$store.state.auth.isLoggedIn;
        },
        isChanged(){
            return JSON.stringify(this.configurationList) !== JSON.stringify(this.configurationListToMatch)
        }
    },
    mounted(){
        this.filteredLithiumTypeData = this.lithiumTypeData.slice();
        this.loadData()
        this.getColumnConfiguration()
    },
    methods: {
        loadData(){
            if(!this.commoditiesList?.length){
                this.$store.dispatch("getCommodities");
            }
        },
        getColumnConfiguration(){
            // this.isLoading = true
            let payload = {
                commodities: this.defaultItemSector.alias,
                view: this.configurationTabContentList?.find(item => item.uuid == this.selectedConfigurationTab)?.view,
                ...(this.defaultItemSector?.name === "Lithium" && {
                  lithium_type: this.defaultLithiumType?.alias,
                }),
            }
            getcolumnConfigurationListService(payload)
            .then((response) => {
                // this.isLoading = false
                this.configurationList = response?.data?.result?.configuration
                this.configurationListToMatch = JSON.parse(JSON.stringify(this.configurationList))
            })
            .catch((error) => {
                // this.isLoading = false
            })
        },
        filterDataSector(filter) {
            const data = this.commoditiesList?.slice();
            return filterBy(data, filter);
        },
        filterChangeSector(event) {
            const newData =
                event.filter.value.length >= 3
                    ? this.filterDataSector(event.filter)
                    : this.commoditiesList?.slice();
            this.sectorDropdownData = newData;
        },

        handleSectorDropdown(e) {
            this.defaultItemSector = e.value;
            this.getColumnConfiguration();
        },
        onOpenCDD(field) {
            if (field == 'commodity') this.isDropdeoOpen = true;
            else this.isLithiumTypeDropdownOpen = true
        },
        onCloseCDD(field) {
            if (field == 'commodity') this.isDropdeoOpen = false;
            else this.isLithiumTypeDropdownOpen = false
        },
        // onFocusSector(e) {
        //     this.sectorDropdownData = this.commoditiesList;
        //     let updatedCommoditiesList = [...this.sectorDropdownData];
        //     if (this.selectedView.name != "sector_view") {
        //         updatedCommoditiesList.unshift({
        //             id: 0,
        //             name: "All Commodities",
        //             alias: "all_commodities",
        //         });
        //     } else {
        //         updatedCommoditiesList.unshift({
        //             id: 0,
        //             name: "Any Sector",
        //             alias: "all_commodities",
        //         });
        //     }
        //     this.sectorDropdownData = updatedCommoditiesList;
        // },
        onFocusSector() {
            const isSectorView = this.selectedView.name === "sector_view";
            const defaultItem = {
                id: 0,
                name: isSectorView ? "Any Sector" : "All Commodities",
                alias: "all_commodities"
            };

            this.sectorDropdownData = [defaultItem, ...this.commoditiesList];
        },
        onSelectConfigurationTab(item) {
            this.selectedConfigurationTab = item.uuid;
            this.getColumnConfiguration()
        },
        changeSelection(e){
        },
        handleClear(){
            this.configurationList = JSON.parse(JSON.stringify(this.configurationListToMatch))
        },
        handleParentSelection(data){
            return data?.map((parent, index) => {
                if(parent?.child && parent?.child?.length){
                    const isChildSelected = parent?.child?.some((child) => child.is_selected);
                    parent.is_selected = isChildSelected;
                }
                return parent
            })
        },
        handleSave(){
            // this.isLoading = true
            let payload = {
                commodities: this.defaultItemSector.alias,
                view: this.configurationTabContentList?.find(item => item.uuid == this.selectedConfigurationTab)?.view,
                configuration: this.handleParentSelection(this.configurationList),
                ...(this.defaultItemSector?.name === "Lithium" && {
                  lithium_type: this.defaultLithiumType?.alias,
                }),
            }
            saveColumnConfigurationListService(payload)
            .then((response) => {
                // this.isLoading = false
                this.getColumnConfiguration()
                let message = response?.data?.result?.message
                this.showNotification(message, 'success')
            })
            .catch((error) => {
                // console.log(error)
                // this.isLoading = false
                let message = error?.response?.data?.result?.message || 'Something went wrong!'
                this.showNotification(message, 'error')
            })
        },
        showNotification(message, type){
            let notify = notificationHandler(
                message,
                true,
                type
            );
            this.notification = notify
            setTimeout(() => {
                let notify = notificationHandler(
                    message,
                    false,
                    type
                );
                this.notification = notify
            }, 4000);
        },
        handleLithiumTypeChange(e) {
            this.defaultLithiumType = e.value;
            this.getColumnConfiguration();
        },
        filterChangeLithiumType(event){
            const newData = 
                event.filter.value.length >= 1
                ? this.filterLithiumData(event.filter)
                : this.lithiumTypeData?.slice()

            this.filteredLithiumTypeData = newData
        },
        filterLithiumData(filter) {
            const data = this.lithiumTypeData?.slice();
            return filterBy(data, filter);
        },
    }
}
</script>
