<template>
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12H19C19.5523 12 20 12.4477 20 13C20 13.5128 19.614 13.9355 19.1166 13.9933L19 14H1C0.447715 14 0 13.5523 0 13C0 12.4872 0.38604 12.0645 0.883379 12.0067L1 12H19H1ZM0.99988 6L18.9999 5.99783C19.5522 5.99777 20 6.44543 20 6.99771C20 7.51055 19.6141 7.93327 19.1167 7.99109L19.0001 7.99783L1.00012 8C0.447836 8.00007 0 7.5524 0 7.00012C0 6.48728 0.385936 6.06457 0.88326 6.00674L0.99988 6L18.9999 5.99783L0.99988 6ZM1 0H19C19.5523 0 20 0.447715 20 1C20 1.51284 19.614 1.93551 19.1166 1.99327L19 2H1C0.447715 2 0 1.55228 0 1C0 0.487164 0.38604 0.0644928 0.883379 0.0067277L1 0H19H1Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "iconHamburger",
};
</script>
