// main.ts
import { createApp } from 'vue'
import store from './store/index'
import router from './router/index'
import App from './App.vue'
import axios from "axios";

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return router.push("/");
      }
    }
  });

const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')
