<template src="./AppGoogleMaps.html"></template>

<style lang="scss" scoped>
@import "./AppGoogleMaps.scss";
</style>

<script>
import { ref, watch, computed, defineAsyncComponent } from "vue";
import { GoogleMap, Polygon, Marker } from "vue3-google-map";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import debounce from "lodash/debounce";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import { configuration } from "@/configurationProvider";
import {
  getProjectforMapsService,
  getTenementsForProjectService,
  getMarkerProjectByProjectUuidService,
  getProjectByProjectUuidService,
} from "@/services/tenement.service.ts";

const multipleProjectListModal = defineAsyncComponent(() =>
  import(
    "@/components/common/AppGoogleMaps/multipleProjectListModal/multipleProjectListModal.vue"
  )
);
const projectDetailsModal = defineAsyncComponent(() =>
  import(
    "@/components/common/AppGoogleMaps/projectDetailsModal/projectDetailsModal.vue"
  )
);
import { highlightPolygons } from "@/helpers/utility";


export default {
  name: "AppGoogleMaps",
  components: {
    GoogleMap,
    Polygon,
    Marker,
    dropdownlist: DropDownList,
    "k-input": Input,
    multipleProjectListModal,
    projectDetailsModal,
    "app-loader": AppGlobalLoader,
  },
  data() {
    return {
      apiKey: configuration?.googleApiKey,
      center: { lat: -25.274398, lng: 133.775136 },
      zoom: 6,
      polygonOptionList: [],
      placemarkMarkers: [],
      searchResultPolygonOptionList: [],
      selectedPolygonId: "",
      selectedPolygonIndex: null,
      loading: false,
      isLoadingModal: false,
      marker: null,
      distanceKm: 0,
      mapOptions: null,
      dropdownDataItem: ["Location", "Project"],
      selectedDDdata: "Location",
      showPopup: false,
      inputValue: "",
      showDrawerMap: false,
      selectedPolygonObj: {},
      hasPlacemark: false,
      selectedMarkerObj: null,
      loadedIds: [],
      isSearchLoading: false,
      modalData: {},
      multipleModalData: [],
      isFromMultiple: false,
    };
  },
  props: {
    polygonOptionProps: {
      type: Array,
      default: [],
    },
    placemarksMarkerList: {
      type: Array,
      default: [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    polygonOptionProps: {
      handler(newVal, oldVal) {
        this.polygonOptionList = newVal;
        if (!Object.keys(this.selectedPolygonObj).length) return;
        const projectId = this.$route?.params?.projectId;
        const hasProjectId = newVal.some(polygon => (polygon.project_uuid === projectId || polygon.project_uuid === this.selectedPolygonObj?.project_uuid));

        if (!hasProjectId) {
          this.selectedPolygonObj = {};
          this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
          this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", false);
        }
        if (
          this.isMultipleTenementsModalShow &&
          !this.isSingleTenementsModalShow
        ) {
          return;
        }
        this.highlightPolygons(
          this.selectedPolygonObj?.project_uuid,
          "rgba(253, 94, 63, 0.37)",
          "rgba(253, 94, 63, 1)"
        );
      },
      deep: true,
    },
    placemarksMarkerList: {
      handler(newVal, oldVal) {
        this.placemarkMarkers = newVal;
      },
      deep: true,
    },
    selectedPolygonObj: {
      handler(newVal) {
        if (newVal?.project_uuid) {
          this.selectedMarkerObj = null;
          this.$router.push({
            name: this.$route.name,
            params: { ...this.$route.params, projectId: newVal.project_uuid, placeMark: 0 }
          });
        }
      },
      deep: true,
    },
    selectedMarkerObj: {
      handler(newVal) {
        if (newVal?.project_uuid) {
          this.selectedPolygonObj = {};
          this.highlightPolygons(
            null, null, null
          );
          this.$router.push({
            name: this.$route.name,
            params: { ...this.$route.params, projectId: newVal.project_uuid, placeMark: 1 }
          });
        }
      },
      deep: true,
    }
  },
  computed: {
    polygonOptions() {
      return this.zoom >= 8 ? this.polygonOptionList : [];
    },
    isSingleTenementsModalShow() {
      return this.$store.state.tenements.isSingleTenementsModalShow;
    },
    isMultipleTenementsModalShow() {
      return this.$store.state.tenements.isMultipleTenementsModalShow;
    },
    isLocationSelected() {
      return this.selectedDDdata === "Location";
    },
    isProjectSelected() {
      return this.selectedDDdata === "Project";
    },
    isProjectViewMap() {
      const { name, params } = this.$route;
      return name === "projectviewmap" && params.id && params.projectId;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.initializeMap();
    this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
    this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", false);

  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    getMarkerOptions(marker) {
      const isRoundIcon = this.selectedMarkerObj && this.selectedMarkerObj.project_uuid === marker.project_uuid;

      const iconMapPinRed = `<svg width="27" height="39" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.375 36.5L12.9837 36.7884L13.375 37.3193L13.7663 36.7884L13.375 36.5ZM13.375 36.5C13.7663 36.7884 13.7664 36.7883 13.7665 36.7881L13.767 36.7875L13.7687 36.7851L13.7755 36.7759L13.8017 36.7402C13.8248 36.7086 13.8589 36.662 13.9033 36.6009C13.992 36.4789 14.122 36.2993 14.2872 36.0686C14.6176 35.6073 15.0892 34.9414 15.655 34.1216C16.7862 32.4827 18.2953 30.2268 19.8052 27.7612C21.3141 25.2973 22.8291 22.6153 23.9684 20.1256C25.0991 17.6548 25.8924 15.3057 25.8924 13.5312C25.8924 6.61825 20.288 1.01389 13.375 1.01389C6.462 1.01389 0.857639 6.61825 0.857639 13.5312C0.857639 15.3057 1.65093 17.6548 2.78161 20.1256C3.92095 22.6153 5.43594 25.2973 6.94482 27.7612C8.45471 30.2268 9.96382 32.4827 11.095 34.1216C11.6608 34.9414 12.1324 35.6073 12.4628 36.0686C12.628 36.2993 12.758 36.4789 12.8467 36.6009C12.8911 36.662 12.9252 36.7086 12.9483 36.7402L12.9745 36.7759L12.9813 36.7851L12.983 36.7875L12.9835 36.7881C12.9836 36.7883 12.9837 36.7884 13.375 36.5ZM13.375 20.7014C9.41203 20.7014 6.20486 17.4942 6.20486 13.5312C6.20486 9.56828 9.41203 6.36111 13.375 6.36111C17.338 6.36111 20.5451 9.56828 20.5451 13.5312C20.5451 17.4942 17.338 20.7014 13.375 20.7014ZM13.375 17.2986C15.455 17.2986 17.1424 15.6112 17.1424 13.5312C17.1424 11.4513 15.455 9.76389 13.375 9.76389C11.295 9.76389 9.60764 11.4513 9.60764 13.5312C9.60764 15.6112 11.295 17.2986 13.375 17.2986Z"
            fill="#EB5E3F" stroke="#EB2B00" stroke-width="0.972222" />
    </svg>`;

      const iconMapPinYellow = `<svg width="27" height="39" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.375 36.5L12.9837 36.7884L13.375 37.3193L13.7663 36.7884L13.375 36.5ZM13.375 36.5C13.7663 36.7884 13.7664 36.7883 13.7665 36.7881L13.767 36.7875L13.7687 36.7851L13.7755 36.7759L13.8017 36.7402C13.8248 36.7086 13.8589 36.662 13.9033 36.6009C13.992 36.4789 14.122 36.2993 14.2872 36.0686C14.6176 35.6073 15.0892 34.9414 15.655 34.1216C16.7862 32.4827 18.2953 30.2268 19.8052 27.7612C21.3141 25.2973 22.8291 22.6153 23.9684 20.1256C25.0991 17.6548 25.8924 15.3057 25.8924 13.5312C25.8924 6.61825 20.288 1.01389 13.375 1.01389C6.462 1.01389 0.857639 6.61825 0.857639 13.5312C0.857639 15.3057 1.65093 17.6548 2.78161 20.1256C3.92095 22.6153 5.43594 25.2973 6.94482 27.7612C8.45471 30.2268 9.96382 32.4827 11.095 34.1216C11.6608 34.9414 12.1324 35.6073 12.4628 36.0686C12.628 36.2993 12.758 36.4789 12.8467 36.6009C12.8911 36.662 12.9252 36.7086 12.9483 36.7402L12.9745 36.7759L12.9813 36.7851L12.983 36.7875L12.9835 36.7881C12.9836 36.7883 12.9837 36.7884 13.375 36.5ZM13.375 20.7014C9.41203 20.7014 6.20486 17.4942 6.20486 13.5312C6.20486 9.56828 9.41203 6.36111 13.375 6.36111C17.338 6.36111 20.5451 9.56828 20.5451 13.5312C20.5451 17.4942 17.338 20.7014 13.375 20.7014ZM13.375 17.2986C15.455 17.2986 17.1424 15.6112 17.1424 13.5312C17.1424 11.4513 15.455 9.76389 13.375 9.76389C11.295 9.76389 9.60764 11.4513 9.60764 13.5312C9.60764 15.6112 11.295 17.2986 13.375 17.2986Z"
            fill="#FFB54D" stroke="#EB8A00" stroke-width="0.972222" />
    </svg>`;

      return {
        position: marker.position,
        title: marker.title,
        icon: {
          url: isRoundIcon
            ? `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconMapPinRed)}`
            : `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconMapPinYellow)}`,
          scaledSize: new google.maps.Size(40, 40)
        }
      };
    },
    initializeMap() {
      watch(
        () => this.$refs.mapRef?.ready,
        (isReady) => {
          if (isReady) {
            this.onMapReady();
          }
        }
      );
    },
    onMapReady() {
      const gmap = this.$refs.mapRef.map;
      this.mapOptions = {
        zoom: this.zoom,
        minZoom: 5,
        maxZoom: 18,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        mapTypeControl: false,
        // mapTypeControlOptions: {
        //   style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        //   mapTypeIds: [
        //     google.maps.MapTypeId.ROADMAP,
        //     google.maps.MapTypeId.SATELLITE,
        //     google.maps.MapTypeId.HYBRID,
        //     google.maps.MapTypeId.TERRAIN,
        //   ],
        // },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: true,
        rotateControl: true,
        panControl: true,
        clickableIcons: true,
        keyboardShortcuts: true,
        gestureHandling: "greedy",
        draggableCursor: "auto",
      };
      gmap.setOptions(this.mapOptions);

      if (this.isLocationSelected) {
        this.initAutocomplete(gmap);
      }
      this.setupZoomListener(gmap);
      this.setupDragListener(gmap);
      if (this.isProjectViewMap) {
        this.hasPlacemark = this.$route?.params?.placeMark != 0 ? true : false;
        this.getTenementsForProject({
          project_uuid: this.$route?.params?.projectId,
        });
      }
    },
    setupZoomListener(gmap) {
      let zoomTimeout;
      gmap.addListener("zoom_changed", () => {
        if (zoomTimeout) {
          clearTimeout(zoomTimeout);
        }
        zoomTimeout = setTimeout(() => {
          if (this.zoom != gmap.getZoom()) {
            this.zoom = gmap.getZoom();
            this.logZoomPayload(gmap);
          }
        }, 1000);
      });
    },
    setupDragListener(gmap) {
      let dragTimeout;
      gmap.addListener("dragend", () => {
        if (dragTimeout) {
          clearTimeout(dragTimeout);
        }
        dragTimeout = setTimeout(() => {
          this.logZoomPayload(gmap);
        }, 1000);
      });
    },
    initAutocomplete(map) {
      const input = document.getElementById("pac-input");
      const searchBox = new google.maps.places.SearchBox(input);
      map.addListener(
        "bounds_changed",
        debounce(() => {
          searchBox.setBounds(map.getBounds());
        }, 300)
      );

      searchBox.addListener(
        "places_changed",
        debounce(() => {
          const places = searchBox.getPlaces();
          this.handlePlacesChanged(map, places);
        }, 300)
      );
    },
    handlePlacesChanged(map, places) {
      this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
      this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", false);
      const input = document.getElementById("pac-input");
      input.value = "";
      if (!places?.length) return;

      if (this.marker) {
        this.marker.setMap(null);
      }

      this.marker = new google.maps.Marker({
        map,
        position: places[0].geometry.location,
        title: places[0].formatted_address,
      });

      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    },
    calculateDistanceInKm(zoomLevel) {
      const earthCircumferenceKm = 40075;
      const baseTileSize = 256;
      const distancePerPixel =
        earthCircumferenceKm / (Math.pow(2, zoomLevel) * baseTileSize);
      const mapWidthInPixels = this.$refs.mapRef.$el.clientWidth;
      const distanceInKm = distancePerPixel * mapWidthInPixels;

      return distanceInKm;
    },
    onClickBackSingleModal() {
      if (this.isSingleTenementsModalShow) {
        this.highlightPolygons(null, null, null);
        this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
        this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", true);
      }
    },
    onCloseDrawerMap() {
      if (this.isSingleTenementsModalShow) {
        const timeId2 = setTimeout(() => {
          this.outsideClick();
          clearTimeout(timeId2);
        }, 100);
      }
    },
    onCloseMultipleProjectListModal() {
      if (this.isMultipleTenementsModalShow) {
        const timeId2 = setTimeout(() => {
          this.outsideClick();
          clearTimeout(timeId2);
        }, 100);
      }
    },
    debounceSearch(event, cellData) {
      this.outsideClick();
      this.inputValue = event.value;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (this.inputValue?.length >= 3) {
          this.showPopup = true;
          this.searchResultPolygonOptionList = [];
          this.searchTenementsList({
            search_query: this.inputValue,
          });
        }
      }, 600);
    },
    searchTenementsList(payload) {
      this.isSearchLoading = true;
      getProjectforMapsService(payload)
        .then((response) => {
          this.isSearchLoading = false;
          this.searchResultPolygonOptionList = response?.data?.result?.data;
        })
        .catch((error) => {
          this.isSearchLoading = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    handleClickOutside(event) {
      const popup = this.$refs.popupList;
      if (popup && !popup.contains(event.target)) {
        this.showPopup = false;
      }
    },
    debouncedDropdownChange: debounce(function (e) {
      this.onChangedropdown(e);
    }, 300),
    onChangedropdown(e) {
      this.selectedDDdata = e.value;
      this.$nextTick(() => {
        if (this.isLocationSelected) {
          this.initAutocomplete(this.$refs.mapRef.map);
        }
      });
    },
    onSelectPolygon(option) {
      let isFromProjectSearch = true;
      this.showPopup = false;
      this.inputValue = "";
      this.hasPlacemark = option?.has_placemark != 0 ? true : false;
      if (!this.hasPlacemark)
        this.onClickPolygon(option, isFromProjectSearch);
      else
        this.onClickMarker(option, isFromProjectSearch);
    },
    getTenementsForProject(payload) {
      getTenementsForProjectService(payload)
        .then((response) => {
          this.goToCenter(
            response?.data?.overall_center_point,
            response?.data?.bounding_box
          );
          let responsedata = response?.data;
          this.modalData = responsedata?.project;

          if (!this.hasPlacemark) {
            this.selectedPolygonObj = responsedata?.project;
            this.highlightPolygons(
              this.selectedPolygonObj?.project_uuid,
              "rgba(253, 94, 63, 0.37)",
              "rgba(253, 94, 63, 1)"
            );
          } else {
            this.selectedMarkerObj = responsedata?.project;
          }

          this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", true);
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", false);
          console.error("Error fetching tenement list:", error);
        });
    },
    getMarkerProjectByProjectUuid(payload) {
      this.isLoadingModal = true;
      getMarkerProjectByProjectUuidService(payload)
        .then((response) => {
          this.isLoadingModal = false;
          let responsedata = response?.data?.result;
          this.modalData = responsedata?.project;
          this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", true);
        })
        .catch((error) => {
          this.isLoadingModal = false;
          this.$store.commit("SET_IS_RELOADNEEDED", false);
          console.error("Error fetching tenement list:", error);
        });
    },
    getProjectData(payload) {
      this.isLoadingModal = true;
      this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
      this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", false);
      getProjectByProjectUuidService(payload)
        .then((response) => {
          this.isLoadingModal = false;
          let responsedata = response?.data?.result;

          if (responsedata?.is_multiple) {
            this.isFromMultiple = true;
            this.multipleModalData = responsedata?.project;
            this.highlightPolygons(null, null, null);
            this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", true);
          } else {
            this.isFromMultiple = false;
            this.modalData = responsedata?.project;
            this.highlightPolygons(
              this.selectedPolygonObj?.project_uuid,
              "rgba(253, 94, 63, 0.37)",
              "rgba(253, 94, 63, 1)"
            );
            this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", true);
          }
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", false);
          this.isLoadingModal = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    showProjectDetails(data) {
      if (Object.keys(data).length) {
        this.modalData = data;
        this.selectedPolygonObj = data;
        this.highlightPolygons(
          this.modalData?.project_uuid,
          "rgba(253, 94, 63, 0.37)",
          "rgba(253, 94, 63, 1)"
        );
        this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", false);
        this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", true);
      }
    },
    highlightPolygons(project_uuid, fillColor, strokeColor) {
      let parsedPolygonOptionList = JSON.parse(
        JSON.stringify(this.polygonOptionList)
      );
      parsedPolygonOptionList = highlightPolygons(
        parsedPolygonOptionList,
        project_uuid,
        fillColor,
        strokeColor
      );
      this.polygonOptionList = JSON.parse(
        JSON.stringify(parsedPolygonOptionList)
      );
    },
    onPolygonFromProj(selectedPolygon) {
      if (selectedPolygon) {
        const bounds = new google.maps.LatLngBounds();

        if (Array.isArray(selectedPolygon.paths)) {
          selectedPolygon.paths.forEach((path) => {
            if (Array.isArray(path)) {
              path.forEach((point) => {
                bounds.extend(new google.maps.LatLng(point.lat, point.lng));
              });
            } else {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        }

        this.$refs.mapRef.map.fitBounds(bounds);
      }
    },
    onClickPolygon(option, isFromProjectSearch = false) {
      this.selectedPolygonObj = option;

      if (isFromProjectSearch) {
        this.getTenementsForProject({
          project_uuid: option?.uuid,
        });
      }

      if (!isFromProjectSearch) {
        this.getProjectData({
          tenement_uuids: option?.tenement_uuids,
        });
      }
    },
    onClickMarker(option, isFromProjectSearch = false) {
      this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
      this.selectedMarkerObj = option;
      if (isFromProjectSearch) {
        this.getTenementsForProject({
          project_uuid: option?.uuid,
        });
      }
      if (!isFromProjectSearch) {
        this.getMarkerProjectByProjectUuid(
          {
            project_uuid: option?.project_uuid
          }
        )
      }
    },
    goToCenter(centerPoint, tenementCoordinates) {
      const map = this.$refs.mapRef.map;
      const bounds = new google.maps.LatLngBounds();
      tenementCoordinates.forEach((coordinate) => {
        bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
      });
      map.fitBounds(bounds);
      this.logZoomPayload(map);
    },
    outsideClick() {
      this.showPopup = false;
      this.inputValue = "";
      this.selectedMarkerObj = {};
      if (!Object.keys(this.selectedPolygonObj).length) return;
      this.selectedPolygonObj = {};
      this.$store.commit("SET_MULTIPLE_TENEMENTS_MODAL_SHOW", false);
      this.$store.commit("SET_SINGLE_TENEMENTS_MODAL_SHOW", false);
      this.highlightPolygons(null, null, null);
    },
    logZoomPayload(map, isOutsideCLick = false) {
      const center = map.getCenter();
      const bounds = map.getBounds();
      const northeast = bounds.getNorthEast();
      const southwest = bounds.getSouthWest();

      const payload = {
        zoom: this.zoom,
        coordinates: [
          { lat: northeast.lat(), lng: northeast.lng() },
          { lat: southwest.lat(), lng: northeast.lng() },
          { lat: southwest.lat(), lng: southwest.lng() },
          { lat: northeast.lat(), lng: southwest.lng() },
          { lat: northeast.lat(), lng: northeast.lng() },
        ],
      };
      if (isOutsideCLick) {
        this.$emit("onOutsideClickEmitFunc", payload);
      } else {
        this.$emit("onZoomEmitFunc", payload);
      }
    },
  },
};
</script>
