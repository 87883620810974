<template src="./headerFilterPopupTemplateTwo.html" ></template>

<script>
import { Input, NumericTextBox } from '@progress/kendo-vue-inputs';
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { filter_object_generator_from_cell_data } from '@/helpers/utility';
import { notificationHandler } from "@/helpers/utility";
export default {
  name: "headerFilterPopupTemplate",
  components: {
    DropDownButton,
    Popup,
    "k-button": Button,
    "k-input": Input,
    "k-checkbox": Checkbox,
    numerictextbox: NumericTextBox,
  },
  props: {
    isShowFilter: {
      type: Boolean,
      default: false
    },
    anchorTag: {
      type: String,
      default: 'showFilter'
    },
    onCancel: {
      type: Function,
      default: () => { }
    },
    index: {
      type: Number,
      default: 0
    },
    cellData: {
      type: Object,
      default: () => { }
    },
    enableDragScroll: {
      type: Function,
      default: () => { }
    }
  },
  computed: {
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
  },
  data: function () {
    return {
      preservedState: {},
      inputFrom: null,
      inputTo: null
    };
  },
  mounted() {
    this.preservedState = { ...this?.cellData?.filter_value };
    if (this.cellData.filter_value.from == 0 && this.cellData.filter_value.to == 0) {
      this.cellData.filter_value.from = null;
      this.cellData.filter_value.to = null;
      this.inputFrom = null;
      this.inputTo = null;
    }
    // document.addEventListener('keyup', this.onKeyUp);
  },
  methods: {
    // onKeyUp(event) {
    //   if (event.key === 'Enter') {
    //     // this.$refs.applyButton.$el.click();
    //   }
    // },
    applyEmit() {
      if ((this.cellData.filter_value.from != "" && this.cellData.filter_value.to != "") && (this.cellData.filter_value.from > 0 && this.cellData.filter_value.to > 0) && this.cellData.filter_value.from > this.cellData.filter_value.to) {
        let notification = notificationHandler(
          `The 'From' value should be less than or equal to the 'To' value.`,
          true,
          "warning"
        );
        this.$store.commit("SET_DASHBOARD_FILTERS_POPUP_ERROR", notification);
        setTimeout(() => {
          let notification = notificationHandler(
            `The 'From' value should be less than or equal to the 'To' value.`,
            false,
            "warning"
          );
          this.$store.commit("SET_DASHBOARD_FILTERS_POPUP_ERROR", notification);
        }, 4000);
        return;
      }
      this.cellData.is_apply = true;
      const filterData = filter_object_generator_from_cell_data(this.cellData, 'filter_value')
      this.$emit('filterEmitFunc', filterData?.data, filterData?.fieldName, filterData?.parentFieldName, filterData?.subParentFieldName, filterData?.hasAnyParentField, filterData?.hasAnySubParentField);
      this.$nextTick(() => {
        this.enableDragScroll();
      })
    },
    onCancelClick() {
      this.cellData.filter_value.from = null;
      this.cellData.filter_value.to = null;
      this.inputFrom = null;
      this.inputTo = null;
      this.cellData.is_apply = false;
      const filterData = filter_object_generator_from_cell_data(this.cellData, 'filter_value')
      this.$emit('filterEmitFunc', filterData?.data, filterData?.fieldName, filterData?.parentFieldName, filterData?.subParentFieldName, filterData?.hasAnyParentField, filterData?.hasAnySubParentField);
      this.onCancel(this.index)
    },
    handleFrom(event) {
      if (event.target.value == '') {
        this.cellData.filter_value.from = null;
      }
      else {
        this.handleDebounce();
      }
    },
    handleTo(event) {
      if (event.target.value == '') {
        this.cellData.filter_value.to = null;
      }
      else {
        this.handleDebounce();
      }
    },
    handleDebounce() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.applyEmit();
      }, 700);
    },
    handleEnter() {
      this.cellData.is_filter_visible = false
      return
    }
  },
};

</script>