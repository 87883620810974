<AppSnackBar :message="notification.message" :notificationType="notification.type" v-if="notification.isNotify">
</AppSnackBar>
<AppGlobalLoader :loaderColor="'solid'" :isFullpage="true" v-if="isLoadingCreateUpdate || isAsxLoading || isRicLoading || isRkdLoading " />
<div v-if="!isLoadingCreateUpdate">
    <div class="add-company-header-inner d-flex justify-content-between">
        <kbutton :fill-mode="'flat'" :icon="'k-icon k-i-arrow-chevron-left'" :class="'py-0 font-20 font-weight-400'"
            @click="gotoPrev">{{buttonName=="Create"?"New Company":"Edit Company"}}
        </kbutton>
        <div>
            <kbutton :fill-mode="'flat'" @click="cancel" :class="'me-2'">Cancel</kbutton>
            <kbutton :disabled="isErrorInAsxCode" :theme-color="'primary'" :class="''" @click="saveOrUpdate">
                {{buttonName}}</kbutton>
        </div>
    </div>

    <div class="k-overflow-y-auto max-h-100-130">
        <div class="add-company-form-wrap">
            <div class="row gx-4">
                <div class="col-md-12">
                    <div class="file-uploader-custom d-inline-flex mb-32">
                        <div v-if="!tempPhotoPath || !model.image_path"
                            class="custom-uploader-icon-box radius-100 d-inline-flex justify-content-center align-items-center">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M28 8H24L20 4H12L8 8H4C2.9 8 2 8.9 2 10V28C2 29.1 2.9 30 4 30H28C29.1 30 30 29.1 30 28V10C30 8.9 29.1 8 28 8ZM16 26C12.1313 26 9 22.8687 9 19C9 15.1313 12.1313 12 16 12C19.8687 12 23 15.1313 23 19C23 22.8687 19.8687 26 16 26ZM16 23C18.2062 23 20 21.2062 20 19C20 16.7937 18.2062 15 16 15C13.7937 15 12 16.7937 12 19C12 21.2062 13.7937 23 16 23Z"
                                    fill="#DEE2E6" />
                            </svg>
                        </div>
                        <img v-if="tempPhotoPath && model.image_path" :src="tempPhotoPath" />
                        <input v-if="!tempPhotoPath && !model.image_path" type="file" title="Select a file" @change="handleCompanyLogoUpload"
                            accept="image/png, image/jpeg" />
                        <div v-if="tempPhotoPath && model.image_path" class="info-action-box pl-23">
                            <p class="font-14 line-h-20 gray-color-700 mb-0">{{model.image_name}}</p>
                            <p class="font-12 line-h-20 color-gray-600 mb-10">{{model.image_size}}</p>
                            <div class="d-flex">
                                <label for="edit-image">
                                    <div class="edit-buton-box position-relative mr-8">
                                        <input type="file" accept="image/png, image/jpeg" id="edit-image" :class="'hidden'" @change="handleCompanyLogoUpload" />
                                        <kbutton :class="'h-38'" :size="'medium'" :icon="'edit'"></kbutton>
                                    </div>
                                </label>
                                <kbutton :class="'h-38 mr-8'" :size="'medium'" :icon="'delete'" @click="handleDeleteImage"></kbutton>
                            </div>
                        </div>
                    </div>
                    <span class="error-msg">{{this.errorMessage['logo']}}</span>
                </div>

                <div class="col-md-3">
                    <div class="postion-relative">
                        <label class="w-100">ASX Code</label>
                        <k-input :class="'h-38'" :placeholder="'-'" v-model="model.code" :maxLength="'8'"
                            @input="onChnageAsxCode" @keyup="this.errorMessage['code']=''" />
                        <span class="error-msg">{{this.errorMessage['code']}}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="postion-relative">
                        <label class="w-100">Name</label>
                        <!-- <AppGlobalLoader :loaderColor="'solid'" v-if="isAsxLoading" /> -->
                        <k-input :class="'h-38'" :placeholder="'-'" v-model="model.name"
                            @keyup="this.errorMessage['name']=''" :disabled="true">
                        </k-input>
                        <span class="error-msg">{{this.errorMessage['name']}}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="postion-relative">
                        <label class="w-100">Sector</label>
                        <div v-if="sectorData" class="dropdown-tree-wrap min-h-38 multiselect-drop-icon">
                            <multiselect :class="'min-h-38 py-0 custom-multisilect'" :style="{  }"
                            :data-items="sectorDataNew" :value="model.sector" placeholder="Select" @change="sectorOnchange"
                            :filterable="true" @filterchange="filterSectorChange" :text-field="'name'" :autoClose="true" :data-item-key="'id'">
                        </multiselect>
                        <span class="error-msg">{{this.errorMessage['sector']}}</span>
                    </div>
                </div>
            </div>                
            <div class="col-md-2">
                <div class="postion-relative">
                    <label class="w-100">Primary Sector</label>
                    <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="model.market_map"
                    :data-items="marketMapValue" :text-field="'name'" @filterchange="filterChangeMarketMap"
                    :data-item-key="'id'" :filterable="false" @change="handleMarketMap" :default-item="{id: 0, name: 'Please select..'}">
                </dropdownlist>
                <span class="error-msg">{{this.errorMessage['market_map']}}</span>
            </div>
        </div>
        <div class="col-md-3 offset-md-3">
            <div class="postion-relative">
                <!-- <label class="w-100">Name</label> -->
                <!-- <AppGlobalLoader :loaderColor="'solid'" v-if="isAsxLoading" /> -->
                <k-input :class="'h-38'" :placeholder="'-'" v-model="model.new_name"
                    @keyup="this.errorMessage['new_name']=''" @input="handleNewName" >
                </k-input>
                <span class="error-msg">{{this.errorMessage['new_name']}}</span>
            </div>
        </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="postion-relative">
                                <label class="w-100">Cash & Debt Method</label>
                                <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="model.method"
                                    :data-items="cash_and_debt_items" :text-field="'title'"
                                    :data-item-key="'id'" :filterable="false" @change="handleCashAndDebt">
                                </dropdownlist>
                                <!-- <span class="error-msg">{{this.errorMessage['market_map']}}</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="postion-relative">
                        <label class="w-100">Cash</label>
                        <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" v-model="model.cash" :disabled="model?.method?.name == 'RKD'"
                            @change="this.errorMessage['cash']=''" />
                        <span class="error-msg">{{this.errorMessage['cash']}}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="postion-relative">
                        <label class="w-100">Debt</label>
                        <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" v-model="model.debt" :disabled="model?.method?.name == 'RKD'"
                            @change="this.errorMessage['debt']=''" />
                        <span class="error-msg">{{this.errorMessage['debt']}}</span>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="postion-relative">
                        <label class="w-100">Currency</label>
                        <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="defaultItemCurrency"
                        :data-items="currencyItem" :text-field="'text'" @filterchange="filterChangeCurrency" :disabled="model?.method?.name == 'RKD'"
                        :data-item-key="'value'" :filterable="true" @change="handleCurrency"> 
                    </dropdownlist>
                    <span class="error-msg">{{this.errorMessage['currency_type']}}</span>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="postion-relative">
                        <label class="w-100">As of date</label>
                        <datePicker :format="'dd-MM-yyyy'" :class="'h-38 w-100 mb-24'"
                            :style="{ width: '230px' , minWidth: '18%' }" placeholder="DD-MM-YYYY" :disabled="model?.method?.name == 'RKD'"
                            :value="model.as_of_date?new Date(model.as_of_date): null" @change="onDateChange($event)">
                        </datePicker>
                        <span class="error-msg">{{this.errorMessage['as_of_date']}}</span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="postion-relative mb-24">
                        <label class="w-100">Company Overview</label>
                        <textarea @keyup="this.errorMessage['company_overview']=''" class="w-100 min-h-80" id="w3review"
                            name="w3review" v-model="model.company_overview" rows="4"></textarea>
                        <span
                            class="error-msg position-relative w-100 d-block">{{this.errorMessage['company_overview']}}</span>
                    </div>
                </div>
                <div class="col-md-12" v-if="showNewOverview">
                    <div class="postion-relative mb-46">
                        <label class="w-100">New Company Overview</label>
                        <textarea :disabled="true" @keyup="this.errorMessage['company_overview']=''" class="w-100 min-h-80 enable_highlight_and_copy" id="w3review"
                            name="w3review" v-model="model.new_company_overview" rows="4"></textarea>
                        <span
                            class="error-msg position-relative w-100 d-block">{{this.errorMessage['new_company_overview']}}</span>
                            <div class="mt-2">
                                <kbutton :theme-color="'primary'" :class="'font-20 font-weight-400'" @click="handleOverviewApprove">Approve</kbutton>
                                <kbutton :class="'ms-3 font-20 font-weight-400'" @click="toggleOverviewRejectDialog">Reject</kbutton>
                            </div>
                    </div>
                </div>
                <div class="col-md-12"><hr></div>
                <div class="col-md-12" v-if="buttonName == 'Update'">
                    <label class="w-100">Shares Method</label>
                    <dropdownlist :style="{ width: '230px' }" :value="model.soi_method[0]" :data-items="viewTypes" @change="handleMethod" :text-field="'title'" :class="'h-32 mb-24'" :data-item-key="'id'"></dropdownlist>
                    <div v-if="model.soi_method[0].name == 'fundamentals'">
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Primary RIC</label>
                                <k-input :class="'h-38'" @input="primaryRicChange" v-model="model.primary_ric" @keyup="this.errorMessage['ric']=''" ></k-input>
                                <span class="error-msg">{{this.errorMessage['ric']}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Fundamentals Shares</label>
                                <k-input :class="'h-38'" :disabled="true" v-model="model.fundamentals_shares" ></k-input>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Unquoted Shares</label>
                                <numerictextbox :class="'h-38'" type="number" :disabled="true" v-model="model.unquoted_shares" ></numerictextbox>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">CDI Ratio</label>
                                <div class="d-flex justify-content-start align-items-center">
                                    <k-input :class="'h-38 mb-0 w-66'" type="number" v-model="model.cdi_ratio" @change="handleCdiRatio" ></k-input>
                                    <label class="ps-3">:1</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div v-if="model.include_quoted_shares" class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Quoted Shares</label>
                                <numerictextbox :class="'h-38'" :disabled="true" v-model="model.quoted_shares" ></numerictextbox>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="postion-relative pt-4 mt-2">
                                <k-checkbox v-model="model.include_quoted_shares" @change="quoteCheck" :class="'color-primary checkbox-label-font-15 d-block mb-2'" :label="'Include Quoted Shares'" />
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Shares on Issue</label>
                                <numerictextbox :class="'h-38'" :disabled="true" v-model="model.shares_on_issue" ></numerictextbox>
                            </div>
                        </div>
                    </div>
                </div>
                    <div v-if="model.soi_method[0].name == 'quotes'">
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Quoted Shares</label>
                                <numerictextbox :class="'h-38'" type="number" :disabled="true" v-model="model.quoted_shares" ></numerictextbox>
                            </div>
                        </div>
                        <div class="col-lg-6" v-if="showNewQuotedShares">
                            <div class="postion-relative">
                                <label class="w-100">New Quoted Shares</label>
                                <div class="d-flex justify-content-start">
                                    <numerictextbox :class="'h-38'" type="number" :disabled="true" v-model="model.new_quoted_shares" ></numerictextbox>
                                    <kbutton :theme-color="'primary'" class="h-38 ms-4 px-4" @click="handleApprove">Approve</kbutton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Unquoted Shares</label>
                                <numerictextbox :class="'h-38'" type="number" v-model="model.unquoted_shares" @change="handleQuoteUnquotedShareChange" ></numerictextbox>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="postion-relative mb-24">
                                <label class="w-100">CDI Ratio (if applicable)</label>
                                <div class="d-flex justify-content-start align-items-center">
                                    <k-input :class="'h-38 mb-0 w-66'" type="number" v-model="model.cdi_ratio" @change="handleCdiRatio" ></k-input>
                                    <label class="ps-3">:1</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6">
                            <div class="postion-relative">
                                <label class="w-100">Shares on Issue</label>
                                <numerictextbox :class="'h-38'" type="number" :disabled="true" v-model="model.shares_on_issue"></numerictextbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-4">
                    <div class="col-lg-12">
                        <div class="postion-relative">
                            <label class="w-100">Notes</label>
                            <textarea class="w-100 min-h-80" id="" name="" :spellcheck="false" rows="4" v-model="model.company_note" ></textarea>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-md-12"><hr v-if="buttonName == 'Update'"></div>
                <div class="col-md-12">
                    <label class="w-100">Company Options</label>
                    <div class="row mb-13">
                        <div class="campaign-create-input-wrap">
                            <div class="d-flex flex-nowrap w-100">
                                <div class="company-options-table w-100">
                                    <div class="co-row d-flex justify-content-between align-items-stretch">
                                        <div class="co-col p-2">
                                            <span class="font-15 line-h-1_4 font-weight-500">Option Code</span>
                                        </div>
                                        <div class="co-col p-2">
                                            <span class="font-15 line-h-1_4 font-weight-500">Exercise Price</span>
                                        </div>
                                        <div class="co-col p-2">
                                            <span class="font-15 line-h-1_4 font-weight-500">Expiry Date</span>
                                        </div>
                                        <div class="co-col p-2 w-48 min-w-48 max-w-48">
                                            <span class="font-15 line-h-1_4 font-weight-500">&shy;</span>
                                        </div>
                                    </div>
                                    <div v-for="(item, index) in model.company_options" :key="item">
                                        <companyOverViewTable :lengthOfOptions="model.company_options?.length"
                                            :index="index" :onSync="onSyncQuantitys" :value="item"
                                            @QuantityCloseHandler="QuantityCloseHandler"
                                            :errormsg="erroCompany_options" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <kbutton @click="addQuantityFieldHanlder" :class="'h-38 mb-16'" :size="'medium'" :icon="''">Add
                        Options
                    </kbutton>
                </div>
                <div>
                    <label class="w-100">Previous Company Names</label>
                    <div class="row mb-13">
                        <div class="campaign-create-input-wrap">
                            <div class="d-flex flex-nowrap w-100">
                                <div class="company-options-table w-100">
                                    <div class="co-row d-flex justify-content-between align-items-stretch">
                                        <div class="co-col p-2">
                                            <span class="font-15 line-h-1_4 font-weight-500">Previous ASX Code</span>
                                        </div>
                                        <div class="co-col p-2">
                                            <span class="font-15 line-h-1_4 font-weight-500">Previous Company
                                                Name</span>
                                        </div>
                                        <div class="co-col p-2">
                                            <span class="font-15 line-h-1_4 font-weight-500">Date Of Change</span>
                                        </div>
                                        <div class="co-col p-2 w-48 min-w-48 max-w-48">
                                            <span class="font-15 line-h-1_4 font-weight-500">&shy;</span>
                                        </div>
                                    </div>
                                    <div v-for="(item, index) in model.company_Previous_names" :key="item">
                                        <previousNameOptions :lengthOfOptions="model.company_Previous_names?.length"
                                            :index="index" :onSync="onSyncPreviousName" :value="item"
                                            @QuantityCloseHandler="previousNameCloseHandler"
                                            :errormsg="erroCompanyName_options" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <kbutton @click="addPreviousFieldHanlder" :class="'h-38 mb-16'" :size="'medium'" :icon="''">Add
                        Previous Company Name
                    </kbutton>
                </div>
            </div>
        </div>
    </div>
</div>
<deleteCompanyOptionsModal :companyId="model?.id" :deleteCompanyOptionsId="deleteCompanyOptionsId">
</deleteCompanyOptionsModal>

<deletePreviousCompanyModal :companyId="model?.id" :deletePreviousNameId="deletePreviousNameId">
</deletePreviousCompanyModal>

<k-dialog v-if="isDialogVisible" @close="toggleDialog">
    <p class="m-4">Are you sure you want to update quoted shares?</p>
    <dialog-actions-bar>
      <kbutton @click="toggleDialog">No</kbutton>
      <kbutton @click="handleApproveFromModal">Yes</kbutton>
    </dialog-actions-bar>
  </k-dialog>
<k-dialog v-if="isOverviewDialogVisible" @close="toggleOverviewDialog">
    <p class="m-4">Are you sure you want to approve?</p>
    <dialog-actions-bar>
      <kbutton @click="toggleOverviewDialog">No</kbutton>
      <kbutton @click="handleApproveOverviewFromModal">Yes</kbutton>
    </dialog-actions-bar>
  </k-dialog>
<k-dialog v-if="isRejectDialogVisible" @close="toggleOverviewRejectDialog">
    <p class="m-4">Are you sure you want to reject?</p>
    <dialog-actions-bar>
      <kbutton @click="toggleOverviewRejectDialog">No</kbutton>
      <kbutton @click="handleRejectOverviewFromModal">Yes</kbutton>
    </dialog-actions-bar>
  </k-dialog>