<div class="position-relative">
    <div ref="button" @click="togglePopup" class="d-flex align-items-center project-filter-btn cursor-pointer me-2"
        :title="'Only show companies that have at least one project which meets the nominated criteria'">
        <button class="filter-btn-icon focus-shadow-none me-1"
            :style="isProjectFilterClearIcon ? 'background-color: #F5D2CB;' : ''">
            <svg v-if="isProjectFilterClearIcon" width="14" height="15" viewBox="0 0 14 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2.48438 2L7.74062 7.25625L7 8V13L5 15V8L0 3V2H2.48438Z" fill="" />
                <path d="M9.8625 5.1375L12 3V2H6.72813L4.72813 0L4 0.6875L13.3125 10L14 9.27188L9.8625 5.1375Z"
                    fill="" />
            </svg>

            <svg v-else width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0V1L5 6V13L7 11V6L12 1V0H0Z" fill="" />
            </svg>
        </button>
        Project Filter
    </div>

    <div v-if="popupShow" class="popup project-filter-popup-container bg-white position-absolute" ref="popup">
        <div class="custom-table">
            <div class="custom-table-row">
                <div class="custom-table-col min-w-150">
                    <p class="mb-0">Commodity</p>
                </div>
                <div class="custom-table-col">
                    <DropDownList :popup-settings="popupSettings" :style="{ width: '333px'}" :value="defaultItemSector"
                        :data-items="sectorDropdownData" @focus="onFocusSector" :text-field="'name'"
                        :class="'h-32 w-242'" @filterchange="filterChangeSector" :data-item-key="'id'"
                        :filterable="true" @change="handleDropdownSelect($event,'defaultItemSector')">
                    </DropDownList>

                    <div class="d-flex align-items-center gap-3 mt-2 pt-1">
                        <label v-for="radio in reportRadioOne" :key="radio.key"
                            class="radio-container last-of-type:mb-0">{{ radio.label }}
                            <input @change="handleRadioChange" type="radio" :id="radio.key" :value="radio.label"
                                :name="'radioGroup'" v-model="radio_filter" />
                            <span class="radiomark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="custom-table-row">
                <div class="custom-table-col min-w-150">
                    <p class="mb-0">Country</p>
                </div>
                <div class="custom-table-col">
                    <DropDownList :popup-settings="popupSettings" :style="{ width: '333px'}" :value="defaultItemCountry"
                        :data-items="countryList" :text-field="'title'" :class="'h-32 w-242'"
                        @filterchange="filterChangeCountry" :data-item-key="'id'" :filterable="true"
                        @change="handleDropdownSelect($event,'defaultItemCountry')">
                    </DropDownList>
                </div>
            </div>
            <div class="custom-table-row">
                <div class="custom-table-col min-w-150">
                    <p class="mb-0">State</p>
                </div>
                <div class="custom-table-col">
                    <DropDownList :popup-settings="popupSettings" :style="{ width: '333px'}" :value="defaultItemState"
                        :data-items="stateList" :text-field="'title'" :class="'h-32 w-242'"
                        @filterchange="filterChangeState" :data-item-key="'id'" :filterable="true"
                        @change="handleDropdownSelect($event,'defaultItemState')">
                    </DropDownList>
                </div>
            </div>
            <div class="custom-table-row">
                <div class="custom-table-col min-w-150">
                    <p class="mb-0">Stage</p>
                </div>
                <div class="custom-table-col">
                    <DropDownList :popup-settings="popupSettings" :style="{ width: '333px'}" :value="defaultItemStage"
                        :data-items="stageList" :text-field="'title'" :class="'h-32 w-242'"
                        @filterchange="filterChangeStage" :data-item-key="'id'" :filterable="true"
                        @change="handleDropdownSelect($event,'defaultItemStage')">
                    </DropDownList>
                </div>
            </div>
        </div>
        <footer class="d-flex justify-content-end py-2 mt-2">
            <kbutton @click="resetFunc" :size="'small'" :theme-color="'base'" :fill-mode="'solid'"
                :class="'focus-shadow-none'" :icon="'k-icon k-i-reset-sm'"></kbutton>
        </footer>
    </div>
</div>