<template>
    <section class="login-page-wrap">
        <div class="row m-0 h-100">
            <div class="col-md-6 p-0">
                <div class="login-logo-part">
                    <img src="../../../assets/images/logo.png" alt="logo" />
                </div>
            </div>

            <div class="col-md-6 p-0">
                <div class="login-form-wrap">
                    <div class="login-inner">
                        <div class="title-block">
                            <h3 class="text-center">Reset Password</h3>
                            <p>Continue to MiningBull</p>
                        </div>
                        <form>
                            <div class="k-form-field view-pass-icon with-label">
                                <label>Enter New Password</label>
                                <k-input :type="passIcon ? 'text' : 'password'" :placeholder="'Password'"
                                v-model="model.password" />
                                <kbutton type="button" @click="showPassHandler" :class="'view-pass p-0 bg-transparent border-0'">
                                    <img src="../../../assets/images/icon/preview.svg" alt="View Password" class="" />
                                </kbutton>
                            </div>
                            <div class="password-hint p-2 mb-24">
                                <p class="mb-0 red-color font-13">Password must be:</p>
                                <ul class="mb-0 red-color font-13">
                                    <li>At least 8 characters</li>
                                    <li>A mixture of both uppercase and lowercase letters</li>
                                    <li>A mixture of letters and numbers</li>
                                    <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                                </ul>
                            </div>
                            <div class="k-form-field view-pass-icon with-label">
                                <label>Re-enter Password</label>
                                <k-input :type="passIcon ? 'text' : 'password'" :placeholder="'Password'"
                                v-model="model.password" />
                                <kbutton type="button" @click="showPassHandler" :class="'view-pass p-0 bg-transparent border-0'">
                                    <img src="../../../assets/images/icon/preview.svg" alt="View Password" class="" />
                                </kbutton>
                            </div>
                            <span class="red-color" v-if="errorMsg!=null">{{errorMsg}}</span>
                            <kbutton type="submit" :theme-color="'primary'" :class="'w-100 mt-12'">
                                <AppGlobalLoader :loaderColor="'solid'" v-if="isLoading" />
                                {{isLoading? "":"Submit"}}
                            </kbutton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
    .password-hint {
        border-radius: 3px;
        border: 1px solid var(--primary-opacity-primarycolor-25, rgba(235, 94, 63, 0.25));
        background: var(--primary-opacity-primarycolor-8, rgba(235, 94, 63, 0.08));
    }
</style>
<script>
    import { Input } from '@progress/kendo-vue-inputs';
    import { Checkbox } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
    export default {
        name: 'resetPassword',
        components: {
        "k-input": Input,
        kbutton: Button,
        checkbox: Checkbox,
        AppGlobalLoader
    },

    data: function () {
        return {
        model: {
            email: "",
            password: "",
        },
        passIcon: false
        };
    },

    computed: {
        isLoggedIn() {
        return this.$store.state.auth.isLoggedIn;
        },
        errorMsg() {
        return this.$store.state.auth.errorMsg;
        },
        isLoading() {
        return this.$store.state.auth.isLoading;
        }
    },

    created() {
        if (this.isLoggedIn) {
        this.$router.push("/companies");
        }
    },

    methods: {
        goToClubDashboard() {
        this.$store.commit("SET_ERROR_MESSAGE", "");
        this.$store.commit("SET_ISLOADING_AUTH", true);
        if (window.location.pathname == '/admin') {
            this.$store.dispatch("getTokenForAdmin", this.model);
        } else{
            this.$store.dispatch("getTokenUser", this.model);
        }
        },

        showPassHandler(e) {
        this.passIcon = !this.passIcon;
        }
    },
    };
</script>