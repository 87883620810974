import { DELETE, GET, POST, PUT } from "@/service";

export const getNewProjectCreateView = () => {
    return GET(`api/v1/project/create-view`)
};
export const getProjectView = (payload: any) => {
    return GET(`api/v1/company/${payload.companyId}/project/${payload.projectId}/show`)
};

export const getProjectEdit = (payload: any) => {
    return GET(`api/v1/company/${payload.companyId}/project/${payload.projectId}/edit-view`)
};

export const getCommoditiesTableResource = (payload: any) => {
    return POST(`api/v1/commodities/resource`, payload)
};

export const projectCreate = (payload: any) => {
    return POST(`api/v1/company/${payload.companyId}/project/create`, payload);
};

export const deleteProject = (payload: any) => {
    return DELETE(`api/v1/company/${payload.companyId}/project/${payload.deleteProjectId}/delete`);
};

export const updateProject = (payload: any) => {
    return PUT(`api/v1/company/${payload.companyId}/project/${payload.uuid}/update`, payload);
};