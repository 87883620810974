<template src="./projectsTabContent.html" />

<script>
import { Checkbox } from "@progress/kendo-vue-inputs";

export default {
    name: 'ProjectsTabContent',
    components:{        
        "k-checkbox": Checkbox,
    },
    props: {
        configurationList: {
            type: Array,
            default: [],
        },
    },
    data(){
        return{            
            is_selected: false,
        }
    },
    methods:{
        onChnageCheckbox(event) {
            this.$emit('changeSelection', event)
        },
    }
}
</script>