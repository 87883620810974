<template src="./headerDropDownFilterPopupTemplate.html"></template>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { filter_object_generator_from_cell_data } from "@/helpers/utility";
import { getCountryandState } from "@/services/dashboard.service";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
export default {
  name: "headerDropDownFilterPopupTemplate",
  components: {
    DropDownButton,
    Popup,
    "k-button": Button,
    "k-input": Input,
    "k-checkbox": Checkbox,
    AppGlobalLoader,
  },
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
    anchorTag: {
      type: String,
      default: "showFilter",
    },
    onCancel: {
      type: Function,
      default: () => { },
    },
    index: {
      type: Number,
      default: 0,
    },
    cellData: {
      type: Object,
      default: () => { },
    },
    checkBoxDatas: {
      type: Object,
      default: () => { },
    },
    countryData: {
      type: Object,
      default: () => { },
    },
    commodities: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: null,
    },
    sortFilterObject: {
      type: Object,
      default: () => { }
    },
    isCommodities: {
      type: Boolean,
      default: false,
    },
    isDisplayAll: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return {
      preservedState: {},
      preservedStateAsArray: [],
      isLoading: false,
      searchQuery: ''
    };
  },
  computed: {
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
    filteredList() {
      if (!this.searchQuery) {
        return this.cellData?.drop_down_value;
      }
      const query = this.searchQuery.toLowerCase();
      return this.cellData?.drop_down_value.filter(item => item.title.toLowerCase().includes(query));
    },
  },
  mounted() {
    this.preservedState = { ...this?.cellData?.drop_down_value };
    this.preservedStateAsArray = [...this?.cellData?.drop_down_value];
    var countryDataValue = Object.values(this.countryData)?.find(
      (obj) => obj?.country
    );
    if (
      this.cellData.headerCell == "state_template" ||
      this.cellData.headerCell == "country_template" ||
      this.cellData.headerCell == "status_template" ||
      this.cellData.headerCell == "stage_template" ||
      this.cellData.headerCell == "method_template"
    ) {
      this.isLoading = true;
      var payload = {
        commodities: this.commodities.alias,
        view: this.view.name,
        is_commodities: this.isCommodities,
        is_display_all: this.isDisplayAll,
        filters: this.countryData,
        sorting: this.sortFilterObject
      };
      getCountryandState(payload)
        .then((response) => {
          if (this.cellData.headerCell == "state_template") {
            this.cellData.drop_down_value = response.data.result.state;
            var mainData = this.cellData;
            const filteredState = mainData.drop_down_value.filter((state) => {
              const country = countryDataValue.country?.drop_down_value.find(
                (c) => c.country_code === state.country_code
              );
              return country && country.is_checked && state.is_checked;
            });
            this.cellData.drop_down_value = filteredState;
          }
          if (this.cellData.headerCell == "country_template") {
            this.cellData.drop_down_value = response.data.result.country;
          }
          if (this.cellData.headerCell == "status_template") {
            this.cellData.drop_down_value = response.data.result.status;
          }
          if (this.cellData.headerCell == "stage_template") {
            this.cellData.drop_down_value = response.data.result.stage;
          }
          if (this.cellData.headerCell == 'method_template') {
            this.cellData.drop_down_value = response.data.result.method;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
        });

    }
    document.addEventListener('keyup', this.onKeyUp);
  },
  beforeUnmount() {
    this.cellData.drop_down_value = this.preservedStateAsArray;
  },
  methods: {
    onKeyUp(event) {
      if (event.key === 'Enter') {
        this.onCancel(this.index);
      }
    },
    applyEmit() {
      if (this.cellData.headerCell == "country_template") {
        if (
          this.cellData.drop_down_value.every((obj) => obj.is_checked === false)
        ) {
          this.cellData.drop_down_value = [
            {
              id: 0,
              country_code: "Empt",
              country_name: "Empty",
              is_checked: true,
              title: "Empty",
            },
          ];
        }
      }
      if (this.cellData.headerCell == "state_template") {
        if (
          this.cellData.drop_down_value.every((obj) => obj.is_checked === false)
        ) {
          this.cellData.drop_down_value = [
            {
              id: 0,
              country_code: "Empt",
              state_name: "Empty",
              is_checked: true,
              title: "Empty",
            },
          ];
        }
      }
      const filterData = filter_object_generator_from_cell_data(
        this.cellData,
        "drop_down_value"
      );

      this.$emit(
        "filterEmitFunc",
        filterData?.data,
        filterData?.fieldName,
        filterData?.parentFieldName,
        filterData?.subParentFieldName,
        filterData?.hasAnyParentField,
        filterData?.hasAnySubParentField
      );

      this.$emit('dropDownFilterShowHide', true, this.cellData.field);
    },
    handleChange(e, item) {
      item.is_checked = e.value;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.applyEmit();
      }, 100);
    },
    clearList() {
      this.cellData.drop_down_value.forEach((item) => {
        item.is_checked = false;
      });
    },
    removeItemFromFilter() {
      const newObj = { ...this.countryData }
      if (Object.keys(this.countryData[this.cellData.parent_field_name]).includes(this.cellData.field)) {
        if (this.countryData[this.cellData.parent_field_name].hasOwnProperty(this.cellData.field)) {
          delete this.countryData[this.cellData.parent_field_name][this.cellData.field]
        }
      }
    },
    onCancelClick() {
      this.cellData.drop_down_value = this.preservedStateAsArray;
      this.cellData.drop_down_value.forEach((state) => {
        state.is_checked = true;
      });
      const filterData = filter_object_generator_from_cell_data(
        this.cellData,
        "drop_down_value"
      );
      if (this.cellData.field == 'state' || this.cellData.field == 'method') {
        this.$emit("updateColumnFilterObject", this.cellData.parent_field_name, this.cellData.field)
      } else {
        this.$emit(
          "filterEmitFunc",
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );
      }
      this.$emit('dropDownFilterShowHide', false, this.cellData.field);
      this.onCancel(this.index);
    },
  },
};
</script>
