export default class filterShowHideLithiumModel {
    day_template = false;
    cf_net_change_template = false;
    soi_template = false;
    market_cap_template = false;
    cash_template = false;
    debt_template = false;
    project_name_template = false;
    country_template = false;
    state_template = false;
    current_o_ship_template = false;
    stage_template = false;
    mineral_hard_tonnes_template = false;
    mineral_hard_li_template = false;
    mineral_hard_cut_off_grade_template = false;
    mineral_hard_contained_li2_template = false;
    mineral_clay_tonnes_template = false;
    mineral_clay_li_template = false;
    mineral_clay_cut_off_grade_template = false;
    mineral_clay_contained_li2_template = false;
    mineral_brine_tonnes_template = false;
    mineral_brine_avg_li_mg_l_template = false;
    mineral_brine_cut_off_grade_template = false;
    mineral_brine_contained_li2_template = false;
    mineral_lce_kt_template = false;
    ore_hard_tonnes_template = false;
    ore_hard_li_template = false;
    ore_hard_cut_off_grade_template = false;
    ore_hard_contained_li2_template = false;
    ore_clay_tonnes_template = false;
    ore_clay_li_template = false;
    ore_clay_cut_off_grade_template = false;
    ore_clay_contained_li2_template = false;
    ore_brine_tonnes_template = false;
    ore_brine_avg_li_mg_l_template = false;
    ore_brine_cut_off_grade_template = false;
    ore_brine_contained_li2_template = false;
    ore_lce_kt_template = false;
}
  