<template src="./deleteProject.html"></template>
  
<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";
export default {
    name: 'deleteProject',
    components: {
        "k-dialog": Dialog,
        "dialog-actions-bar": DialogActionsBar,
        kbutton: Button,
        "k-input": Input,
        modal,
    },
    props: {
        deleteProjectId: {
            type: Number,
            required: true,
        },
        isReload: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modalVisible() {
            return this.$store.state.newProject.isProjectdeleteModalOpen;
        },
    },
    data: function () {
        return {
            visibleDialog: true,
            message: ''
        };
    },
    mounted() {

    },
    methods: {
        cancelModal() {
            this.$store.commit("SET_PROJECT_DELETE_MODAL_SHOW_HIDE", false);
        },
        deleteClub() {
            let payload = {
                companyId: this.$route.params.id,
                deleteProjectId: this.deleteProjectId,
                isReload: this.isReload
            }
            this.$store.commit("SET_ISLOADING_DELETE_PROJECT", true);
            this.$store.dispatch("deleteProject", payload);
        },
        modalHandler(data) {
            this.$store.commit("SET_PROJECT_DELETE_MODAL_SHOW_HIDE", false);
        },
    },

}
</script>
  
<style></style>
  