<Popup :anchor="'showSearchFilter'" :show="isShowFilter" :popup-class="'filter-popup search-t-head-box'"
  :anchor-align="anchorAlignProperty" :popup-align="popupAlignProperty">
  <div class="search-box search-table-box-wrap">
    <k-input ref="projectSearch" :placeholder="'Search'" v-model="cellData.search_value" @input="debounceSearch()" />
    <span class="close-icon-t-head-s-box" v-if="cellData.search_value.length" @click="clearSearchData"><span
        class="k-icon k-i-close"></span></span>
    <span class="icon">
      <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.7063 20H17.4156L21 23.5844L19.5844 25L16 21.4156V20.7063L15.1656 19.8719C14.3031 20.5781 13.2 21 12 21C9.2375 21 7 18.7625 7 16C7 13.2375 9.2375 11 12 11C14.7625 11 17 13.2375 17 16C17 17.2 16.5781 18.3031 15.8719 19.1656L16.7063 20ZM12 12C9.79063 12 8 13.7906 8 16C8 18.2094 9.79063 20 12 20C14.2094 20 16 18.2094 16 16C16 13.7906 14.2094 12 12 12Z"
          fill="#6C757D" />
      </svg>
    </span>
  </div>
</Popup>