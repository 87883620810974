import { DELETE, GET, POST, PUT } from "@/service";

export const getStateListTenementsListService = (payload: any) => {
  return GET(`api/v1/tenement/state-list${payload ? "/" + payload : ""}`);
};

export const getTenementListService = (payload: any) => {
  return POST(`api/v1/project/${payload?.project_uuid}/tenement/list`, payload);
};

export const getNewProjectTenementListService = (payload: any) => {
  return POST(`api/v1/new-project/tenement/list`, payload);
};

export const getSelectedProjectTenementListService = (payload: any) => {
  return POST(
    `api/v1/project/${payload.projectId}/tenement/selected-list `,
    payload
  );
};

export const updateTenementService = (payload: any) => {
  return POST(
    `api/v1/project/${payload?.project_uuid}/tenement/update`,
    payload
  );
};

export const testMapDataService = (payload: any) => {
  return GET(`api/v1/test-map-data`);
};

export const getTenementWithinPolygonDataService = (payload: any) => {
  return POST(`api/v1/get-tenements-within-polygon`, payload);
};

export const getPlaceMarksWithinDataService = (payload: any) => {
  return POST(`api/v1/get-placemarks-within-polygon`, payload);
};

export const getProjectforMapsService = (payload: any) => {
  return POST(`api/v1/get-projects-for-project-maps`, payload);
};

export const getTenementsForProjectService = (payload: any) => {
  return POST(`api/v1/get-project-center-point`, payload);
};
export const getMarkerProjectByProjectUuidService = (payload: any) => {
  return POST(`api/v1/get-project-by-project-uuid`, payload);
};

export const getProjectByProjectUuidService = (payload: any) => {
  return POST(`api/v1/get-project-by-tenement-uuid`, payload);
};
