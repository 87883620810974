<template src="./AppLightBox.html"></template>
<style lang="scss">
@import "./AppLightBox.scss";
</style>

<script>
export default {
data() {
  return {
    showLightbox: false,
  };
},
props: {
    imageSrc : {
        type: String,
        default: 'https://i.imgur.com/bdXtzl5.jpeg'
    },
    trial: {
      type: Number,
      default: null
    }
},
methods: {
  openLightbox() {
    // if(this.trial == 1) return
    this.showLightbox = true;
  },
  closeLightbox() {
    this.showLightbox = false;
  },
},
};
</script>

    