import { DELETE, GET, POST, PUT } from "@/service";

export const getcolumnConfigurationListService = (payload: any) => {
  return GET(`api/v1/main-board/column-configuration`, {
    params: payload
  });
};

export const saveColumnConfigurationListService = (payload: any) => {
  return POST(`api/v1/main-board/column-configuration`, payload);
};