<template>
    <modal :title="'Edit Placemark'" :isLoading="isLoading" :handler="true"
        :cssClass="'modal-md-2 window-content-px-20 edit-placement-modal'" @modalHandler="modalHandler">
        <template v-slot:content>
            <div class="position-relative">
                <label class="w-100">Latitude (e.g., 42°40'15"S)</label>
                <k-input v-model="model.decimalLatitude" :class="'h-38'" />
                <span v-if="validateErrorMsg?.latitude" class="error-msg">{{ validateErrorMsg?.latitude }}</span>
            </div>
            <div class="position-relative">
                <label class="w-100">Longitude (e.g., 143°38'45"E)</label>
                <k-input v-model="model.decimalLongitude" :class="'h-38'" />
                <span v-if="validateErrorMsg?.longitude" class="error-msg">{{ validateErrorMsg?.longitude }}</span>
            </div>
        </template>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <kbutton :disabled="isLoading" :theme-color="'primary'" @click="onSaveClick">Save</kbutton>
            </div>
        </template>
    </modal>
</template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";

export default {
    components: {
        "k-dialog": Dialog,
        "dialog-actions-bar": DialogActionsBar,
        kbutton: Button,
        "k-input": Input,
        modal
    },
    props: {
        propsModelValue: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            isLoading: false,
            model: {
                decimalLatitude: null,
                decimalLongitude: null
            },
            validateErrorMsg: {}
        };
    },
    mounted() {
        const { latitude, longitude } = this.propsModelValue || {};
        if (latitude) {
            this.model.decimalLatitude = this.decimalToDMS(latitude, 'latitude');
        }
        if (longitude) {
            this.model.decimalLongitude = this.decimalToDMS(longitude, 'longitude');
        }
    },
    methods: {
        modalHandler() {
            this.$emit("toggleClosePlacement");
        },
        validate() {
            const errors = {};

            let deciMalLat = this.dmsToDecimal(this.model.decimalLatitude);
            let deciMalLon = this.dmsToDecimal(this.model.decimalLongitude);

            if (!deciMalLat || !deciMalLon) {
                    this.model.decimalLatitude = null;
                    this.model.decimalLongitude = null;
                    return true;
            } else {
                if (deciMalLat !== null && (deciMalLat < -90 || deciMalLat > 90)) {
                    errors.latitude = "Latitude must be between -90 and 90.";
                }
                if (deciMalLon !== null && (deciMalLon < -180 || deciMalLon > 180)) {
                    errors.longitude = "Longitude must be between -180 and 180.";
                }
            }

            this.validateErrorMsg = errors;
            return Object.keys(errors).length === 0;
        },

        decimalToDMS(decimal, type) {
            const direction = type === 'latitude' ? (decimal >= 0 ? 'N' : 'S') : (decimal >= 0 ? 'E' : 'W');
            const absolute = Math.abs(decimal);
            const degrees = Math.floor(absolute);
            const minutesDecimal = (absolute - degrees) * 60;
            const minutes = Math.floor(minutesDecimal);
            const seconds = ((minutesDecimal - minutes) * 60).toFixed(2);
            return `${degrees}°${minutes}'${seconds}"${direction}`;
        },
        dmsToDecimal(dms) {
            const regex = /(\d+)[°]\s*(\d+)[']\s*([\d.]+)["]\s*([NSWE])/;
            const match = regex.exec(dms);
            if (match) {
                let decimal = parseFloat(match[1]) + parseFloat(match[2]) / 60 + parseFloat(match[3]) / 3600;
                if (match[4] === 'S' || match[4] === 'W') {
                    decimal = -decimal;
                }
                return decimal;
            }
            return null;
        },
        onSaveClick() {
            if (this.validate()) {
                this.$emit("onSavePlacement", {
                    latitude: this.dmsToDecimal(this.model.decimalLatitude),
                    longitude: this.dmsToDecimal(this.model.decimalLongitude)
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "./editPlacementModal.scss";
</style>
