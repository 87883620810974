
    <div class="co-row d-flex justify-content-between align-items-stretch">
        <div class="co-col p-1">
            <k-input :class="'mb-0 w-100'" ref="OptionCodeInput" placeholder="Enter Previous ASX Code" type="text" v-model="previous_asx_code" @input="inputHandler" aria-required="true"></k-input>
            <span class="error-msg position-relative w-100 d-block">{{errormsg[index]?.previous_asx_code? 'Please provide Previous ASX Code':''}}</span>
        </div>
        <!-- <div class="co-col p-1">
            <numerictextbox :class="'mb-0 w-100'" ref="QuantityInput" placeholder="Enter quantity" v-model="quantity" @input="inputHandler" aria-required="true"></numerictextbox>
            <span class="error-msg position-relative w-100 d-block">{{errormsg[index]?.quantity? 'Please provide quantity':''}}</span>
        </div> -->
        <div class="co-col p-1">
            <k-input :class="'mb-0 w-100'" ref="ExercisePriceInput" placeholder="Enter Previous Company Name" v-model="previous_company_name" @input="inputHandler" aria-required="true"></k-input>
            <span class="error-msg position-relative w-100 d-block">{{errormsg[index]?.previous_company_name? 'Please provide Previous Company Name.':''}}</span>
        </div>
        <div class="co-col p-1">
            <datePicker :format="'dd-MM-yyyy'" :class="'w-100 h-38 mb-0'" :style="{  }" :value="previous_company_date_of_change?new Date(previous_company_date_of_change): null" @change="onDateChange($event)" placeholder="DD-MM-YYYY"></datePicker>
        </div>
        <div class="co-col p-1 w-48 text-center min-w-48 max-w-48">
           <kbutton  @click="QuantityCloseHandler(index)" :class="''" :fill-mode="'clear'" :icon="'k-icon k-i-close-outline'"></kbutton>
        </div>
    </div>
