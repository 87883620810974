<AppSnackBar :message="notification.message" :notificationType="notification.type" v-if="notification.isNotify">
</AppSnackBar>
<header class="dashboard-header d-flex flex-wrap">
    <div class="d-flex py-2">
        <div class="label-with-dropdown-wrap me-2">
            <label :class="isDropdeoOpen ? 'dropdown-append-label active':'dropdown-append-label'"
                for="ddw">{{selectedView.name == "sector_view" ? 'Sector:' : 'Commodity:'}}</label>
            <dropdownlist id="ddw" :popup-settings="
            popupSettings" :value="defaultItemSector" :data-items="sectorDropdownData" @focus="onFocusSector"
                @open="onOpenCDD('commodity')" @close="onCloseCDD('commodity')" :text-field="'name'"
                :class="selectedView.name == 'sector_view' ? 'h-32 pl-45' : 'h-32'" @filterchange="filterChangeSector"
                :data-item-key="'id'" :filterable="true" @change="handleSectorDropdown">
            </dropdownlist>
        </div>
    </div>

    <div class="d-flex py-2" v-if="selectedConfigurationTab == 2">      
        <div ref="secondElement" :class="`label-with-dropdown-wrap type-dropdown type-dropdown-filter`" v-if="defaultItemSector?.name  == 'Lithium'">
          <label :disabled="radio_filter == 'Any'" :class="isLithiumTypeDropdownOpen ? 'dropdown-append-label active':'dropdown-append-label'"
            for="abc">Type:</label>
          <dropdownlist id="abc" :style="{ width: '160px' }" :popup-settings="popupSettings" :disabled="radio_filter == 'Any'" :value="defaultLithiumType" :data-items="filteredLithiumTypeData"
            @open="onOpenCDD('lithium')" @close="onCloseCDD('lithium')" :text-field="'name'"
            :class="'h-32'" @filterchange="filterChangeLithiumType"
            :data-item-key="'id'" :filterable="true" @change="handleLithiumTypeChange">
          </dropdownlist>
        </div>
    </div>

    <div class="grow"></div>
    <div class="d-flex align-items-center py-2">
        <!-- {{isChanged}} -->
        <k-button v-if="isChanged" @click="handleClear" :fill-mode="'flat'" class="h-32 me-2">Clear</k-button>
        <k-button :theme-color="'primary'" :fill-mode="'solid'" class="h-32" @click="handleSave">Save</k-button>
    </div>
</header>

<div class="dashboard-table-area column-configuration-area">
    <header class="column-configuration-header">
        <h2 class="font-19 line-h-1_5 font-weight-400 base-color mb-0">Column Configuration</h2>
    </header>
    <div class="column-configuration-container">
        <nav class="flex-none inline-tab">
            <ul class="d-flex tab-items">
                <li v-for="item in configurationTabContentList" :key="item.uuid"
                    :class="`${item.uuid == selectedConfigurationTab ? 'selected':''} tab-item`">
                    <span @click="onSelectConfigurationTab(item)"
                        :class="`${item.uuid == selectedConfigurationTab ? 'selected primary-color':'base-color'} font-weight-500 tab-item-content`">{{item.name}}</span>
                </li>
            </ul>
        </nav>

        <component v-if="!isLoading" :is="currentTabComponent" :configurationList="configurationList" @changeSelection="changeSelection" />
    </div>
</div>