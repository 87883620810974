<template>
    <section class="login-page-wrap">
        <div class="row m-0 h-100">
            <div class="col-md-6 p-0">
                <div class="login-logo-part">
                    <img src="../../../assets/images/logo.png" alt="logo" />
                </div>
            </div>

            <div class="col-md-6 p-0">
                <div class="login-form-wrap">
                    <div class="login-inner">
                        <div class="title-block">
                            <h3 class="text-center">Forgot Password?</h3>
                            <p>Continue to MiningBull</p>
                        </div>
                        <form>
                            <div class="k-form-field">
                                <label>Email Address</label>
                                <k-input :placeholder="'Email Address'" />
                            </div>
                            <span class="red-color" v-if="errorMsg != null">{{ errorMsg }}</span>
                            <kbutton type="submit" :theme-color="'primary'" :class="'w-100 mt-12'">
                                <AppGlobalLoader :loaderColor="'solid'" v-if="isLoading" />
                                {{ isLoading ? "" : "Send Email" }}
                            </kbutton>
                            <kbutton :theme-color="'primary'" :fill-mode="'outline'" :class="'w-100 mt-12 border-none'">
                                <AppGlobalLoader :loaderColor="'solid'" v-if="isLoading" />
                                {{ isLoading ? "" : "Back to Login" }}
                            </kbutton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { Input } from '@progress/kendo-vue-inputs';
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
export default {
    name: "forgetPassword",
    components: {
        "k-input": Input,
        kbutton: Button,
        checkbox: Checkbox,
        AppGlobalLoader
    },

    data: function () {
        return {
            model: {
                email: "",
                password: "",
            },
            passIcon: false
        };
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.auth.isLoggedIn;
        },
        errorMsg() {
            return this.$store.state.auth.errorMsg;
        },
        isLoading() {
            return this.$store.state.auth.isLoading;
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.$router.push("/companies");
        }
    },

    methods: {
        goToClubDashboard() {
            this.$store.commit("SET_ERROR_MESSAGE", "");
            this.$store.commit("SET_ISLOADING_AUTH", true);
            if (window.location.pathname == '/admin') {
                this.$store.dispatch("getTokenForAdmin", this.model);
            } else {
                this.$store.dispatch("getTokenUser", this.model);
            }
        },

        showPassHandler(e) {
            this.passIcon = !this.passIcon;
        }
    },
};
</script>