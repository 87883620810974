import Toastify from "toastify-js";
import router from "../../router";

type NotificationMsg = {
  type: string | null;
  Class: string | null;
  Message: string | null;
  IsShowNotification: boolean;
};

type ValidationErrors = {
  [key: string]: string;
};

type State = {
  notificationMsg: NotificationMsg;
  isLoading: boolean;
  isLoadingModal: boolean;
  isSidebarOpen: boolean;
  isAdditionalNotesLoadingModal: boolean;
  shiftDetailsDrawerVisibility: boolean;
  shiftDetailsComponentVisibility: boolean;
  isEditVendorMsaNumberModal: boolean;
  selectedShiftUuid: string | null;
  validationErrors: ValidationErrors;
  isCommentsDrawerVisibility: boolean;
  headerTitle: any;
  documentExpireDrawerVisibility: any;
  showExpireDrawerVisibility: any;
};

const state: State = {
  notificationMsg: {
    type: null,
    Class: null,
    Message: null,
    IsShowNotification: false,
  },
  isLoading: false,
  isLoadingModal: false,
  isSidebarOpen: true,
  isAdditionalNotesLoadingModal: false,
  isEditVendorMsaNumberModal: false,
  shiftDetailsDrawerVisibility: false,
  shiftDetailsComponentVisibility: false,
  selectedShiftUuid: null,
  isCommentsDrawerVisibility: false,
  validationErrors: {},
  headerTitle: 0,
  documentExpireDrawerVisibility: false,
  showExpireDrawerVisibility: false,
};

const toastify = {
  state,
  getters: {},
  mutations: {
    SET_NOTIFICATION(
      state: State,
      {
        type,
        message,
        duration,
      }: { type: string; message: string; duration: number }
    ) {
      state.notificationMsg.type = type;
      state.notificationMsg.Class = `status-${type}`;
      state.notificationMsg.Message = message;
      state.notificationMsg.IsShowNotification = true;

      setTimeout(() => {
        state.notificationMsg.IsShowNotification = false;
      }, duration);
    },
    SET_VALIDATION_ERRORS(state: State, validationErrors: ValidationErrors) {
      state.validationErrors = validationErrors;
    },
  },
  actions: {
    showNotification(
      { commit }: { commit: Function },
      {
        type,
        message,
        duration,
      }: { type: string; message: string; duration: number }
    ) {
      commit("SET_NOTIFICATION", { type, message, duration });
    },
    convertValidationArray({ commit, dispatch }: any, validationArray: any) {
      const validationErrors: ValidationErrors = {};

      for (const field in validationArray) {
        if (validationArray.hasOwnProperty(field)) {
          const errors = validationArray[field];
          validationErrors[field] = errors[0];
          dispatch("toastifyNotification", {
            text: validationErrors[field],
            duration: 5000,
            className: "error",
          });
        }
      }

      commit("SET_VALIDATION_ERRORS", validationErrors);
    },
    toastifyNotification({ commit }: any, customConfig: any) {
      const defaultConfig = {
        text: "This is a toast",
        duration: 3000,
        // destination: "https://github.com/apvarun/toastify-js",
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
        style: {},
        className: "success",
      };

      const config = { ...defaultConfig, ...customConfig };

      Toastify(config).showToast();
    },
  },
};

export default toastify;