<header class="d-flex align-items-center justify-content-between px-16 py-15 border-bottom-1-gray">
    <span class="font-14 line-h-1_4 font-weight-400 gray-color-700">Columns</span>
    <span class="font-14 line-h-1_4 font-weight-400 gray-color-700">Set as Default</span>
</header>
<div v-for="(item, index) in configurationList" :class="`py-16 ${index+1 != configurationList?.length ? 'border-bottom-1-gray' : ''}`">
    <div class="px-16">
        <h4 class="font-16 line-h-1_4 mb-2">{{item?.name}}</h4>
    </div>
    <k-checkbox v-for="(child, idx) in item?.child" v-model="child.is_selected" @change="onChnageCheckbox" :label-placement="'before'"
        :class="'checkbox-list color-primary checkbox-label-font-14 d-flex align-items-center'" :label="child?.name" />
    <!-- <k-checkbox v-model="is_selected" @change="onChnageCheckbox" :label-placement="'before'"
        :class="'checkbox-list color-primary checkbox-label-font-14 d-flex align-items-center'"
        :label="'Primary commodities'" /> -->
</div>