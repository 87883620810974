import { DELETE, GET, POST, PUT } from "@/service";

export const dashboardCompanies = () => {
  return GET(`api/v1/companies`);
};

export const getCommoditiesService = () => {
  return GET(`api/v1/main-board/primary-commodities/list`);
};

export const dashboardColumnList = (payload: any) => {
  return POST(`api/v1/main-board/column/list`, payload);
};

export const getSearchCompany = (payload: any) => {
  return POST(`api/v1/company/details`, payload);
};

export const getSelectedCommoditiesDataService = (payload: any) => {
  return POST(`api/v1/main-board/get-projects/list?page=${payload.page ? payload.page : 1}`, payload);
};

export const getCountryandState = (payload: any) => {
  return POST(`api/v1/main-board/get-country-and-state`, payload);
};

export const getNotifications = () => {
  return GET('api/v1/get-notifications');
};

export const getUserData = () => {
  return GET('api/v1/get/user');
}