<template src="./headerDropDownFilterPopupTemplateForCountry.html"></template>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { filter_object_generator_from_cell_data } from "@/helpers/utility";
import { getCountryandState } from "@/services/dashboard.service";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import CryptoJS from 'crypto-js';

export default {
  name: "headerDropDownFilterPopupTemplateForCountry",
  components: {
    DropDownButton,
    Popup,
    "k-button": Button,
    "k-input": Input,
    "k-checkbox": Checkbox,
    AppGlobalLoader,
  },
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
    anchorTag: {
      type: String,
      default: "showFilter",
    },
    onCancel: {
      type: Function,
      default: () => { },
    },
    index: {
      type: Number,
      default: 0,
    },
    cellData: {
      type: Object,
      default: () => { },
    },
    checkBoxDatas: {
      type: Object,
      default: () => { },
    },
    countryData: {
      type: Object,
      default: () => { },
    },
    commodities: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: null,
    },
    sortFilterObject: {
      type: Object,
      default: () => { }
    },
    isCommodities: {
      type: Boolean,
      default: false,
    },
    isDisplayAll: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return {
      preservedState: {},
      preservedStateAsArray: [],
      isLoading: false,
      searchQuery: '',
      dropDownValue: [],
      stateDropDownValue: [],
      stageDropDownValue: [],
      sessionModel: [],
    };
  },
  computed: {
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
    filteredList() {
      if (!this.searchQuery) {
        if (this.cellData.headerCell == "country_template" && this.hasValueFilter()) {
          if (this.isFirstClick('country')) {
            return this.dropDownValue;
          } else {
            return this.cellData?.drop_down_value;
          }
        }
        else if (this.cellData.headerCell == "state_template" && this.hasStateValueFilter()) {
          if (this.isFirstClick('state')) {
            return this.stateDropDownValue;
          } else {
            return this.cellData?.drop_down_value;
          }
        }
        else if (this.cellData.headerCell == "stage_template" && this.hasStageValueFilter()) {
          if (this.isFirstClick('stage')) {
            return this.stageDropDownValue;
          } else {
            return this.cellData?.drop_down_value;
          }
        }
        else {
          return this.cellData?.drop_down_value;
        }
      }
      const query = this.searchQuery.toLowerCase();
      return this.cellData?.drop_down_value.filter(item => item.title.toLowerCase().includes(query));
    },
  },
  mounted() {
    // const countryList = sessionStorage.getItem('sessionIdsAndModel');
    // Get the encrypted data from sessionStorage
    let encryptedData = window.sessionStorage.getItem("sessionIdsAndModel");

    if (encryptedData) {
      // Decrypt the data
      let bytes = CryptoJS.AES.decrypt(encryptedData, '1fcd35d4efe320460827f80c89e640ce');
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      let sessionIdsAndModel = decryptedData || [];
      let index = sessionIdsAndModel.findIndex(
        (obj) => obj.sessionId === window.name
      );

      this.sessionModel = sessionIdsAndModel;
      if (this.view.name == 'sector_view') {
        this.dropDownValue = sessionIdsAndModel[0]?.columnFilterObjectStore?.main_project_information?.country?.drop_down_value;
        this.stateDropDownValue = sessionIdsAndModel[0]?.columnFilterObjectStore?.main_project_information?.state?.drop_down_value;
        this.stageDropDownValue = sessionIdsAndModel[0]?.columnFilterObjectStore?.main_project_information?.stage?.drop_down_value;
      } else if (this.view.name == 'project_view') {
        this.dropDownValue = sessionIdsAndModel[0]?.columnFilterObjectStore?.project_information?.country?.drop_down_value;
        this.stateDropDownValue = sessionIdsAndModel[0]?.columnFilterObjectStore?.project_information?.state?.drop_down_value;
        this.stageDropDownValue = sessionIdsAndModel[0]?.columnFilterObjectStore?.project_information?.stage?.drop_down_value;
      }
      // console.log("aaa", this.cellData.headerCell);
      this.preservedState = { ...this?.cellData?.drop_down_value };
      this.preservedStateAsArray = [...this?.cellData?.drop_down_value];
      var countryDataValue = Object.values(this.countryData)?.find(
        (obj) => obj?.country
      );
      if (
        this.cellData.headerCell == "state_template" ||
        this.cellData.headerCell == "country_template" ||
        this.cellData.headerCell == "status_template" ||
        this.cellData.headerCell == "stage_template" ||
        this.cellData.headerCell == "method_template"
      ) {
        this.isLoading = true;
        var payload = {
          commodities: this.commodities.alias,
          view: this.view.name,
          is_commodities: this.isCommodities,
          is_display_all: this.isDisplayAll,
          filters: this.countryData,
          sorting: this.sortFilterObject
        };
        getCountryandState(payload)
          .then((response) => {
            if (this.cellData.headerCell == "state_template") {
              this.cellData.drop_down_value = response.data.result.state;
              var mainData = this.cellData;
              const filteredState = mainData.drop_down_value.filter((state) => {
                const country = countryDataValue.country?.drop_down_value.find(
                  (c) => c.country_code === state.country_code
                );
                return country && country.is_checked && state.is_checked;
              });
              this.cellData.drop_down_value = filteredState;
            }
            if (this.cellData.headerCell == "country_template") {
              this.cellData.drop_down_value = response.data.result.country;
            }
            if (this.cellData.headerCell == "status_template") {
              this.cellData.drop_down_value = response.data.result.status;
            }
            if (this.cellData.headerCell == "stage_template") {
              this.cellData.drop_down_value = response.data.result.stage;
            }
            if (this.cellData.headerCell == 'method_template') {
              this.cellData.drop_down_value = response.data.result.method;
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
          });

      }
      document.addEventListener('keyup', this.onKeyUp);
    }
  },
  beforeUnmount() {
    this.cellData.drop_down_value = this.preservedStateAsArray;
  },
  methods: {
    isFirstClick(field) {
      const columnFilterObjectStore = this.sessionModel[0]?.columnFilterObjectStore;

      let hasMainProjectInformation;
      let hasCountry;
      if (this.view.name == 'sector_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.main_project_information;
        hasCountry = hasMainProjectInformation && columnFilterObjectStore.main_project_information[field];
      } else if (this.view.name == 'project_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.project_information;
        hasCountry = hasMainProjectInformation && columnFilterObjectStore.project_information[field];
      }

      const hasFirtClick = hasCountry && hasCountry?.firstClick;
      return !!hasFirtClick
    },
    hasValueFilter() {
      const columnFilterObjectStore = this.sessionModel[0]?.columnFilterObjectStore;

      let hasMainProjectInformation;
      let hasCountry;
      if (this.view.name == 'sector_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.main_project_information;
        hasCountry = hasMainProjectInformation && columnFilterObjectStore.main_project_information.country;
      } else if (this.view.name == 'project_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.project_information;
        hasCountry = hasMainProjectInformation && columnFilterObjectStore.project_information.country;
      }

      const hasDropDownValue = hasCountry && hasCountry.drop_down_value;

      return !!hasDropDownValue && Array.isArray(hasDropDownValue) && hasDropDownValue.length > 0;
    },
    hasStateValueFilter() {
      const columnFilterObjectStore = this.sessionModel[0]?.columnFilterObjectStore;

      let hasMainProjectInformation;
      let hasState;
      if (this.view.name == 'sector_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.main_project_information;
        hasState = hasMainProjectInformation && columnFilterObjectStore.main_project_information.state;
      } else if (this.view.name == 'project_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.project_information;
        hasState = hasMainProjectInformation && columnFilterObjectStore.project_information.state;
      }


      const hasDropDownValue = hasState && hasState.drop_down_value;

      return !!hasDropDownValue && Array.isArray(hasDropDownValue) && hasDropDownValue.length > 0;
    },
    hasStageValueFilter() {
      const columnFilterObjectStore = this.sessionModel[0]?.columnFilterObjectStore;

      let hasMainProjectInformation;
      let hasStage;
      if (this.view.name == 'sector_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.main_project_information;
        hasStage = hasMainProjectInformation && columnFilterObjectStore.main_project_information.stage;
      } else if (this.view.name == 'project_view') {
        hasMainProjectInformation = columnFilterObjectStore && columnFilterObjectStore.project_information;
        hasStage = hasMainProjectInformation && columnFilterObjectStore.project_information.stage;
      }

      const hasDropDownValue = hasStage && hasStage.drop_down_value;

      return !!hasDropDownValue && Array.isArray(hasDropDownValue) && hasDropDownValue.length > 0;
    },
    onKeyUp(event) {
      if (event.key === 'Enter') {
        this.onCancel(this.index);
      }
    },
    applyEmit() {
      // if(this.hasValueFilter() || this.hasStateValueFilter()){
      if (this.cellData.headerCell == "country_template" && this.hasValueFilter()) {
        if (
          this.dropDownValue.every((obj) => obj.is_checked === false)
        ) {
          this.dropDownValue = [
            {
              id: 0,
              country_code: "Empt",
              country_name: "Empty",
              is_checked: true,
              title: "Empty",
            },
          ];
        }

        this.cellData.drop_down_value = this.dropDownValue;
        const filterData = filter_object_generator_from_cell_data(
          this.cellData,
          "drop_down_value"
        );
        // if(!this.hasStateValueFilter() && !this.hasStageValueFilter()){
        //   filterData.data[this.cellData.field].firstClick = true
        // }

        this.$emit(
          "filterEmitFunc",
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );

        this.$emit('dropDownFilterShowHide', true, this.cellData.field);

      }
      if (this.cellData.headerCell == "state_template" && this.hasStateValueFilter()) {
        if (
          this.stateDropDownValue.every((obj) => obj.is_checked === false)
        ) {
          this.stateDropDownValue = [
            {
              id: 0,
              country_code: "Empt",
              country_name: "Empty",
              is_checked: true,
              title: "Empty",
            },
          ];
        }
        this.cellData.drop_down_value = this.stateDropDownValue;
        const filterData = filter_object_generator_from_cell_data(
          this.cellData,
          "drop_down_value"
        );
        // filterData.data[this.cellData.field].firstClick = true

        this.$emit(
          "filterEmitFunc",
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );

        this.$emit('dropDownFilterShowHide', true, this.cellData.field);
      }
      if (this.cellData.headerCell == "stage_template" && this.hasStageValueFilter()) {
        if (
          this.stageDropDownValue.every((obj) => obj.is_checked === false)
        ) {
          this.stageDropDownValue = [
            {
              id: 0,
              country_code: "Empt",
              country_name: "Empty",
              is_checked: true,
              title: "Empty",
            },
          ];
        }
        this.cellData.drop_down_value = this.stageDropDownValue;
        const filterData = filter_object_generator_from_cell_data(
          this.cellData,
          "drop_down_value"
        );
        // filterData.data[this.cellData.field].firstClick = true

        this.$emit(
          "filterEmitFunc",
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );

        this.$emit('dropDownFilterShowHide', true, this.cellData.field);
      }
      // if(this.cellData.headerCell == "country_template"){
      //   this.cellData.drop_down_value = this.dropDownValue;
      // }else if(this.cellData.headerCell == "state_template"){
      //   this.cellData.drop_down_value = this.stateDropDownValue;
      // }
      // const filterData = filter_object_generator_from_cell_data(
      //   this.cellData,
      //   "drop_down_value"
      // );

      // this.$emit(
      //   "filterEmitFunc",
      //   filterData?.data,
      //   filterData?.fieldName,
      //   filterData?.parentFieldName,
      //   filterData?.subParentFieldName,
      //   filterData?.hasAnyParentField,
      //   filterData?.hasAnySubParentField
      // );

      // this.$emit('dropDownFilterShowHide',true,this.cellData.field);
      else {

        if (this.cellData.headerCell == "country_template") {
          if (
            this.cellData.drop_down_value.every((obj) => obj.is_checked === false)
          ) {
            this.cellData.drop_down_value = [
              {
                id: 0,
                country_code: "Empt",
                country_name: "Empty",
                is_checked: true,
                title: "Empty",
              },
            ];
          }
        }
        if (this.cellData.headerCell == "state_template") {
          if (
            this.cellData.drop_down_value.every((obj) => obj.is_checked === false)
          ) {
            this.cellData.drop_down_value = [
              {
                id: 0,
                state_code: "Empt",
                state_name: "Empty",
                is_checked: true,
                title: "Empty",
              },
            ];
          }
        }
        if (this.cellData.headerCell == "stage_template") {
          if (
            this.cellData.drop_down_value.every((obj) => obj.is_checked === false)
          ) {
            this.cellData.drop_down_value = [
              {
                id: 0,
                stage_code: "Empt",
                stage_name: "Empty",
                is_checked: true,
                title: "Empty",
              },
            ];
          }
        }
        const filterData = filter_object_generator_from_cell_data(
          this.cellData,
          "drop_down_value"
        );
        if (!this.hasValueFilter() && !this.hasStateValueFilter() && !this.hasStageValueFilter()) {
          filterData.data[this.cellData.field].firstClick = true
        }

        this.$emit(
          "filterEmitFunc",
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );

        this.$emit('dropDownFilterShowHide', true, this.cellData.field);
      }
    },
    handleChange(e, item) {
      item.is_checked = e.value;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.applyEmit();
      }, 100);
      // this.applyEmit();
    },
    clearList() {
      if (this.cellData.headerCell == "country_template" && this.hasValueFilter()) {
        this.dropDownValue.forEach((item) => {
          item.is_checked = false;
        });
      }
      if (this.cellData.headerCell == "state_template" && this.hasStateValueFilter()) {
        this.stateDropDownValue.forEach((item) => {
          item.is_checked = false;
        });
      }
      if (this.cellData.headerCell == "stage_template" && this.hasStageValueFilter()) {
        this.stageDropDownValue.forEach((item) => {
          item.is_checked = false;
        });
      } else {
        this.cellData.drop_down_value.forEach((item) => {
          item.is_checked = false;
        });
      }
    },
    removeItemFromFilter() {
      const newObj = { ...this.countryData }
      if (Object.keys(this.countryData[this.cellData.parent_field_name]).includes(this.cellData.field)) {
        if (this.countryData[this.cellData.parent_field_name].hasOwnProperty(this.cellData.field)) {
          delete this.countryData[this.cellData.parent_field_name][this.cellData.field]
        }
      }
    },
    onCancelClick() {
      this.cellData.drop_down_value = this.preservedStateAsArray;
      this.cellData.drop_down_value.forEach((state) => {
        state.is_checked = true;
      });
      const filterData = filter_object_generator_from_cell_data(
        this.cellData,
        "drop_down_value"
      );

      // this.updateColumnFilterObject(this.cellData.parent_field_name, this.cellData.field);
      if (this.cellData.field == 'state' || this.cellData.field == 'country' || this.cellData.field == 'stage') {
        this.$emit("updateColumnFilterObject", this.cellData.parent_field_name, this.cellData.field)
      } else {
        this.$emit(
          "filterEmitFunc",
          filterData?.data,
          filterData?.fieldName,
          filterData?.parentFieldName,
          filterData?.subParentFieldName,
          filterData?.hasAnyParentField,
          filterData?.hasAnySubParentField
        );
      }
      this.$emit('dropDownFilterShowHide', false, this.cellData.field);
      this.onCancel(this.index);
    },
  },
};
</script>
